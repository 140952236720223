import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import { TooltipProvider } from "../contexts/TooltipContext";
import useReportAccessManagement from "../hooks/useReportAccessManagement";
import { logException } from '../services/loggerFront';
import useEnsureUserData from '../hooks/useEnsureUserData';
import useAuth from '../hooks/useAuth';
import { hasPermission } from "../utils/hasPermission";
import { getPermissionLevel } from '../utils/getPermissionLevel';
import formatDate from '../utils/formatDate';
import {getReportAccessTableColumns} from '../dataInputs/reportAccessTableColumns';
// Standard page components
import Header from "../components/common/Header";
import FreeTrialBanner from '../components/common/FreeTrialBanner';
import ReportIssue from "../components/common/ReportIssue";
import TopRibbon from "../components/common/TopRibbon";
import Button from "../components/common/Button";
import FadeLoader from '../components/common/FadeLoader';
import AccessDenied from '../components/common/AccessDenied';

import SortableTable from "../components/common/SortableTable";
import ActionButton from "../components/common/ActionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../components/common/ConfirmModal";
import AdminModal from "../components/common/AdminModal"; 
import AddReportAccessForm from "../components/inputs/AddReportAccessForm";
import EditReportAccessForm from "../components/inputs/EditReportAccessForm"; 
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 
import tableStyles from "../sharedStyles/TableStyles.module.css";
import { useSortData } from "../hooks/useSortData";

const ReportAccessManagementPage = () => {
    const { companyId, userPermissions, setUserPermissions, userFullName, companyAccountType } = useAppContext(); // Get the company ID from the context
    const { companyDetails } = useTestContext(); // Get the company details from the context
    
    // on load check if user is logged in and fetch user data
    const { fetchUserData } = useEnsureUserData();
    const { checkAndHandleTokenExpiration } = useAuth();
    useEffect(() => {
        checkAndHandleTokenExpiration();
        fetchUserData();
    }, []);
    const location = useLocation(); // Get the current location
    const { emailsToAdd } = location.state || {}; // Retrieve emails from state if passed from the AddUserForm

    // PERMISSIONS STATES
    const [viewPermissionLevel, setViewPermissionLevel] = useState(null); // Permission level to view the report access
    const [canViewReportAccess, setCanViewReportAccess] = useState(null); // Permission to view the report access

    // check permissions 
    const checkPermissions = () => {
        // if permissions are not set, return
        if (!userPermissions) return;
        // check if the user has the permission to view report access
        const viewPermissionLevel = getPermissionLevel(userPermissions, 'VIEW_PERMISSIONS');
        const canViewReportAccess = hasPermission(userPermissions, 'VIEW_PERMISSIONS');
        setViewPermissionLevel(viewPermissionLevel);
        setCanViewReportAccess(canViewReportAccess);
    };

    // call the checkPermissions function when userPermissions change
    useEffect(() => {
        checkPermissions();
    }, [userPermissions]);

    const { 
        reportAccesses, 
        users,
        uniqueRoles, 
        uniqueCompanies, 
        uniqueGrantingUsers,
        testAssignments,
        companyAssignments,
        loading, 
        modalLoading, 
        error, 
        setError, 
        handleAddReportAccess, 
        handleRemoveReportAccess, 
        handleEditReportAccess 
    } = useReportAccessManagement(companyId, userPermissions, userFullName); // Custom hook to manage report access

    // State for filters and search
    const [roleFilter, setRoleFilter] = useState("All");
    const [companyFilter, setCompanyFilter] = useState("All"); // Filter for company when Global
    const [grantingUserFilter, setGrantingUserFilter] = useState("All"); // Filter for the granting user
    const [userEmailSearchQuery, setUserEmailSearchQuery] = useState("");
    const [userNameSearchQuery, setUserNameSearchQuery] = useState("");

    // State for modals
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [selectedReportAccessId, setSelectedReportAccessId] = useState(null);
    const [modalType, setModalType] = useState('');
    const [editedTest, setEditedTest] = useState('');
    const [editedCompanyId, setEditedCompanyId] = useState('');
    const [editedDateFrom, setEditedDateFrom] = useState('');
    const [editedDateTo, setEditedDateTo] = useState('');

    // Adding user states
    const [userIdsToAdd, setUserIdsToAdd] = useState([]); // New emails to add
    const [selectedTest, setSelectedTest] = useState(null); // Test of the new users to add
    const [selectedTestName, setSelectedTestName] = useState(null); // Test name of the new users to add
    const [companyToAddTo, setCompanyToAddTo] = useState(null); // Company to add the new users to
    const [selectedDateFrom, setSelectedDateFrom] = useState(null); // Date from for the new users to add
    const [selectedDateTo, setSelectedDateTo] = useState(null); // Date to for the new users to add
    const [selectedCompanyName, setSelectedCompanyName] = useState(null); // Company name of the new users to add
    const [usersToAdd, setUsersToAdd] = useState([]); // Array of users to add

    // Data tables
    const reportAccessColumns = getReportAccessTableColumns(viewPermissionLevel); // Get columns based on the current permission level

    const fileName = 'ReportAccessManagementPage.js'; // Define the filename for error logging

    // When the user comes from the AddUserForm, preselect the users and show the modal
    useEffect(() => {
        if (emailsToAdd && users.length > 0) {
            // Set the users
            const preselectedUsers = users.filter(user => emailsToAdd.includes(user.email));
            setUserIdsToAdd(preselectedUsers.map(user => user.id));
            setUsersToAdd(preselectedUsers);
            // Show the modal
            setShowAdminModal(true);
            setModalType('addReportAccess');
        }
    }, [emailsToAdd, users]);

    // Sorting hooks for report accesses
    const { items: sortedReportAccesses, requestSort: requestReportAccessSort, sortConfig: reportAccessSortConfig } = useSortData(reportAccesses, { key: 'userName', direction: 'ascending' });

    // Filter and search logic for report accesses
    const filteredReportAccesses = useMemo(() => {
        return sortedReportAccesses.filter(access => {
            const matchesRole = roleFilter === "All" || access.roleName === roleFilter;
            const matchesEmailSearch = (access.userEmail?.toLowerCase() || '').includes(userEmailSearchQuery.toLowerCase());
            const matchesNameSearch = (access.userName?.toLowerCase() || '').includes(userNameSearchQuery.toLowerCase());
            const matchesCompany = companyFilter === "All" || access.companyName === companyFilter;
            const matchesGrantingUser = grantingUserFilter === "All" || access.grantingUserName === grantingUserFilter;
            return matchesRole && matchesEmailSearch && matchesNameSearch && (viewPermissionLevel !== 'GLOBAL' || matchesCompany) && matchesGrantingUser;
        });
    }, [sortedReportAccesses, roleFilter, userEmailSearchQuery, userNameSearchQuery, companyFilter, grantingUserFilter, viewPermissionLevel]);
    
    // Handle Add Report Access
    const handleAddReportAccessClick = () => {
        setModalType('addReportAccess');
        setShowAdminModal(true);
    };

    // Handle Edit Report Access by setting the relevant states and opening the AdminModal
    const handleEditClick = (reportAccessId) => {
        const access = reportAccesses.find(access => access.id === reportAccessId);
        if (access) {
            if (access.companyTestId !== editedTest) {
                setEditedTest(access.companyTestId); // Set the edited test
            }
            if (access.dateFrom !== editedDateFrom) {
                setEditedDateFrom(access.dateFrom); // Set the edited dateFrom
            }
            if (access.dateTo !== editedDateTo) {
                setEditedDateTo(access.dateTo); // Set the edited dateTo
            }
            if (access.company.id !== editedCompanyId) {
                setEditedCompanyId(access.company.id); // Set the edited companyId
            }
        }
        setSelectedReportAccessId(reportAccessId);
        setModalType('editReportAccess');
        setShowAdminModal(true);
    };

    // Handle Remove Report Access
    const handleRemoveClick = (reportAccessId) => {
        setSelectedReportAccessId(reportAccessId);
        setShowConfirmModal(true);
    };

    // Confirm and remove the report access
    const handleConfirmRemove = () => {
        if (selectedReportAccessId) {
            handleRemoveReportAccess(selectedReportAccessId);
        }
        setShowConfirmModal(false);
        setSelectedReportAccessId(null);
    };

    // Cancel the remove action
    const handleCancelRemove = () => {
        setShowConfirmModal(false);
        setSelectedReportAccessId(null);
    };

    // Close AdminModal
    const handleCloseAdminModal = () => {
        setShowAdminModal(false);
        setSelectedReportAccessId(null);
    };

    // Update the states based on what is set in the AddReportAccessForm
    const setAddReportAccessDetails = ({ userIds, testId, testName, companyId, companyName, users, dateFrom, dateTo }) => {
        setUserIdsToAdd(userIds);
        setSelectedTest(testId);
        setSelectedTestName(testName);
        setCompanyToAddTo(companyId);
        setSelectedCompanyName(companyName); // Add companyName to state
        setUsersToAdd(users); // Add the users array to state
        setSelectedDateFrom(dateFrom);
        setSelectedDateTo(dateTo);
    };
    
    // Handle Confirm action for AdminModal (Add/Edit report access)
    const handleConfirmAdminModal = async () => {
        try {
            let success = false;
    
            if (modalType === 'addReportAccess') {
                const accessData = {
                    userIds: userIdsToAdd, // Array of user IDs
                    companyTestId: selectedTest, 
                    testName: selectedTestName, // Pass the testName
                    companyId: companyToAddTo,
                    companyName: selectedCompanyName, // Pass the companyName
                    users: usersToAdd, // Pass the users array
                    dateFrom: selectedDateFrom,
                    dateTo: selectedDateTo,
                };
                await handleAddReportAccess(accessData, selectedTestName, selectedCompanyName, usersToAdd);
                success = true;
            } else if (modalType === 'editReportAccess') {
                const accessData = {
                    companyTestId: editedTest, 
                    dateFrom: editedDateFrom,
                    dateTo: editedDateTo,
                };
                await handleEditReportAccess(selectedReportAccessId, accessData);
                success = true;
            }
    
            // If successful, close the modal and clear the form
            if (success) {
                setShowAdminModal(false);
                setSelectedReportAccessId(null);
                setUserIdsToAdd([]);
                setSelectedTest(null);
                setSelectedTestName(null);
                setCompanyToAddTo(null);
                setSelectedCompanyName(null);
                setUsersToAdd([]);
                setSelectedDateFrom(null);
                setSelectedDateTo(null);
            }
    
        } catch (error) {
            logException('Error adding/editing report access:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName: fileName,
            });
        }
    };

    // Show access denied message if the user doesn't have permission
    if (!canViewReportAccess) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title="Gerenciar acesso a relatórios" />
                    </div>
                    <div className={styles.contentContainer}>
                        <AccessDenied />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    // Show loading spinner while fetching data
    if (loading) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title="Gerenciar acesso a relatórios" />
                    </div>
                    <div className={styles.contentContainer}>
                        <FadeLoader text="Carregando dados..." />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    return (
        <TooltipProvider>
            <ReportIssue/>
            <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                <div className={styles.header}>
                    <Header />
                </div>
                {companyAccountType === 'free' && (
                    <div className={styles.banner}>
                        <FreeTrialBanner />
                    </div>
                )}
                <div className={styles.ribbon}>
                    <TopRibbon title="Gerenciar acesso a relatórios">
                        <Button
                            label="Adicionar Acesso"
                            type="proceed"
                            onClick={handleAddReportAccessClick}
                        >
                            <FontAwesomeIcon icon={faPlus} className={styles.plusIcon} /> {/* Add Plus Icon */}
                            Adicionar Acesso
                        </Button>
                    </TopRibbon>
                </div>
                {/* Confirm Modal for removing report access */}
                <ConfirmModal
                    show={showConfirmModal}
                    title="Remover Acesso ao Relatório"
                    message="Tem certeza de que deseja remover este acesso?"
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancelRemove}
                    confirmLabel="Confirmar"
                    cancelLabel="Cancelar"
                />

                {/* Admin Modal for adding/editing report access */}
                {showAdminModal && (
                    <AdminModal
                        title={modalType === 'addReportAccess' ? 'Adicionar Acesso' : 'Editar Acesso'}
                        onClose={handleCloseAdminModal}
                        onConfirm={handleConfirmAdminModal}
                        errorMessage={error}
                        isLoading={modalLoading}
                    >
                        {modalType === 'addReportAccess' && 
                            <AddReportAccessForm
                                users={users}
                                tests={testAssignments}
                                userPermissions={userPermissions}
                                setFormError={setError}
                                setAddReportAccessDetails={setAddReportAccessDetails}
                                companyDetails={companyDetails}
                                companies={companyAssignments}
                                preselectedUsers={usersToAdd}
                            />}

                        {modalType === 'editReportAccess' && 
                            <EditReportAccessForm 
                                testId={editedTest}
                                setTestId={setEditedTest}
                                companyId={editedCompanyId}
                                dateFrom={editedDateFrom}
                                setDateFrom={setEditedDateFrom}
                                dateTo={editedDateTo}
                                setDateTo={setEditedDateTo}
                                testAssignments={testAssignments}
                            />
                        }
                    </AdminModal>
                )}

                <div className={styles.contentContainer}>
                    <div className={styles.tableContainer}>
                        {/* Filters and Search for Report Access */}
                        <div className={styles.filterContainer}>
                            <div className={styles.search}>
                                <input
                                    type="text"
                                    placeholder="Pesquisar por email"
                                    value={userEmailSearchQuery}
                                    onChange={(e) => setUserEmailSearchQuery(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Pesquisar por nome"
                                    value={userNameSearchQuery}
                                    onChange={(e) => setUserNameSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className={styles.filter}>
                                {viewPermissionLevel === 'GLOBAL' && (
                                    <select value={companyFilter} onChange={(e) => setCompanyFilter(e.target.value)}>
                                        <option value="All">Todas as Empresas</option>
                                        {uniqueCompanies.map(company => (
                                            <option key={company} value={company}>{company}</option>
                                        ))}
                                    </select>
                                )}
                                <select value={roleFilter} onChange={(e) => setRoleFilter(e.target.value)}>
                                    <option value="All">Todos os Cargos</option>
                                    {uniqueRoles.map((role) => (
                                        <option key={role} value={role}>{role}</option>
                                    ))}
                                </select>
                                <select value={grantingUserFilter} onChange={(e) => setGrantingUserFilter(e.target.value)}>
                                    <option value="All">Concedido Por</option>
                                    {uniqueGrantingUsers.map((user) => (
                                        <option key={user} value={user}>{user}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <SortableTable
                            columns={reportAccessColumns}
                            data={filteredReportAccesses}
                            onSort={requestReportAccessSort}
                            sortConfig={reportAccessSortConfig}
                            renderRow={(row) => (
                                <>
                                    <td style={{ minWidth: '160px' }}>{row.userName}</td>
                                    <td style={{ minWidth: '180px' }}>{row.userEmail}</td>
                                    {viewPermissionLevel === 'GLOBAL' && <td>{row.companyName}</td>} {/* Conditionally render the companyName */}
                                    <td>{row.testName}</td>
                                    <td>{row.dateFrom ? formatDate(row.dateFrom, true) : 'Ilimitado'}</td>
                                    <td>{row.dateTo ? formatDate(row.dateTo, true) : 'Ilimitado'}</td>
                                    <td>{row.grantingUserName}</td>
                                    <td className={tableStyles.centeredColumn}>
                                        <ActionButton 
                                            icon={faTrash} 
                                            label="Excluir Acesso" 
                                            onClick={() => handleRemoveClick(row.id)}
                                            showHoverPopout={true}
                                            popoutText="Excluir Acesso"
                                            popoutPosition="left"
                                        />
                                        <ActionButton 
                                            icon={faEdit} 
                                            label="Editar Acesso" 
                                            onClick={() => handleEditClick(row.id)}
                                            showHoverPopout={true}
                                            popoutText="Editar Acesso"
                                            popoutPosition="left"
                                        />
                                    </td>
                                </>
                            )}
                            styles={tableStyles} // Use shared table styles
                        />
                    </div>
                </div>
            </div>
        </TooltipProvider>
    );
};

export default ReportAccessManagementPage;
