import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const EndTestContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
            <div className={styles.popupTextLarge}>
                Sua entrevista foi finalizada com sucesso, parabéns!
            </div>
            <div className={styles.popupText}>
                Sua opinião é extremamente valiosa para nós. Por favor, clique no link abaixo para nos fornecer seu feedback anonimamente.
            </div>
        </>
    );
};

export default EndTestContent;
