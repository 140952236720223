import React, { useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import FileExplorer from './FileExplorer';
import { languageMappings } from './LanguageMapping';
import styles from './SimpleMultiFileEditor.module.css';

const SimpleMultiFileEditor = ({ 
    files, // Object containing file structure
    defaultFile = null, // Initial file to show
    language = 'javascript', // Default language
    readOnly = false
}) => {
    const getFolderPaths = () => {
        const folders = new Set();
        Object.keys(files).forEach(path => {
            const parts = path.split('/');
            let currentPath = '';
            parts.slice(0, -1).forEach(part => {
                currentPath = currentPath ? `${currentPath}/${part}` : part;
                folders.add(currentPath);
            });
        });
        return Array.from(folders);
    };

    const getSortedPaths = () => {
        // Combine folder paths and file paths
        const allPaths = [...getFolderPaths(), ...Object.keys(files)];
        
        return allPaths.sort((a, b) => {
            // Check if paths are folders
            const aIsFolder = !a.includes('.');
            const bIsFolder = !b.includes('.');
            
            // If one is a folder and other is a file, folder comes first
            if (aIsFolder !== bIsFolder) {
                return aIsFolder ? -1 : 1;
            }
            
            // If both are the same type, sort alphabetically
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });
    };
    // Get the first file (not folder) from sorted paths
    const getFirstFile = () => {
        const sortedPaths = getSortedPaths();
        return sortedPaths.find(path => path.includes('.')) || '';
    };

    // Determine initial file
    const initialFile = defaultFile || getFirstFile();
    
    const [activeFile, setActiveFile] = useState(initialFile);
    const [editorContent, setEditorContent] = useState(
        // Get initial content from the files object based on defaultFile
        getFileContent(files, initialFile)
    );

    // Helper function to get content of a file from nested structure
    function getFileContent(files, path) {
        // Handle empty path
        if (!path) return '';
        
        // Simply return the content for the exact path
        return files[path] || '';
    }

    // Handle file selection from FileExplorer
    const handleFileSelect = (filePath) => {
        setActiveFile(filePath);
        setEditorContent(getFileContent(files, filePath));
    };

    const getLanguageFromPath = (filePath) => {
        if (!filePath) return language;
        
        const extension = filePath.substring(filePath.lastIndexOf('.'));

        const basicMappings = {
            '.json': 'json',
            '.xml': 'xml',
            '.yaml': 'yaml',
            '.yml': 'yaml',
            '.html': 'html',
            '.htm': 'html',
            '.txt': 'plaintext',
            '.md': 'markdown',
            '.markdown': 'markdown'
        };

        // Check basic mappings first
        if (basicMappings[extension]) {
            return basicMappings[extension];
        }
        
        // Look through all language mappings to find matching extension
        for (const [, langConfig] of Object.entries(languageMappings)) {
            if (langConfig.extension.includes(extension)) {
                return langConfig.monaco;
            }
        }
        
        return language; // Fallback to prop if no match found
    };

    return (
        <div className={styles.container}>
            <div className={styles.fileExplorer}>
                <FileExplorer 
                    currentCode={files}
                    activeFile={activeFile}
                    setActiveFile={handleFileSelect}
                    defaultFile={defaultFile}
                    sortedPaths={getSortedPaths()} 
                />
            </div>
            <div className={styles.editor}>
                <MonacoEditor
                    height="400px"
                    language={getLanguageFromPath(activeFile)}
                    value={editorContent}
                    theme="vs-dark"
                    options={{
                        minimap: { enabled: false },
                        scrollBeyondLastLine: false,
                        fontSize: 14,
                        padding: {
                            top: 5,
                            bottom: 5,
                            right: 5
                        },
                        readOnly: readOnly
                    }}
                />
            </div>
        </div>
    );
};

export default SimpleMultiFileEditor;