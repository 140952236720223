// Plain text version for copy/paste
export const plainTextTemplate = `Olá, tudo bem?

É com prazer que te convidamos para o desafio técnico da vaga {selectedTest.role}.

Trata-se de um desafio de programação através da plataforma Degrau, com duração estimada de {selectedTest.time} minutos.

Ao clicar no link você receberá todas as instruções para resolução da situação problema, será apresentado um enunciado com requisitos para um desafio que reflete o dia a dia da vaga; esperamos que você implemente um código que atenda aos requisitos.

{generatedLink}

Dicas sobre o processo:
- Durante o exercício você contará com a interação com a IA para tirar dúvidas de programação e sobre o desafio;
- Além da funcionalidade do código, avaliamos se a solução apresenta características de código limpo, boas práticas;
- Não se preocupe se ficar sem tempo para terminar o código, pois você terá a chance de explicar o que faria se tivesse mais tempo.
- Após submeter o código, você responderá algumas perguntas sobre arquitetura de software.

Boa sorte!`;

// HTML version for sending directly
export const htmlTemplate = `
<div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px;">
  <div style="text-align: center; margin-bottom: 20px;">
    <img src="https://degrau.blob.core.windows.net/companylogos/degrau-transparent.png" alt="Degrau Logo" style="height: 40px; width: auto;">
    ${'{partnerLogo}' !== '' ? `<img src="{partnerLogo}" alt="Partner Logo" style="height: 40px; width: auto; margin-left: 20px;">` : ''}
  </div>
  
  <p>Olá, tudo bem?</p>

  <p>É com prazer que te convidamos para o desafio técnico da vaga <strong>{selectedTest.role}</strong>.</p>

  <p>Trata-se de um desafio de programação através da plataforma Degrau, com duração estimada de <strong>{selectedTest.time} minutos</strong>.</p>

  <p>Ao clicar no link você receberá todas as instruções para resolução da situação problema, será apresentado um enunciado com requisitos para um desafio que reflete o dia a dia da vaga; esperamos que você implemente um código que atenda aos requisitos.</p>

  <p style="text-align: center; margin: 30px 0;">
    <a href="{generatedLink}" style="display: inline-block; padding: 10px 20px; background-color: #24624e; color: #fff !important; text-decoration: none; border-radius: 5px; border: none;">
      Iniciar Desafio Técnico
    </a>
  </p>

  <p style="color: #666; font-size: 0.9em;">Se o botão não funcionar, copie e cole este link no seu navegador: <br>
    <span style="color: #888; word-break: break-all;">{generatedLink}</span>
  </p>

  <div style="background-color: #f5f5f5; padding: 20px; border-radius: 5px; margin: 20px 0;">
    <p style="font-weight: bold; margin-bottom: 10px;">Dicas sobre o processo:</p>
    <ul style="margin: 0; padding-left: 20px;">
      <li>Durante o exercício você contará com a interação com a IA para tirar dúvidas de programação e sobre o desafio;</li>
      <li>Além da funcionalidade do código, avaliamos se a solução apresenta características de código limpo, boas práticas;</li>
      <li>Não se preocupe se ficar sem tempo para terminar o código, pois você terá a chance de explicar o que faria se tivesse mais tempo;</li>
      <li>Após submeter o código, você responderá algumas perguntas sobre arquitetura de software.</li>
    </ul>
  </div>

  <p style="font-weight: bold;">Boa sorte!</p>
</div>
`;

// Helper function to convert HTML to plain text (for copy/paste)
export const convertToPlainText = (html) => {
  return html
    .replace(/<div[^>]*>/g, '')
    .replace(/<\/div>/g, '\n')
    .replace(/<p[^>]*>/g, '')
    .replace(/<\/p>/g, '\n')
    .replace(/<br\s*\/?>/g, '\n')
    .replace(/<ul[^>]*>/g, '')
    .replace(/<\/ul>/g, '\n')
    .replace(/<li[^>]*>/g, '- ')
    .replace(/<\/li>/g, '\n')
    .replace(/<[^>]+>/g, '')
    .replace(/\n\s*\n/g, '\n\n')
    .trim();
};