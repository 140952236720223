import React, { useState, useEffect, useRef } from 'react';
import AudioMessage from './AudioMessage';
import { audioPlayer } from '../../services/audioPlayerService';
import styles from './AudioConversationBox.module.css';

const AudioConversationBox = ({ messages = [] }) => {
    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        return () => {
            audioPlayer.stop();
        };
    }, []);

    useEffect(() => {
        // Global subscription to track currently playing message
        console.log("subscribing to audio events globally");
        const unsubscribe = audioPlayer.subscribe(null, {
            onPlay: (messageId) => {
                console.log('Audio playback started for message:', messageId);
                setCurrentlyPlaying(messageId);
            },
            onPause: () => setCurrentlyPlaying(null),
            onEnded: () => {
                console.log('Audio playback ended');
                setCurrentlyPlaying(null);
            },
            onError: () => setCurrentlyPlaying(null),
            onProgress: scrollToBottom
        });

        return () => unsubscribe();
    }, []);

    const handlePlay = (messageId) => {
        setCurrentlyPlaying(messageId);
    };

    const handlePause = () => {
        setCurrentlyPlaying(null);
    };

    // Group messages by question number
    const renderMessages = () => {
        let currentQuestionNumber = null;

        return messages.map((message, index) => {
            const elements = [];

            // Add question separator if this is a new question
            if (message.questionNumber !== currentQuestionNumber) {
                currentQuestionNumber = message.questionNumber;
                elements.push(
                    <div key={`separator-${message.questionNumber}`} className={styles.questionSeparator}>
                        <div className={styles.separatorLine} />
                        <span className={styles.questionNumber}>
                            Pergunta {message.questionNumber}/{message.totalQuestions}
                        </span>
                    </div>
                );
            }

            // Add the message
            elements.push(
                <AudioMessage
                    key={message.id}
                    message={message}
                    isPlaying={currentlyPlaying === message.id}
                    onPlay={() => handlePlay(message.id)}
                    onPause={handlePause}
                    scrollToBottom={scrollToBottom}
                />
            );

            return elements;
        });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // Re-run when messages change

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className={styles.conversationBox}>
            {renderMessages()}
            <div ref={messagesEndRef} />
        </div>
    );
};

export default AudioConversationBox;