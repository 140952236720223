import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'; // For styling the instructions
import { useAppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';
import Button from '../common/Button';
import AudioCheck from '../voice/AudioCheck';
import useRealtimeVoiceChat from '../../hooks/useRealtimeVoiceChat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { BounceLoader, BeatLoader, PulseLoader, DotLoader } from 'react-spinners';
import styles from './RolePlayInterface.module.css';

const RolePlayInterface = () => {
    const { webSocketUrl } = useAppContext();
    // Get the case instructions
    const { caseInstructions, currentSection } = useTestContext();
    const sectionName = currentSection.sectionName;
    const systemPrompt = caseInstructions[sectionName].systemPrompt;
    const candidateSummary = caseInstructions[sectionName].candidateSummary;
    const characterName = caseInstructions[sectionName].characterName;
    const characterImageName = caseInstructions[sectionName].image;
    let characterImage;
    try {
        characterImage = characterImageName ? require(`../../assets/images/${characterImageName}`) : null;
    } catch (error) {
        characterImage = null; // or a default image path
    }
    const { 
        backendWsReady, 
        aiWsReady,
        isStreaming, 
        userSpeaking, 
        aiSpeaking, 
        isInCall,
        aiAudioAmplitude,
        userAudioAmplitude,
        processingUserSpeech,
        isDialing,
        visualizerData,
        transcript, 
        startStreaming, 
        stopStreaming 
    } = useRealtimeVoiceChat(webSocketUrl, systemPrompt);
    const [audioCheckComplete, setAudioCheckComplete] = useState(false);

    useEffect(() => {
        console.log('isStreaming', isStreaming);
        console.log('isDialing', isDialing);
    }, [isStreaming, isDialing]);
    
    const handleAudioCheckComplete = () => {
        setAudioCheckComplete(true);
    };

    const handleToggleStreaming = async () => {
        if (isStreaming || isDialing) {
            console.log('Stopping streaming');
            await stopStreaming();
        } else {
            await startStreaming();
        }
    };

    const handleToggleMute = async () => {
        if (isMuted) {
            await unmute();
        } else {
            await mute();
        }
    };

    const renderStatusIndicator = () => {
        const color = "#246e24";
        if (userSpeaking) {
            return (
                <div className={styles.statusIndicator}>
                    <p>Ouvindo...</p>
                </div>
            );
        } else if (aiSpeaking) {
            return (
                <div className={styles.statusIndicator}>
                    <p>Fale para interromper</p>
                </div>
            );
        } else if (isStreaming) {
            return (
                <div className={styles.statusIndicator}>
                    <p>Ouvindo...</p>
                </div>
            );
        } else if (isDialing) {
            return (
                <div className={styles.statusIndicator}>
                    <PulseLoader color={color} size={40} />
                </div>
            );
        } else if (aiWsReady) {
            return (
                <div className={styles.statusIndicator}>
                    {characterName}
                </div>
            );
        } else {
            return (
                <div className={styles.statusIndicator}>
                    <p>Desconectado</p>
                </div>
            );
        }
    };

    const renderTranscript = () => {
        if (!transcript || transcript.length === 0) {
            return <div className={styles.emptyTranscript}>Não há mensagens ainda.</div>;
        }
        
        return transcript.map((entry, index) => (
            <div key={index} className={styles[entry.speaker.toLowerCase()]}>
                <strong>{entry.speaker}:</strong> {entry.message}
            </div>
        ));
    };

    if (!audioCheckComplete) {
        return <AudioCheck onTestComplete={handleAudioCheckComplete} />;
    }

    return (
        <div className={styles.rolePlayInterface}>
            <div className={styles.characterContainer}>
                <div className={styles.instructionsContainer}>
                    <div className={styles.subTitle}>Instruções</div>
                    <div className={styles.instructions}>
                        <ReactMarkdown>
                            {candidateSummary}
                        </ReactMarkdown>
                    </div>
                </div>
                <div className={styles.characterImageContainer}>
                <img 
                    src={characterImage} 
                    alt={characterName} 
                    className={`${styles.characterImage} ${(aiSpeaking || processingUserSpeech) ? styles.aiSpeakingWithAmplitude : ''}`}
                    style={{
                        '--amplitude-blur': aiSpeaking ? `${5 + (aiAudioAmplitude * 15)}px` : '0px',
                        '--amplitude-spread': aiSpeaking ? `${5 + (aiAudioAmplitude * 10)}px` : '0px',
                        '--glow-color': '#246e24'
                    }}
                />
                </div>
                {renderStatusIndicator()}
                <div 
                    className={`${styles.controls} ${(isInCall && !isDialing) && (userSpeaking || (!processingUserSpeech && !aiSpeaking)) ? styles.userSpeakingWithAmplitude : ''}`}
                    style={{
                        '--amplitude-blur': userSpeaking ? `${5 + (userAudioAmplitude * 15)}px` : '0px',
                        '--amplitude-spread': userSpeaking ? `${5 + (userAudioAmplitude * 10)}px` : '0px',
                        '--glow-color': '#246e24'
                    }}
                >
                <div className={styles.voiceButtonAndText}>
                    <button 
                            className={`${styles.voiceButton} ${isStreaming || isDialing ? styles.red : styles.green}`}
                            onClick={handleToggleStreaming} 
                            disabled={!aiWsReady}
                        >
                            {(isStreaming || isDialing) ? (
                                <>
                                    <FontAwesomeIcon icon={faPhoneSlash} />  
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faPhone} /> 
                                </>
                            )}
                        </button>
                        <div className={styles.buttonText}>{isStreaming || isDialing ? 'Encerrar Chamada' : 'Iniciar Chamada'}</div>
                    </div>
                    {(isStreaming || isDialing) && (
                        <div className={styles.voiceButtonAndText}>
                            <button className={styles.voiceButton}>
                                <FontAwesomeIcon icon={faMicrophone} />
                            </button>
                            <div className={styles.buttonText}>Silenciar</div>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className={styles.transcriptContainer}>
                <div className={styles.transcriptTitle}>Transcrição</div>
                <div className={styles.transcript}>
                    {renderTranscript()}
                </div>
            </div> */}
        </div>
    );
};

export default RolePlayInterface;