// RETRIEVE SESSION //

// TODO: Status to sections
import { useState, useEffect, useRef } from 'react';
import { fetchTestAttemptData, fetchCodeResponseData, fetchRetrievalConfig, fetchCandidateDetailsById, checkValidCompanyAndRole } from '../services/databaseService';
import { getTestIdFromCompanyTestId } from '../services/candidateTestService';
import { useAppContext } from '../contexts/AppContext';
import { logTrace, logException } from '../services/loggerFront';
import { languageMappings } from '../components/coding/LanguageMapping';

const useTestRetrieval = () => {
    const { setIsAdmin, fetchBaseUrls, setF12Count, setSwitchScreenCount } = useAppContext();
    const [retrievedSessionId, setRetrievedSessionId] = useState(false); // The id of the retrieved session to save with the record of the new session
    const [retrievedMessages, setRetrievedMessages] = useState([]); // Update the chatbox with the messages from the testAttempt
    const [retrievedInstructionIndex, setRetrievedInstructionIndex] = useState('0'); // For useChatInstructions to start at the right instruction
    const [retrievedCode, setRetrievedCode] = useState(); // Update the Editor with the code from the testAttempt
    const [retrievedGptCodeEvaluation, setRetrievedGptCodeEvaluation] = useState(); // So gpt can use in prompts
    const [retrievedCodeSubmitted, setRetrievedCodeSubmitted] = useState(false); // To inform the BackendInterface on what UI elements to render for the restored session
    const [retrievedCodeActivities, setRetrievedCodeActivities] = useState(); // Use the base for the retrieved session code_transcript
    const [retrievedSqlLogs, setRetrievedSqlLogs] = useState([]); // Set the sql logs for the retrieved session 
    const [retrievedTimeLeft, setRetrievedTimeLeft] = useState(null); // Set the time left for the retrieved session
    const [retrievedSessionSection, setRetrievedSessionSection] = useState(); // Set the section for the retrieved session
    const [languageToRestore, setLanguageToRestore] = useState(null); // Set the language in the IDE to the last language used by the candidate

    const fileName = 'useTestRetrieval.js'; // Used for logging

    // Entry function to go through all the steps of the test retrieval process
    const restoreTestAttemptFromURL = async (setRetrievedSession, setTestStage, setCompanyDetails, setRoleDetails, setCandidateId, setCandidateUsername, setCandidateEmail, setTimeNotActive, setFullScreenExited, setTimeTaken, setKeyFactsLog, page, setAccessibilityMode, setAccessibilityDetails, setCompanyTestId, setTestId) => {

        // if it is not the interview, return
        if (page !== '/entrevista') {
            return;
        }

        // 1. Look at the url parameters to see if tentativa if there
        const urlParams = new URLSearchParams(window.location.search);
        const linkCode = urlParams.get('tentativa');

        logTrace('restoring test attempt from URL', {
            linkCode,
            fileName: fileName,
        });

        // If there is no linkCode in the URL parameters, exit the function
        if (!linkCode) {
            return; // Exit if there is not code in the unique url to retrieve a test
        }

        setRetrievedSession(true) // Go to retrieved session mode, which impacts things like useChatInstructons hook and the IDE

        // 2. Get the configurations of the retrieval such as the test attempt id and any overlays
        const retrievalConfig = await getRetrievalConfig(linkCode, setTestStage)
        // If this returns nothing then exit the function, getRetrievalConfig will trigger the fail state
        if (!retrievalConfig) {
            return;
        }
        logTrace(`Success: restoreTestAttemptFromURL retrieved config for ${linkCode}`, {
            linkCode,
            type: 'TestRetrieval',
        });
        // Get the overlay timestamp if it exists
        let overlayTimestamp = null
        if (retrievalConfig.retrieve_to_timestamp_unix != null) {
            overlayTimestamp = new Date(retrievalConfig.retrieve_to_timestamp_unix);
            logTrace(`retrieving to timestamp: ${overlayTimestamp}`, {
                overlayTimestamp,
                fileName: fileName,
            });
        }
        
        // 3. Set the testAttemptId and get the data
        const testAttemptId = retrievalConfig.test_attempt_id;
        setRetrievedSessionId(testAttemptId); // Set it in this state so it can be saved in the database when the user moves to test stage

        if (testAttemptId) {
            // Await the fetching of test data
            const testData = await getTestDataForRetrieval(testAttemptId, setTestStage);
            // If this returns nothing then exit the function, getTestDataForRetrieval will trigger the fail state
            if (!testData) {
                return; // Exit if no test data
            }

            logTrace(`Retrieved test data for ${testAttemptId}`, {
                testAttemptId,
                testData,
                fileName: fileName,
            });

            // 4. Update test details to the global states
            const companyCode = testData.company_code;
            const roleCode = testData.role_code;
            const candidateId = testData.candidate_id;
            const companyTestId = testData.company_test_id;
            setCompanyTestId(companyTestId);
            setRetrievedTestId(companyTestId, setTestId);

            setCandidateId(candidateId); // Set the candidateId in the global state

            // 5. Get the associated candidate details
            const candidateDetails = await fetchCandidateDetailsById(candidateId) // Fetch the candidate details from the database
            const username = candidateDetails.username;
            const email = candidateDetails.email;
            const adminStatus = candidateDetails.admin;

            setCandidateEmail(email);
            setCandidateUsername(username);
            setIsAdmin(adminStatus); // Update state in AppContext

            // 6. Get the associated company and role data
            const companyAndRoleData = await checkValidCompanyAndRole(companyCode, roleCode); // Fetch the company and role data from the database
            const { isValid, company, role } = companyAndRoleData.isValid;

            setCompanyDetails(company); // This will trigger TestContext to load the config, set the sections and load the instructions
            setRoleDetails(role);

            fetchBaseUrls(); // Get the url to connect to the back (differs by staging, local, prod), this also opens the connection websocket

            // 7. Set the chat messages, either back to the last user message and carry on from there or use the TIMESTAMP overlay 
            const { chatMessages, lastUserMessageIndex } = await filterAndSetChatMessages(testData, overlayTimestamp);
            logTrace(`retrieving chatMessages`, {
                chatMessages,
                lastUserMessageIndex,
                fileName: fileName,
            });
            setRetrievedMessages(chatMessages); // Set the messages as retrievedMessages so it can be accessed when the test is restarted
            logTrace(`Success: restoreTestAttemptFromURL retrieved messages for ${testAttemptId}`, {
                testAttemptId,
                type: 'TestRetrieval',
                fileName: fileName,
                chatMessages,
            });

            // 8. Then set the retrievedIndex for the chatbox to know which instruction to start from
            const retrievedIndex = identifyRetrievedIndex(chatMessages, lastUserMessageIndex);
            setRetrievedInstructionIndex(retrievedIndex); // Used by useChatInstructions hook to know where to restart from

            // 10. Set the status to decide what ui elements are needed and how to gather the code (TODO: NEED TO UPDATE TO SECTIONS)
            const testStatus = await determineTestStatus(retrievalConfig, chatMessages, testData);
            logTrace('restoring with status:', testStatus);

            // 11. Set the code facts depending on the status (TODO: NEED TO UPDATE TO SECTIONS)
            let codeResponseData = null;

            // Depending on the state, get the coding data either from the coderesponse db or the chatmessages (TODO: NEED TO CHANGE TO SECTIONS)
            switch (testStatus) {
                // If status is coding challenge, get data from the coderesponsedb and set the coding facts in codingChallengeRestoration. Use the timestamp if it exists
                case 'codingChallenge':
                    codeResponseData = await codingChallengeRestoration(testAttemptId, overlayTimestamp, setTestStage);
                    if (!codeResponseData) {
                        return; // Exit as critical to restoration
                    }
                    break;
                case 'complete':
                    setTestStage('retrievedTestComplete');
                    return; 
                default:
                    setRetrievedCodeSubmitted(true); // Indicate to UI (backend interface) that coding is complete and therefore we don't need the IDE
                    codingDataFromMessages(chatMessages); // If the user has passed the codingChallenge get the code from the messages as it may be used for prompts
                    break;
            }

            // 12. Set key facts such as anti-fraud and time left either using the last values in the testattempt entry or using the timestamp overlay
            setRetrievedFacts(testData, overlayTimestamp, retrievalConfig, setTimeNotActive, setTimeTaken, setFullScreenExited, setKeyFactsLog, setAccessibilityMode, setAccessibilityDetails);

        } else {
            // If there is no associated testAttemptId
            setTestStage('loadFailed')
            logException(`Error: no testAttemptId linked to the retrieval link`, { 
                status: 'Failed',
                type: 'TestRetrieval',
                fileName: fileName,
            });
        }
    };

    // Support functions for session retrieval //

    // Get the retrieval configurations from the unique url code such as the testattempt and any overlays such as time, instruction index or role.code
    const getRetrievalConfig = async (linkCode, setTestStage) => {
        try {
            const retrievalConfig = await fetchRetrievalConfig(linkCode); // Fetch the retrieval config from the database
            logTrace(`Success: retrieved test config for ${linkCode}`, { 
                linkCode,
                type: 'TestRetrieval',
            });
            return retrievalConfig;
        } catch (error) {
            logException(`Error: Fetching retrieval config for: ${linkCode}`, { 
                errorMessage: error.message,
                errorStack: error.stack, 
                linkCode,
                status: 'Failed',
                type: 'TestRetrieval',
            });
            setTestStage('retrievalFailed')
        }
    };

    // Get the testattempt record for the retrieved session
    const getTestDataForRetrieval = async (testAttemptId, setTestStage) => {
        try {
            const testData = await fetchTestAttemptData(testAttemptId); // Fetch the testAttempt data from the database
            logTrace(`Success: restoreTestAttemptFromURL retrieved data for ${testAttemptId}`, { 
                testAttemptId,
                type: 'TestRetrieval',
                fileName: fileName,
            });
            return testData;
        } catch (error) {
            logException(`Error: Fetching testAttempt data for id: ${testAttemptId}`, { 
                errorMessage: error.message,
                errorStack: error.stack, 
                testAttemptId,
                status: 'Failed',
                type: 'TestRetrieval',
                fileName: fileName,
            });
            setTestStage('loadFailed')
        }
    };

    // Process the chatMessages either to the last user message or using the overlay from the retrieval config
    async function filterAndSetChatMessages(testData, overlayedTimestamp) {
        logTrace(`Filtering and setting chat messages for ${testData.test_attempt_id}`, {
            testData,
            overlayedTimestamp,
            fileName: fileName,
        });
        let chatMessages = testData.test_chat_transcript; // Get the retrievd chat messages from the testAttempt data

        // Adjust and filter chat messages
        if (overlayedTimestamp !== null) { // If there is an overlay timestamp
            // Ensure overlayedTimestamp is treated as a UTC timestamp for comparison and convert to milliseconds
            overlayedTimestamp = new Date(overlayedTimestamp).getTime();
            chatMessages = chatMessages.filter(message => {
                // Treat message timestamps as UTC by appending 'Z' and convert to milliseconds
                const messageTimestampUTC = new Date(message.timestamp + 'Z').getTime();
                return messageTimestampUTC <= overlayedTimestamp;
            });
        }

        const reverseIndex = chatMessages.slice().reverse().findIndex(msg => msg.type === 'user'); // Find the last user message
        const lastUserMessageIndex = chatMessages.length - 1 - reverseIndex; // Calculate the index of the last user message

        if (reverseIndex === -1) { // If no user messages are found
            return { chatMessages: [], lastUserMessageIndex: -1 };
        } else { // If user messages are found
            chatMessages = chatMessages.slice(0, lastUserMessageIndex + 1); // Slice the chatMessages to the last user message
            return { chatMessages, lastUserMessageIndex };
        }
    }

    // Determine the index where the chatbox should start from based on the processed messages
    function identifyRetrievedIndex(chatMessages, lastUserMessageIndex) {
        if (lastUserMessageIndex === -1) { // If no user messages are found
            return 0; // Default value when no user messages are found
        }

        let retrievedIndex = chatMessages[lastUserMessageIndex].instructionsId; // Get the instructionsId of the last user message

        const lastSystemMessageIndex = chatMessages.slice(0, lastUserMessageIndex).reverse().findIndex(msg => msg.type === 'system'); // Find the last system message
        const lastSystemMessage = lastSystemMessageIndex !== -1 ? chatMessages[lastUserMessageIndex - 1 - lastSystemMessageIndex] : null; // Get the last system message

        if (chatMessages[lastUserMessageIndex].isCodeSubmission) { // If the last message was a code submission
            retrievedIndex = 3; // Set the retrieved index to the code submission instruction
        } else if (lastSystemMessage && lastSystemMessage.instructionType === 'gptConversation') { // If the last system message was a GPT conversation
            retrievedIndex -= 1; // Set the retrieved index to the GPT conversation instruction
        }

        return retrievedIndex;
    }

    // Set the status of the retrieved test to trigger what UI elements are needed e.g. the IDE for coding
    async function determineTestStatus(retrievalConfig, chatMessages, testData) {
        if (chatMessages.length === 0) { // If there are no chat messages
            return 'codingChallenge'; // Return 'codingChallenge' status
        }
        
        const lastMessage = chatMessages[chatMessages.length - 1]; // check if the last message was a code submission

        if (lastMessage.isCodeSubmission === true) { // If the last message was a code submission
            return 'followUp'; // Return 'followUp' status
        }

        // If not, determine the status based on the section of the last message
        const lastMessageSection = lastMessage.section;
        
        // Return 'codingChallenge' if the last message section is 'codingChallenge', else return the status from testData
        return lastMessageSection === 'codingChallenge' ? 'codingChallenge' : 'followUp';
    }

    // Restore code data from code response db if retrieved test status is codingChallenge, using the timestamp if it exists
    async function codingChallengeRestoration(testAttemptId, overlayTimestamp, setTestStage) {
        try {
            const codeResponseData = await fetchCodeResponseDataWithRetry(testAttemptId); // Fetch the code response data from the database
            if (codeResponseData && codeResponseData.length > 0) { // If code response data is found
                const codeResponseFirstEntry = codeResponseData[0]; // Get the first code response entry (in case there was more than 1)
                const codeTranscript = codeResponseFirstEntry.code_transcript; // Get the code transcript from the first entry
                const sqlLogs = codeResponseFirstEntry.sql_logs; // Get the sql logs from the first entry

                let newSessionTranscript = codeTranscript; // Default to using the full code transcript
                let finalSessionSqlLogs = sqlLogs; // Default to using the full sql logs
                let finalCodeToRestore = codeResponseFirstEntry.final_code; // Default to using the final code
                let finalLanguageToRestore = codeResponseFirstEntry.code_language; // Default to using the final code

                if (overlayTimestamp !== null) { // If there is an overlay timestamp
                    const overlayTimestampMillis = new Date(overlayTimestamp).getTime(); // Convert overlayTimestamp to UTC milliseconds for comparison

                    // Filter the transcript based on UTC timestamp conversion
                    const filteredCodeTranscript = codeTranscript.filter(entry =>
                        entry && (new Date(entry.timestamp + 'Z').getTime() <= overlayTimestampMillis)
                    );
                    newSessionTranscript = filteredCodeTranscript // Update the code transcript to the filtered transcript
                    // Filter for 'currentCode' entries
                    const relevantCodeEntries = filteredCodeTranscript.filter(entry =>
                        entry && entry.type === 'currentCode' 
                    );

                    // Use the code from the last relevant 'currentCode' entry, if available, to set the code to restore
                    if (relevantCodeEntries.length > 0) {
                        const lastRelevantEntry = relevantCodeEntries[relevantCodeEntries.length - 1]; // Get the last relevant entry
                        finalSessionSqlLogs = lastRelevantEntry.detail.sqlLogs; // Update the final sql logs to restore
                        finalCodeToRestore = lastRelevantEntry.detail.currentCode; // Update the final code to restore
                        finalLanguageToRestore = lastRelevantEntry.detail.currentLanguage; // Update the final language to restore
                    }
                }
                setRetrievedCodeActivities(newSessionTranscript); // Set the code activities for the retrieved session to continue the keystroke script
                setRetrievedSqlLogs(finalSessionSqlLogs); // Set the sql logs for the retrieved session
                setLanguageToRestore(finalLanguageToRestore, languageMappings) // Update the language in the IDE
                setRetrievedCode(finalCodeToRestore); // Set the final code to restore in the IDE
                logTrace('final code to restore', {
                    finalCodeToRestore,
                    fileName: fileName,
                });
                return codeResponseData;
            } else {
                throw new Error('No code response data found');
            }
        } catch (error) {
            setTestStage('loadFailed')
            logException(`Error fetching codeResponse data for testAttempt id: ${testAttemptId}`, {
                errorMessage: error.message,
                errorStack: error.stack,
                testAttemptId,
                status: 'Failed',
                type: 'TestRetrieval',
                fileName: fileName,
            });
            return null; // Return null to indicate failure in data fetching
        }
    }

    // Fetch codeResponse data when retrieving a session which is in the coding challenge status
    const fetchCodeResponseDataWithRetry = async (testAttemptId) => {
        try {
            // First attempt to fetch code response data
            const codeResponseData = await fetchCodeResponseData(testAttemptId);
            return codeResponseData; // Return the data if successful
        } catch (error) {
            logTrace(`Error: Initial fetch of code response data failed, retrying for id: ${testAttemptId}`, { 
                errorMessage: error.message,
                errorStack: error.stack, 
                testAttemptId,
                status: 'Failed',
                type: 'TestRetrieval',
                fileName: fileName,
            });
            // Wait for 5 seconds before retrying
            await new Promise(resolve => setTimeout(resolve, 5000));

            // Second attempt to fetch code response data
            const codeResponseData = await fetchCodeResponseData(testAttemptId);
            return codeResponseData; // This will either return the data or throw an error
        }
    }; 

    // Get the final coding data from the messages array if the user has passed the codingChallenge
    function codingDataFromMessages(chatMessages) {
        const codeSubmissionMessage = chatMessages.find(msg => msg.isCodeSubmission === true); // Find code submission message

        if (codeSubmissionMessage) { // If code submission message is found
            // Find and set the code
            const retrievedCode = codeSubmissionMessage.content || 'missing'; // Check for content; if missing, set as 'missing'
            setRetrievedCode(retrievedCode); // Set retrieved code from code submission message

            // Find and set the GPT code evaluation response
            const gptCodeEvaluation = codeSubmissionMessage.gptCodeEvaluationResponse || 'missing'; // Check for GPT code evaluation response; if missing, set as 'missing'
            setRetrievedGptCodeEvaluation(gptCodeEvaluation); // Set GPT response to be used in Chatbox prompts
        } else { // If code submission message is not found, set both the 'missing'
            setRetrievedCode('missing');
            setRetrievedGptCodeEvaluation('missing');
        }
    } 

    // Set the section and the anti-fraud facts for the retrieved session
    function setRetrievedFacts(testData, overlayTimestamp, retrievalConfig, setTimeNotActive, setTimeTaken, setFullScreenExited, setKeyFactsLog, setAccessibilityMode, setAccessibilityDetails) {
        // Initialize base values from testData
        let timeLeft = testData.time_left * 60; // Default time left, converted to seconds
        let baseLog = typeof testData.key_facts_log === 'string' 
            ? JSON.parse(testData.key_facts_log) 
            : testData.key_facts_log; // Parse if string, otherwise use as is
        let section = testData.current_section; // Use the current section by default

        // Convert overlayTimestamp to UTC milliseconds for comparison
        const overlayTimestampMillis = overlayTimestamp ? new Date(overlayTimestamp).getTime() : null;

        if (overlayTimestampMillis) {
            // Filter the log to the timestamp and set this as the base
            baseLog = baseLog.filter(log => {
                const logTimestampUTC = new Date(log.timestamp + 'Z').getTime();
                return logTimestampUTC <= overlayTimestampMillis;
            });

            const relevantLog = baseLog.slice().reverse().find(log => {
                const logTimestampUTC = new Date(log.timestamp + 'Z').getTime();
                return logTimestampUTC <= overlayTimestampMillis;
            });

            // Update values based on the most recent log entry before the overlay timestamp
            if (relevantLog) {
                setF12Count(relevantLog?.facts.F12Count ?? testData.f12_count);
                setSwitchScreenCount(relevantLog?.facts.SwitchScreenCount ?? testData.switch_screen_count);
                setTimeNotActive((relevantLog?.facts.TimeNotActive ?? testData.time_not_active) * 60); // Convert to seconds if necessary
                setFullScreenExited(relevantLog?.facts.FullScreenExited ?? testData.full_screen_exited);
                setTimeTaken(relevantLog?.facts.timeTaken ?? testData.time_taken);
                setRetrievedSessionSection(relevantLog?.facts.currentSection ?? section); // Update the section if it's specified in the log
                // Update timeLeft if it's specified in the log
                if (relevantLog?.facts.TimeLeft !== undefined) {
                    timeLeft = relevantLog.facts.TimeLeft * 60; // Convert minutes to seconds
                }
            }
        } else {
            // If there is no overlay timestamp, use the latest values in testData
            setF12Count(testData.f12_count);
            setSwitchScreenCount(testData.switch_screen_count);
            setTimeNotActive(testData.time_not_active * 60); // Assume testData provides this in minutes, convert to seconds
            setFullScreenExited(testData.full_screen_exited);
            setTimeTaken(testData.time_taken);
            setRetrievedSessionSection(section); // Set the section for the retrieved session from the testData
        }

        // Override timeLeft with retrievalConfig.time_left if it's explicitly set
        if (retrievalConfig && retrievalConfig.time_left !== null) {
            timeLeft = retrievalConfig.time_left * 60; // Convert minutes to seconds
        }

        setRetrievedTimeLeft(timeLeft); // Set the time left for the retrieved session

        // Initialize the KeyFactsLog with the base log or the filtered log up to the overlay timestamp
        setKeyFactsLog(baseLog);

        // Set the accessibility mode and details from the test data
        setAccessibilityMode(testData.accessibility_mode);
        setAccessibilityDetails(testData.accessibility_details);
    }

    // Get and set the testId from the retrieved session using the companyTestId
    async function setRetrievedTestId(companyTestId, setTestId) {
        // API call to get test id using companyTestId
        const result = await getTestIdFromCompanyTestId(companyTestId);
        const testId = result.test_id;
        logTrace('retrieved testId', { testId, fileName });
        setTestId(testId);
    }

    return {
        restoreTestAttemptFromURL,
        retrievedSessionId,
        retrievedMessages,
        retrievedInstructionIndex,
        retrievedCode,
        retrievedGptCodeEvaluation,
        retrievedCodeSubmitted,
        retrievedCodeActivities,
        retrievedSqlLogs,
        retrievedTimeLeft,
        retrievedSessionSection,
        languageToRestore,
    };
};

export default useTestRetrieval;