import React, { useState, useEffect, useRef } from 'react';
import { audioPlayer } from '../../services/audioPlayerService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import styles from './AudioMessage.module.css';

const AudioMessage = ({ message, isPlaying, onPlay, onPause, scrollToBottom }) => {
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(message.duration || 0);
    const [isDragging, setIsDragging] = useState(false);
    const [displayedText, setDisplayedText] = useState('');
    const progressBarRef = useRef(null);
    const typingIntervalRef = useRef(null);

    useEffect(() => {
        const callbacks = {
            onPlay: () => {
                onPlay();
                startTyping();
            },
            onPause: () => {
                onPause();
                stopTyping();
            },
            onProgress: (p) => {
                if (!isDragging) {
                    setProgress(p);
                    updateTypedText(p);
                }
            },
            onDuration: (d) => !message.duration && setDuration(d),
            onEnded: () => {
                setProgress(0);
                setDisplayedText(message.content || '');
                onPause();
            },
            onError: () => {
                onPause();
                stopTyping();
            },
        };

        const unsubscribe = audioPlayer.subscribe(message.id, callbacks);
        return () => {
            unsubscribe();
            stopTyping();
        };
    }, [message.id, message.duration, isDragging, onPlay, onPause, message.content]);

    const startTyping = () => {
        setDisplayedText(''); // Reset text when starting
        updateTypedText(progress); // Set initial text based on current progress
    };

    const stopTyping = () => {
        if (typingIntervalRef.current) {
            clearInterval(typingIntervalRef.content);
        }
    };

    const updateTypedText = (progressPercent) => {
        if (message.content) {
            const textLength = message.content.length;
            const charactersToShow = Math.floor((progressPercent / 100) * textLength);
            setDisplayedText(message.content.slice(0, charactersToShow));
            if (scrollToBottom) scrollToBottom();
        }
    };

    const handlePlayPause = () => {
        if (isPlaying) {
            audioPlayer.pause();
            onPause();
            stopTyping();
        } else {
            audioPlayer.play(message.id, message.audioUrl);
            onPlay();
            startTyping();
        }
    };

    // Only render progress bar controls for non-assistant messages
    const renderProgressBar = () => {
        if (message.role !== 'assistant') {
            return (
                <div 
                    ref={progressBarRef}
                    className={`${styles.progressBar} ${styles[`progressBar${message.role.charAt(0).toUpperCase() + message.role.slice(1)}`]}`}
                    onClick={handleProgressClick}
                >
                    <div 
                        className={styles.progress}
                        style={{ width: `${progress}%` }}
                    >
                        <div 
                            className={styles.progressHandle}
                            onMouseDown={handleMouseDown}
                        />
                    </div>
                </div>
            );
        }
        return null;
    };

    const handleProgressClick = (e) => {
        if (progressBarRef.current) {
            const bounds = progressBarRef.current.getBoundingClientRect();
            const percent = (e.clientX - bounds.left) / bounds.width;
            audioPlayer.seek(message.id, percent);
        }
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (isDragging && progressBarRef.current) {
            const bounds = progressBarRef.current.getBoundingClientRect();
            const percent = Math.max(0, Math.min(100, 
                ((e.clientX - bounds.left) / bounds.width) * 100
            ));
            setProgress(percent);
        }
    };

    const handleMouseUp = (e) => {
        if (isDragging) {
            setIsDragging(false);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            
            // Update audio position
            if (progressBarRef.current) {
                const bounds = progressBarRef.current.getBoundingClientRect();
                const percent = (e.clientX - bounds.left) / bounds.width;
                audioPlayer.seek(message.id, percent);
            }
        }
    };

    return (
        <div className={`${styles.audioMessage} ${styles[message.role]}`}>
            <div className={styles.audioControls}>
                <button 
                    className={styles.playButton}
                    onClick={handlePlayPause}
                >
                    {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
                </button>
            </div>
            {message.role === 'assistant' ? (
                <div className={styles.messageText}>
                    {displayedText}
                </div>
            ) : (
                <div className={styles.messageRecording}>
                    {renderProgressBar()}
                    <span className={styles.duration}>
                        {formatTime(duration)}
                    </span>
                </div>
            )}
        </div>
    );
};

export default AudioMessage;