// When user's screen width is too small
import React from "react";
import Logo from "../common/DegrauLogo";
import FadeLoader from "../common/FadeLoader";
import styles from "./Popup.module.css";

const LoadingDevEnvContent = ({ onLoad, logoLoaded, ...otherProps }) => {
  return (
    <>
      <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
      <FadeLoader 
          text="Estamos configurando seu ambiente de desenvolvimento, isso pode demorar alguns minutos..."
          height="100%"
          width="100%"
          zIndex="11"
          textColor="#333"
          centeredText={true}
      />
    </>
  );
};

export default LoadingDevEnvContent;
