// ResultsSummaryTable.js
import React, { useState, useMemo, useEffect } from 'react';
import { faShareAlt, faTimes, faRedo, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import ShareLinkModal from './ShareLinkModal';
import TagSelector from './TagSelector';
import getRelativeTime from '../../utils/getRelativeTime';
import colourScore5point from '../../utils/colourScore5point';
import { useSortData } from '../../hooks/useSortData';
import { approveResult, invalidateResult, unapproveResult } from '../../services/databaseService';
import { getCompanyResultsTableColumns } from '../../dataInputs/companyResultsTableColumns'; 
import SortableTable from '../common/SortableTable';
import ActionButton from '../common/ActionButton';
import styles from './ResultsSummaryTable.module.css';
import tableStyles from '../../sharedStyles/TableStyles.module.css';

const ResultsSummaryTable = ({ 
    companySummaryData,
    urlCodes,
    roles,
    tests,
    companies,
    resultTagsMap, 
    removeTagFromResult, 
    addTagToResult, 
    availableTags, 
    updateResultTagsMap, 
    isGlobalDashboard = false,
    refreshData,
    errorMessage,
}) => {
    const { items: sortedData, requestSort, sortConfig } = useSortData(companySummaryData, { key: 'last_activity', direction: 'descending' }); // Sorting logic
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [currentReportCode, setCurrentReportCode] = useState('');
    const [currentCandidateName, setCurrentCandidateName] = useState('');
    const [currentCompanyTestId, setCurrentCompanyTestId] = useState('');
    const [selectedCell, setSelectedCell] = useState(null);
    const [isTagSelectorVisible, setIsTagSelectorVisible] = useState(false);

    // Define the columns
    const columns = useMemo(() => getCompanyResultsTableColumns(isGlobalDashboard, companies), [isGlobalDashboard, companies]);   

    const getRoleName = (roleCode) => {
        const role = (roles || []).find(r => r.role_code === roleCode);
        return role ? role.role_name : 'Cargo não encontrado';
    };    

    const getCompanyName = (companyId) => {
        const company = (companies || []).find(c => c.company_id === companyId);
        return company ? company.company_name : 'Empresa não encontrada';
    };

    const getTestName = (testId) => {
        const test = (tests || []).find(t => t.test_id === testId);
        return test ? test.test_name : 'Entrevista não encontrada';
    };

    function getRoundedScore(score) {
        return Math.round(score);
    }

    // Open the report in a new tab when the name is clicked
    const handleNameClick = (urlCode, approved = false, version) => {
        const baseUrl = `${window.location.origin}/relatorio`;
        const reportUrl = new URL(baseUrl);
        reportUrl.searchParams.set('tentativa', urlCode);
        if (version && !approved) {
            reportUrl.searchParams.set('version', version);
        }
        window.open(reportUrl.toString(), '_blank');
    };

    const toggleShareModal = (data) => {
        const { url_code: urlCode, candidate_name: candidateName, company_test_id: companyTestId } = data;
        setCurrentReportCode(urlCode);
        setCurrentCandidateName(candidateName);
        setCurrentCompanyTestId(companyTestId);
        setIsShareModalVisible(prev => !prev);
    };

    // Remove a tag from a result
    const handleRemoveTag = async (url_code, tag_id) => {
        await removeTagFromResult(url_code, tag_id);
        await updateResultTagsMap(urlCodes);
        handleTagSelectorClose();
    };

    // Show the tag selector when a tag cell is clicked
    const handleTagRowClick = (data) => {
        setSelectedCell(data);
        setIsTagSelectorVisible(true);
    };

    // When a tag is selected, add it to the result
    const handleSelectTag = async (tag) => {
        if (selectedCell) {
            const urlCode = selectedCell.url_code; // apply tag to all report versions wit hthe same url_code
            const tagId = tag.tag_id;
            await addTagToResult(urlCode, tagId);
            await updateResultTagsMap(urlCodes);
            handleTagSelectorClose();
        }
    };

    const handleTagSelectorClose = () => {
        setSelectedCell(null);
        setIsTagSelectorVisible(false);
    };

    // Rerun and evaluation
    const handleRedo = async (testAttemptId) => {
        const confirmRedo = window.confirm("Are you sure you want to run a new evaluation for this test?");
        if (!confirmRedo) return;
        try {
            const response = await fetch('/api/trigger-evaluation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ testAttemptId: testAttemptId, isAdmin: isGlobalDashboard }),
            });

            if (response.ok) {
                refreshData();
            } else {
                const errorData = await response.json();
                console.error('Error redoing report:', errorData.message);
            }
        } catch (error) {
            console.error('Error redoing report:', error);
        }
    };

    // Approve a result
    const handleApprove = async (resultId) => {
        const confirmApprove = window.confirm("Are you sure you want to approve these results?");
        if (!confirmApprove) return;
        const response = await approveResult(resultId);
        console.log('response', response);
        if (response) {
            refreshData();
        } else {
            console.error('Error approving result:', response);
        }
    };

    // Unapprove a result
    const handleUnapprove = async (resultId) => {
        const confirmUnapprove = window.confirm("Are you sure you want to unapprove these results?");
        if (!confirmUnapprove) return;
        const response = await unapproveResult(resultId);
        console.log('response', response);
        if (response) {
            refreshData();
        } else {
            console.error('Error unapproving result:', response);
        }
    };

    // Invalidate a result
    const handleInvalidate = async (resultId) => {
        console.log('handleInvalidate called', resultId);
        const confirmInvalidate = window.confirm("Are you sure you want to invalidate these results?");
        if (!confirmInvalidate) return;
        const response = await invalidateResult(resultId);
        if (response) {
            refreshData();
        } else {
            console.error('Error invalidating result:', response);
        }
    }
    
    const renderRow = (data) => {
        // Check if tags exist for the current url_code, default to an empty array if not
        const tags = resultTagsMap[data.url_code] || [];

        if (companySummaryData.length === 0) {
            return null;
        } else {
            return (
                <>
                    {data.status === 'Avaliado' ? (
                        <td className={tableStyles.boldColumn} style={{ minWidth: '180px' }}>
                            <ActionButton 
                                text={`${data.candidate_name}`}
                                label={`Abrir relatório para ${data.candidate_name}`}
                                onClick={() => handleNameClick(data.url_code, data.approved, data.version)}
                                showHoverPopout={true}
                                popoutText={`Abrir relatório para ${data.candidate_name}`}
                                popoutPosition="right"
                            />
                        </td>
                    ) : (
                        <td style={{ minWidth: '180px' }}>{data.candidate_name}</td>
                    )}
                    {isGlobalDashboard && companies && companies.length > 0 && (
                        <td>{getCompanyName(data.company_id)}</td>
                    )}
                    <td style={{ minWidth: '180px' }}>{getTestName(data.test_id)}</td>
                    <td>{data.status}</td>
                    <td>{getRelativeTime(data.last_activity)}</td>
                    <td className={tableStyles.boldCenteredColumn} style={{ color: colourScore5point(data.total_score) }}>
                        {data.total_score ? getRoundedScore(data.total_score) : ''}
                    </td>
                    {isGlobalDashboard && <td className={tableStyles.centeredColumn}>{data.approved}</td>}
                    {isGlobalDashboard && <td className={tableStyles.centeredColumn}>{data.test_attempt_id}</td>}
                    {isGlobalDashboard && <td className={tableStyles.centeredColumn}>{data.results_id}</td>}
                    {isGlobalDashboard && <td className={tableStyles.centeredColumn}>{data.version}</td>}
                    <td className={tableStyles.inputColumn} onClick={() => handleTagRowClick(data)}>
                        {/* Ensure tags is an array and map over it */}
                        {Array.isArray(tags) && tags.map(tag => (
                            <div key={tag.tag_id} className={styles.tagLabel} style={{ backgroundColor: tag.colour, color: tag.text_colour }}>
                                {tag.tag_name}
                                <ActionButton 
                                    icon={faTimes} 
                                    label="Remover tag do relatório" 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveTag(data.url_code, tag.tag_id);
                                    }}
                                    showHoverPopout={true}
                                    popoutText="Remover tag do relatório"
                                    popoutPosition="left"
                                />
                            </div>
                        ))}
                        {selectedCell && selectedCell.url_code === data.url_code && isTagSelectorVisible && (!('results_id' in selectedCell) || selectedCell.results_id === data.results_id) && (
                            <TagSelector 
                                availableTags={availableTags} 
                                onSelectTag={handleSelectTag} 
                                onClose={handleTagSelectorClose}
                            />
                        )}
                    </td>
                    <td className={tableStyles.centered65Column}>
                        <ActionButton 
                            icon={faShareAlt} 
                            label="Compartilhar Relatório" 
                            onClick={() => toggleShareModal(data)}
                            showHoverPopout={true}
                            popoutText="Compartilhar relatório"
                            popoutPosition="left"
                        />
                        {isGlobalDashboard && (
                            <>
                                <ActionButton 
                                    icon={faRedo} 
                                    label="Reexecutar Avaliação" 
                                    onClick={() => handleRedo(data.test_attempt_id)}
                                    showHoverPopout={true}
                                    popoutText="Reexecutar a avaliação"
                                    popoutPosition="left"
                                />
                                {data.approved ? (
                                    <ActionButton 
                                        icon={faTimes} 
                                        label="Desaprovar Resultado" 
                                        onClick={() => handleUnapprove(data.results_id)} 
                                        showHoverPopout={true}
                                        popoutText="Desaprovar o resultado"
                                        popoutPosition="left"
                                        textColor="red"
                                    />
                                ) : (
                                    <ActionButton 
                                        icon={faCheck} 
                                        label="Aprovar Resultado" 
                                        onClick={() => handleApprove(data.results_id)} 
                                        showHoverPopout={true}
                                        popoutText="Aprovar o resultado"
                                        popoutPosition="left"
                                        textColor="green"
                                    />
                                )}
                                <ActionButton 
                                    icon={faTrash} 
                                    label="Invalidar Resultado" 
                                    onClick={() => handleInvalidate(data.results_id)} 
                                    showHoverPopout={true}
                                    popoutText="Invalidar o resultado"
                                    popoutPosition="left"
                                />
                            </>
                        )}
                    </td>
                </>
            )
        };
    }   

    return (
        <div>
            {isShareModalVisible && (
                <ShareLinkModal
                    currentReportCode={currentReportCode}
                    currentCandidateName={currentCandidateName}
                    currentCompanyTestId={currentCompanyTestId}
                    setIsShareModalVisible={setIsShareModalVisible}
                />
            )}
            <SortableTable
                columns={columns}
                data={sortedData}
                sortConfig={sortConfig}
                onSort={requestSort}
                renderRow={renderRow}
                styles={tableStyles}
            />
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        </div>
    );
};

export default ResultsSummaryTable;
