import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const VsCodeLoadFailContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <div className={styles.popupTextLeft}>
            <p>Desculpe, mas estamos enfrentando dificuldades para carregar seu ambiente de desenvolvimento. Por favor, tente o seguinte:</p>
                <ul style={{ listStylePosition: 'inside' }}>
                    <li>Atualize a página do seu navegador.</li>
                    <li>Verifique sua conexão de internet.</li>
                    <li>Tente acessar o site novamente em alguns minutos.</li>
                </ul>
            <p>Se o problema persistir, por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b> para obter ajuda. Estamos aqui para ajudar!</p>
        </div>
        </>
    );
};

export default VsCodeLoadFailContent;