import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import navigateWithContext from '../utils/navigateWithContext';
import getInitials from "../utils/getInitials";
import useAuth from './useAuth'; 
import { useAppContext } from '../contexts/AppContext';
import { useTestContext } from '../contexts/TestContext';
import { logException } from '../services/loggerFront';
import { fetchCompanyDetailsByCode } from '../services/databaseService';
import {fetchUserNameAndEmail} from '../services/companyUserService';

const useEnsureUserData = () => {
  const { 
    userRoles, 
    setUserRoles,
    userPermissions, 
    setUserPermissions,
    setUserFirstName,
    setUserLastName,
    setUserFullName,
    setUserEmail,
    userId,
    setUserId,
    userInitials,
    setUserInitials,
    setIsOrgAdmin, 
    setIsGlobalAdmin, 
    setCompanyId,
    setCompanyAccountType,
    companyAccountType,
  } = useAppContext();

  const { companyDetails, setCompanyDetails } = useTestContext();
  
  const { 
    getPermissions, 
    getUserRoles, 
    getCompanyDetailsFromToken,
    getCompanyAccountType,
    getUserId,
    signOut,
    updateUserToken
  } = useAuth();

  const navigate = useNavigate();

  // Function to fetch and update user data
  const fetchUserData = useCallback(async () => {
    try {
      let permissions = userPermissions;
      let company = companyDetails;
      let roles = userRoles;
      let id = userId;

      // Permissions
      if (!permissions) {
        permissions = await getPermissions();
        if (!permissions || permissions.length === 0) {
          signOut();
          navigateWithContext(navigate, "/login", window.location.pathname + window.location.search);
          return;
        }
        setUserPermissions(permissions);
      }

      // Company details
      if (!company || Object.keys(company).length === 0) {
        company = await getCompanyDetailsFromToken();
        if (company) {
          setCompanyDetails(company);
          setCompanyId(company.companyID);
        } else {
          signOut();
          navigateWithContext(navigate, "/login", window.location.pathname + window.location.search);
          return;
        }
      }

      // Company account type
      if (!companyAccountType) {
        const companyAccountType = await getCompanyAccountType();
        if (companyAccountType) {
          setCompanyAccountType(companyAccountType);
        } else {
          setCompanyAccountType(null);
        }
      }

      // User ID
      if (!id) {
        const fetchedUserId = getUserId();
        if (fetchedUserId) {
          setUserId(fetchedUserId);
        } else {
          signOut();
          navigateWithContext(navigate, "/login", window.location.pathname + window.location.search);
          return;
        }
      }

      // User roles
      if (!roles || roles.length === 0) {
        roles = await getUserRoles();
        if (roles) {
          setUserRoles(roles);
          setIsOrgAdmin(roles.includes('Admin') || roles.includes('SuperAdmin'));
          // log if it would set is org admin
          setIsGlobalAdmin(roles.includes('GlobalAdmin'));
        } else {
          signOut();
          navigateWithContext(navigate, "/login", window.location.pathname + window.location.search);
          return;
        }
      }

      // Fetch and update name and email
      await updateUserNameAndEmail();
    } catch (error) {
      logException('Failed to fetch user data', {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: 'useEnsureUserData.js',
      });
    }
  }, [
    userPermissions, 
    userRoles, 
    companyDetails, 
    userId,
    setUserPermissions, 
    setCompanyDetails, 
    setIsOrgAdmin, 
    setIsGlobalAdmin, 
    setCompanyId, 
    getPermissions, 
    getUserRoles, 
    getCompanyDetailsFromToken, 
    getUserId, 
    signOut, 
    navigate
  ]);

  // Fetch and update user name and email
  const updateUserNameAndEmail = useCallback(async () => {
    try {
      const { name, surname, email } = await fetchUserNameAndEmail();
      setUserFirstName(name);
      setUserLastName(surname);
      const fullName = `${name} ${surname}`;
      setUserFullName(fullName);
      const initials = await getInitials(fullName);
      setUserInitials(initials);
      setUserEmail(email);
    } catch (error) { 
      logException('Failed to fetch user name and email', {
        errorMessage: error.message,
        errorStack: error.stack,
        userId: userId || 'No user ID',
        fileName: 'useEnsureUserData.js',
      });
      if (!userInitials) {
        setUserInitials('')
      }
    }
  }, [
    setUserFirstName,
    setUserLastName,
    setUserFullName,
    setUserEmail,
    setUserInitials,
    userId,
    signOut,
    navigate
  ]);

   // Fetch and update company details
   const updateCompanyDetails = useCallback(async () => {
    try {
      const newCompanyDetails = await fetchCompanyDetailsByCode(companyDetails.companyCode);
      setCompanyDetails(newCompanyDetails[0]);
      setCompanyId(newCompanyDetails.companyID);

      // Update the token with the new company details
      updateUserToken();

    } catch (error) {
      logException('Failed to fetch company details', {
        errorMessage: error.message,
        errorStack: error.stack,
        userId: userId || 'No user ID',
        fileName: 'useEnsureUserData.js',
      });
    }
  }, [companyDetails, setCompanyDetails, setCompanyId, updateUserToken, signOut, navigate]);

  return { fetchUserData, updateUserNameAndEmail, updateCompanyDetails };
};

export default useEnsureUserData;
