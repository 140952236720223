export const typeMessage = (message, index, setChats, currentChatId) => {
    return new Promise((resolve) => {
        let typedMessage = '';
        let charIndex = 0;
        const batchSize = 10; // Number of characters to add in each batch

        const typeChar = () => {
            if (charIndex < message.content.length) {
                // Calculate the next batch end index
                const nextBatchEndIndex = Math.min(charIndex + batchSize, message.content.length);
                
                // Add the next batch of characters
                typedMessage += message.content.slice(charIndex, nextBatchEndIndex);
                charIndex = nextBatchEndIndex;

                // Update the specific message being 'typed'
                setChats(prevChats => {
                    const newChats = { ...prevChats };
                    const newMessages = Array.isArray(newChats[currentChatId]) ? [...newChats[currentChatId]] : [];
                    const updatedMessage = { ...newMessages[index], content: typedMessage };
                    newMessages[index] = updatedMessage;
                    newChats[currentChatId] = newMessages;
                    return newChats;
                });

                setTimeout(typeChar, 40);
            } else {
                // Once typing is complete, remove fullText from the message
                setChats(prevChats => {
                    const newChats = { ...prevChats };
                    const newMessages = [...newChats[currentChatId]];
                    const { fullText, ...messageWithoutFullText } = newMessages[index];
                    newMessages[index] = messageWithoutFullText;
                    newChats[currentChatId] = newMessages;
                    return newChats;
                });

                resolve(); // Resolve the promise after the typing animation and cleanup are complete
            }
        };

        typeChar();
    });
};
