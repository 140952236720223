import React, { useState, useEffect } from 'react';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';

const EditReportAccessForm = ({
    testId,
    setTestId,
    companyId,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    testAssignments, 
}) => {
    const [noStartDate, setNoStartDate] = useState(false);
    const [noEndDate, setNoEndDate] = useState(false);
    const [previousDateFrom, setPreviousDateFrom] = useState(dateFrom); // Store the original start date
    const [previousDateTo, setPreviousDateTo] = useState(dateTo); // Store the original end date
    const [filterTestAssignments, setFilterTestAssignments] = useState([]);

    // Update the handleDateFromChange function
    const handleDateFromChange = (e) => {
        const newDateFrom = e.target.value;
        setDateFrom(newDateFrom);
    };

    // Update the handleDateToChange function
    const handleDateToChange = (e) => {
        const newDateTo = e.target.value;
        setDateTo(newDateTo);
    };

    // Filter testAssignments based on companyId
    useEffect(() => {
        if (companyId) {
            setFilterTestAssignments(testAssignments.filter(test => test.companyId === companyId));
        } else {
            setFilterTestAssignments(testAssignments);
        }
    }, [companyId, testAssignments]);

    // Trigger noStartDate and noEndDate states if dateFrom or dateTo is null on load
    useEffect(() => {
        if (dateFrom === null) {
            setNoStartDate(true);
        }
        if (dateTo === null) {
            setNoEndDate(true);
        }
    }, [dateFrom, dateTo]);

    // Handle changes to the no start date checkbox
    const handleNoStartDateChange = () => {
        if (!noStartDate) {
            setPreviousDateFrom(dateFrom); // Save the current date before setting it to null
            setDateFrom(null); // Set date to null when the checkbox is checked
        } else {
            setDateFrom(previousDateFrom); // Revert to the previous date when the checkbox is unchecked
        }
        setNoStartDate(!noStartDate);
    };

    // Handle changes to the no end date checkbox
    const handleNoEndDateChange = () => {
        if (!noEndDate) {
            setPreviousDateTo(dateTo); // Save the current date before setting it to null
            setDateTo(null); // Set date to null when the checkbox is checked
        } else {
            setDateTo(previousDateTo); // Revert to the previous date when the checkbox is unchecked
        }
        setNoEndDate(!noEndDate);
    };
    
    const handleTestChange = (e) => {
        const newTestId = e.target.value;
        if (newTestId !== testId) {
            setTestId(newTestId);
        }
    };
    

    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Cargo</label>
                <select
                    value={testId}
                    onChange={handleTestChange}
                >
                    {filterTestAssignments.map((test) => (
                        <option key={test.id} value={test.id}>
                            {test.testName}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.inputGroup}>
                <label>Data de Início</label>
                <input
                    type="date"
                    value={dateFrom || ''}
                    onChange={handleDateFromChange}
                    className={noStartDate ? styles.disabledDateInput : ''}
                    disabled={noStartDate} // Visually disable the input field
                />
                <label className={styles.checkboxContainer}>
                    <input
                        type="checkbox"
                        checked={noStartDate}
                        onChange={handleNoStartDateChange}
                        className={styles.checkboxInput}
                    />
                    <span className={styles.checkboxLabel}>Sem Data de Início</span>
                </label>
            </div>
            <div className={styles.inputGroup}>
                <label>Data de Fim</label>
                <input
                    type="date"
                    value={dateTo || ''}
                    onChange={handleDateToChange}
                    className={noEndDate ? styles.disabledDateInput : ''}
                    disabled={noEndDate} // Visually disable the input field
                />
                <label className={styles.checkboxContainer}>
                    <input
                        type="checkbox"
                        checked={noEndDate}
                        onChange={handleNoEndDateChange}
                        className={styles.checkboxInput}
                    />
                    <span className={styles.checkboxLabel}>Sem Data de Fim</span>
                </label>
            </div>
        </div>
    );
};

export default EditReportAccessForm;
