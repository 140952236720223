// Database services for report access management
import axios from "axios";
import { retryRequest } from '../utils/retryRequest';
import { logEvent, logException, logTrace } from "./loggerFront"; // To log errors
import useAuth from "../hooks/useAuth";

const fileName = "reportAccessService.js";

// Fetch report access summary with optional companyId
export const fetchReportAccessSummary = async (companyId = null) => {

    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
      const url = companyId 
        ? `/api/company-report-access/report-access-summary?companyId=${companyId}` 
        : `/api/company-report-access/report-access-summary`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: { 
          "Authorization": `Bearer ${token}` // Include the auth token
        }
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error fetching report access summary", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        companyId,
      });
      throw error; // Handle the error as needed
    }
  };
  
  // Add a report access
  export const addReportAccess = async (accessData) => {
    logTrace("addReportAccess", { accessData });
  
    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
      const response = await fetch("/api/company-report-access/add-report-access", {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
        body: JSON.stringify(accessData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error adding report access", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        accessData,
      });
      throw error; // Handle the error as needed
    }
  };
  
  // Remove report access passing the id of the access to remove
  export const removeReportAccess = async (reportId) => {
    logTrace("removeReportAccess", { reportId });
  
    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
      const response = await fetch(`/api/company-report-access/remove-report-access/${reportId}`, {
        method: "DELETE",
        headers: { 
          "Authorization": `Bearer ${token}` // Include the auth token
        }
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error removing report access", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        reportId,
      });
      throw error; // Handle the error as needed
    }
  };
  
  // Edit report access passing the id of the access to edit and the new access data
  export const editReportAccess = async (reportId, accessData) => {
    logTrace("editReportAccess", { reportId, accessData });
  
    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
      const response = await fetch(`/api/company-report-access/edit-report-access/${reportId}`, {
        method: "PUT",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
        body: JSON.stringify(accessData),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error editing report access", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        reportId,
        accessData,
      });
      throw error; // Handle the error as needed
    }
  };
  
  // Get the list of roles and companies the user has access (so they can assign reports for example) to based on the unique list of roles in the results they have access to
  export const fetchReportAssignments = async (companyId = null) => {
    const { accessToken } = useAuth();
    const token = accessToken();
    logTrace("fetchReportAssignments", { companyId, token });
  
    try {
      const url = companyId 
        ? `/api/company-report-access/report-assignments?companyId=${companyId}` 
        : `/api/company-report-access/report-assignments`;
      
      const response = await fetch(url, {
        method: "GET",
        headers: { 
          "Authorization": `Bearer ${token}` // Include the auth token
        }
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Return the fetched role assignments
    } catch (error) {
      logException("Error fetching report assignments", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
      });
      throw error; // Rethrow the error to be handled by the caller
    }
  };
  