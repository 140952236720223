// Simple input field 
import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputField.module.css'; 

const InputField = ({ type, value, onChange, placeholder, multiline, rows }) => {
  return (
    <div className={styles.inputFieldContainer}>
      {multiline ? (
        <textarea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`${styles.inputField} ${styles.textareaField}`}
          rows={rows || 4}
        />
      ) : (
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={styles.inputField}
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

export default InputField;