// Form the form to edit interview details
import React, { useEffect } from 'react';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';

const EditInterviewForm = ({ editedTestName, editedRoleName, setTestName, setRoleName }) => {

    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Nome da entrevista</label>
                <input
                    type="text"
                    value={editedTestName}  // Bind to the name state
                    onChange={(e) => setTestName(e.target.value)}
                />
            </div>
            <div className={styles.inputGroup}>
                <label>Cargo</label>
                <input
                    type="text"
                    value={editedRoleName}  // Bind to the name state
                    onChange={(e) => setRoleName(e.target.value)}
                />
            </div>
        </div>
    );
};

export default EditInterviewForm;

