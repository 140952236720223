import React from 'react';
import { FadeLoader as Spinner } from 'react-spinners'; // Import the FadeLoader from react-spinners
import styles from './FadeLoader.module.css';

const FadeLoader = ({ text = "", height = "calc(100vh - 112px)", width = "100%", zIndex = 1, textColor = "#666", centeredText = false }) => {
  return (
    <div className={styles.loaderContainer} style={{ height, width, zIndex }}>
      <Spinner color="#246e24" /> {/* Set your desired color here */}
      {text && <div className={`${styles.loaderText} ${centeredText ? styles.centeredText : ''}`} style={{ color: textColor }}>{text}</div>} {/* Display the text if provided */}
    </div>
  );
};

export default FadeLoader;
