import React, { useEffect, useRef } from 'react';
import styles from './VolumeVisualizer.module.css';

const VolumeVisualizer = ({ isActive, width = 100, height = 20 }) => {
  const visualizerRef = useRef(null);
  const animationFrameId = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const amplitudeHistory = useRef([]);
  const lastSampleTime = useRef(0);
  const SAMPLE_INTERVAL = 200; // making 6s / 30 bars

  useEffect(() => {
    if (isActive) {
      // Reset history and time when starting new recording
      amplitudeHistory.current = [];
      lastSampleTime.current = 0;
      
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const analyser = audioContext.createAnalyser();
          const source = audioContext.createMediaStreamSource(stream);
          
          analyser.fftSize = 32;
          source.connect(analyser);
          analyserRef.current = analyser;
          dataArrayRef.current = new Uint8Array(analyser.frequencyBinCount);
          
          animate();
        })
        .catch(err => console.error('Error accessing microphone:', err));

      return () => {
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
          animationFrameId.current = null;
        }
        // Clear refs on cleanup
        analyserRef.current = null;
        dataArrayRef.current = null;
        amplitudeHistory.current = [];
      };
    }
  }, [isActive]);

  const animate = () => {
    if (!isActive || !analyserRef.current || !dataArrayRef.current) return;
  
    const currentTime = Date.now();
    const canvas = visualizerRef.current;
    const canvasCtx = canvas?.getContext('2d');
    
    if (!canvasCtx) return;

    const targetBars = 50;
  
    // Only sample if enough time has passed
    if (currentTime - lastSampleTime.current >= SAMPLE_INTERVAL) {
      const analyser = analyserRef.current;
      const dataArray = dataArrayRef.current;
  
      analyser.getByteTimeDomainData(dataArray);
  
      // Calculate RMS (Root Mean Square) amplitude
      let sumOfSquares = 0;
      for (let i = 0; i < dataArray.length; i++) {
        const value = (dataArray[i] - 128) / 128.0; // Normalize to [-1, 1]
        sumOfSquares += value * value;
      }
      const rms = Math.sqrt(sumOfSquares / dataArray.length);
      
      // Apply logarithmic scaling for better visual representation
      const amplitude = Math.min(20 * Math.log10(1 + rms), 1);
  
      // Add to history
      amplitudeHistory.current.push(amplitude);
  
      // Keep only the last N samples that fit in our canvas
      const maxSamples = targetBars; // Adjust divisor to change bar spacing
      if (amplitudeHistory.current.length > maxSamples) {
        amplitudeHistory.current = amplitudeHistory.current.slice(-maxSamples);
      }
  
      lastSampleTime.current = currentTime;
    }
  
    // Clear canvas
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    
    // Draw amplitude history
    const barWidth = canvas.width / targetBars;
    amplitudeHistory.current.forEach((amp, index) => {
      const x = index * barWidth;
      const normalizedHeight = amp * canvas.height;  // amp is already 0-1
      const y = (canvas.height / 2) - (normalizedHeight / 2);
      
      canvasCtx.fillStyle = '#333';
      canvasCtx.fillRect(x, y, barWidth - 1, normalizedHeight);
    });
  
    animationFrameId.current = requestAnimationFrame(animate);
  };

  return (
    <canvas 
      ref={visualizerRef} 
      className={styles.visualizer} 
      width={width}
      height={height}
    />
  );
};

export default VolumeVisualizer;