const getSectionTimeInMinutes = (time) => {
    // Check if time has 'setTimeTo' or 'minimum' and extract the value
    if (time?.setTimeTo) {
        return time.setTimeTo / 60; // Convert seconds to minutes
    } else if (time?.minimumTime) {
        return time.minimumTime / 60; // Convert seconds to minutes
    } else if (typeof time === 'number') {
        return time / 60; // If time is directly a number
    }
    return 0; // Default to 0 if no time found
};

export default getSectionTimeInMinutes;