import { useState, useRef, useCallback, useEffect } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { useTestContext } from '../contexts/TestContext';

const MIME_TYPE = 'audio/webm';
const CHUNK_INTERVAL = 100; // ms

const setupAudioContext = async () => {
    const context = new AudioContext({
        sampleRate: 44100,
        latencyHint: 'interactive'
    });
    
    // Add state change listener
    context.onstatechange = () => {
    };

    await context.resume();
    
    if (context.state !== 'running') {
        throw new Error(`Audio context failed to start: ${context.state}`);
    }
    
    return context;
};

const setupMixer = (audioContext) => {
    const mixer = audioContext.createGain();
    mixer.gain.value = 1.0;
    return mixer;
};

const connectAIAudio = async (audioContext, mixer, wavPlayer) => {
    if (!wavPlayer?.context) {
        console.warn('No wavPlayer context available');
        return null;
    }

    // Create a bridge between contexts
    const wavDestination = wavPlayer.context.createMediaStreamDestination();
    
    // Add our destination to the WavPlayer
    wavPlayer.addDestination(wavDestination);
    
    // Create a source in our recording context
    const aiSource = audioContext.createMediaStreamSource(wavDestination.stream);
    aiSource.connect(mixer);

    return {
        node: aiSource,
        cleanup: () => {
            wavPlayer.removeDestination(wavDestination);
            aiSource.disconnect();
        }
    };
};

const connectUserAudio = (audioContext, mixer, userStream) => {
    if (!userStream?.active) {
        console.warn('No active user stream provided to connectUserAudio');
        return null;
    }

    const userSource = audioContext.createMediaStreamSource(userStream);
    userSource.connect(mixer);
    
    return {
        node: userSource,
        cleanup: () => {
            userSource.disconnect();
        }
    };
};

const setupAudioMonitor = (audioContext, mixer) => {
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 2048;
    mixer.connect(analyzer);

    const monitorInterval = setInterval(() => {
        const dataArray = new Float32Array(analyzer.frequencyBinCount);
        analyzer.getFloatTimeDomainData(dataArray);
        const rms = Math.sqrt(dataArray.reduce((acc, val) => acc + val * val, 0) / analyzer.frequencyBinCount);
    }, 1000);

    return { analyzer, monitorInterval };
};

const useConversationRecorder = ({ wavPlayer, onError }) => {
    const [isRecording, setIsRecording] = useState(false);
    const recorder = useRef(null);
    const chunks = useRef([]);
    const audioContext = useRef(null);
    const cleanupRef = useRef(null);
    const { testAttemptId } = useTestContext();

    const startRecording = useCallback(async (userStream) => {

        if (isRecording) {
            return;
        }

        if (!userStream?.active) {
            throw new Error('No active user stream provided');
        }

        setIsRecording(true);
    
        try {
            // Setup audio routing
            audioContext.current = await setupAudioContext();
             // Verify context is still running
            if (audioContext.current.state !== 'running') {
                await audioContext.current.resume();
            }

            if (audioContext.current.state !== 'running') {
                throw new Error(`Audio context not running: ${audioContext.current.state}`);
            }

            const mixer = setupMixer(audioContext.current);
            const outputDestination = audioContext.current.createMediaStreamDestination();
            mixer.connect(outputDestination);

            // Initialize recorder before connecting sources
            recorder.current = new MediaRecorder(outputDestination.stream, {
                mimeType: MIME_TYPE,
                audioBitsPerSecond: 128000
            });

            chunks.current = [];
            recorder.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.current.push(event.data);
                }
            };
    
            // Connect audio sources and store their cleanup functions
            const sources = await Promise.all([
                connectAIAudio(audioContext.current, mixer, wavPlayer),
                connectUserAudio(audioContext.current, mixer, userStream)
            ]);
            
            const activeSources = sources.filter(Boolean);
    
            // Setup monitoring
            const { analyzer, monitorInterval } = setupAudioMonitor(audioContext.current, mixer);
    
            // Start recording
        recorder.current.start(50); // 50ms chunks
        setIsRecording(true);

        // Store cleanup function
        cleanupRef.current = () => {
            clearInterval(monitorInterval);
            
            activeSources.forEach(source => {
                if (source?.cleanup) {
                    try {
                        source.cleanup();
                    } catch (err) {
                        console.warn('Error during source cleanup:', err);
                    }
                }
            });

            if (analyzer?.disconnect) analyzer.disconnect();
            if (mixer?.disconnect) mixer.disconnect();
            
        };

        } catch (err) {
            console.error('Recording setup failed:', err);
            onError?.(err.message);
        }
    }, [wavPlayer, isRecording, onError]);

    const downloadRecording = useCallback(async (blob) => {
        try {
            if (!blob) {
                throw new Error('No recording data available');
            }
    
            // Download original WebM file
            const webmUrl = URL.createObjectURL(blob);
            const webmLink = document.createElement('a');
            webmLink.href = webmUrl;
            webmLink.download = `conversation-${new Date().toISOString()}.webm`;
            document.body.appendChild(webmLink);
            webmLink.click();
            document.body.removeChild(webmLink);
            URL.revokeObjectURL(webmUrl);
    
            // Convert to MP3 using FFmpeg
            const ffmpeg = new FFmpeg();
            await ffmpeg.load();
    
            // Write the blob to FFmpeg's virtual filesystem
            const inputFileName = 'input.webm';
            const outputFileName = 'output.mp3';
            ffmpeg.writeFile(inputFileName, await fetchFile(blob));
    
            // Run the conversion
            await ffmpeg.exec([
                '-i', inputFileName,
                '-c:a', 'libmp3lame',
                '-b:a', '128k',
                outputFileName
            ]);
    
            // Read the converted file and download it
            const mp3Data = await ffmpeg.readFile(outputFileName);
            const mp3Blob = new Blob([mp3Data.buffer], { type: 'audio/mp3' });
            
            const mp3Url = URL.createObjectURL(mp3Blob);
            const mp3Link = document.createElement('a');
            mp3Link.href = mp3Url;
            mp3Link.download = `conversation-${new Date().toISOString()}.mp3`;
            document.body.appendChild(mp3Link);
            mp3Link.click();
            document.body.removeChild(mp3Link);
            URL.revokeObjectURL(mp3Url);
    
            // Cleanup FFmpeg
            await ffmpeg.terminate();
    
        } catch (err) {
            console.error('Download failed:', err);
            onError?.(err.message);
        }
    }, [onError]);

    const saveRecording = useCallback(async (blob) => {
        try {
            if (!blob) {
                throw new Error('No recording data available');
            }

            const formData = new FormData();
            formData.append('file', blob);
            formData.append('role', 'realtime');

            const response = await fetch(`/api/audio/${testAttemptId}/message`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to save recording');
            }

            const result = await response.json();
            console.log('Recording saved:', result);

        } catch (err) {
            console.error('Save recording failed:', err);
            onError?.(err.message);
        }
    }, [testAttemptId, onError]);

    const stopRecording = useCallback(async () => {
        if (!recorder.current || recorder.current.state !== 'recording') {
            console.warn('Recording not in progress');
            return null;
        }
    
        try {
            // Stop all monitoring and cleanup
            if (cleanupRef.current) {
                cleanupRef.current();
                cleanupRef.current = null;
            }
    
            const blob = await new Promise((resolve, reject) => {
                recorder.current.onstop = () => {
                    if (!chunks.current.length) {
                        reject(new Error('No audio data recorded'));
                        return;
                    }
                    resolve(new Blob(chunks.current, { type: MIME_TYPE }));
                };
                
                recorder.current.requestData();
                recorder.current.stop();
            });
    
            setIsRecording(false);
            await saveRecording(blob);
            
            // Cleanup audio context
            if (audioContext.current) {
                // Disconnect all nodes
                if (audioContext.current.destination) {
                    audioContext.current.destination.disconnect();
                }
                
                // Close the context
                await audioContext.current.close();
                audioContext.current = null;
            }
    
            // Clear recorder and chunks
            recorder.current = null;
            chunks.current = [];
    
            // Remove any remaining event listeners
            if (wavPlayer?.current) {
                wavPlayer.current.onAudioData = null;
            }
    
            return blob;
        } catch (err) {
            console.error('Stop recording failed:', err);
            onError?.(err.message);
            return null;
        }
    }, [onError, saveRecording]);

    // Also add a cleanup effect
    useEffect(() => {
        return () => {
            // Cleanup when component unmounts
            if (cleanupRef.current) {
                cleanupRef.current();
                cleanupRef.current = null;
            }
            
            if (audioContext.current) {
                audioContext.current.close().catch(console.error);
                audioContext.current = null;
            }
            
            if (recorder.current && recorder.current.state === 'recording') {
                recorder.current.stop();
            }
            
            recorder.current = null;
            chunks.current = [];
        };
    }, []);

    return {
        isRecording,
        startRecording,
        stopRecording
    };
};

export default useConversationRecorder;