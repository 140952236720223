//Centralised file for all shareable link functions
import { retryRequest } from '../utils/retryRequest';
import { logEvent, logException, logTrace } from "./loggerFront"; // To log errors
import useAuth from "../hooks/useAuth";

const fileName = "shareableLinkService";

// Create a new shareable link
export const createShareableLink = async (shareableLinkDetails) => {
    logTrace("createShareableLink", { shareableLinkDetails });
  
    // Get the access token from the auth hook
    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
      const response = await fetch("/api/shareable-link/create", {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
        body: JSON.stringify(shareableLinkDetails),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error creating shareable link", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        shareableLinkDetails,
      });
      throw error; // Handle the error as needed
    }
  };