import { parseISO, differenceInMinutes, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns';

// Format the time to be relative to current time
const getRelativeTime = (time) => {
    // if time is null, return an empty string
    if (!time) {
        return '';
    }
    const now = new Date();
    const date = parseISO(time);
    const minutesDiff = differenceInMinutes(now, date);
    const hoursDiff = differenceInHours(now, date);
    const daysDiff = differenceInDays(now, date);
    const weeksDiff = differenceInWeeks(now, date);
    const monthsDiff = differenceInMonths(now, date);
    const yearsDiff = differenceInYears(now, date);

    if (minutesDiff < 60) {
        return 'menos de 1 hora atrás';
    } else if (hoursDiff < 24) {
        return `${hoursDiff} hora${hoursDiff > 1 ? 's' : ''} atrás`;
    } else if (daysDiff < 7) {
        return `${daysDiff} dia${daysDiff > 1 ? 's' : ''} atrás`;
    } else if (weeksDiff < 5) {
        return `${weeksDiff} semana${weeksDiff > 1 ? 's' : ''} atrás`;
    } else if (monthsDiff < 12) {
        return `${monthsDiff} mes${monthsDiff > 1 ? 'es' : ''} atrás`;
    } else {
        return `${yearsDiff} ano${yearsDiff > 1 ? 's' : ''} atrás`;
    }
}

export default getRelativeTime;
