// Send formatted array of prompts to GPT and and receive back a streamed response to enable conversations
import formatGPTMessages from '../utils/formatGPTMessages';
import replacePlaceholdersForGpt from '../utils/replacePlaceholdersForGpt';
import formatDatabaseSchema from '../utils/formatDatabaseSchema';
import { logEvent, logException, logTrace } from './loggerFront';

const fileName = 'handleGPTConversation.js';

const handleGptConversation = async (chatInstruction, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, addGptSystemMessage, openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen, gptCodeEvaluation, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, testAttemptId, isAdmin, currentChatId, currentSection, interactionType = '', endOfConversation = false ) => {
    // Get the relevent messages from the chats
    const messages = chats[currentChatId] || [];
    
    // Notify the user of a brief delay via ChatInput error message
    const responseDelayTimeout = setTimeout(() => {
        setIsGptResponseDelayed(true); // Notify the user of the delay
    }, 15000); // 1000 = 1 seconds

    // Bring popup with unique link to reload after longer delay
    const chatInterruptedTimeout = setTimeout(() => {
        setIsTestInterrupted({ interrupted: true, reason: 'gpt streaming' }); // Indicate that the chat has been interrupted
    }, 75000); // 70 seconds

    // Close existing WebSocket connection if open to avoid any incorrect carry overs or retry if the previous attempt was an error 
    if (isGptWebSocketCurrentlyOpen()) {
        await closeGptWebSocket(); // Wait for the WebSocket to close
    }
    
    // GPT is instructed to say [next] when it judges the question is fully answer, this trigger code searches for [next] in the stream and triggers the flow to deal with the back in processChatInstruction
    let skipToNextTriggerMet = false; 
    const trigger = chatInstruction.skipToNextTrigger;
    const triggerStart = '[';
    const triggerEnd = ']';
    let triggerStarted = false;
    let aggregatedContent = ''; // A buffer to check for triggers
    const maxAggregationLength = 20; // Controlling the length of the buffer
    let textForUi = false; // The text in the buffer is for the UI
    let fullGptMessage = ''; // The full message from GPT

    let resolveStreamEnd;

    let currentRunId = null; // Store the `runId` from langsmith

    const streamEndPromise = new Promise(async resolve => {
        resolveStreamEnd = async () => {
            await closeGptWebSocket(); // Ensure WebSocket is closed before resolving
            resolve(); // Now resolve the promise
        };
    });    

    // Buffer logic
    let messageQueue = [];
    let isProcessingQueue = false;
    const processQueueDelay = 50;

    // Function to process the chunks received back to stream to the UI smoothly and check if the trigger is met
    const processMessageQueue = async () => {
        if (isProcessingQueue) return;
        isProcessingQueue = true;

        while (messageQueue.length > 0) {
            const data = messageQueue.shift(); // Dequeue the first message

            if (isTimeUpCallback()) {
                break;
            }

            if (data.content) {
                const content = data.content;
                fullGptMessage += data.content; // Append the content to the full message always
                
                // Your existing trigger handling logic here, adapted for the queue processing
                if (content.includes(triggerStart)) {
                    triggerStarted = true;
                    let contentBeforeTriggerStart = content.split(triggerStart)[0];
                    aggregatedContent += contentBeforeTriggerStart;

                    if (contentBeforeTriggerStart.trim() !== '') {
                        addGptSystemMessage({ content: contentBeforeTriggerStart }, false, false, chatInstruction, false, messagesWithReplacedPlaceholders, currentRunId, skipToNextTriggerMet);
                        textForUi = true;
                    }
                }

                if (triggerStarted) {
                    aggregatedContent += content;

                    if (aggregatedContent.length > maxAggregationLength || aggregatedContent.includes(triggerEnd)) {
                        if (aggregatedContent.includes(trigger)) {
                            skipToNextTriggerMet = true;

                            if (!textForUi) {
                                addGptSystemMessage({}, false, false, chatInstruction, true, messagesWithReplacedPlaceholders, currentRunId, skipToNextTriggerMet);
                            }

                            logEvent("GPT triggered [next]");
                        } else {
                            addGptSystemMessage({ content: aggregatedContent }, false, false, chatInstruction, false, messagesWithReplacedPlaceholders, currentRunId, skipToNextTriggerMet);
                            triggerStarted = false;
                            aggregatedContent = '';
                            textForUi = true;
                        }
                    }
                } else if (!triggerStarted) {
                    addGptSystemMessage(data, false, false, chatInstruction, false, messagesWithReplacedPlaceholders, currentRunId, skipToNextTriggerMet);
                    textForUi = true;
                }
            } else if (data.endOfStream) {
                resolveStreamEnd();
                addGptSystemMessage({}, false, true, chatInstruction, false, messagesWithReplacedPlaceholders, currentRunId, skipToNextTriggerMet, fullGptMessage);
                textForUi = false;
            }

            await new Promise(resolve => setTimeout(resolve, processQueueDelay)); // Wait before processing the next item
        }

        isProcessingQueue = false;
    };

    // First add the placeholder system message to the UI so the user knows a response is coming
    addGptSystemMessage('', true, false, chatInstruction, false, '' );

    // Call formatGPTMessages to gather and arrange the relevent prompts (from the instructions) and messages (from the chatbox), format as the GPT API needs and then replace any placeholders 
    const formattedMessages = formatGPTMessages(messages, chatInstruction.conversationChain, chatInstruction, endOfConversation );
    const messagesWithReplacedPlaceholders = formattedMessages.map(message => {
        return replacePlaceholdersForGpt(message, { instructions: instructionsText, preEvalCriteria: preEvalCriteria, finalCode: finalCode, boilerplateCode: boilerplateCode, initialDatabaseSchema: initialDatabaseSchema, finalDatabaseSchema: formatDatabaseSchema(databaseSchema), gptCodeEvaluation: gptCodeEvaluation, codeLanguage: codeLanguage })
    });

    // If websocket isnt open, call for it to be opened. When opened messages can be sent to GPT API and responses streamed back, passing it to the UI and looking out for the stop trigger
    if (!isGptWebSocketCurrentlyOpen()) {
        openGptWebSocket({
            onRunIdReceived: (runId) => {
                currentRunId = runId; // Store the received `runId`
            },
            // Function for when the WebSocket passes back data from the backend
            onMessageReceived: (data) => {

                // If time runs out then stopfnext
                if (isTimeUpCallback()) {
                    return; 
                }

                // Clear the delay timeout upon receiving any response
                clearTimeout(responseDelayTimeout); 
                clearTimeout(chatInterruptedTimeout);
                setIsGptResponseDelayed(false);
                setIsTestInterrupted({ interrupted: false, reason: null });

                // Enqueue the incoming data for processing
                messageQueue.push(data);

                // Process the message queue
                processMessageQueue();
            },
        }, webSocketUrl );
    }
    // Send the formatted message to the GPT API via the WS specifying the type so the backend knows how to deal with it
    const messagePayload = {
        type: 'gpt',
        messages: messagesWithReplacedPlaceholders,
        testAttemptId,  // To add the langsmith metadata
        interactionType, 
        section: currentSection.sectionName, 
        isAdmin,
        model: currentSection.chat.model,
        seed: currentSection.chat.seed,
    };
    logTrace('gpt chat messagePayload', {messagePayload, fileName});
    sendGptMessage(messagePayload);
    await streamEndPromise;
    return skipToNextTriggerMet; // Return whether the trigger was met
};

export default handleGptConversation;