import { useEffect } from "react";
import { checkValidCompanyAndRole } from "../services/databaseService";
import { getTestCodes, getTestIdsFromCodes } from "../services/candidateTestService";

const useValidateTestType = (
  testStage,
  testUuid,
  setTestId,
  setCompanyTestId,
  setCompanyDetails,
  setRoleDetails,
  setTestStage,
  page,
  setTrialTest
) => {

  // Run the function to validate the test type when the testStage is 'initialLoading'
  useEffect(() => {
    const validateTestTypeFromURL = async () => {
      // if there is no testUuid and it is not the test page, return
      if (!testUuid && page !== "/entrevista") {
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      let companyCode, roleCode;

      // Option 1: check for a uuid (new mothod that connects to company test)
      
      if (testUuid) { // If there is a UUID, save it, 
        // with this, we can now fetch the company and role codes from the database
        const testCodes = await getTestCodes(testUuid);
        companyCode = testCodes.companyCode;
        roleCode = testCodes.roleCode;
        const testId = testCodes.testId;
        const companyTestId = testCodes.companyTestId;
        setTestId(testId);
        setCompanyTestId(companyTestId);
      } else { // otherwise option 2 try the URL params (old method will role and company code)
        // First check if there is a test attempt in the url, if so dont proceed as we need to retrieve the test data
        const testAttemptId = urlParams.get("tentativa");
        if (testAttemptId) return;

        // Gather the company and role codes from the url
        companyCode = urlParams.get("empresa");
        roleCode = urlParams.get("cargo");
        const testIds = await getTestIdsFromCodes(companyCode, roleCode);
        setTestId(testIds.test_id);
        setCompanyTestId(testIds.company_test_id);

        // overlay rolecode if companyCode is lg073 due to mistaken link sent to candidates
        if (companyCode === "lg073") {
          roleCode = "bdevdb946";
        }

        // If there is none, go to fail state
        if (!companyCode || !roleCode) {
          setTestStage("uniqueUrlFail");
          return;
        }
      }

      // check if trial test from the param teste = 1
      const trialTest = urlParams.get("teste");
      if (trialTest) {
        setTrialTest(true);
      }

      // Fetch the full role and company data from the database
      const companyAndRoleData = await checkValidCompanyAndRole( companyCode, roleCode );
      const { isValid, company, role } = companyAndRoleData.isValid;

      // If it works, save the data so it is available throughout the test
      if (isValid) {
        setCompanyDetails(company), setRoleDetails(role);
      } else {
        // If it fails, go to fail state
        setTestStage("uniqueUrlFail");
      }
    };

    // Call the function to validate the test type if the testStage is 'initialLoading'
    if (testStage === "initialLoading") {
      validateTestTypeFromURL();
    }
  }, [testUuid, page, testStage]);
};

export default useValidateTestType;
