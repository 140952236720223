export const replaceIntroChatPlaceholder = (instruction, currentSection) => {
    if (typeof instruction.content !== 'string') {
        return instruction;
    }
  
    const newContent = instruction.content.replace(/\$\{([^}]+)\}/g, (match, path) => {
        const keys = path.split('.');
        let value = currentSection;
        for (const key of keys) {
            if (value && value.hasOwnProperty(key)) {
                value = value[key];
            } else {
                return match;
            }
        }
        return value !== undefined ? value : match;
    });
  
    return { ...instruction, content: newContent };
};