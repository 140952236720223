import React from 'react';
import PropTypes from 'prop-types';
import styles from './SsoContainer.module.css';

const SsoContainer = ({ providers, handleSsoAction, headerText }) => {
  return (
    <div className={styles.ssoContainer}>
      <div className={styles.ssoHeader}>{headerText}</div>
      <div className={styles.ssoButtonsContainer}>
        {providers.map((provider) => (
          <div
            key={provider.name}
            role="button"
            tabIndex="0"
            onClick={() => handleSsoAction(provider.name)}
            onKeyPress={(e) => e.key === 'Enter' && handleSsoAction(provider.name)}
            className={styles.ssoButton}
            aria-label={provider.name}
          >
            <img
              src={provider.logoUrl}
              alt={`${provider.name} logo`}
              className={styles.ssoLogo}
            />
            {provider.name}
          </div>
        ))}
      </div>
    </div>
  );
};

SsoContainer.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logoUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSsoAction: PropTypes.func.isRequired,
  headerText: PropTypes.string,
};

export default SsoContainer;