import { useState, useEffect, useCallback } from 'react';
import { getAllCompanies } from '../services/databaseService';
import { fetchUserSummary, 
    addUser, 
    removeUser, 
    acceptUserRequest,
    editUser, 
    fetchRoleAssignments
} from '../services/companyUserService';
import { hasPermission } from "../utils/hasPermission";
import { getPermissionLevel } from '../utils/getPermissionLevel';
import { logException } from '../services/loggerFront';

const useUserManagement = (companyId, userPermissions) => {
    const [users, setUsers] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);
    const [error, setError] = useState(null);
    const [roleAssignments, setRoleAssignments] = useState([]);
    const [uniqueStatuses, setUniqueStatuses] = useState([]); // Unique statuses for filtering
    const [uniqueRoles, setUniqueRoles] = useState([]); // Unique roles for filtering
    const [uniqueCompanies, setUniqueCompanies] = useState([]); // Unique companies for filtering
    const fileName = 'useUserManagement.js';

    // Fetch users when the hook is first used or when the companyId changes
    const fetchUsers = useCallback(async () => {
        setLoading(true);
        setError(null);
    
        try {
            const permissionLevel = getPermissionLevel(userPermissions, 'VIEW_USERS');
            let data;
    
            if (permissionLevel === 'GLOBAL') {
                data = await fetchUserSummary(); // Fetch all users globally
                // For Global Admins, fetch all companies
                const companies = await getAllCompanies();
                setAllCompanies(companies);
            } else if (permissionLevel === 'ORGANISATION' && companyId) {
                data = await fetchUserSummary(companyId); // Fetch users for the specific company
            } else {
                throw new Error("You do not have permission to view users.");
            }
    
            setUsers(data);

            // Extract unique values for filters
            const statuses = [...new Set(data.map(user => user.status))];
            const roles = [...new Set(data.map(user => user.roleName))];
            const companies = [...new Set(data.map(user => user.companyName))];
            setUniqueStatuses(statuses);
            setUniqueRoles(roles);
            setUniqueCompanies(companies);

        } catch (err) {
            logException('Error fetching users:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
                companyId
            });
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [companyId, userPermissions]);      

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers, companyId]);

    // Function to add a user
    const handleAddUser = async (userData, roleName) => {
        if (!hasPermission(userPermissions, 'ADD_USER')) {
            setError("Você não tem permissão para adicionar usuários.");
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            const response = await addUser(userData);
            const newUsers = response.users.map(user => ({
                ...user,
                roleName, // Add the roleName to each user object
            }));
    
            // Update the state with the newly added users
            setUsers((prevUsers) => [...prevUsers, ...newUsers]);
        } catch (err) {
            const errorMessage = 'Falha ao adicionar pessoas. Por favor, tente novamente.';
            setError(errorMessage);
            logException('Error adding user:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
            });
        } finally {
            setLoading(false);
        }
    };    

    // Remove a user by ID
    const handleRemoveUser = async (userId) => {
        if (!hasPermission(userPermissions, 'REMOVE_USER')) {
            setError("You do not have permission to remove users.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await removeUser(userId);
            setUsers((prevUsers) => prevUsers.filter(user => user.id !== userId));
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Edit the users name or email
    const handleEditUser = async (userId, userData) => {
        setModalLoading(true);
        if (!hasPermission(userPermissions, 'EDIT_USER')) {
            setError("You do not have permission to edit users.");
            return;
        }
    
        setError(null);
    
        try {
            const response = await editUser(userId, userData);
            const updatedUser = response.user;
    
            // Merge the updated fields into the existing user object
            setUsers((prevUsers) =>
                prevUsers.map(user =>
                    user.id === userId ? { ...user, ...updatedUser } : user
                )
            );
            setModalLoading(false);
        } catch (err) {
            setModalLoading(false);
            // error message for user in brasil
            const errorMessage = 'Falha ao editar usuário. Por favor, tente novamente.';
            setError(errorMessage);
            setTimeout(() => {
                setError(null);
            }, 5000);
            logException('Error editing user:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
            });
        }
    };

    // Accept a user request
    const handleAcceptUserRequest = async (userId) => {
        if (!hasPermission(userPermissions, 'ADD_USER')) {
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await acceptUserRequest(userId);
            fetchUsers();
        } catch (err) {
            logException('Error accepting user request:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
            });
        } finally {
            setLoading(false);
        }
    };

    // On load get the role assignments
    useEffect(() => {
        const fetchRoleAssignmentsData = async () => {
            try {
                const data = await fetchRoleAssignments();
                setRoleAssignments(data);
            } catch (err) {
                logException('Error fetching role assignments:', {
                    errorMessage: err.message,
                    errorStack: err.stack,
                    fileName: fileName,
                });
                setError(err.message);
            }
        };

        fetchRoleAssignmentsData();
    }, []);

    return {
        users,
        allCompanies,
        uniqueStatuses,
        uniqueRoles,
        uniqueCompanies,
        roleAssignments,
        loading,
        modalLoading,
        error,
        setError,
        fetchUsers,
        handleAddUser,
        handleRemoveUser,
        handleEditUser,
        handleAcceptUserRequest,
    };
};

export default useUserManagement;