import { logEvent, logException, logTrace } from "./loggerFront";
import useAuth from "../hooks/useAuth";
import { retryRequest } from '../utils/retryRequest';

const fileName = "companyAccountService.js";

// Function to contact sales
export const contactSales = async (email = '', mobile = '', message = '', source = 'unknown') => {
  return retryRequest(async () => {
    logTrace("contactSales", { email, mobile, message, source });

    const { accessToken } = useAuth();
    const token = accessToken();

    try {
      const response = await fetch("/api/company-account/contact-sales", {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
        body: JSON.stringify({ email, mobile, message, source }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      const result = await response.json();
      logEvent("Contact sales request sent", { email, mobile, source });
      return result;
    } catch (error) {
      logException("Error contacting sales", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        email,
        mobile,
        source,
      });
      throw error; // Propagate the error to be handled by the caller
    }
  });
};

// You can add other company account related functions here...

export const checkCompanyAccountCredit = async (companyId) => {
  return retryRequest(async () => {
    logTrace("checkCompanyAccountCredit", { companyId });

    const { accessToken } = useAuth();
    const token = accessToken();

    try {
      const response = await fetch(`/api/company-account/${companyId}/credit`, {
        method: "GET",
        headers: { 
          "Authorization": `Bearer ${token}` // Include the auth token
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      const result = await response.json();
      return result.credits;
    } catch (error) {
      logException("Error checking company account credit", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        companyId,
      });
      throw error; // Propagate the error to be handled by the caller
    }
  });
};

export const deductCompanyCredit = async (companyId, creditsToDeduct) => {
  return retryRequest(async () => {
    logTrace("deductCompanyCredit", { companyId, creditsToDeduct });

    const { accessToken } = useAuth();
    const token = accessToken();

    try {
      const response = await fetch(`/api/company-account/${companyId}/deduct-credit`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
        body: JSON.stringify({ creditsToDeduct }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      const result = await response.json();
      logEvent("Company credit deducted", { companyId, creditsDeducted: creditsToDeduct, remainingCredits: result.remainingCredits });
      return result;
    } catch (error) {
      logException("Error deducting company credit", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        companyId,
        creditsToDeduct,
      });
      throw error; // Propagate the error to be handled by the caller
    }
  });
};