import { useState, useEffect, useCallback } from 'react';
import { logException, logTrace, logEvent } from '../services/loggerFront';

const useCameraAccess = () => {
    const [videoStream, setVideoStream] = useState(null);
    const [cameraError, setCameraError] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const fileName = 'useCameraAccess';

    const enumerateDevices = useCallback(async () => {
        try {
            const deviceList = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = deviceList.filter(device => device.kind === 'videoinput');
            setDevices(videoDevices);
            
            if (videoDevices.length === 0) {
                logEvent('CameraAccessException', 'No cameras found', {
                    devices: devices || [],
                    fileName
                });
                throw new Error('Nenhuma câmera encontrada');
            }
        } catch (error) {
            logException('DeviceEnumerationException', error);
            setCameraError(getErrorMessage(error));
        }
    }, []);

    const startStream = useCallback(async (deviceId = null) => {
        try {
            const constraints = {
                video: deviceId ? { deviceId: { exact: deviceId } } : { facingMode: 'user' }
            };

            const streamPromise = navigator.mediaDevices.getUserMedia(constraints);
            const timeoutPromise = new Promise((_, reject) =>
                setTimeout(() => {
                    logEvent('CameraAccessTimeout', 'getUserMedia request timed out', {
                        devices: devices || [],
                        fileName
                    });
                    reject(new Error('getUserMedia request timed out'));
                }, 30000)
            );

            const stream = await Promise.race([streamPromise, timeoutPromise]);
            setVideoStream(stream);
            setCameraError(null);

            const videoTrack = stream.getVideoTracks()[0];
            const newSelectedDeviceId = videoTrack.getSettings().deviceId;
            setSelectedDeviceId(newSelectedDeviceId);

            await enumerateDevices();
        } catch (error) {
            setCameraError(getErrorMessage(error));
            logException('CameraAccessException', {
                errorMessage: error.message || 'unknown error',
                errorName: error.name || 'unknown error',
                devices: devices || [],
                
                fileName: fileName
            });
        }
    }, [enumerateDevices, devices]);

    const requestCameraAccess = useCallback(async () => {
        try {
            // Check if the browser supports getUserMedia
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                logEvent('CameraAccessException', 'getUserMedia is not supported in this browser', {
                    devices: devices || [],
                    fileName
                });
                throw new Error('getUserMedia is not supported in this browser');
            }

            // Check for existing permissions
            const permission = await navigator.permissions.query({ name: 'camera' });
            if (permission.state === 'denied') {
                setCameraError('A permissão da câmera foi negada');
                logTrace('Camera permission has been denied');
                throw new Error('Camera permission has been denied');
            }

            await startStream();
        } catch (error) {
            setCameraError(getErrorMessage(error));
            logException('CameraAccessException', {
                errorMessage: error.message || 'unknown error',
                errorName: error.name || 'unknown error',
                devices: devices || [],
                fileName: fileName
            });
        }
    }, [startStream, devices]);

    const getErrorMessage = (error) => {
        logTrace('getErrorMessage for camera access', { error });
        switch (error.name) {
            case 'NotAllowedError':
                return 'O acesso à câmera foi negado. Por favor, verifique as configurações do seu navegador.';
            case 'NotFoundError':
                return 'Nenhuma câmera foi encontrada no seu dispositivo.';
            case 'NotReadableError':
                return 'Sua câmera já está em uso por outro aplicativo.';
            default:
                return 'Ocorreu um erro ao tentar acessar a câmera.';
        }
    };

    useEffect(() => {
        enumerateDevices();
        requestCameraAccess();

        return () => {
            if (videoStream) {
                videoStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []); // Empty dependency array to ensure this only runs once

    const switchCamera = useCallback((newDeviceId) => {
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
        }
        startStream(newDeviceId);
    }, [videoStream, startStream]);

    return { 
        videoStream, 
        cameraError, 
        requestCameraAccess, 
        devices, 
        selectedDeviceId, 
        switchCamera 
    };
};

export default useCameraAccess;