// Sort a table by column
import { useState, useMemo } from 'react';

// Pass the hook an array of items and an optional configuration object
export const useSortData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config); // State to hold the sort configuration

  // Memoize the sorted items
  const sortedItems = useMemo(() => {
    if (!items) return [];

    let sortableItems = [...items];
    if (sortConfig !== null && sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle total_score specifically, as it's stored as a string but should be treated as a number
        if (sortConfig.key === 'total_score') {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        // Determine if the values are numbers
        const isANumber = typeof aValue === 'number' && typeof bValue === 'number';

        // Perform the appropriate comparison
        if (isANumber) {
          return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
        } else {
          const aString = aValue ? String(aValue).toLowerCase() : '';
          const bString = bValue ? String(bValue).toLowerCase() : '';
          
          if (aString < bString) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aString > bString) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  // Function to request a sort, passing the column to sort by
  const requestSort = (key) => {
    console.log('key', key)
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
