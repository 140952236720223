import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { TooltipProvider } from "../contexts/TooltipContext";
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import useCompanyReportData from "../hooks/useCompanyReportData";
import useEnsureUserData from '../hooks/useEnsureUserData';
import useReportTags from "../hooks/useReportTags";
import useFilterData from "../hooks/useFilterReportSummary";
import useAuth from "../hooks/useAuth";
import { addTagToResult, removeTagFromResult } from "../services/databaseService";
import { logException, logEvent, logTrace } from "../services/loggerFront";
// Standard page components
import Header from "../components/common/Header";
import FreeTrialBanner from "../components/common/FreeTrialBanner";
import TopRibbon from "../components/common/TopRibbon";
import ReportIssue from "../components/common/ReportIssue";
import Button from "../components/common/Button";
import FadeLoader from '../components/common/FadeLoader';
import CompanyPageSidebar from '../components/common/CompanyPageSidebar';
import CompanyPageSidebarToggle from '../components/common/CompanyPageSidebarToggle';
import ResultsSummaryTable from "../components/report/ResultsSummaryTable";
import DashboardTagModal from "../components/report/DashboardTagModal";
import ReportSummarySidebarFilters from "../components/report/ReportSummarySidebarFilters";
import ActionButton from "../components/common/ActionButton";
import ConfirmModal from "../components/common/ConfirmModal";
import { faChevronLeft, faChevronRight, faBars, faSync } from "@fortawesome/free-solid-svg-icons";
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 

const CompanyResultsPage = () => {
    const navigate = useNavigate(); // Function to navigate to a different page
    const { userPermissions, isOrgAdmin, companyId, companyAccountType } = useAppContext();
    const { companyDetails } = useTestContext();
    const { fetchUserData } = useEnsureUserData();
    const { checkAndHandleTokenExpiration } = useAuth();
    // on load fetch check user is logged and get user data
    useEffect(() => {
        checkAndHandleTokenExpiration();
        fetchUserData();
    }, []);
    const [isGlobalDashboard, setIsGlobalDashboard] = useState(null);
    const [noResultsData, setNoResultsData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null); // Error message to display when there is an error
    const { companyList, 
        roleList, 
        testList,
        statusList,
        companySummaryData, 
        urlCodes, 
        resultTagsMap, 
        setResultTagsMap, 
        companyCustomTags,
        totalCount, 
        roleCounts, 
        testCounts,
        statusCounts,
        companyCounts, 
        approvedCounts, 
        tagCounts,
        isLoadingInitialData, 
        refreshData, 
        fetchTagsForResults, 
        fetchCompanyTags
    } = useCompanyReportData(isGlobalDashboard, isOrgAdmin, companyDetails, setErrorMessage, setNoResultsData); // Hook to fetch the company data
    const { updateResultTagsMap, addOrUpdateTag, deleteTag } = useReportTags(companyId, setResultTagsMap, fetchCompanyTags); // Hook to manage tags for the company
    const { 
        filteredData, 
        testFilters,
        companyFilters,
        approvedFilters,
        statusFilters,
        tagFilters, 
        selectAll, 
        candidateNameFilter, 
        handleTestFilterChange,
        handleCompanyFilterChange,
        handleApprovedFilterChange,
        handleStatusFilterChange,
        handleTagFilterChange, 
        handleSelectAll, 
        handleCandidateNameChange,
    } = useFilterData(companySummaryData, resultTagsMap); // Hook to filter the data based on the selected roles and tags
    const [showConfirmDeleteTagModal, setShowConfirmDeleteTagModal] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Manage the visibility of the sidebar with filters
    const [isTagModalVisible, setIsTagModalVisible] = useState(false); // Manage the visibility of the tag modal for user to add or edit tags
    const [tagModalVariant, setTagModalVariant] = useState("add"); // Variant of the tag modal - add or edit
    const [selectedTag, setSelectedTag] = useState(null); // Tag to delete when the user confirms the delete action
    const [editingTag, setEditingTag] = useState(null); // Tag to edit when the user clicks on the edit button

    const fileName = "CompanyResultsPage.js"; 

    // CHECK ACCESS LEVELS

    // Check if the dashbaord permission is a Global
    const checkGlobalDashboardPermission = async () => {
        let viewDashboardPermission = null;

        if (userPermissions) {
            viewDashboardPermission = userPermissions.find(permission => permission.startsWith('ACCESS_DASHBOARD'));
        } else {
            return;
        }

        if (!viewDashboardPermission) {
            logException('User does not have ACCESS_DASHBOARD permission.', { fileName });
            navigate('/login');
            return;
        }

        const [, level] = viewDashboardPermission.split(':');
        setIsGlobalDashboard(level === 'GLOBAL');
    };

    // call on load
    useEffect(() => {
        checkGlobalDashboardPermission();
    }, [userPermissions]);

    // Toggle the visibility of the sidebar
    const handleSidebarToggle = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    // Use effect to automatically hide the sidebar on small screens
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsSidebarVisible(false); // Hide sidebar when screen is small
            }
        };

        // Call handleResize on initial load
        handleResize();

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Hide siebar if there is only one report or less
    useEffect(() => {
        if (companySummaryData) {
            if (companySummaryData.length < 2) {
                setIsSidebarVisible(false);
            } else {
                setIsSidebarVisible(true);
            }
        }
    }, [companySummaryData]);

    /* TAGS */

    // Open the tag modal to add a new tag
    const handleAddCompanyTag = () => {
        setEditingTag(null);
        setTagModalVariant("add");
        setIsTagModalVisible(true);
    };

    // Open the tag modal to edit a tag
    const handleEditCompanyTag = (tagId) => {
        const tagToEdit = companyCustomTags.find((tag) => tag.tag_id === tagId);
        setTagModalVariant("edit");
        setEditingTag(tagToEdit);
        setIsTagModalVisible(true);
    };

    // Handle the addition or update of a tag
    const handleAddOrUpdateTag = async (tagDetails) => {
        await addOrUpdateTag(tagDetails, () => {
            setIsTagModalVisible(false);
            fetchTagsForResults(filteredData); // Update the tags for the results
        });
    };

    // confirm before deleting a tag

    //First save the tag and ask to confirm
    const handleDeleteTagClick = (tagId) => {
        setSelectedTag(tagId);
        setShowConfirmDeleteTagModal(true);
    };

    // If the user cancels the deletion of a tag
    const handleCancelDeleteTag = () => {
        setShowConfirmDeleteTagModal(false);
    };

    // Handle the deletion of a tag aftwr user confirms
    const handleDeleteTag = async () => {
        if (selectedTag) {
            await deleteTag(selectedTag, () => {
                fetchTagsForResults(filteredData); // Update the tags for the results
            });
            setShowConfirmDeleteTagModal(false);
        }
    };

    if (isLoadingInitialData) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title="Painel de resultados" />
                    </div>
                    <div className={styles.contentContainer}>
                        <FadeLoader text="Carregando dados..." />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    return (
        <>
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${!isSidebarVisible ? styles.sidebarHidden : ''}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    {companyAccountType === 'free' && (
                        <div className={styles.banner}>
                            <FreeTrialBanner />
                        </div>
                    )}
                    <div className={styles.ribbon}>
                        <TopRibbon title="Painel de resultados" />
                    </div>
                    {/* Confirm Modal for deleting tags */}
                    <ConfirmModal
                        show={showConfirmDeleteTagModal}
                        title="Excluir Tag"
                        message="Tem certeza de que deseja excluir esta tag? Isso removerá todas as instâncias da tag nos relatórios."
                        onConfirm={handleDeleteTag}
                        onCancel={handleCancelDeleteTag}
                        confirmLabel="Confirmar"
                        cancelLabel="Cancelar"
                    />
                    {isTagModalVisible && (
                        <DashboardTagModal
                            editingTag={editingTag}
                            addCustomCompanyTag={handleAddOrUpdateTag}
                            setIsTagModalVisible={setIsTagModalVisible}
                            tagModalVariant={tagModalVariant}
                        />
                    )}
                    {noResultsData ? (
                        <div className={styles.contentContainer}>
                            <div className={styles.noTestDataContainer}>
                                <div className={styles.noTestDataHeader}>
                                    Vamos avaliar suas primeiras pessoas!
                                </div>
                                <div className={styles.noTestDataText}>
                                    Agora nao tem resultados. Convide pessoas para entrevistas e veja os resultados aqui.
                                </div>
                                <div className={styles.noTestDataButton}>
                                    <Button type="proceed" onClick={() => navigate('/entrevistas')}>Ir para entrevistas</Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {isSidebarVisible && (
                                <div className={styles.sidebar}>
                                    <CompanyPageSidebar
                                        header={
                                            <>
                                                <span>Filtros</span>
                                                <ActionButton 
                                                    icon={faSync} 
                                                    label="Atualizar Dados" 
                                                    onClick={refreshData}
                                                    showHoverPopout={true}
                                                    popoutText="Atualizar dados"
                                                    popoutPosition="left"
                                                />
                                            </>
                                        }
                                        isVisible={isSidebarVisible}
                                    >
                                        <ReportSummarySidebarFilters
                                            tests={testList}
                                            testFilters={testFilters}
                                            handleTestFilterChange={handleTestFilterChange}
                                            totalCount={totalCount}
                                            testCounts={testCounts}
                                            tagFilters={tagFilters}
                                            handleTagFilterChange={handleTagFilterChange}
                                            tagCounts={tagCounts}
                                            statusList={statusList}
                                            statusFilters={statusFilters}
                                            handleStatusFilterChange={handleStatusFilterChange}
                                            statusCounts={statusCounts}
                                            handleAddCompanyTag={handleAddCompanyTag}
                                            companyCustomTags={companyCustomTags}
                                            handleEditCompanyTag={handleEditCompanyTag}
                                            handleRemoveTag={handleDeleteTagClick}
                                            handleSelectAll={handleSelectAll}
                                            selectAll={selectAll}
                                            candidateNameFilter={candidateNameFilter}
                                            handleCandidateNameChange={handleCandidateNameChange}
                                            companies={companyList}
                                            companyFilters={companyFilters}
                                            handleCompanyFilterChange={handleCompanyFilterChange}
                                            companyCounts={companyCounts}
                                            approvedFilters={approvedFilters}
                                            handleApprovedFilterChange={handleApprovedFilterChange}
                                            approvedCounts={approvedCounts}
                                            isGlobalDashboard={isGlobalDashboard}
                                            refreshData={refreshData}
                                        />
                                    </CompanyPageSidebar>
                                </div>
                            )}
                            {companySummaryData.length > 0 && (
                                <div className={styles.sidebarButton}>
                                    <CompanyPageSidebarToggle
                                        onToggle={handleSidebarToggle}
                                        toggleIcon={isSidebarVisible ? faChevronLeft : faChevronRight}
                                        toggleLabel={isSidebarVisible ? "Ocultar Barra Lateral" : "Mostrar Barra Lateral"}
                                    />
                                </div>
                            )}
                            <div className={styles.contentContainer} aria-label="Tabela resumo dos resultados dos candidatos" tabIndex="0">
                                <ResultsSummaryTable
                                    companySummaryData={filteredData}
                                    urlCodes={urlCodes}
                                    roles={roleList}
                                    tests={testList}
                                    companies={companyList}
                                    resultTagsMap={resultTagsMap}
                                    removeTagFromResult={removeTagFromResult}
                                    addTagToResult={addTagToResult}
                                    availableTags={companyCustomTags}
                                    updateResultTagsMap={updateResultTagsMap}
                                    isGlobalDashboard={isGlobalDashboard}
                                    refreshData={refreshData}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        </>
                    )}
                </div>
            </TooltipProvider>
        </>
    );
};

export default CompanyResultsPage;
