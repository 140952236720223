import React, { useRef, useEffect } from 'react';
import styles from './AudioVisualizer.module.css';

const AudioVisualizer = ({ isUserSpeaking, isAISpeaking, visualizerData, width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!visualizerData || !visualizerData.values) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    ctx.clearRect(0, 0, width, height);

    const barCount = visualizerData.values.length;
    const barWidth = width / barCount;

    ctx.fillStyle = isAISpeaking ? '#0000ff' : (isUserSpeaking ? '#246e24' : '#00ff00');

    for (let i = 0; i < barCount; i++) {
      const value = visualizerData.values[i];
      const barHeight = value * height;
      ctx.fillRect(i * barWidth, height - barHeight, barWidth, barHeight);
    }

  }, [visualizerData, isUserSpeaking, isAISpeaking]);

  const getStatusTextClass = () => {
    if (isUserSpeaking) return styles.userSpeaking;
    if (isAISpeaking) return styles.aiSpeaking;
    return styles.listening;
  };

  return (
    <div className={styles.container}>
      <canvas 
        ref={canvasRef} 
        className={styles.audioVisualizerCanvas} 
        width={width}
        height={height}
      />
    </div>
  );
};

export default AudioVisualizer;