import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail } from '../services/authenticationService';
import { TooltipProvider } from '../contexts/TooltipContext';
import ReportIssue from '../components/common/ReportIssue';
import Header from '../components/common/Header';
import TopRibbon from '../components/common/TopRibbon';
import styles from '../sharedStyles/CompanyAdminPages.module.css';

const EmailVerificationPage = () => {
    const [status, setStatus] = useState('Verifying...');
    const navigate = useNavigate();
    const location = useLocation();

    // Verify the email token when the page is loaded
    useEffect(() => {
        const verifyToken = async () => {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');

            if (!token) {
                setStatus('Link de verificação inválido.');
                return;
            }

            try {
                const result = await verifyEmail(token);
                console.log(result);
                if (result.success) {
                    setStatus('Email verificado com sucesso!');
                    setTimeout(() => {
                        navigate('/resumo', { state: { message: 'Email verificado com sucesso!' } });
                    }, 3000);
                } else {
                    setStatus(result.error || 'Falha na verificação. Por favor, tente novamente.');
                }
            } catch (error) {
                setStatus('Ocorreu um erro durante a verificação. Por favor, tente novamente mais tarde.');
            }
        };

        verifyToken();
    }, [location, navigate]);

  
    return (
        <TooltipProvider>
            <ReportIssue/>
            <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                <div className={styles.header}>
                    <Header />
                </div>
                <div className={styles.ribbon}>
                    <TopRibbon title="Verificação de email" />
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.emailVerificationStatusContainer}>
                        <p>{status}</p>
                    </div>
                </div>
            </div>
        </TooltipProvider>
    );

};

export default EmailVerificationPage;