// Form for user to login. Requires email and password. Also includes a link to the forgot password page and a link to the request access page if they arrived from a report.
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import Logo from "../common/DegrauLogo";
import InputField from "../inputs/InputField";
import styles from "./Popup.module.css";

const ContactSalesContent = ({
  email,
  setEmail,
  mobile,
  setMobile,
  message,
  setMessage,
  handleAction,
  setIsPopupOpen,
  errorMessage,
  successMessage,
  onLoad,
  logoLoaded,
  ...otherProps
}) => { 

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
      {logoLoaded ? (
        <>
          <p className={styles.popupTextLarge}>
            Solicite um upgrade para sua conta.
          </p>
          <form
            className={styles.inputFieldContainer}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              handleAction(); // Call the custom action handler
            }}
          >
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="email">Email</label>
              <InputField
                id="email"
                type="email"
                autocomplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="mobile">Celular</label>
              <InputField
                id="mobile"
                type="mobile"
                autocomplete="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="message">Mensagem (se quiser)</label>
              <InputField
                id="message"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder=""
                multiline
                rows={3}
              />
            </div>
            <div className={styles.multipleButtonsContainer}>
              <Button
                disabled={!email && !mobile}
                type="form"
              >
                Contate-nos
              </Button>
              <Button
                type="subtle"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </div>
          </form>
          {errorMessage && (
            <p className={styles.errorText} role="alert">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className={styles.successText} role="alert">
              {successMessage}
            </p>
          )}
        </>
      ) : null}
    </>
  );
};

ContactSalesContent.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  mobile: PropTypes.string.isRequired,
  setMobile: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

export default ContactSalesContent;
