import React, { useContext, useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import { TooltipProvider } from "../contexts/TooltipContext";
import { logException, logEvent, logTrace } from "../services/loggerFront";
import useUserManagement from "../hooks/useUserManagement";
import { useSortData } from "../hooks/useSortData";
import useEnsureUserData from '../hooks/useEnsureUserData';
import useAuth from '../hooks/useAuth';
import { hasPermission } from "../utils/hasPermission";
import { getPermissionLevel } from '../utils/getPermissionLevel';
import navigateWithContext from "../utils/navigateWithContext";
import formatDate from '../utils/formatDate';
import { canActOnRole } from '../utils/canActOnRole';
import { getPendingUserColumns, getUserColumns } from '../dataInputs/userTableColumns'; // Functions to get columns for the user management tables
import { roleSummaryTableHeaders, roleSummaryTableRows } from '../dataInputs/roleSummaryTable'; // Data for the user role summary table
// Standard page components
import Header from "../components/common/Header";
import FreeTrialBanner from '../components/common/FreeTrialBanner';
import TopRibbon from '../components/common/TopRibbon';
import ReportIssue from "../components/common/ReportIssue";

import Button from "../components/common/Button";
import SortableTable from "../components/common/SortableTable";
import FadeLoader from '../components/common/FadeLoader';
import AccessDenied from '../components/common/AccessDenied';
import ActionButton from "../components/common/ActionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faEdit, faCheck, faTrash, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../components/common/ConfirmModal";
import AdminModal from "../components/common/AdminModal"; 
import AddUserForm from "../components/inputs/AddUserForm";
import EditUserForm from "../components/inputs/EditUserForm"; 
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 
import tableStyles from "../sharedStyles/TableStyles.module.css";

const UserManagementPage = () => {
    const { companyId, userPermissions, companyAccountType } = useAppContext(); // Get the company ID from the context
    
    // on load check if user is logged in and fetch user data
    const { fetchUserData } = useEnsureUserData();
    const { checkAndHandleTokenExpiration } = useAuth();
    useEffect(() => {
        checkAndHandleTokenExpiration();
        fetchUserData();
    }, []);
    const navigate = useNavigate(); // Function to navigate to a different page to help go to the login page if the user is not logged in
    const location = useLocation(); // Get the current location

    // PERMISSION STATES //
    const [canViewUsers, setCanViewUsers] = useState(false); // State to check if the user has the VIEW_USERS permission
    const [viewPermissionLevel, setViewPermissionLevel] = useState(''); // State to check the permission level of the user

    // check permissions 
    const checkPermissions = () => {
        // if permissions are not set, return
        if (!userPermissions) return;
        // check if the user has the permission to view users
        const canViewUsers = hasPermission(userPermissions, 'VIEW_USERS');
        setCanViewUsers(canViewUsers);  
        // get the permission level of the user
        const viewPermissionLevel = getPermissionLevel(userPermissions, 'VIEW_USERS');
        setViewPermissionLevel(viewPermissionLevel);
    };

    // call the checkPermissions function when userPermissions change
    useEffect(() => {
        checkPermissions();
    }, [userPermissions]);
    
    const { companyDetails } = useTestContext(); // Get the company details from the context
    const [emailEnding, setEmailEnding] = useState(companyDetails.emailEnding); // State for email ending
    const { users, 
        allCompanies, 
        uniqueStatuses, 
        uniqueRoles, 
        uniqueCompanies, 
        roleAssignments, 
        loading, 
        modalLoading, 
        error, 
        setError, 
        fetchUsers, 
        handleAddUser, 
        handleRemoveUser, 
        handleEditUser,
        handleAcceptUserRequest,
    } = useUserManagement(companyId, userPermissions); // Custom hook to manage users

    // State for filters and search
    const [userStatusFilter, setUserStatusFilter] = useState("All");
    const [userRoleFilter, setUserRoleFilter] = useState("All");
    const [companyFilter, setCompanyFilter] = useState("All"); // Filter for company when Global
    const [userEmailSearchQuery, setUserEmailSearchQuery] = useState("");
    const [userNameSearchQuery, setUserNameSearchQuery] = useState("");

    // State for modals
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [modalType, setModalType] = useState('');
    const [editedUserFirstName, setEditedUserFirstName] = useState('');
    const [editedUserLastName, setEditedUserLastName] = useState('');
    const [editedUserEmail, setEditedUserEmail] = useState('');
    const [currentEmails, setCurrentEmails] = useState([]); // State for storing emails in the AddUserForm to check for duplicates before adding

    // Adding user states
    const [emailsToAdd, setEmailsToAdd] = useState([]); // New emails to add
    const [selectedRole, setSelectedRole] = useState(null); // Role of the new users to add
    const [selectedRoleName, setSelectedRoleName] = useState(null); // Role name of the new users to add
    const [companyToAddTo, setCompanyToAddTo] = useState(null); // Company to add the new users to
    const [moveToPermissionPage, setMoveToPermissionPage] = useState(false); // State to move to permission page after adding user

    // Data table states
    const pendingUserColumns = getPendingUserColumns(viewPermissionLevel); // Get columns for pending users based on permission level
    const userColumns = getUserColumns(viewPermissionLevel, roleSummaryTableHeaders, roleSummaryTableRows); // Get columns for users based on permission level

    const fileName = 'UserManagementPage.js'; // Define the filename for logging

    // set emails when users updates
    useEffect(() => {
        setCurrentEmails(users.map(user => user.email));
    }, [users]);

    // Filter out users with status 'Solicitado'
    const pendingUsers = useMemo(() => users.filter(user => user.status === 'Solicitado'), [users]);

    // Sorting hooks for users and permissions
    const { items: sortedUsers, requestSort: requestUserSort, sortConfig: userSortConfig } = useSortData(users.filter(user => user.status !== 'Solicitado'), { key: 'name', direction: 'ascending' });

    // Filter and search logic for users
    const filteredUsers = useMemo(() => {
        return sortedUsers.filter(user => {
            const matchesStatus = userStatusFilter === "All" || user.status === userStatusFilter;
            const matchesRole = userRoleFilter === "All" || user.roleName === userRoleFilter;
            const matchesEmailSearch = (user.email?.toLowerCase() || '').includes(userEmailSearchQuery.toLowerCase());
            const matchesNameSearch = (user.name?.toLowerCase() || '').includes(userNameSearchQuery.toLowerCase());
            const matchesCompany = companyFilter === "All" || user.companyName === companyFilter;
            
            return matchesStatus && matchesRole && matchesEmailSearch && matchesNameSearch && (viewPermissionLevel !== 'GLOBAL' || matchesCompany);
        });
    }, [sortedUsers, userStatusFilter, userRoleFilter, userEmailSearchQuery, userNameSearchQuery, companyFilter, viewPermissionLevel]);

    // Actions for main table
    const renderActions = (row) => {
        // Determine if the current user can act on the row's role
        const canAct = roleAssignments.some(assignment => 
            canActOnRole(assignment.roleName, row.roleName)
        );
    
        return (
            <>
                {canAct ? (
                    <>
                        <ActionButton 
                            icon={faTrash} 
                            label="Excluir Conta" 
                            onClick={() => handleRemoveClick(row.id)}
                            showHoverPopout={true}
                            popoutText="Excluir conta"
                            popoutPosition="left"
                        />
                        <ActionButton 
                            icon={faEdit} 
                            label="Editar Conta" 
                            onClick={() => handleEditClick(row.id)}
                            showHoverPopout={true}
                            popoutText="Editar conta"
                            popoutPosition="left"
                        />
                    </>
                ) : (
                    <span className={styles.noPermissionText}></span>
                )}
            </>
        );
    };

    // Actions for pending table
    const renderPendingActions = (row) => {
        // Determine if the current user can act on the row's role
        const canAct = roleAssignments.some(assignment => 
            canActOnRole(assignment.roleName, row.roleName)
        );

        return (
            <>
                {canAct ? (
                    <>
                        <ActionButton 
                            icon={faCheck} 
                            label="Aceitar Solicitação" 
                            onClick={() => handleAcceptUserRequest(row.id)}
                            showHoverPopout={true}
                            popoutText="Aceitar solicitação"
                            popoutPosition="left"
                            textColor="green"
                        />
                        <ActionButton 
                            icon={faTimes} 
                            label="Rejeitar Solicitação" 
                            onClick={() => handleRemoveUser(row.id)}
                            showHoverPopout={true}
                            popoutText="Rejeitar solicitação"
                            popoutPosition="left"
                            textColor="red"
                        />
                    </>
                ) : (
                    <span className={styles.noPermissionText}>Sem Permissão</span>
                )}
            </>
        );
    };

    // Rows for main table
    const renderRow = (row) => (
        <>
            <td style={{ minWidth: '160px' }}>{row.name} {row.surname}</td>
            <td style={{ minWidth: '180px' }}>{row.email}</td>
            {viewPermissionLevel === 'GLOBAL' && <td>{row.companyName}</td>} {/* Conditionally render the companyName */}
            <td>{row.roleName}</td>
            <td>{row.status}</td>
            <td>{formatDate(row.createdAt)}</td>
            <td className={tableStyles.centeredColumn}>
                {renderActions(row)} {/* Render actions based on permissions */}
            </td>
        </>
    );

    // Rows for pending table
    const renderPendingRow = (row) => (
        <>
            <td>{row.email}</td>
            {viewPermissionLevel === 'GLOBAL' && <td>{row.companyName}</td>}
            <td>{row.roleName}</td>
            <td>{row.status}</td>
            <td>{formatDate(row.createdAt)}</td>
            <td className={tableStyles.centeredColumn}>
                {renderPendingActions(row)} {/* Render actions based on permissions */}
            </td>
        </>
    );

    // Handle Add User
    const handleAddUserClick = () => {
        setModalType('addUser');
        setShowAdminModal(true);
    };

    // Handle Edit User by setting the name and email states and opening the AdminModal
    const handleEditClick = (userId) => {
        const user = users.find(user => user.id === userId);
        if (user) {
            setEditedUserFirstName(user.name); // Set the edited user name as the current user name to pre-fill the form
            setEditedUserLastName(user.surname); // Set the edited user name as the current user name to pre-fill the form
            setEditedUserEmail(user.email); // Set the edited user email as the current user email to pre-fill the form
        }
        setSelectedUserId(userId);
        setModalType('editUser');
        setShowAdminModal(true);
    };    

    // Handle Remove User
    const handleRemoveClick = (userId) => {
        setSelectedUserId(userId);
        setShowConfirmModal(true);
    };

    // Confirm and remove the user
    const handleConfirmRemove = () => {
        if (selectedUserId) {
            handleRemoveUser(selectedUserId);
        }
        setShowConfirmModal(false);
        setSelectedUserId(null);
    };

    // Cancel the remove action
    const handleCancelRemove = () => {
        setShowConfirmModal(false);
        setSelectedUserId(null);
    };

    // Close AdminModal
    const handleCloseAdminModal = () => {
        setShowAdminModal(false);
        setSelectedUserId(null);
    };

    // Set emails and role for AddUserForm
    const setAddUserDetails = (emails, role, roleName, companyId) => {
        setEmailsToAdd(emails);
        setSelectedRole(role);
        setSelectedRoleName(roleName);
        setCompanyToAddTo(companyId);
    };

    // Handle Confirm action for AdminModal (Add/Edit user)
    const handleConfirmAdminModal = async () => {
    
        try {
            if (modalType === 'addUser') {
                if (emailsToAdd.length === 0) {
                    setError('Por favor, adicione pelo menos um email antes de prosseguir.');
                    return;
                }
                const userData = {
                    emailsToAdd, // Emails collected from AddUserForm
                    role: selectedRole, // Role selected in AddUserForm
                    companyId: companyToAddTo, // Company selected in AddUserForm
                };
                await handleAddUser(userData, selectedRoleName);

                if (moveToPermissionPage) {
                    const currentUrl = location.pathname + location.search;
                    navigateWithContext(navigate, '/acesso-a-relatorios', currentUrl, { emailsToAdd });
                  }

            } else if (modalType === 'editUser') {
                // Gather the updated data for the selected user
                const userData = {
                    name: editedUserFirstName,   // Gather this from your EditUserForm state
                    surname: editedUserLastName, // Gather this from your EditUserForm state
                    email: editedUserEmail, // Gather this from your EditUserForm state
                };
                // Call the editUser function with the selected user ID and the gathered data
                await handleEditUser(selectedUserId, userData);
            }
    
            // If successful, close the modal
            setShowAdminModal(false);
            setSelectedUserId(null);
        } catch (error) {
            // If an error occurs, keep the modal open and display the error
            logException('Error adding/editing user:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName: fileName,
            });
        }
    };

    // Show access denied message if the user doesn't have permission
    if (!canViewUsers) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title="Gerenciar contas" />
                    </div>
                    <div className={styles.contentContainer}>
                        <AccessDenied />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    // Show loading spinner while fetching data
    if (loading) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title="Gerenciar contas" />
                    </div>
                    <div className={styles.contentContainer}>
                        <FadeLoader text="Carregando dados..." />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    return (
        <TooltipProvider>
            <ReportIssue/>
            <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                <div className={styles.header}>
                    <Header />
                </div>
                {companyAccountType === 'free' && (
                    <div className={styles.banner}>
                        <FreeTrialBanner />
                    </div>
                )}
                <div className={styles.ribbon}>
                    <TopRibbon title="Gerenciar contas" >
                        <Button
                            label="Convidar pessoas"
                            type="proceed"
                            onClick={handleAddUserClick}
                        >
                            <FontAwesomeIcon icon={faPlus} className={styles.plusIcon} /> {/* Add Plus Icon */}
                            Convidar pessoas
                        </Button>
                    </TopRibbon>
                </div>
                {/* Confirm Modal for removing users */}
                <ConfirmModal
                    show={showConfirmModal}
                    title="Excluir Conta"
                    message="Tem certeza de que deseja excluir este conta?"
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancelRemove}
                    confirmLabel="Confirmar"
                    cancelLabel="Cancelar"
                />

                {/* Admin Modal for adding/editing users */}
                {showAdminModal && (
                    <AdminModal
                        title={modalType === 'addUser' ? 'Adicionar conta' : 'Editar conta'}
                        onClose={handleCloseAdminModal}
                        onConfirm={handleConfirmAdminModal}
                        errorMessage={error}
                        isLoading={modalLoading}
                    >
                        {modalType === 'addUser' && 
                            <AddUserForm 
                                roles={roleAssignments}
                                userPermissions={userPermissions}
                                emailEnding={emailEnding}
                                setFormError={setError}
                                currentEmails={currentEmails}
                                setAddUserDetails={setAddUserDetails}
                                companyId={companyId}
                                allCompanies={allCompanies}
                                moveToPermissionPage={moveToPermissionPage}
                                setMoveToPermissionPage={setMoveToPermissionPage}
                            />}

                        {modalType === 'editUser' && 
                            <EditUserForm 
                                editedUserFirstName={editedUserFirstName}
                                editedUserLastName={editedUserLastName} 
                                editedUserEmail={editedUserEmail}
                                setFirstName={setEditedUserFirstName} 
                                setLastName={setEditedUserLastName}
                                setEmail={setEditedUserEmail}   
                            />
                        }
                    </AdminModal>
                )}

                <div className={styles.contentContainer}>
                    {/* Table for pending users with status 'Solicitado' */}
                    <div className={styles.adminPageTableHeader}>Solicitações pendentes</div>
                    {pendingUsers.length > 0 ? (
                        <>
                            <div className={styles.pendingUsersContainer}>
                                <SortableTable
                                    columns={pendingUserColumns}
                                    data={pendingUsers}
                                    renderRow={renderPendingRow} 
                                    styles={tableStyles} 
                                />
                            </div>
                        </>
                    ) : (
                        <div className={styles.noPendingRequests}>
                            Não há solicitações pendentes de contas.
                        </div>
                    )}

                    {/* Table for main users */}
                    <div className={styles.adminPageTableHeader}>Contas ativos</div>
                    <div className={styles.tableContainer}>
                        {/* Filters and Search for Users */}
                        <div className={styles.filterContainer}>
                            <div className={styles.search}>
                                <input
                                    type="text"
                                    placeholder="Pesquisar por email"
                                    value={userEmailSearchQuery}
                                    onChange={(e) => setUserEmailSearchQuery(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Pesquisar por nome"
                                    value={userNameSearchQuery}
                                    onChange={(e) => setUserNameSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className={styles.filter}>
                                {viewPermissionLevel === 'GLOBAL' && (
                                    <select value={companyFilter} onChange={(e) => setCompanyFilter(e.target.value)}>
                                        <option value="All">Todas as Empresas</option>
                                        {uniqueCompanies.map(company => (
                                            <option key={company} value={company}>{company}</option>
                                        ))}
                                    </select>
                                )}
                                <select value={userStatusFilter} onChange={(e) => setUserStatusFilter(e.target.value)}>
                                    <option value="All">Todos os Status</option>
                                    {uniqueStatuses.map((status) => (
                                        <option key={status} value={status}>{status}</option>
                                    ))}
                                </select>
                                <select value={userRoleFilter} onChange={(e) => setUserRoleFilter(e.target.value)}>
                                    <option value="All">Todos os Tipos</option>
                                    {uniqueRoles.map((role) => (
                                        <option key={role} value={role}>{role}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <SortableTable
                            columns={userColumns}
                            data={filteredUsers}
                            onSort={requestUserSort}
                            sortConfig={userSortConfig}
                            renderRow={renderRow} 
                            styles={tableStyles} 
                        />
                    </div>
                </div>
            </div>
        </TooltipProvider>
    );
};

export default UserManagementPage;
