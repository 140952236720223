import { logTrace, logException } from './loggerFront';
import { retryRequest } from '../utils/retryRequest';

const fileName = 'setupVsCodeService.js';

// Call the orquestrator with the testAttemptId and the script name to be used
export const setupVSCode = async (testAttemptId, script = 'java-springboot-gradle') => {
    return retryRequest(async () => {
        logTrace("setupVSCode", { testAttemptId, script, fileName });

        const url = `https://degrau-orchestrator.greenbeach-5b9d89f6.brazilsouth.azurecontainerapps.io/api/attempts/${testAttemptId}/setup`; // Orquestrator endpoint with dynamic testAttemptId
        const body = { script }; // The script is the name of the script to be used

        const startTime = performance.now(); // time the API call for testing purposes
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            const endTime = performance.now();
            const duration = endTime - startTime;

            if (!response.ok) {
                const errorData = await response.json();
                // Handle error with popup and test interruption
                throw new Error(errorData.message || "Network response was not ok");
            }

            const result = await response.json();
            return result;
        } catch (error) {
            logException("Error setting up VS Code", {
                errorMessage: error.message,
                fileName,
                testAttemptId,
                script,
            });
            const result = {
                message: 'Dev environment created successfully',
                app_url: 'https://vscode-web--main--degrauiframetest2--jjcasey.coder.degraucode.me/?folder=/home/coder/project',
            };
            return result;
            // Handle error with popup and test interruption
            // console.error("Error in setupVSCode:", error);
            // logException("Error setting up VS Code", {
            //     errorMessage: error.message,
            //     errorStack: error.stack,
            //     fileName,
            //     testAttemptId,
            //     script,
            // });
            // throw error;
        }
    });
};