// Form for user to create an account directly. Requires name, business email, company name, password, and password confirmation. 
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import freeTrialMessage from '../../dataInputs/freeTrialMessage';
import InputField from '../inputs/InputField';
import PasswordInputField from "../inputs/PasswordInputField";
import SsoContainer from '../inputs/SsoContainer';
import Logo from '../common/DegrauLogo';
import Button from '../common/Button';
import MarketingMessage from '../marketing/MarketingMessage';
import styles from './Popup.module.css';

const CreateAccountContent = ({
  email,
  setEmail,
  firstNames,
  setFirstNames,
  lastNames,
  setLastNames,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  companyName,
  setCompanyName,
  handleAction,
  errorMessage,
  successMessage,
  passwordRules,
  ssoProviders,
  handleSsoAction,
  onLoad,
  logoLoaded,
  ...otherProps
}) => {
  const [showPasswordRules, setShowPasswordRules] = useState(false);

  // Show password rules if error message containr 'senha'
  useEffect(() => {
    if (errorMessage && errorMessage.toLowerCase().includes('senha')) {
      setShowPasswordRules(true);
    }
  }, [errorMessage]);

  return (
    <div className={styles.splitPopupContent}>
      <div className={styles.popupContentSplitForm}>
        <p className={styles.popupTextLarge}>
          Bem-vindo a Degrau! Comece criando sua conta grátis.
        </p>
        <form
          className={styles.inputFieldContainerDouble}
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission
            handleAction(); // Call the custom action handler
          }}
        >
          <div className={styles.doubleInputFieldContainer}>
            <div className={styles.inputFieldAndLabelNarrow}>
              <label htmlFor="name">Nome(s)</label>
              <InputField
                id="name"
                type="text"
                value={firstNames}
                onChange={(e) => setFirstNames(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            <div className={styles.inputFieldAndLabelNarrow}>
              <label htmlFor="name">Sobrenome(s)</label>
              <InputField
                id="name"
                type="text"
                value={lastNames}
                onChange={(e) => setLastNames(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={styles.doubleInputFieldContainer}>
            <div className={styles.inputFieldAndLabelNarrow}>
              <label htmlFor="email">Email</label>
              <InputField
                id="email"
                type="email"
                autocomplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            <div className={styles.inputFieldAndLabelNarrow}>
              <label htmlFor="email">Empresa</label>
              <InputField
                id="company"
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={styles.doubleInputFieldContainer}>
            <div className={styles.inputFieldAndLabelNarrow}>
              <label htmlFor="password">Senha</label>
              <PasswordInputField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder=""
                autoComplete="new-password"
              />
            </div>
            <div className={styles.inputFieldAndLabelNarrow}>
              <label htmlFor="confirmPassword">Confirmar senha</label>
              <PasswordInputField
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder=""
                autoComplete="new-password" 
              />
            </div>
          </div>
          {showPasswordRules && (
            <div className={styles.passwordRules}>
              <p>A senha deve conter pelo menos:</p>
              <ul>
                {passwordRules.map((rule, index) => (
                  <li key={index}>{rule}</li>
                ))}
              </ul>
            </div>
          )}
          <Button
            disabled={!firstNames || !password || !confirmPassword || !companyName}
            type="form"
          >
            Experimente grátis
          </Button>
          <div className={styles.ssoContainer}> 
            <SsoContainer
              providers={ssoProviders}
              handleSsoAction={handleSsoAction}
              headerText="Crie sua conta com:"
            />
          </div>
          {errorMessage && (
            <p className={styles.errorText} role="alert">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className={styles.successText} role="alert">
              {successMessage}
            </p>
          )}
        </form>
      </div>
      <div className={styles.popupContentSplitMarketing}>
        <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
        <MarketingMessage information={freeTrialMessage} />
      </div>
    </div>
  );
};

CreateAccountContent.propTypes = {
  email: PropTypes.string.isRequired, 
  setEmail: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

export default CreateAccountContent;
