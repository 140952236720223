export const passwordRules = {
    minLength: 10,
    requiresUpperLowercase: true,
    requiresDigit: true,
    requiresSpecialChar: true,
    maxRepetitions: 3,
    sequenceLength: 4,
    passwordHistory: 12,
};

export const passwordRulesDescriptions = [
    `Pelo menos ${passwordRules.minLength} caracteres${passwordRules.requiresDigit ? `, incluindo pelo menos um número` : ''}.`,
    passwordRules.requiresUpperLowercase && "Pelo menos uma letra maiúscula e uma minúscula.",
    passwordRules.requiresSpecialChar && "Pelo menos um caractere especial (ex: !@#$%^&*).",
    `Não mais que ${passwordRules.maxRepetitions} caracteres repetidos consecutivamente (ex: aaa, 111).`,
    `Sem sequências de ${passwordRules.sequenceLength} ou mais caracteres (ex: abc, 123, cba, 321).`,
    "Não pode conter partes do seu nome, sobrenome ou email.",
    `Não pode ser igual às últimas ${passwordRules.passwordHistory} senhas utilizadas.`,
].filter(Boolean);

export const validatePassword = async (password) => {
    if (password.length < passwordRules.minLength) {
        throw new Error(`A senha deve ter pelo menos ${passwordRules.minLength} caracteres.`);
    }
    if (passwordRules.requiresUpperLowercase && (!/[A-Z]/.test(password) || !/[a-z]/.test(password))) {
        throw new Error("A senha deve conter pelo menos uma letra maiúscula e uma letra minúscula.");
    }
    if (passwordRules.requiresDigit && !/\d/.test(password)) {
        throw new Error("A senha deve conter pelo menos um número.");
    }
    if (passwordRules.requiresSpecialChar && !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        throw new Error("A senha deve conter pelo menos um caractere especial.");
    }
    if (hasRepetitiveCharacters(password, passwordRules.maxRepetitions)) {
        throw new Error(`A senha não pode conter mais de ${passwordRules.maxRepetitions} caracteres repetitivos consecutivos.`);
    }
    if (hasSequentialCharacters(password, passwordRules.sequenceLength)) {
        throw new Error(`A senha não pode conter sequências de ${passwordRules.sequenceLength} ou mais caracteres.`);
    }
};

const hasRepetitiveCharacters = (password, maxRepetitions) => {
    const regex = new RegExp(`(.)\\1{${maxRepetitions},}`, 'i');
    return regex.test(password);
};

const hasSequentialCharacters = (password, sequenceLength) => {
    const normalizedPassword = password.toLowerCase();
    for (let i = 0; i <= normalizedPassword.length - sequenceLength; i++) {
        const substr = normalizedPassword.substring(i, i + sequenceLength);
        if (isSequential(substr)) return true;
    }
    return false;
};

const isSequential = (str) => {
    const asciiCodes = str.split('').map(char => char.charCodeAt(0));
    return asciiCodes.every((code, index) => 
        index === 0 || Math.abs(code - asciiCodes[index - 1]) === 1
    );
};
