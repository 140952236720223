import useAuth from "../hooks/useAuth";
import { logTrace, logException, logEvent } from './loggerFront';
import { retryRequest } from '../utils/retryRequest';

const fileName = 'companyTestService.js';

// Get summary details of all test within filtering ranges like category or role
export const getAllTests = async (filters = {}) => {
    logTrace("getAllTests", { filters, fileName });
  
    const { accessToken } = useAuth();
    const token = accessToken();
  
    const query = new URLSearchParams(filters).toString(); // Convert filters to query string
  
    try {
      const response = await fetch(`/api/company-test/all-tests?${query}`, {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error fetching all tests", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: "testService.js",
        filters,
      });
      throw error; // Handle the error as needed
    }
};
  
// The names of all the tests to show in the dropdown
export const getAllTestNames = async () => {
    logTrace("getAllTestNames", { fileName });
  
    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
      const response = await fetch("/api/company-test/all-test-names", {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error fetching all test names", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
      });
      throw error; // Handle the error as needed
    }
};
  
// Get the overview of a test based on the version IDs
export const getTestOverview = async (versionIds) => {
    logTrace("getTestOverview", { versionIds, fileName });
  
    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
      const response = await fetch("/api/company-test/test-overview", {
        method: "POST",  // Assuming POST for multiple version IDs
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the auth token
        },
        body: JSON.stringify({ versionIds }), // Send the versionIds in the body
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }
  
      const result = await response.json();
      return result; // Handle the result as needed
    } catch (error) {
      logException("Error fetching test overview", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: "testService.js",
        versionIds,
      });
      throw error; // Handle the error as needed
    }
};
  
// Get the summary of the tests of a company, potentially filtered by companyID and a start at date
export const fetchCompanyTests = async (companyId = null, startAt = null) => {
    return retryRequest(async () => {
        logTrace("fetchCompanyTests", { companyId, startAt, fileName });

        // Construct the URL
        let url = new URL(`/api/company-test/company-tests`, window.location.origin);

        // Append companyId and startAt parameters if provided
        if (companyId) {
            url.searchParams.append("companyId", companyId);
        }
        if (startAt) {
            url.searchParams.append("startAt", startAt);
        }
        
        const { accessToken } = useAuth();
        const token = accessToken();

        try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            if (response.status === 404) {
            // Handle the case where no data is found
            return { data: [], issue: "noData" };
            } else {
            throw new Error("Network response was not ok");
            }
        }

        const results = await response.json();
        return { data: results, issue: null }; // Return the results with no issue
        } catch (error) {
            logException("Error fetchCompanyTests", {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName: fileName,
                companyId,
            });
            return { data: [], issue: "unknown" }; // Return an empty array with an "unknown" issue
        }
    });
};
  
// Get the short summary of the tests of a company, potentially filtered by companyID
export const fetchCompanyTestsSummary = async (companyId = null, resultLimit = null) => {
    return retryRequest(async () => {
      logTrace("fetchCompanyTestsSummary", { companyId, resultLimit, fileName });
  
      // Construct the URL
      let url = new URL(`/api/company-test/company-tests-short`, window.location.origin);
  
      // Append companyId and startAt parameters if provided
      if (companyId) {
        url.searchParams.append("companyId", companyId);
      }
      if (resultLimit) {
        url.searchParams.append("resultLimit", resultLimit);
      }
      
      const { accessToken } = useAuth();
      const token = accessToken();
  
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          if (response.status === 404) {
            // Handle the case where no data is found
            return { data: [], issue: "noData" };
          } else {
            throw new Error("Network response was not ok");
          }
        }
  
        const results = await response.json();
        return { data: results, issue: null }; // Return the results with no issue
      } catch (error) {
        logException("Error fetching tests short summary", {
          errorMessage: error.message,
          errorStack: error.stack,
          fileName: fileName,
          companyId,
        });
        return { data: [], issue: "unknown" }; // Return an empty array with an "unknown" issue
      }
    });
};

// Add a new test to a company
export const addCompanyTest = async (testId, companyId) => {
    return retryRequest(async () => {
      logTrace("addCompanyTest", { testId, companyId, fileName });
  
      const { accessToken } = useAuth();
      const token = accessToken();
  
      try {
        const response = await fetch("/api/company-test/add-company-test", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({ testId, companyId }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Network response was not ok");
        }
  
        const result = await response.json();
        return result;
      } catch (error) {
        logException("Error adding company test", {
          errorMessage: error.message,
          errorStack: error.stack,
          fileName,
          testId,
          companyId,
        });
        throw error;
      }
    });
};

// Edit a company test
export const editCompanyTest = async (id, testData) => {
    return retryRequest(async () => {
        logTrace("editCompanyTest", { id, testData });

        const { accessToken } = useAuth();
        const token = accessToken();

        try {
            const response = await fetch("/api/company-test/edit-company-test", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({ id, testData }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Network response was not ok");
            }

            const result = await response.json();
            return result;
        } catch (error) {
            logException("Error editing company test", {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
                id,
                testData,
            });
            throw error;
        }
    });
};

// Remove a company test
export const removeCompanyTest = async (id) => {
  return retryRequest(async () => {
      logTrace("removeCompanyTest", { id, fileName });

      const { accessToken } = useAuth();
      const token = accessToken();

      try {
          const response = await fetch(`/api/company-test/remove-company-test/${id}`, {
              method: "DELETE",
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
              },
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || "Network response was not ok");
          }

          const result = await response.json();
          logEvent("Company test removed", { id, token, fileName });
          return result;
      } catch (error) {
          logException("Error removing company test", {
              errorMessage: error.message,
              errorStack: error.stack,
              fileName,
              id,
          });
          throw error;
      }
  });
};

// Get the companyId from a companyTestId
export const getCompanyIdFromCompanyTestId = async (companyTestId) => {
  logTrace("getCompanyIdFromCompanyTestId", { companyTestId, fileName });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-test/company-id/${companyTestId}`, {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const result = await response.json();
    return result.companyId;
  } catch (error) {
    logException("Error fetching companyId from companyTestId", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      companyTestId,
    });
    throw error;
  }
};


