import { useState, useEffect } from 'react';

const useFilterTestSummary = (initialData) => {
    const [filteredData, setFilteredData] = useState(initialData);
    const [roleFilters, setRoleFilters] = useState([]); // Filter by role
    const [companyFilters, setCompanyFilters] = useState([]); // Filter by company
    const [selectAll, setSelectAll] = useState(true);
    const [testNameFilter, setTestNameFilter] = useState("");

    useEffect(() => {
        updateFilteredData(initialData, roleFilters, companyFilters, testNameFilter, selectAll);
    }, [roleFilters, companyFilters, testNameFilter, selectAll, initialData]);

    const updateFilteredData = (data, roleFilters, companyFilters, nameFilter, selectAll) => {
        let newData = [...data];
        if (!selectAll) {
            if (roleFilters.length > 0) {
                newData = newData.filter((d) => roleFilters.includes(d.roleName));
            }
            if (companyFilters.length > 0) {
                newData = newData.filter((d) => companyFilters.includes(d.companyName));
            }
        }
        if (nameFilter) {
            newData = newData.filter((d) =>
                d.testName.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }
        setFilteredData(newData);
    };

    return {
        filteredData,
        roleFilters,
        companyFilters,
        selectAll,
        testNameFilter,
        setRoleFilters,
        setCompanyFilters,
        setSelectAll,
        setTestNameFilter,
        handleRoleFilterChange: (roleName) => {
            if (roleFilters.includes(roleName)) {
                setRoleFilters(roleFilters.filter((name) => name !== roleName));
            } else {
                setRoleFilters([...roleFilters, roleName]);
            }
            setSelectAll(false);
        },
        handleCompanyFilterChange: (companyName) => { 
            if (companyFilters.includes(companyName)) {
                setCompanyFilters(companyFilters.filter((name) => name !== companyName));
            } else {
                setCompanyFilters([...companyFilters, companyName]);
            }
            setSelectAll(false);
        },
        handleSelectAll: () => {
            setSelectAll(true);
            setRoleFilters([]);
            setCompanyFilters([]);
        },
        handleTestNameChange: (event) => {
            setTestNameFilter(event.target.value);
        }
    };
};

export default useFilterTestSummary;
