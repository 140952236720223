import React, { useEffect } from 'react';
import ActionButton from '../common/ActionButton';
import { faPlus, faEdit, faTrash, faSync } from '@fortawesome/free-solid-svg-icons';
import styles from './ReportSummarySidebarFilters.module.css';

const TestSummarySidebarFilters = ({ 
    roleList, 
    roleFilters, 
    handleRoleFilterChange,
    totalCount,
    roleCounts,
    handleSelectAll, 
    selectAll, 
    testNameFilter, 
    handleTestNameChange,
    companies,
    companyFilters,
    handleCompanyFilterChange,
    companyCounts,
    isGlobalAccess = false,
}) => {

    // Sort roles list by count
    const sortedRoles = (roleList && roleCounts) ? roleList
        .filter(role => roleCounts[role])
        .sort((a, b) => roleCounts[b] - roleCounts[a])
        : [];

   // Sort companies list by count
   const sortedCompanies = (companies && companies.length > 0 && companyCounts) ? companies
        .filter(company => companyCounts[company])
        .sort((a, b) => companyCounts[b] - companyCounts[a])
        : [];
    
    return (
        <div className={styles.sidebarContainer}>
            <input
                type="text"
                placeholder="Buscar por nome"
                value={testNameFilter}
                onChange={handleTestNameChange}
                className={styles.filterInput}
            />
            <div className={`${styles.totalFilterRow} ${styles.filterRow}`}>
                <input
                    type="radio"
                    id="selectAll"
                    name="roleFilter"
                    checked={selectAll}
                    onChange={handleSelectAll}
                />
                <label htmlFor="selectAll">
                    Todos ({totalCount})
                </label>
            </div>
            {/* Role filters */}
            <div className={styles.filterTitle}>Cargos</div>
            {sortedRoles.map(
                (role) => (
                    <div key={role} className={styles.filterRow}>
                        <input
                            type="checkbox"
                            id={role}
                            name="roleFilter"
                            checked={
                                roleFilters.includes(role) && !selectAll
                            }
                            onChange={() => handleRoleFilterChange(role)}
                        />
                        <label htmlFor={role}>
                            {role} ({roleCounts[role]})
                        </label>
                    </div>
                )
            )}
            {/* Company filters */}
            {isGlobalAccess && sortedCompanies && sortedCompanies.length > 0 && (
                <>
                    <div className={styles.filterTitle}>Empresas</div>
                    {sortedCompanies.map(
                        (company) => (
                            <div key={company} className={styles.filterRow}>
                                <input
                                    type="checkbox"
                                    id={company}
                                    name="companyFilter"
                                    checked={
                                        companyFilters.includes(company) && !selectAll
                                    }
                                    onChange={() => handleCompanyFilterChange(company)}
                                />
                                <label htmlFor={company}>
                                    {company} ({companyCounts[company]})
                                </label>
                            </div>
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default TestSummarySidebarFilters;
