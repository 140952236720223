import React, { useState, useEffect } from 'react';
import getSectionTimeInMinutes from '../../utils/getSectionTimeInMinutes';
import FadeLoader from '../common/FadeLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import styles from './InterviewOverview.module.css';

const InterviewOverview = ({ data }) => {
    // State to track the currently expanded section
    const [expandedSection, setExpandedSection] = useState(null);

    // Function to handle expanding/collapsing sections
    const toggleSection = (index) => {
        setExpandedSection(index === expandedSection ? null : index);
    };

    // Sort the testSections array by section_order before rendering
    const sortedSections = data?.testSections.sort((a, b) => {
        return a.TestSection.section_order - b.TestSection.section_order;
    });

    // if no data return null
    if (!data) {
        return <FadeLoader text="Carregando dados..." height='300px' width='500px' />;
    }

    return (
        <div className={styles.interviewOverviewContainer}>
            <div className={styles.interviewOverviewHeader}>
                <div className={styles.interviewTitleContainer}>
                    <div className={styles.interviewTitle}>
                        {data.name} 
                    </div>
                    <div className={styles.interviewDetail}>
                        <FontAwesomeIcon icon={faClock} className={styles.interviewSummaryIcon} />
                        {Math.round(data.totalTime / 60)} mins
                    </div>
                </div>
                <div className={styles.interviewDetails}>
                    <p className={styles.interviewSummary}>{data.short_description}</p>
                </div>
            </div>
            <div className={styles.interviewOverviewBody}>
                <div className={styles.interviewSections}>
                    <div className={styles.interviewSubtitle}>Seções ({data.testSections.length})</div>
                    <ul className={styles.sectionsList}>
                        {sortedSections.map((section, index) => (
                            <li key={index} className={styles.sectionItem}>
                                <div className={styles.sectionHeader} onClick={() => toggleSection(index)}>
                                    <span className={styles.sectionName}>{section.name}</span>
                                    <span className={styles.sectionTime}>
                                        <FontAwesomeIcon icon={faClock} className={styles.interviewSummaryIcon} />{getSectionTimeInMinutes(section.time)} mins
                                    </span>
                                    <FontAwesomeIcon 
                                        icon={expandedSection === index ? faChevronUp : faChevronDown} 
                                        className={styles.expandSectionIcon}
                                    />
                                </div>
                                <div className={styles.sectionSummaryDetails}>
                                    <span className={styles.sectionDetail}>Tipo: {section.sectionType.name}</span>
                                    <span className={styles.sectionDetail}>|  Formato: {section.interactionFormat.name}</span>
                                    <span className={styles.sectionDetail}>|  Dificuldade: {section.difficulty}</span>
                                    {section.tools && <span className={styles.sectionDetail}>|  Ferramentas: {section.tools}</span>}
                                    {section.languages && <span className={styles.sectionDetail}>|  Liguagens: {section.languages}</span>}
                                    {section.frameworks && <span className={styles.sectionDetail}>|  Frameworks: {section.frameworks}</span>}
                                </div>
                                {expandedSection === index && (
                                    <div className={styles.sectionExpanded}>
                                        <div className={styles.sectionDetail}>
                                            <strong>Descrição:</strong> {section.long_description}
                                        </div>
                                        <div className={styles.sectionDetail}>
                                            <strong>Competências:</strong>
                                            <ul>
                                                {section.sectionSkills.map((skill, skillIndex) => (
                                                    <li key={skillIndex}>{skill.name}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.interviewSkills}>
                    <div className={styles.interviewDetails}>
                        <div className={styles.interviewSubtitle}>Cargo:</div>
                        <p className={styles.interviewDetail}>{data.role.roleName}</p>
                    </div>
                    <div className={styles.interviewDetails}>
                        <div className={styles.interviewSubtitle}>Competências Principais</div>
                        <ul className={styles.skillsList}>
                            {data.testSkills.map((skill, index) => (
                                <li key={index} className={styles.skillItem}>
                                    {skill.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InterviewOverview;
