// Interface for the general interview where assistant asks questions (voice and text) and the candidate answers and there can be follow up questions
import React, { useState, useEffect, useRef } from 'react';
import { useTestContext } from '../../contexts/TestContext';
import { useAppContext } from '../../contexts/AppContext';
import { openSpeechToTextWebSocket, subscribe, startRecordingAudio, stopRecordingAudio, cancelRecordingAudio, startInterview } from '../../services/transcribeConversationService';
import Button from '../common/Button';
import AudioConversationBox from './AudioConversationBox';
import VolumeVisualizer from './VolumeVisualizer';
import RecordingTimer from '../common/RecordingTimer';
import AudioCheck from './AudioCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './GeneralInterviewInterface.module.css';

const GeneralInterviewInterface = () => {
  const [audioCheckComplete, setAudioCheckComplete] = useState(false);
  const [isInterviewStarted, setIsInterviewStarted] = useState(false); // Show start interview button before
  const [isInterviewEnded, setIsInterviewEnded] = useState(false); // Show end interview button after
  const [isQuestionActive, setIsQuestionActive] = useState(false); // Decide whether the usr can respond or not
  const [isRecording, setIsRecording] = useState(false);
  const { testAttemptId, testId, completeTest } = useTestContext();
  const [messages, setMessages] = useState([]); // Set of user (audio) and assistant
  const { webSocketUrl } = useAppContext(); // Base url for the websocket

  // Initialize the websocket connection on load and subscribe to alerts
  useEffect(() => {
    const initWebSocket = async () => {
        try {
          const url = webSocketUrl + '/speech-to-text';
          await openSpeechToTextWebSocket(url, testAttemptId, testId);
            
            // Subscribe to conversation updates
          const unsubscribeConversation = subscribe('CONVERSATION_UPDATE', 
              (conversation) => {
                  // Ensure conversation is an array before updating state
                  if (Array.isArray(conversation)) {
                      setMessages(conversation);
                  } else {
                      console.warn('Invalid conversation format:', conversation);
                      setMessages([]); // Set empty array if invalid
                  }
              }
          );

          const unsubscribeQuestionStarted = subscribe('QUESTION_STARTED', 
            () => {
                console.log('Question started - enabling recording');
                setIsQuestionActive(true);
            }
          );
        
          const unsubscribeQuestionCompleted = subscribe('QUESTION_COMPLETED', 
            () => {
                console.log('Question completed - disabling recording');
                setIsQuestionActive(false);
            }
          );
          
          const unsubscribeComplete = subscribe('INTERVIEW_COMPLETE', 
            () => {
                setIsInterviewEnded(true);
            }
          );
          
          return () => {
              unsubscribeConversation();
              unsubscribeComplete();
              unsubscribeQuestionStarted();
              unsubscribeQuestionCompleted();
          };
        } catch (error) {
            console.error("Failed to initialize WebSocket:", error);
        }
    };

    initWebSocket();
  }, [testAttemptId]);

  const handleAudioCheckComplete = () => {
    setAudioCheckComplete(true);
  };

  const toggleRecording = () => {
    if (!isRecording) {
        startRecordingAudio();
    } else {
        stopRecordingAudio();
    }
    setIsRecording(!isRecording);
  };

  // Stop recording and dont send the audio
  const handleCancelRecording = () => {
    cancelRecordingAudio();
    setIsRecording(false);
  };

  const handleStartInterview = () => {
    startInterview();
    setIsInterviewStarted(true);
  };

  const handleEndInterview = () => {
    console.log("Ending interview");
    setIsInterviewEnded(true);
    completeTest();
  };

  // Animated buttons

  const buttonVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 }
  };

  const cancelAnimation = {
    initial: { scale: 1, opacity: 1 },
    exit: { 
      scale: 0,
      opacity: 0,
      rotate: -20,
      transition: { duration: 0.2 }
    }
  };

  const sendAnimation = {
    initial: { scale: 1, opacity: 1 },
    exit: { 
      scale: 0,
      opacity: 0,
      x: 50,
      transition: { duration: 0.3 }
    }
  };

  // First do the audio check
  if (!audioCheckComplete) {
    return <AudioCheck onTestComplete={handleAudioCheckComplete} />;
  }

  // Then start the interview button
  if (!isInterviewStarted) {
    return (
      <div className={styles.startInterviewContainer}>
        <Button
          onClick={handleStartInterview}
          type="proceed">
          Iniciar Entrevista
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.generalInterviewInterface}>
        <div className={styles.chatContainer}>
            <AudioConversationBox messages={messages || []}/>
        </div>
        {isInterviewEnded ? ( 
          <div className={styles.controlGroup}>
            <Button 
              onClick={handleEndInterview} 
              type="proceed">
              Finalizar Entrevista
            </Button>
          </div>
        ) : (
          <div className={styles.controlGroup}>
            <AnimatePresence mode="wait">
              {!isRecording ? (
                <motion.button
                  key="microphone"
                  className={`${styles.voiceButton} ${styles.green}`}
                  onClick={toggleRecording}
                  disabled={!isQuestionActive}
                  variants={buttonVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <FontAwesomeIcon icon={faMicrophone} size="1x"/>
                </motion.button>
              ) : (
                <>
                  <motion.button
                    key="send"
                    className={`${styles.voiceButton} ${styles.green}`}
                    onClick={toggleRecording}
                    variants={sendAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} size="1x"/>
                  </motion.button>
                  <VolumeVisualizer isActive={isRecording} />
                  <div className={styles.recordingTimerContainer}>
                    <RecordingTimer />
                  </div>
                  <motion.button
                    key="cancel"
                    className={`${styles.voiceButton} ${styles.red}`}
                    onClick={handleCancelRecording}
                    variants={cancelAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <FontAwesomeIcon icon={faTrash} size="1x"/>
                  </motion.button>
                </>
              )}
            </AnimatePresence>
          </div>
        )}
    </div>
  );
};

export default GeneralInterviewInterface;
