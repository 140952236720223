// Form to edit the company name
import React from 'react';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';

const EditCompanyNameForm = ({ currentCompanyName, setCompanyName }) => {
    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Nome da empresa</label>
                <input
                    type="text"
                    value={currentCompanyName}  // Bind to the company name state
                    onChange={(e) => setCompanyName(e.target.value)}
                />
            </div>
        </div>
    );
};

export default EditCompanyNameForm;
