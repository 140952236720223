class AudioPlayerService {
    constructor() {
        this.currentAudio = null;
        this.activeMessageId = null;
        this.callbacks = new Map();
    }

    subscribe(messageId, callbacks) {
        // Store callbacks separately for different sources
        const key = `${messageId}-${Math.random()}`; // Create unique key for each subscriber
        this.callbacks.set(key, { messageId, callbacks });
        
        return () => {
            this.callbacks.delete(key);
        };
    }

    async play(messageId, audioUrl) {
        // Stop current audio if different message
        if (this.activeMessageId && this.activeMessageId !== messageId) {
            this.stop();
        }

        // Create or reuse audio instance
        if (!this.currentAudio || this.activeMessageId !== messageId) {
            this.currentAudio = new Audio(audioUrl);
            this.activeMessageId = messageId;
            
            // Set up listeners
            this.currentAudio.addEventListener('timeupdate', () => this.handleTimeUpdate(messageId));
            this.currentAudio.addEventListener('loadedmetadata', () => this.handleMetadata(messageId));
            this.currentAudio.addEventListener('ended', () => this.handleEnded(messageId));
        }

        try {
            await this.currentAudio.play();
            this.notifyCallback(messageId, 'onPlay');
        } catch (error) {
            console.error('Error playing audio:', error);
            this.notifyCallback(messageId, 'onError', error);
        }
    }

    pause() {
        if (this.currentAudio) {
            this.currentAudio.pause();
            this.notifyCallback(this.activeMessageId, 'onPause');
        }
    }

    stop() {
        if (this.currentAudio) {
            this.currentAudio.pause();
            this.currentAudio.currentTime = 0;
            this.cleanup();
        }
    }

    seek(messageId, percent) {
        if (this.currentAudio && this.activeMessageId === messageId) {
            this.currentAudio.currentTime = percent * this.currentAudio.duration;
        }
    }

    cleanup() {
        if (this.currentAudio) {
            this.currentAudio.removeEventListener('timeupdate', () => {});
            this.currentAudio.removeEventListener('loadedmetadata', () => {});
            this.currentAudio.removeEventListener('ended', () => {});
            this.currentAudio = null;
        }
        this.activeMessageId = null;
    }

    handleTimeUpdate(messageId) {
        if (!this.currentAudio || !this.currentAudio.duration) {
            return;
        }
        
        const progress = (this.currentAudio.currentTime / this.currentAudio.duration) * 100;
        if (!isNaN(progress) && isFinite(progress)) {
            this.notifyCallback(messageId, 'onProgress', progress);
        }
    }

    handleMetadata(messageId) {
        this.notifyCallback(messageId, 'onDuration', this.currentAudio.duration);
    }

    handleEnded(messageId) {
        this.notifyCallback(messageId, 'onEnded');
        this.cleanup();
    }

    notifyCallback(messageId, type, data) {
        
        // Iterate through all callbacks
        for (const [key, { messageId: callbackMessageId, callbacks }] of this.callbacks) {
            // Handle global callbacks (null messageId)
            if (callbackMessageId === null && callbacks[type]) {
                if (data !== undefined) {
                    callbacks[type](data);
                } else {
                    callbacks[type](messageId);  // Pass messageId to global callbacks
                }
            }
            
            // Handle message-specific callbacks
            if (callbackMessageId === messageId && callbacks[type]) {
                if (data !== undefined) {
                    callbacks[type](data);
                } else {
                    callbacks[type]();  // Message-specific callbacks don't need messageId
                }
            }
        }
    }
}

export const audioPlayer = new AudioPlayerService();