import React, { useEffect, useState, useRef } from 'react';
import Button from '../common/Button';
import { createShareableLink } from '../../services/shareableLinkService';
import LinkContainer from '../common/LinkContainer';
import styles from './ShareLinkModal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FadeLoader } from 'react-spinners';

const ShareLinkModal = ({ 
    currentReportCode, 
    currentCandidateName,
    currentCompanyTestId,
    setIsShareModalVisible,
}) => {
    const [linkType, setLinkType] = useState('PRIVATE'); // PRIVATE, UNIQUE, or RANGE
    const [startDate, setStartDate] = useState(''); 
    const [endDate, setEndDate] = useState(''); 
    const [isGeneratingLink, setIsGeneratingLink] = useState(false); 
    const [shareableLink, setShareableLink] = useState(null); 
    const [privateLink, setPrivateLink] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); 
    const [successMessage, setSuccessMessage] = useState(''); 
    const modalRef = useRef(null); 

    useEffect(() => {
        const defaultStartDate = new Date();
        defaultStartDate.setDate(defaultStartDate.getDate() - 14);
        setStartDate(defaultStartDate.toISOString().split('T')[0]);

        const defaultEndDate = new Date();
        defaultEndDate.setDate(defaultEndDate.getDate() + 14);
        setEndDate(defaultEndDate.toISOString().split('T')[0]);

        const currentUrl = window.location.origin;
        const generatedPrivateLink = `${currentUrl}/relatorio?tentativa=${currentReportCode}`;
        setPrivateLink(generatedPrivateLink);
    }, [currentReportCode]);    

    const handleLinkTypeChange = (event) => {
        setLinkType(event.target.value);
        setShareableLink(null); 
    };

    const handleGenerateLink = () => {
        const data = {
            type: linkType === 'RANGE' ? 'RANGE' : 'UNIQUE',
            startAt: startDate,
            endAt: endDate,
            companyTestId: linkType === 'RANGE' ? currentCompanyTestId : null,
            urlCode: linkType === 'UNIQUE' ? currentReportCode : null,
        };
        generateShareableLink(data);
    };

    const generateShareableLink = async (data) => {
        setIsGeneratingLink(true);
        try {
            const response = await createShareableLink(data);
            setShareableLink(response.link);
            setIsGeneratingLink(false);
            setErrorMessage('');
        } catch (error) {
            setIsGeneratingLink(false);
            setShareableLink('');
            setErrorMessage('Erro ao gerar o link compartilhável');
        }
    };

    const handleClose = () => {
        setIsShareModalVisible(false);
    };

    useEffect(() => {
        const handleTabKey = (e) => {
            if (e.key === 'Tab') {
                const focusableElements = modalRef.current.querySelectorAll(
                    'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
                );
                const firstElement = focusableElements[0];
                const lastElement = focusableElements[focusableElements.length - 1];

                if (!focusableElements.length) return;

                if (e.shiftKey) {
                    if (document.activeElement === firstElement) {
                        e.preventDefault();
                        lastElement.focus();
                    }
                } else {
                    if (document.activeElement === lastElement) {
                        e.preventDefault();
                        firstElement.focus();
                    }
                }
            }
        };

        const handleEscapeKey = (e) => {
            if (e.key === 'Escape') {
                setIsShareModalVisible(false);
            }
        };

        document.addEventListener('keydown', handleTabKey);
        document.addEventListener('keydown', handleEscapeKey);

        if (modalRef.current) {
            const focusableElements = modalRef.current.querySelectorAll(
                'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            if (focusableElements.length) focusableElements[0].focus();
        }

        return () => {
            document.removeEventListener('keydown', handleTabKey);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    return (
        <div className={styles.modalOverlay} role="dialog" aria-labelledby="share-modal-title" aria-modal="true">
            <div className={styles.shareLinkModalContainer} ref={modalRef} tabIndex="-1">
                <button onClick={handleClose} className={styles.closeButton} aria-label="Fechar">
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <div id="share-modal-title" className={styles.header}>Compartilhar Link ({currentCandidateName})</div>

                <div className={styles.radioGroup}>
                    <label className={styles.optionLabel}>
                        <input type="radio" value="PRIVATE" checked={linkType === 'PRIVATE'} onChange={handleLinkTypeChange} />
                        Link privado
                    </label>
                    {linkType === 'PRIVATE' && (
                        <div className={styles.generateLinkNote}>
                            O usuário deve estar logado para visualizar o relatório com este link.
                        </div>
                    )}
                    <label className={styles.optionLabel}>
                        <input type="radio" value="UNIQUE" checked={linkType === 'UNIQUE'} onChange={handleLinkTypeChange} />
                        Link público
                    </label>
                </div>
                {(linkType === 'UNIQUE') && (
                    <>
                        <div className={styles.generateLinkNote}>
                            Qualquer pessoa que receber este link poderá acessar o relatório. O link será válido por 14 dias.
                        </div>
                        {!isGeneratingLink && !shareableLink && (
                            <div className={styles.generateLinkButtonContainer}>
                                <Button onClick={handleGenerateLink} type="proceed">
                                    Gerar Link Público
                                </Button>
                            </div>
                        )}
                    </>
                )}

                {isGeneratingLink && (
                    <div className={styles.loadingSpinner}>
                        <FadeLoader size={10} color={"#246E24"} />
                        Gerando link público...
                    </div>
                )}
                {!isGeneratingLink && shareableLink && linkType === "UNIQUE" && (
                    <LinkContainer shareableLink={shareableLink} />
                )}
                {linkType === "PRIVATE" && (
                    <LinkContainer shareableLink={privateLink} />
                )}

                {errorMessage && (
                    <div className={styles.errorMessage}>
                        {errorMessage}
                    </div>
                )}
                {successMessage && (
                    <div className={styles.successMessage}>
                        {successMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShareLinkModal;
