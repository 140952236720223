// Show terms of use and privacy policy and checkboxes to accept and accept permission to be contacted in the future. 
import React, { useState, useEffect, useRef } from 'react';
import { useTestContext } from '../../contexts/TestContext';
import { getCompanyLogoPath } from '../../utils/getCompanyLogo';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css';

const TermsContent = ({ onTermsChange, onLoad, logoLoaded, ...otherProps }) => {
    const [contactPermission, setContactPermission] = useState(true);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const { termsErrorVisible, setTermsErrorVisible, roleDetails, companyDetails } = useTestContext(); // Error message if they try to continue without clicking accept

    // Extract the company code and logo from the global state
    const [partnerLogoPath, setPartnerLogoPath] = useState(null); // The path to the partner company logo
    useEffect(() => {
      const logoPath = getCompanyLogoPath(companyDetails); // Get the partner company logo path
      setPartnerLogoPath(logoPath);
    }, [companyDetails]);

    // Checkbox for permission to contact in the future
    const handleContactPermissionChange = () => {
        const newContactPermission = !contactPermission;
        setContactPermission(newContactPermission);
        onTermsChange(termsAgreed, newContactPermission);
    };

    // Checkbox for accepting the T&Cs
    const handleTermsAgreementChange = () => {
        const newTermsAgreed = !termsAgreed;
        setTermsAgreed(newTermsAgreed);

        // Notify AppContext about the change
        onTermsChange(newTermsAgreed, contactPermission);

        // Reset error visibility in AppContext if terms are now agreed
        if (newTermsAgreed) {
            setTermsErrorVisible(false);
        }
    };

    return (
        <>
            <div id="popup-title" className={styles.srOnly}>
                Pop-up de aceitação dos termos e condições
            </div>
            <div className={styles.logos}>
                <div className={styles.logoContainer}>
                    {/* Degrau Logo */}
                    <Logo className={styles.degrauLogoTermsPopup} onLoad={onLoad} />
                </div>
                <div className={styles.logoContainer}>
                    {/* Partner Company Logo */}
                    {partnerLogoPath && (
                        <img src={partnerLogoPath} alt="Logotipo da empresa de contratação" className={styles.partnerLogo} />
                    )}
                </div>
            </div>
            <p id="popup-description" className={styles.popupText}>
                Bem-vinda(o)!<br /><br /> Antes de começarmos, precisamos do seu consentimento sobre nossos&nbsp;
                <a href="/termos-e-condicoes" target="_blank" rel="noopener noreferrer" className={styles.link}>Termos de Uso</a> e&nbsp;
                <a href="/politica-de-privacidade" target="_blank" rel="noopener noreferrer" className={styles.link}>Política de Privacidade</a>.
                Esses documentos explicam como suas informações são usadas e protegidas.
            </p>

            {/*Contact permision*/}
            <div className={styles.termsCheckboxContainer}>
                <input 
                    type="checkbox" 
                    id="contactPermissionCheckbox" 
                    name="contact_permission" 
                    checked={contactPermission}
                    onChange={handleContactPermissionChange}
                />
                <label htmlFor="contactPermissionCheckbox">Concordo em ser contatado pela Degrau no futuro.</label>
            </div>

            {/*T&C's*/}
            <div className={styles.termsCheckboxAndErrorContainer}>
                <div className={styles.termsCheckboxContainer}>
                    <input 
                        type="checkbox" 
                        id="termsCheckbox" 
                        name="terms" 
                        checked={termsAgreed}
                        onChange={handleTermsAgreementChange}
                    />
                    <label htmlFor="termsCheckbox">Aceito os Termos de Uso e a Política de Privacidade.</label>
                </div>
                {termsErrorVisible && (
                    <p className={styles.termsError} role="alert">
                        É necessário concordar com os termos para prosseguir.
                    </p>
                )}
            </div>

            <p className={styles.popupText}>
                Seu consentimento permite que você acesse nossos serviços. Se tiver dúvidas, entre em contato em&nbsp;
                <a href="mailto:privacidade@degrau.co">privacidade@degrau.co</a>.
            </p>

        </>
    );
};

export default TermsContent;
