import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';
import { useTestContext } from '../contexts/TestContext';
import { TooltipProvider } from "../contexts/TooltipContext";
import {validatePassword, passwordRulesDescriptions} from '../utils/validatePassword';
import { getCompanyLogoPath } from '../utils/getCompanyLogo';
import useEnsureUserData from '../hooks/useEnsureUserData';
import useAuth from '../hooks/useAuth';
import { removeUser, editProfile } from '../services/companyUserService';
import { logException, logEvent, logTrace } from "../services/loggerFront";
import { changePassword, checkHasPassword, setInitialPassword } from '../services/authenticationService';
import { roleSummaryTableHeaders, roleSummaryTableRows } from '../dataInputs/roleSummaryTable'; // Data for the user role summary table
// Standard page components
import Header from "../components/common/Header";
import FreeTrialBanner from "../components/common/FreeTrialBanner";
import TopRibbon from '../components/common/TopRibbon';
import ReportIssue from "../components/common/ReportIssue";
import ActionButton from '../components/common/ActionButton';

import InfoTable from '../components/common/InfoTable';
import AdminModal from '../components/common/AdminModal';
import EditUserForm from '../components/inputs/EditUserForm';
import ChangePasswordForm from '../components/inputs/ChangePasswordForm';
import ConfirmModal from '../components/common/ConfirmModal';
import { faQuestionCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 

const MyAccountPage = () => {
    const {
        userFirstName,
        userLastName,
        setUserFirstName,
        setUserLastName,
        userInitials,
        userId,
        userEmail,
        setUserEmail,
        userRoles,
        companyAccountType,
    } = useAppContext(); // Access the user context
    const {companyDetails} = useTestContext(); // Access the company context
    const navigate = useNavigate();
    
    // on load fetch check user is logged and get user data
    const { fetchUserData } = useEnsureUserData();
    const { checkAndHandleTokenExpiration, signOut } = useAuth();
    useEffect(() => {
        checkAndHandleTokenExpiration();
        fetchUserData();
    }, []);

    // Edit details states
    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    // UI states
    const [loading, setLoading] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false); // Control modal visibility for editing details
    const [showPasswordModal, setShowPasswordModal] = useState(false); // Control modal visibility for changing password
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Control Confirm modal
    const [partnerLogoPath, setPartnerLogoPath] = useState(null); // The path to the partner company logo
    useEffect(() => {
      const logoPath = getCompanyLogoPath(companyDetails); // Get the partner company logo path
      setPartnerLogoPath(logoPath);
    }, [companyDetails]);
    // Password states
    const [hasPassword, setHasPassword] = useState(true);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const fileName = 'MyAccountPage.js'; // Define the filename for the logs

    // set the new values to the current user data
    useEffect(() => {
        setNewFirstName(userFirstName);
        setNewLastName(userLastName);
        setNewEmail(userEmail);
    }, [userFirstName, userLastName, userEmail]); 

    // check if the user has a password
    useEffect(() => {
        const checkPassword = async () => {
            const response = await checkHasPassword();
            setHasPassword(response);
        };
        checkPassword();
    }, []);

    const handleEditProfileDetails = async () => {
        try {
            setLoading(true);
            await editProfile({ name: newFirstName, surname: newLastName, email: newEmail });
            setUserFirstName(newFirstName);
            setUserLastName(newLastName);
            setUserEmail(newEmail);
            setShowEditModal(false); // Close modal after save
        } catch (error) {
            logException('Failed to update user details', {
                errorMessage: error.message,
                errorStack: error.stack,
                userId: userId || 'No user ID',
                fileName,
            });
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordChange = async () => {
        setError(''); // Clear any previous errors
        setSuccess(''); // Clear any previous success messages

        // Check if the password meets the requirements
        try {
            await validatePassword(newPassword);
        } catch (error) {
            setError(error.message);
            setTimeout(() => setError(''), 5000); // Clear the error message after 5 seconds
            return;
        }

        // Check if they match
        if (newPassword !== confirmNewPassword) {
            setError("As novas senhas não coincidem");
            setTimeout(() => setError(''), 5000); // Clear the error message after 5 seconds
            return;
        }

        try {
            setLoading(true);
            let response;
            if (hasPassword) {
                response = await changePassword(currentPassword, newPassword);
            } else {
                response = await setInitialPassword(newPassword);
            }
            
            if (response.success) {
                setSuccess(response.data.message || "Senha alterada com sucesso");
                setTimeout(() => setSuccess(''), 15000);
                setTimeout(() => setShowPasswordModal(false), 5000);
                clearPasswordFields();
                setHasPassword(true); // Update the state to reflect that the user now has a password
            } else {
                // Handle error from the API
                setError(response.error || "Ocorreu um erro ao alterar a senha");
                setTimeout(() => setError(''), 15000); // Keep the error message visible for longer
            }
        } catch (error) {
            setError(error.message || "Ocorreu um erro ao alterar a senha");
            setTimeout(() => setError(''), 15000); // Keep the error message visible for longer
        } finally {
            setLoading(false);
        }
    };

    const clearPasswordFields = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await removeUser(userId);
            signOut();
            navigate('/login'); 
        } catch (error) {
            logException('Failed to delete account', {
                errorMessage: error.message,
                errorStack: error.stack,
                userId: userId || 'No user ID',
                fileName,
            });
            setError('Erro ao excluir a conta');
            setTimeout(() => setError(''), 5000); // Clear the error message after 5 seconds
        } finally {
            setLoading(false);
            setShowConfirmModal(false); // Close the confirmation modal
        }
    };

    return (
        <TooltipProvider>
            <ReportIssue/>
            <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                <div className={styles.header}>
                    <Header />
                </div>
                {companyAccountType === 'free' && (
                    <div className={styles.banner}>
                        <FreeTrialBanner />
                    </div>
                )}
                <div className={styles.ribbon}>
                    <TopRibbon title="Minha conta" />
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.userProfileContainer}>
                        <div className={styles.userOverview}>
                            <div className={styles.largeUserIcon}>{userInitials}</div>
                            <div className={styles.userDetails}>

                                {/* name(s) */}
                                <div className={styles.userDetailWithIconContainer}>
                                    <div className={styles.userDetail}>
                                        <div className={styles.userName}>{userFirstName} {userLastName}</div>
                                    </div>
                                    <ActionButton 
                                        icon={faEdit} 
                                        label="Editar meus detalhes"
                                        onClick={() => setShowEditModal(true)} // Show modal on click
                                        showHoverPopout={true}
                                        popoutText="Editar meus detalhes"
                                        popoutPosition="left"
                                    />
                                </div>

                                {/* email */}
                                <div className={styles.userDetailWithIconContainer}>
                                    <div className={styles.userDetail}>
                                        <div className={styles.userDetailName}>Email: </div>
                                        <div className={styles.userDetailContent}>{userEmail}</div>
                                    </div>
                                    <ActionButton 
                                        icon={faEdit} 
                                        label="Editar meus detalhes"
                                        onClick={() => setShowEditModal(true)} // Show modal on click
                                        showHoverPopout={true}
                                        popoutText="Editar meus detalhes"
                                        popoutPosition="left"
                                    />
                                </div>

                                <div className={styles.userDetailWithIconContainer}>
                                    <div className={styles.userDetail}>
                                        <div className={styles.userDetailName}>Tipo de conta: </div>
                                        <div className={styles.userDetailContent}>{userRoles}</div>
                                    </div>
                                    <ActionButton 
                                        icon={faQuestionCircle} 
                                        label="Informação Tipo de Conta"
                                        showHoverPopout={true}
                                        popoutPosition="left"
                                        hoverComponent={<InfoTable headers={roleSummaryTableHeaders} rows={roleSummaryTableRows} popout={true} popoutPosition="left" />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.userCompanyOverview}>
                            {partnerLogoPath && (
                                <div className={styles.userCompanyLogo}>
                                    <img
                                        src={partnerLogoPath}
                                        alt="Logotipo da empresa de contratação"
                                        className={styles.partnerLogo}
                                        aria-label="Logotipo da empresa de contratação"
                                    />
                                </div>
                            )}
                            <div className={styles.userDetailContainer}>
                                <div className={styles.userDetail}>
                                    <div className={styles.userDetailName}>Empresa: </div>
                                    <div className={styles.userDetailContent}>{companyDetails.companyName}</div>
                                </div>
                            </div>
                        </div>
                        {hasPassword ? (
                            <p
                                className={styles.changePasswordButton}
                                onClick={() => setShowPasswordModal(true)}
                                role="button"
                                tabIndex="0"
                                aria-pressed="false"
                            >
                                Alterar senha
                            </p>
                        ) : (
                            <p
                                className={styles.changePasswordButton}
                                onClick={() => setShowPasswordModal(true)}
                                role="button"
                                tabIndex="0"
                                aria-pressed="false"
                            >
                                Definir senha
                            </p>
                        )}
                        <p
                            className={styles.deleteAccountButton}
                            onClick={() => setShowConfirmModal(true)} // Open confirm modal
                            role="button"
                            tabIndex="0"
                            aria-pressed="false"
                        >
                            Excluir conta
                        </p>
                    </div>

                    {showPasswordModal && (
                        <AdminModal
                            title={hasPassword ? "Alterar Senha" : "Definir Senha"}
                            onClose={() => setShowPasswordModal(false)}
                            onConfirm={handlePasswordChange}
                            errorMessage={error}
                            successMessage={success}
                            isLoading={loading}
                        >
                            <ChangePasswordForm
                                currentPassword={currentPassword}
                                newPassword={newPassword}
                                confirmNewPassword={confirmNewPassword}
                                setCurrentPassword={setCurrentPassword}
                                setNewPassword={setNewPassword}
                                setConfirmNewPassword={setConfirmNewPassword}
                                passwordRules={passwordRulesDescriptions}
                                showCurrentPassword={hasPassword}
                            />
                        </AdminModal>
                    )}

                    {showEditModal && (
                        <AdminModal
                            title="Editar meus detalhes"
                            onClose={() => setShowEditModal(false)} // Close modal
                            onConfirm={handleEditProfileDetails}
                            isLoading={loading}
                        >
                            <EditUserForm
                                editedUserFirstName={newFirstName}
                                editedUserLastName={newLastName}
                                editedUserEmail={newEmail}
                                setFirstName={setNewFirstName}
                                setLastName={setNewLastName}
                                setEmail={setNewEmail}
                            />
                        </AdminModal>
                    )}

                    {showConfirmModal && (
                        <ConfirmModal
                            show={showConfirmModal}
                            title="Confirmação de Exclusão"
                            message="Tem certeza de que deseja excluir sua conta?"
                            onConfirm={handleDelete} // Call handleDelete on confirm
                            onCancel={() => setShowConfirmModal(false)} // Close the confirm modal
                            confirmLabel="Excluir"
                            cancelLabel="Cancelar"
                        />
                    )}
                </div>
            </div>
        </TooltipProvider>
    );
};

export default MyAccountPage;