import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import Logo from "../common/DegrauLogo";
import InputField from "../inputs/InputField";
import styles from "./Popup.module.css";

const RequestAccessContent = ({
  email,
  setEmail,
  handleAction,
  errorMessage,
  successMessage,
  onLoad,
  logoLoaded,
  ...otherProps
}) => {

  return (
    <>
      <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
      {logoLoaded ? (
        <>
          <p className={styles.popupTextLarge}>
            Bem-vindo à Degrau! Por favor, insira seu email para solicitar acesso.
          </p>
          <form
            className={styles.inputFieldContainer}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              handleAction(); // Call the custom action handler
            }}
          >
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="email">Email</label>
              <InputField
                id="email"
                type="email"
                autocomplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            <Button
              disabled={!email}
              type="form"
            >
              Solicitar acesso
            </Button>
          </form>
          {errorMessage && (
            <p className={styles.errorText} role="alert">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className={styles.successText} role="alert">
              {successMessage}
            </p>
          )}
        </>
      ) : null}
    </>
  );
};

RequestAccessContent.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

export default RequestAccessContent;
