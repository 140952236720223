// Get the path to the company logo image file based on the company details
export const getCompanyLogoPath = (companyDetails) => {
    const baseUrl = 'https://degrau.blob.core.windows.net/companylogos/';
    // the company id
    const companyId = Array.isArray(companyDetails)
        ? companyDetails[0]?.companyID || ""
        : companyDetails?.companyID || "";
    // the company logo version
    const logoVersion = Array.isArray(companyDetails)
        ? companyDetails[0]?.logoVersion || ""
        : companyDetails?.logoVersion || "";

    // return the company logo path
    return logoVersion ? `${baseUrl}${companyId}-logo-v${logoVersion}.png` : null;
};
