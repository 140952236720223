// Database services for candidiate actions in the company portal e.g. inviting candidate or viewing records
import axios from "axios";
import { retryRequest } from '../utils/retryRequest';
import { logEvent, logException, logTrace } from "./loggerFront"; // To log errors
import useAuth from "../hooks/useAuth";

const fileName = "companyCandidateService.js";

// Function to fetch user summary with optional companyId
export const inviteCandidatesByEmail = async (candidates, companyTestId, emailContent, adminEmails = null) => {
    logTrace('Inviting candidates by email', { candidates, companyTestId, emailContent, adminEmails, fileName });
    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
        const response = await fetch("/api/company-candidate/invite-candidate-email", {
            method: "POST",
            headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}` // Include the auth token
            },
            body: JSON.stringify({ candidates, companyTestId, emailContent, adminEmails }), // Correctly combine candidates and emailContent in the body
        });
    
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
    
        const result = await response.json();
        return result; // Handle the result as needed
    } catch (error) {
        logException("Error inviting candidates", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            candidates: candidates || 'No candidates',
            companyTestId: companyTestId || 'No companyTestId',
        });
        throw error; // Handle the error as needed
    }
};
  

// Fetch the summary of all candidate attempts (evaluated results, attempts yet to be prcoessed, invited candidates etc.) potentially limited by companyId and limit
export const fetchCandidateAttemptSummary = async (companyId = null, limit = null) => {
    return retryRequest(async () => {
        logTrace("Fetching candidate attempt summary", { companyId, limit }, fileName);
      
        const { accessToken } = useAuth();
        const token = accessToken();
      
        try {
            let url = '/api/company-candidate/candidate-attempt-summary';
            const params = new URLSearchParams();
            if (companyId) params.append('companyId', companyId);
            if (limit) params.append('limit', limit);
            if (params.toString()) url += `?${params.toString()}`;
        
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        
            if (response.status === 200) {
                return response.data;
            } else if (response.status === 404) {
                // Handle 404 as a valid "no data" response
                return { data: [], issue: "noData" };
            } else {
                logException("Network response was not ok", {
                    errorMessage: response.statusText,
                    errorCode: response.status,
                    fileName,
                });
                throw new Error("Network response was not ok");
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Handle 404 as a valid "no data" response
                return { data: [], issue: "noData" };
            }
            logException("Error getting all results", {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
                companyId: companyId || 'No companyId',
            });
            throw error;
        }
    });
};

