import axios from 'axios';
import useAuth from '../hooks/useAuth';

export const uploadCompanyLogo = async (companyId, file) => {
    const formData = new FormData();
    formData.append('logo', file);

    const { accessToken } = useAuth();
    const token = accessToken();

    try {
        const response = await axios.post(`/api/upload-logo/${companyId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}` // Include the token in the header
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error uploading the logo. Please try again.');
    }
};

export const uploadProfilePhoto = async (userId, file) => {
    const formData = new FormData();
    formData.append('photo', file);

    try {
        const response = await axios.post(`/api/upload-profile-photo/${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error uploading the profile photo. Please try again.');
    }
};
