// Manages the macro states of the site (testStage), the logic to change them and most other states and context that are needed in more than one place in the app
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import getInitials from "../utils/getInitials";
import { logEvent, logException, logTrace } from "../services/loggerFront";
import { set } from "lodash";

export const AppContext = createContext();

export const AppProvider = ({ children, location }) => {
  const [globalState, setGlobalState] = useState({
    screenWarning: false, // For minimum screen size state (triggered if screen too small)
    focusWarning: false, // For active tab state (triggered if user leaves the tab)
    appInsightsInitialized: false, // Turns true when AppInisghts initialises and logging can occur
    // ... other global states
  });

  // LOGGING //
  const fileName = "AppContext"; // for logging

  // Log change in pageStage
  useEffect(() => {
    // First, check if Application Insights has been initialized. It must be initialized before logging events
    if (globalState.appInsightsInitialized) {
      const page = location.pathname;
      let properties = { page: page };
      // Log the event with properties for every testStage change
      logEvent(`Location changed to: ${page}`, properties);
    }
  }, [location, globalState.appInsightsInitialized]); // Include appInsightsInitialized in the dependency array

  // ENVIRONMENT //
  const [environment, setEnvironment] = useState(""); // State to store the environment (staging, production, local)

  // Fetch the environment from the backend
  const fetchEnvironment = async (retryCount = 0) => {
    try {
      const response = await fetch("/api/environment");
      const data = await response.json();
      if (data.environment) {
        setEnvironment(data.environment);
      } else {
        logException("Environment not found in response", {
          fileName: fileName,
        });
        throw new Error("Environment not found in response");
      }
    } catch (error) {
      logException("Error: fetch WebSocket URL", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: fileName,
        retryCount,
      });
      if (retryCount < 2) {
        // Allow up to 2 retries
        const retryDelay = retryCount === 0 ? 5000 : 10000; // Calculate retry delay: 5 seconds for the first retry, then 10 seconds
        setTimeout(() => fetchEnvironment(retryCount + 1), retryDelay);
      }
    }
  };

  // Fetch the environment on load
  useEffect(() => {
    if (!environment) {
      // Only fetch if the URL is not already set
      fetchEnvironment();
    }
  }, []);

  // URLS //

  // State to store the websocket fetched from the backend (as it differs for staging vs prod vs local)
  const [webSocketUrl, setWebSocketUrl] = useState(null);
  const [baseUrl, setBaseUrl] = useState(null);

  // Fetch the websocket URL from the backend
  const fetchBaseUrls = async (retryCount = 0) => {
    try {
      const response = await fetch("/api/base-urls"); // Fetch the URL from the backend
      const data = await response.json();
      if (data.websocketUrl && data.baseUrl) {
        setWebSocketUrl(data.websocketUrl); // Update the state with the fetched URL
        setBaseUrl(data.baseUrl);
      } else {
        logException("URL not found in response", {
          fileName: fileName,
        });
        throw new Error("URL not found in response");
      }
    } catch (error) {
      logException("Error: fetch URL", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: fileName,
        retryCount,
      });
      if (retryCount < 2) {
        // Allow up to 2 retries
        const retryDelay = retryCount === 0 ? 5000 : 10000; // Calculate retry delay: 5 seconds for the first retry, then 10 seconds
        setTimeout(() => fetchBaseUrls(retryCount + 1), retryDelay);
      }
    }
  };

  // Fetch the websocket URL on load
  useEffect(() => {
    if (!webSocketUrl) {
      // Only fetch if the URL is not already set
      fetchBaseUrls();
    }
  }, []);

  // CANDIDATE CONTEXT //

  // SAVING //

  const [isAdmin, setIsAdmin] = useState(false); // This is used when on the interview to know whether to do things like email elerts etc

  // Saving variables for retrying and triggering error states
  const INTERRUPTION_RETRY_MAX = 5; // Maximum retries after the initial failure handling, used in all retry functions
  const INTERRUPTION_RETRY_INTERVAL = 30; // Seconds between retries, used in all retry functions

  // ANTI FRAUD MEASURES //

  const [f12Count, setF12Count] = useState(0); // Count when F12 is clicked
  const [switchScreenCount, setSwitchScreenCount] = useState(0); // Count when user switches screens / tabs etc
  const [isInteractingWithIframe, setIsInteractingWithIframe] = useState(false); // State to track if the user is interacting with the iframe and therefore not leaving the site
  const isInteractingWithIframeRef = useRef(isInteractingWithIframe);

  useEffect(() => {
    isInteractingWithIframeRef.current = isInteractingWithIframe;
  }, [isInteractingWithIframe]);

  // Increment F12 count and alert user the first timer when it is pressed
  const handleF12KeyPress = (e) => {
    if (e.code === "F12" && location.pathname === "/entrevista") {
      setF12Count((prevCount) => {
        // Give an alert the first time F12 is clicked
        if (prevCount === 0) {
          alert("F12 detectado.");
        }
        return prevCount + 1;
      });
    }
  };

  // Trigger f12 function when f12 button detected
  useEffect(() => {
    window.addEventListener("keydown", handleF12KeyPress);

    return () => {
      window.removeEventListener("keydown", handleF12KeyPress);
    };
  }, []);

  // When the user leaves the tab, blur the screen, increment the switch screen count and start timing non-active time
  const handleBlur = () => {
    setTimeout(() => {
      if (!isInteractingWithIframeRef.current) {
        setGlobalState((prevState) => ({ ...prevState, focusWarning: true }));
      }
    }, 100); // Delay of x milliseconds to allow for the iframe focus event to trigger first
  };

  // When the user returns
  const handleFocus = () => {
    setTimeout(() => {
      setGlobalState((prevState) => ({ ...prevState, focusWarning: false }));
    }, 200); // Delay of x milliseconds to allow for the iframe blur event to trigger first
  };

  // Event listeners to enable blur and focus handling
  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  // BROWSER TYPE //

  // State to store the browser type
  const [browserType, setBrowserType] = useState("");
  const [userAgent, setUserAgent] = useState("");

  // Identify the browser type using the userAgent

  function getBrowserType() {
    const userAgent = navigator.userAgent;
    setUserAgent(userAgent);

    switch (true) {
      case userAgent.includes("Firefox"):
        return "Firefox";
      case userAgent.includes("Opera") || userAgent.includes("OPR"):
        return "Opera";
      case userAgent.includes("Trident"):
        return "Internet Explorer";
      case userAgent.includes("Edg"):
        return "Edge";
      case userAgent.includes("Chrome"):
        return "Chrome";
      case userAgent.includes("Safari"):
        return "Safari";
      default:
        return "Unknown";
    }
  }

  // Check browser type when user comes to the test site and update the browserType state
  useEffect(() => {
    if (location.pathname === "/entrevista" || location.pathname.includes('/entrevista/')) {
      const detectedBrowserType = getBrowserType();
      setBrowserType(detectedBrowserType);
    }
  }, [location]);

  // SCREEN SIZE //

  // State to store the screensize of the user
  const [screenSize, setScreenSize] = useState("");

  // Function to check and set screen warning based on window width or height
  const checkScreenSize = () => {
    if (location.pathname === "/entrevista" || location.pathname.includes('/entrevista/')) {
      const screenSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      const isScreenSmall = window.innerWidth < 320 || window.innerHeight < 400;
      setGlobalState((prevState) => ({
        ...prevState,
        screenWarning: isScreenSmall,
      }));
      setScreenSize(screenSize);
    }
  };

  // Event listener to enable checkScreenSize
  useEffect(() => {
    // Check screen size on mount and add event listener for resizing
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Cleanup function to remove event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [location]);

  // IP //

  // State to store the ip of the user
  const [candidateIp, setCandidateIp] = useState("");

  useEffect(() => {
    // Function to fetch the IP address from the backend
    const fetchIP = async () => {
      try {
        const response = await fetch("/api/get-ip");
        const data = await response.json();
        setCandidateIp(data.ip); // Update the context with the fetched IP address
      } catch (error) {
        logException("Error fetching IP address:", { errorMessage: error });
      }
    };

    fetchIP();
  }, [setCandidateIp]);

  // PDF //
  const [pdfMode, setPdfMode] = useState(false); // Trigger changes in UI for pdf version to be made in backend with /api/pdf-generation

  // COMPANY PORTAL CONTEXT //

  // ACCESS LEVELS //
  const [userRoles, setUserRoles] = useState(); // The role of the user in the system
  const [userPermissions, setUserPermissions] = useState(); // The permissions of the user in the system
  const [userFirstName, setUserFirstName] = useState(null); // The name of the user
  const [userLastName, setUserLastName] = useState(null); // The last name of the user
  const [userFullName, setUserFullName] = useState(null); // The full name of the user
  const [userInitials, setUserInitials] = useState(null); // The initials of the user
  const [userEmail, setUserEmail] = useState(); // The email of the user
  const [userId, setUserId] = useState(); // The ID of the user
  const [companyId, setCompanyId] = useState(); // The company ID of the user
  const [isOrgAdmin, setIsOrgAdmin] = useState(null);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(null);
  const [companyAccountType, setCompanyAccountType] = useState(null);
  const [companyAccountCredits, setCompanyAccountCredits] = useState(null);

  // update full name and initials when first or last name changes
  useEffect(() => {
    const updateNameAndInitials = async () => {
      if (userFirstName || userLastName) {
        const fullName = `${userFirstName} ${userLastName}`;
        setUserFullName(fullName);
        const initials = await getInitials(fullName);
        setUserInitials(initials);
      }
    };
    updateNameAndInitials();
  }, [userFirstName, userLastName]);

  // Check if the user is an organization admin
  const checkAdminStatus = () => {
    if (!userRoles) return; // If the user roles are not yet set, return
    // OrgAdmin
    const isOrgAdmin = userRoles.includes('Admin') || userRoles.includes('SuperAdmin');
    setIsOrgAdmin(isOrgAdmin);
    // GlobalAdmin
    const isGlobalAdmin = userRoles.includes('GlobalAdmin');
    setIsGlobalAdmin(isGlobalAdmin);
  };

  // call on load
  useEffect(() => {
      checkAdminStatus();
  }, [userRoles]);

  return (
    <AppContext.Provider
      value={{
        browserType,
        candidateIp,
        environment,
        f12Count,
        fetchBaseUrls,
        globalState,
        INTERRUPTION_RETRY_INTERVAL,
        INTERRUPTION_RETRY_MAX,
        isAdmin,
        isOrgAdmin,
        setIsOrgAdmin,
        isGlobalAdmin,
        setIsGlobalAdmin,
        userRoles,
        setUserRoles,
        userPermissions,
        setUserPermissions,
        companyId,
        setCompanyId,
        userFirstName,
        setUserFirstName,
        userLastName,
        setUserLastName,
        userFullName,
        setUserFullName,
        userInitials,
        setUserInitials,
        userEmail,
        setUserEmail,
        userId,
        setUserId,
        companyAccountType,
        setCompanyAccountType,
        companyAccountCredits,
        setCompanyAccountCredits,
        pdfMode,
        screenSize,
        setF12Count,
        setGlobalState,
        setIsAdmin,
        handleBlur,
        isInteractingWithIframe,
        setIsInteractingWithIframe,
        setPdfMode,
        setSwitchScreenCount,
        switchScreenCount,
        userAgent,
        webSocketUrl,
        baseUrl,
        // ... other context values ...
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
