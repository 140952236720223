// Component to appear during introChat to guide the user to enable screen sharing
import React, { useEffect, useState } from "react";
import screenShareChromeImg from "../../assets/images/screenShareChrome.png";
import screenShareEdgeImg from "../../assets/images/screenShareEdge.png";
import screenShareSafariImg from "../../assets/images/screenShareSafari.png";
import { useAppContext } from "../../contexts/AppContext";
import { useTestContext } from "../../contexts/TestContext";
import { useChatContext } from "../../contexts/ChatContext";
import useScreenShareAccess from "../../hooks/useScreenShareAccess";
import { logEvent, logException, logTrace } from "../../services/loggerFront";
import Button from "../common/Button";
import Popup from "../popup/Popup";
import ScreenCover from "../popup/ScreenCover";
import styles from "./ScreenShare.module.css";

const ScreenShare = () => {
  const { userInputCount, hideScreenShareContainer, addSystemMessage, prepareNextAction, fetchNextChatInstruction, currentChatInstructionIndex, chatInstructions, chats, setChats } = useChatContext();
  const { browserType } = useAppContext(); // To know the default guide to show the user
  const { setScreenShareStatus, setScreen2ShareStatus, setScreenShareStream, candidateId } = useTestContext(); // To know if the user accepted or rejected the screen share
  const [selectedBrowserType, setSelectedBrowserType] = useState(browserType); // If the user changes the browser
  const [showFirstButtonContainer, setShowFirstButtonContainer] = useState(true); // Show container with buttons for the permission for the first screen (or the 2nd as the negative of the function)
  const [showSecondButtonContainer, setShowSecondButtonContainer] = useState(false); // Show container with buttons for the permission for the second screen
  const [showScreenShareGuide, setShowScreenShareGuide] = useState(true); // Whether the guide is visible, it is not needed for the 2nd screens permission as the first one is already succesfully done
  const { handleEnableScreenShare } = useScreenShareAccess(); // Custom hook to ask for permission to record screen
  const [isScreenCoverVisible, setIsScreenCoverVisible] = useState(false); // If user rejects, show popup (with blurred background) to inform of the rationale
  const [isScreenShareRejectPopupVisible, setIsScreenShareRejectPopupVisible] = useState(false); // If user rejects, show popup (with blurred background) to inform of the rationale
  const [isScreenShareFailedPopupVisible, setIsScreenShareFailedPopupVisible] = useState(false); // If it fails, show popup (with blurred background) to guide on next steps
  const [multipleScreens, setMultipleScreens] = useState(false); // If it fails, show popup (with blurred background) to guide on next steps
  const [currentScreenNumber, setCurrentScreenNumber] = useState(1); // To know which screen is being shared
  const fileName = "ScreenShare"; // for logging

  // use effect to check screen count 
  useEffect(() => {
    if (window.screen.isExtended) {
      setMultipleScreens(true);
    } else {
      setMultipleScreens(false);
      setScreen2ShareStatus({ isActive: false, rationale: "Não tenho uma segunda tela" });
    }
  }, []);

  // When the user clicks share screen, request access to record screen using custom hook useScreenShareAccess
  const handleEnableClick = (screenNumber) => () => {
    handleEnableScreenShare(
      (stream) => {
        // Update the context with the stream and the screen number
        setScreenShareStream(stream, screenNumber); // Set the stream and the screen number
        // Adjust UI based on the screen number
        if (screenNumber === 1) { // For the first screen set the status and hide the button container, keep showing the guide if there is a second screen otherwise hide it and show the buttons
          setScreenShareStatus({ isActive: true, rationale: "" });
          setShowFirstButtonContainer(false); // Hides the first button container or button if no second screen
          setCurrentScreenNumber(2);
          if (multipleScreens) {
            setShowSecondButtonContainer(true);
          } else {
            setShowScreenShareGuide(false); // Hide the guide of the how to accept
          }
        } else {
          setScreen2ShareStatus({ isActive: true, rationale: "" });
          hideScreenShareContainer();
          fetchNextChatInstruction(
            currentChatInstructionIndex,
            chatInstructions,
            addSystemMessage,
            prepareNextAction,
            userInputCount,
            chats,
            setChats,
          );
        }
      },
      () => {
        // Handle decline for specific screen number, if needed
      },
      (error) => {
        logException("Screen sharing encountered an error for screen", {
          screenNumber,
          errorMessage: error,
        });
        setIsScreenCoverVisible(true);
        setIsScreenShareFailedPopupVisible(true);
        // Handle error for specific screen number
      }
    );
  };

  // When user select they dont want to share screen, show the popup for them to input their rationale and confirm
  const handleRejectClick = () => {
    setIsScreenCoverVisible(true);
    setIsScreenShareRejectPopupVisible(true);
  };

  // When they click the confirm not to share screen
  const handleConfirmReject = (reason) => {
    // Logic to save the reason
    setIsScreenCoverVisible(false);
    setIsScreenShareRejectPopupVisible(false);
    setIsScreenShareFailedPopupVisible(false);
    hideScreenShareContainer();
    if (currentScreenNumber === 1) {
      setScreenShareStatus({ isActive: false, rationale: reason }); //Save that screen share was opted out and the rationale filled in the popup
    } else {
      setScreen2ShareStatus({ isActive: false, rationale: reason }); //Save that screen share was opted out and the rationale filled in the popup
    }
    fetchNextChatInstruction(
      currentChatInstructionIndex,
      chatInstructions,
      addSystemMessage,
      prepareNextAction,
      userInputCount,
      chats,
      setChats
    );

    // Log in AppInsights
    logTrace("handleConfirmReject screenShare", {
      candidateId: candidateId,
      fileName: fileName,
    });
  };

  // If they return and dont follow through with rejecting the photo taking
  const handleCancelReject = () => {
    setIsScreenCoverVisible(false);
    setIsScreenShareRejectPopupVisible(false);
    setIsScreenShareFailedPopupVisible(false);

    // Log in AppInsights
    logTrace("handleCancelReject screenShare", {
      candidateId: candidateId,
      fileName: fileName,
    });
  };

  // When user select they dont have a second screen
  const handleRejectSecondClick = () => {
    hideScreenShareContainer(); // Hide the buttons
    fetchNextChatInstruction(
      currentChatInstructionIndex,
      chatInstructions,
      addSystemMessage,
      prepareNextAction,
      userInputCount,
      chats,
      setChats,
    ); // Move on to the next instruction
  };

  const getImageSrc = (type) => {
    switch (type) {
      case "Chrome":
        return screenShareChromeImg;
      case "Safari":
        return screenShareSafariImg;
      case "Edge":
        return screenShareEdgeImg;
      default:
        return screenShareChromeImg; // A default image if needed
    }
  };

  return (
    <div className={styles.screenShareContainer}>
      {showScreenShareGuide && (
        <div className={styles.screenShareGuide}>
          <div className={styles.browserTypeOptions}>
            {["Chrome", "Safari", "Edge"].map((type) => (
              <div
                key={type}
                className={`${styles.browserType} ${
                  selectedBrowserType === type ? styles.selectedBrowserType : ""
                }`}
                onClick={() => setSelectedBrowserType(type)}
              >
                {type}
              </div>
            ))}
          </div>
          <img
            src={getImageSrc(selectedBrowserType)}
            className={styles.screenShareImage}
            alt="Guia de compartilhamento de tela"
          />
        </div>
      )}
      {/* First screen */}
      {showFirstButtonContainer && (
        <div className={styles.screen1ShareButtonContainer}>
          <Button onClick={handleEnableClick(1)}>{multipleScreens ? 'Compartilhar primeira tela' : 'Compartilhar tela'}</Button>
          <button
            onClick={handleRejectClick}
            className={styles.rejectScreenShareText}
          >
            Não quero compartilhar minha tela
          </button>
        </div>
      )}
      {/* Second screen */}
      {showSecondButtonContainer && (
        <div className={styles.screen1ShareButtonContainer}>
          <Button onClick={handleEnableClick(2)}>Compartilhar segunda tela</Button>
          <button
            onClick={handleRejectClick}
            className={styles.rejectScreenShareText}
          >
            Não quero compartilhar minha segunda tela
          </button>
        </div>
      )}
      {/* Button if no second screen */}
      {!showFirstButtonContainer && !showSecondButtonContainer && (
        <div className={styles.screen2ShareButtonContainer}>
          <Button onClick={handleEnableClick(2)}>
            Compartilhar segunda tela
          </Button>
          <Button onClick={handleRejectSecondClick}>
            Não tenho uma segunda tela
          </Button>
        </div>
      )}
      <ScreenCover
        isVisible={isScreenCoverVisible}
        variant="blur"
        zIndex={11}
      />
      <Popup
        variant="ScreenShareReject"
        isVisible={isScreenShareRejectPopupVisible}
        zIndex={12}
        onClose={handleCancelReject}
        onConfirmReject={handleConfirmReject}
        onCancelReject={handleCancelReject}
      />
      <Popup
        variant="ScreenShareFail"
        isVisible={isScreenShareFailedPopupVisible}
        zIndex={12}
        onClose={handleCancelReject}
        onConfirmReject={handleConfirmReject}
        onCancelReject={handleCancelReject}
      />
    </div>
  );
};

export default ScreenShare;
