import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserResults } from '../services/companyResultService';
import { fetchCandidateAttemptSummary } from '../services/companyCandidateService';
import { getTestOverview, fetchCompanyTestsSummary } from '../services/companyTestService'; // The summary test for a specific company or all companies if no companyId passed
import { logException } from '../services/loggerFront';

const useCompanySummaryData = (viewPermissionLevel, isOrgAdmin, companyDetails, setTestErrorMessage, setResultErrorMessage, setNoTestData, setNoResultData) => {
    const [companyTestData, setCompanyTestData] = useState([]);
    const [companyResultsSummary, setCompanyResultsSummary] = useState([]);
    const [testOverviews, setTestOverviews] = useState([]); // detailed data of a test that is clicked
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(true); // State to show loading spinner to user
    const [dataFetchCalled, setDataFetchCalled] = useState(false); // State to prevent multiple data fetches
    const navigate = useNavigate();

    const fileName = 'useCompanySummaryData.js';

    // On load, identify the user's permissions and fetch the relevant dashboard data
    useEffect(() => {
        if (dataFetchCalled) return; // If data has already been fetched
        const getDashboardAccessLevel = async () => {
            try {
                // If some is missing, wait, as the useEffect will run again
                if (viewPermissionLevel === null || Object.keys(companyDetails).length === 0 || isOrgAdmin === null) {
                    logException('Missing required data when checking dashboard access:', { viewPermissionLevel, companyDetails, isOrgAdmin, fileName });
                    return;
                }
                // Fetch the data depending on the user's permissions
                if (viewPermissionLevel === 'GLOBAL') { // If it is a global dashboard, fetch all results
                    fetchCompanySummaryData(true);
                    return;
                } else if (isOrgAdmin) { // If it is an org admin, fetch all the company results
                    fetchCompanySummaryData(false, true, companyDetails);
                    return;
                } else { 
                    fetchCompanySummaryData(false, false, companyDetails);
                    return;
                }
            } catch (err) {
                logException('Failed to fetch company summary data:', {
                    errorMessage: err.message,
                    errorStack: err.stack,
                    companyDetails,
                    viewPermissionLevel,
                    isOrgAdmin,
                    fileName,
                });
                navigate('/login');
            }
        };

        getDashboardAccessLevel();
    }, [viewPermissionLevel, companyDetails, isOrgAdmin]);

    // Centralized data fetching logic
    const fetchCompanySummaryData = useCallback(async (globalAccess = false, isOrgAdmin = false, companyDetails = null) => {
        setIsLoadingInitialData(true);
        setDataFetchCalled(true);
        let testData = [];
        let resultsData = [];

        try {
            if (globalAccess) { // fetch all results for global admin
                const testResponse = await fetchCompanyTestsSummary(); // Call without companyId to get all results
                if (testResponse.issue === "noData") {
                    setNoTestData(true);
                } else {
                    testData = testResponse.data;
                }

                const resultResponse = await fetchCandidateAttemptSummary(null, 5);
                if (resultResponse.issue === "noData") {
                    setNoResultData(true);
                } else {
                    resultsData = resultResponse;
                }
            } else if (isOrgAdmin && companyDetails) { // fetch results for a specific company for company admin
                const companyId = companyDetails.companyID;

                try {
                    const testResponse = await fetchCompanyTestsSummary(companyId);
                    if (testResponse.issue === "noData") {
                        setNoTestData(true);
                    } else if (testResponse.issue === "unknown") {
                        setTestErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    } else {
                        testData = testResponse.data;
                    }

                    const resultResponse = await fetchCandidateAttemptSummary(companyId, 5);
                    if (resultResponse.issue === "noData") {
                        setNoResultData(true);
                    } else if (testResponse.issue === "unknown") {
                        setResultErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    } else {
                        resultsData = resultResponse;
                    }
                } catch (error) {
                    setTestErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    setResultErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    throw error;
                }
            } else if (!isOrgAdmin && companyDetails) { // fetch results for a specific company for company admin
                const companyId = companyDetails.companyID;

                try {
                    const testResponse = await fetchCompanyTestsSummary(companyId);
                    if (testResponse.issue === "noData") {
                        setNoTestData(true);
                    } else if (testResponse.issue === "unknown") {
                        setTestErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    } else {
                        testData = testResponse.data;
                    }

                    const resultResponse = await fetchUserResults(null, 5);
                    if (resultResponse.issue === "noData") {
                        setNoResultData(true);
                    } else if (testResponse.issue === "unknown") {
                        setResultErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    } else {
                        resultsData = resultResponse.data;
                    }
                } catch (error) {
                    setTestErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    setResultErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    throw error;
                }
            } else {
                return;
            }

            setCompanyTestData(testData);
            setCompanyResultsSummary(resultsData);

        } catch (error) {
            logException('Error fetching company summary data:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
            setTestErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
            setResultErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
        } finally {
            setIsLoadingInitialData(false); // Set loading to false
        }
    }, [navigate]);


    // Expose a refresh function to re-fetch data
    const refreshData = useCallback(() => {
        if (viewPermissionLevel === 'GLOBAL') {
            resetData();
            fetchCompanySummaryData(true);
            return;
        } else if (isOrgAdmin && companyDetails) {
            resetData();
            fetchCompanySummaryData(false, true, companyDetails);
            return;
        } else if (!isOrgAdmin && companyDetails) {
            resetData();
            fetchCompanySummaryData(false, false, companyDetails);
            return;
        }
    }, [fetchCompanySummaryData, viewPermissionLevel, companyDetails, isOrgAdmin]);

    const resetData = () => {
        setCompanyTestData([]);
        setDataFetchCalled(false);
    };

    const handleTestOverview = async (testId) => {
        // First check if the test overview is already in state
        if (testOverviews[testId]) {
            return;
        }
        // if not, fetch the data
        try {
            const response = await getTestOverview(testId);
            const testOverview = response[0];
            // save the data to testOverviews with the test id
            setTestOverviews(prevState => {
                return {
                    ...prevState,
                    [testId]: testOverview,
                };
            });
        } catch (error) {
            logException('Error fetching test overview:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
            return null;
        }
    }

    return {
        companyTestData,
        companyResultsSummary,
        isLoadingInitialData,
        refreshData,
        handleTestOverview,
        testOverviews,
    };
};

export default useCompanySummaryData;
