import axios from "axios";
import { retryRequest } from '../utils/retryRequest';
import { logEvent, logException, logTrace } from "./loggerFront";
import useAuth from "../hooks/useAuth";

const fileName = "candidateCodeService.js";

// Retrieve code from blob
export const retrieveCodeFromBlob = async (blobName) => {
  logTrace('Retrieving code from blob', { blobName, fileName });

  return retryRequest(async () => {
    try {
      const response = await axios.get("/api/retrieve-code-from-blob", {
        params: { blobName },
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        return response.data.codeFiles;
      } else {
        logException("Network response was not ok", {
          errorMessage: response.statusText,
          errorCode: response.status,
          fileName,
        });
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      logException("Error retrieving code from blob", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
        blobName: blobName || 'No blobName',
      });
      throw error;
    }
  });
};

// ... (your other existing functions)