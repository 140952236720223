import React, { useState, useEffect, useRef } from "react";
import styles from "./Dropdown.module.css";

const Dropdown = ({ 
    items, 
    label, 
    setSelectedItem, 
    placeholder = "-- Selecione um item --", 
    selectedItem = null, 
    minWidth,
    disabled = false,
    searchable = false
}) => {    
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItemId, setSelectedItemId] = useState("");
    const dropdownRef = useRef(null);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const listRef = useRef(null);

    useEffect(() => {
        if (selectedItem) {
            setSelectedItemId(selectedItem.id.toString());
            setSearchTerm(selectedItem.name);
        }
    }, [selectedItem]);

    useEffect(() => {
        // Close dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSelect = (item) => {
        setSelectedItemId(item.id.toString());
        setSearchTerm(item.name);
        setSelectedItem(item);
        setIsOpen(false);
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setIsOpen(true); // Open dropdown when typing
    };

    const filteredItems = items.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleKeyDown = (e) => {
        if (!isOpen) {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
                e.preventDefault();
                setIsOpen(true);
                return;
            }
        }

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setFocusedIndex(prevIndex => {
                    const nextIndex = prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex;
                    // Scroll the focused item into view
                    setTimeout(() => {
                        const element = listRef.current?.children[nextIndex];
                        element?.scrollIntoView({ block: 'nearest' });
                    }, 0);
                    return nextIndex;
                });
                break;
            case 'ArrowUp':
                e.preventDefault();
                setFocusedIndex(prev => prev > 0 ? prev - 1 : prev);
                break;
            case 'Enter':
                e.preventDefault();
                if (focusedIndex >= 0 && focusedIndex < filteredItems.length) {
                    handleSelect(filteredItems[focusedIndex]);
                }
                break;
            case 'Escape':
                setIsOpen(false);
                setFocusedIndex(-1);
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.dropdownContainer} ref={dropdownRef}>
            <label>{label}</label>
            <div className={styles.combobox}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={() => !disabled && setIsOpen(true)}
                    onKeyDown={handleKeyDown}
                    placeholder={!isOpen ? placeholder : ""}
                    disabled={disabled}
                    className={styles.comboboxInput}
                />
                <button 
                    className={styles.dropdownToggle}
                    onClick={() => !disabled && setIsOpen(!isOpen)}
                    disabled={disabled}
                >
                    ▼
                </button>
                {isOpen && !disabled && (
                    <ul className={styles.dropdownList} ref={listRef}>
                        {filteredItems.map((item, index) => (
                            <li
                                key={item.id}
                                onClick={() => handleSelect(item)}
                                className={`
                                    ${styles.dropdownItem}
                                    ${item.id.toString() === selectedItemId ? styles.selected : ''}
                                    ${index === focusedIndex ? styles.focused : ''}
                                `}
                            >
                                {item.name}
                            </li>
                        ))}
                        {filteredItems.length === 0 && (
                            <li className={styles.noResults}>Nenhum resultado encontrado</li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Dropdown;