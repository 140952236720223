import React, { useState, useEffect, useRef } from 'react';
import { validateEmail } from '../../utils/validateEmail';
import { validateEmailEnding} from '../../utils/validateEmailEnding';
import { inviteCandidatesByEmail } from '../../services/companyCandidateService';
import { getCompanyLogoPath } from '../../utils/getCompanyLogo';
import { htmlTemplate, plainTextTemplate } from '../../dataInputs/suggestedEmailTemplate';
import Button from '../common/Button';
import FadeLoader from '../common/FadeLoader';
import LinkContainer from '../common/LinkContainer';
import EmailContentContainer from '../common/EmailContentContainer';
import ActionButton from "../common/ActionButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from '../../sharedStyles/ModalStyles.module.css';

const InviteCandidateModal = ({ onClose, selectedTest, companyDetails }) => {
    const [activeTab, setActiveTab] = useState('shareLink');
    const [candidates, setCandidates] = useState([{ name: '', email: '', accepted: false }]); // Add 'accepted' field
    const [adminEmails, setAdminEmails] = useState([]); // Add CC emails
    const [adminEmailValue, setAdminEmailValue] = useState(''); // Add CC input value
    const [companyEmailEnding, setCompanyEmailEnding] = useState(''); // Add company email ending
    const [generatedLink, setGeneratedLink] = useState('');
    const [suggestedEmailContent, setSuggestedEmailContent] = useState('');
    const [htmlEmailContent, setHtmlEmailContent] = useState('');
    const [plainEmailContent, setPlainEmailContent] = useState('');
    const [finalEmailContent, setFinalEmailContent] = useState('');
    const [finalHtmlContent, setFinalHtmlContent] = useState('');
    const [isInviting, setIsInviting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const modalRef = useRef(null);
    const emailTableContainerRef = useRef(null); // Ref for the email table container to allow auto scroll to bottom

    // Set company email ending based on the company details
    useEffect(() => {
        if (companyDetails) {
            setCompanyEmailEnding(companyDetails.emailEnding);
        }
    }, [companyDetails]);

    // Generate a shareable link for the interview
    useEffect(() => {
        const link = `app.degrau.co/entrevista/${selectedTest.uuid}`; // code will be uuid from company role
        setGeneratedLink(link);
    }, []);

    // Set the suggested email content based on the interview details and generated link
    useEffect(() => {
        // Get the partner company logo
        const partnerLogoPath = getCompanyLogoPath(companyDetails);
        // Create both HTML and plain text versions
        const htmlContent = htmlTemplate
            .replace(/{selectedTest.role}/g, selectedTest.roleName)
            .replace(/{selectedTest.time}/g, Math.round(selectedTest.totalTime / 60))
            .replace(/{generatedLink}/g, generatedLink)  // Added 'g' flag here
            .replace(/{partnerLogo}/g, partnerLogoPath || ''); // Add partner logo path
            
        const plainContent = plainTextTemplate
            .replace("{selectedTest.role}", selectedTest.roleName)
            .replace("{selectedTest.time}", Math.round(selectedTest.totalTime / 60))
            .replace("{generatedLink}", generatedLink);
    
        setHtmlEmailContent(htmlContent);
        setPlainEmailContent(plainContent);
    }, [selectedTest, generatedLink]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleCandidateChange = (index, field, value) => {
        setErrorMessage('');
        const updatedCandidates = [...candidates];
        updatedCandidates[index][field] = value;
        setCandidates(updatedCandidates);
    };

    useEffect(() => {
        // Scroll to the bottom of the email table container whenever a new candidate is added
        if (emailTableContainerRef.current) {
            emailTableContainerRef.current.scrollTop = emailTableContainerRef.current.scrollHeight;
        }
    }, [candidates]);

    const handleAddEmailField = () => {
        const lastEntry = candidates[candidates.length - 1];
    
        // Ensure name and email are not blank
        if (!lastEntry.name.trim()) {
            setErrorMessage("O nome não pode estar vazio.");
            setTimeout(() => setErrorMessage(""), 5000);
            return; // Prevent adding a new field
        }
        if (!lastEntry.email.trim()) {
            setErrorMessage("O email não pode estar vazio.");
            setTimeout(() => setErrorMessage(""), 5000);
            return; // Prevent adding a new field
        }

        // Validate email
        const isValid = validateEmail(lastEntry.email);
        if (!isValid) {
            setErrorMessage("Por favor, insira um email válido.");
            setTimeout(() => setErrorMessage(""), 5000);
            return; // Prevent adding a new field
        }

        // Ensure the email is unique
        const isDuplicate = candidates.some((entry, i) => i !== candidates.length - 1 && entry.email === lastEntry.email);
        if (isDuplicate) {
            setErrorMessage("Este email já foi adicionado.");
            setTimeout(() => setErrorMessage(""), 5000);
            return; // Prevent adding a new field
        }

        // Mark the last entry as accepted
        lastEntry.accepted = true;

        // If both fields are filled and valid, add a new entry
        setCandidates([...candidates, { name: '', email: '', accepted: false }]);
    };

    // Remove a candidate from the invite list
    const handleRemoveCandidateField = (index) => {
        const updatedCandidates = candidates.filter((_, i) => i !== index);
        setCandidates(updatedCandidates);
    };

    // Send the email invites to the candidate(s)
    const handleInviteCandidates = async () => {
        setIsInviting(true);
        const acceptedCandidates = candidates.filter(candidate => candidate.accepted);
        const companyTestId = selectedTest.companyTestId;
    
        if (acceptedCandidates.length === 0) {
            setErrorMessage("Nenhum candidato.");
            setTimeout(() => setErrorMessage(""), 5000);
            setIsInviting(false);
            return;
        }
    
        try {
            const response = await inviteCandidatesByEmail(
                acceptedCandidates, 
                companyTestId, 
                finalHtmlContent, // Use the HTML version
                adminEmails
            );
            if (response) {
                setIsInviting(false);
                setCandidates([{ name: '', email: '', accepted: false }]);
                setSuccessMessage("Os convites foram enviados com sucesso.");
                setTimeout(() => setSuccessMessage(""), 5000);
            }
        } catch (error) {
            setIsInviting(false);
            setErrorMessage("Ocorreu um erro ao enviar os convites. Por favor, tente novamente.");
            setTimeout(() => setErrorMessage(""), 5000);
        }
    };    

    // Add a CC email
    const handleAdminEmailInputChange = (e) => {
        setAdminEmailValue(e.target.value);
        setErrorMessage(''); 
    };

    // Add admin email when Enter, Space, or Tab is pressed (if input is not empty)
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ' || (e.key === 'Tab' && adminEmailValue)) {
            tryAddAdminEmail();
        }
        if (e.key === 'Backspace' && !adminEmailValue && adminEmails.length) {
            const newEmails = [...adminEmails];
            newEmails.pop();
            setAdminEmails(newEmails);
        }
    };

    // Trigger to add admin email when input field loses focus
    const handleBlur = () => {
        tryAddAdminEmail();
    };

    // Add admin email to the list
    const tryAddAdminEmail = () => {
        if (adminEmailValue) {
            const validEmail = validateEmail(adminEmailValue);
            if (!validEmail) {
                setErrorMessage('Por favor, insira um email válido.');
                setTimeout(() => setErrorMessage(''), 5000);
                return;
            } 
            const validEmailEnding = validateEmailEnding(adminEmailValue, companyEmailEnding);
            if (!validEmailEnding) {
                setErrorMessage(`O email deve terminar com ${companyEmailEnding}.`);
                setTimeout(() => setErrorMessage(''), 5000);
                return;
            }
            if (!adminEmails.includes(adminEmailValue)) {
                setAdminEmails([...adminEmails, adminEmailValue]);
                setAdminEmailValue('');
            } else {
                setErrorMessage('Este email já foi adicionado.');
            }
        }
    };

    const removeAdminEmail = (index) => {
        setAdminEmails(adminEmails.filter((_, i) => i !== index));
    };

    return (
        <div 
            className={styles.modalOverlay} 
            role="dialog" 
            aria-labelledby="invite-modal-title" 
            aria-modal="true"
            onClick={handleOverlayClick}
        >
            <div className={`${styles.modalContainer} ${styles.wide}`} ref={modalRef} tabIndex="-1">
                <button onClick={onClose} className={styles.closeButton} aria-label="Fechar">
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className={styles.modalHeader}>
                        <div 
                            id="invite-modal-title" 
                            className={styles.modalTitle}>
                            Envie os convites 
                            <div className={styles.modalSubtitle}>
                                ({selectedTest.testName})
                            </div>
                        </div>
                    </div>

                <div className={styles.tabs}>
                    <button
                        className={`${styles.tabButton} ${activeTab === 'shareLink' ? styles.active : ''}`}
                        onClick={() => handleTabChange('shareLink')}
                    >
                        Compartilhar link
                    </button>
                    <button
                        className={`${styles.tabButton} ${activeTab === 'inviteByEmail' ? styles.active : ''}`}
                        onClick={() => handleTabChange('inviteByEmail')}
                    >
                        Convidar por e-mail
                    </button>
                </div>

                <div className={styles.tabContent}>
                    {activeTab === 'shareLink' && (
                        <>
                            <div className={styles.tabDescription}>Envie o link abaixo para convidar candidatos a participar.</div>
                            <div className={styles.contentSubheader}>Link para a entrevista</div>
                            <LinkContainer shareableLink={generatedLink} />

                            <div className={styles.contentSubheader}>E-mail sugerido para o candidato</div>
                            <div className={styles.emailContentContainer}>
                                <EmailContentContainer 
                                    initialEmailContent={plainEmailContent}
                                    htmlContent={htmlEmailContent}
                                    isEditable={true}
                                    isForSending={false}
                                    setFinalEmailContent={(content) => {
                                        // Update both plain text and HTML versions with the actual link
                                        const updatedPlainText = content.replace(/{generatedLink}/g, generatedLink);
                                        setPlainEmailContent(updatedPlainText);
                                
                                        const updatedHtmlContent = htmlEmailContent.replace(
                                            /{generatedLink}/g,  // Replace all instances of {generatedLink}
                                            generatedLink
                                        );
                                        setFinalHtmlContent(updatedHtmlContent);
                                        setFinalEmailContent(updatedHtmlContent);
                                    }}
                                />
                            </div>
                        </>
                    )}

                    {activeTab === 'inviteByEmail' && (
                        <>
                            <div className={styles.tabDescription}>Convide pessoas inserindo o nome e o e-mail abaixo.</div>
                            {isInviting ? (
                                <FadeLoader text="Enviando convites..." height='300px' width='100%' />
                            ):(
                                <>
                                    <div className={styles.contentSubheader}>Adicionar candidatos</div>
                                    <div className={styles.emailTableContainer} ref={emailTableContainerRef}>
                                        <table className={styles.emailTable}>
                                            <thead className={styles.emailTableHeader}>
                                                <tr>
                                                    <th aria-label="Nome da pessoa" className={styles.firstColumn}>Nome</th>
                                                    <th aria-label="Endereço de e-mail" className={styles.middleColumn}>E-mail</th>
                                                    <th aria-label="Ações" className={styles.lastColumn}></th>
                                                </tr>
                                            </thead>
                                            <tbody className={styles.emailTableBody}>
                                                {candidates.map((entry, index) => (
                                                    <tr key={index} className={entry.accepted ? styles.acceptedRow : ''}>
                                                        <td className={styles.firstColumn}>
                                                            <input
                                                                type="text"
                                                                placeholder="Nome completo"
                                                                value={entry.name}
                                                                onChange={(e) => handleCandidateChange(index, 'name', e.target.value)}
                                                                className={`${styles.nameInput} ${entry.accepted ? styles.acceptedInput : ''}`}
                                                            />
                                                        </td>
                                                        <td className={styles.middleColumn}>
                                                            <input
                                                                type="email"
                                                                placeholder="email"
                                                                value={entry.email}
                                                                onChange={(e) => handleCandidateChange(index, 'email', e.target.value)}
                                                                className={`${styles.emailInput} ${entry.accepted ? styles.acceptedInput : ''}`}
                                                            />
                                                        </td>
                                                        <td className={styles.lastColumn}>
                                                            {entry.accepted ? (
                                                                <ActionButton 
                                                                    icon={faTimes} 
                                                                    label="Excluir candidato" 
                                                                    onClick={() => handleRemoveCandidateField(index)}
                                                                    showHoverPopout={true}
                                                                    popoutText="Excluir candidato"
                                                                    popoutPosition="left"
                                                                />
                                                            ) : (
                                                                <Button
                                                                    type="proceed"
                                                                    onClick={handleAddEmailField}
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} className={styles.plusIcon} />
                                                                    Adicionar
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={styles.contentSubheader}>Email(s) em cópia</div>
                                    <div className={styles.inputGroup}>
                                        <div className={styles.emailInputWrapper}>
                                            {adminEmails.map((email, index) => (
                                                <span key={index} className={styles.emailTag}>
                                                    {email}
                                                    <button type="button" onClick={() => removeAdminEmail(index)}>x</button>
                                                </span>
                                            ))}
                                            <input
                                                type="text"
                                                value={adminEmailValue}
                                                onChange={handleAdminEmailInputChange}
                                                onKeyDown={handleKeyDown}
                                                onBlur={handleBlur}
                                                placeholder="exemplo@empresa.com"
                                            />
                                        </div>
                                    </div>
                                    {errorMessage && (
                                        <div className={styles.errorMessage}>{errorMessage}</div>
                                    )}
                                    {successMessage && (
                                        <div className={styles.successMessage}>{successMessage}</div>
                                    )}
                                    <div className={styles.contentSubheader}>Editar o email antes de enviar</div>
                                    <div className={styles.emailContentContainer}>
                                        <EmailContentContainer 
                                            initialEmailContent={plainEmailContent}
                                            htmlContent={htmlEmailContent}
                                            isEditable={true}
                                            isForSending={true}
                                            setFinalEmailContent={(content) => {
                                                // Update both plain text and HTML versions with the actual link
                                                const updatedPlainText = content.replace(/{generatedLink}/g, generatedLink);
                                                setPlainEmailContent(updatedPlainText);
                                        
                                                const updatedHtmlContent = htmlEmailContent.replace(
                                                    /{generatedLink}/g,  // Replace all instances of {generatedLink}
                                                    generatedLink
                                                );
                                                setFinalHtmlContent(updatedHtmlContent);
                                                setFinalEmailContent(updatedHtmlContent);
                                            }}
                                        />
                                    </div>
                                    <Button
                                        type="form"
                                        onClick={handleInviteCandidates}
                                        disabled={candidates.filter(candidate => candidate.accepted).length === 0} 
                                    >
                                        Convidar {candidates.filter(candidate => candidate.accepted).length} {candidates.filter(candidate => candidate.accepted).length === 1 ? 'pessoa' : 'pessoas'}
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InviteCandidateModal;