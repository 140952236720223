//Send one off messages to gpt for non-streamed responses
import formatGPTMessages from '../utils/formatGPTMessages';
import replacePlaceholdersForGpt from '../utils/replacePlaceholdersForGpt';
import formatDatabaseSchema from '../utils/formatDatabaseSchema';
import { logEvent, logException, logTrace } from './loggerFront';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export async function sendNonStreamedGptMessages(chatInstruction, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, instructionsText, preEvalCriteria, testAttemptId, isAdmin = 'unknown', currentSection, attempt = 0) {
    const maxRetries = 3;
    const retryDelays = [2000, 4000, 8000];
    const formattedMessages = formatGPTMessages([], '', chatInstruction); // Use empty array and empty string to bypass chat data and section filtering
    const fileName = 'sendNonStreamedGptMessages'
    const messagesWithReplacedPlaceholders = formattedMessages.map(message => {
        return replacePlaceholdersForGpt(message, { instructions: instructionsText, preEvalCriteria: preEvalCriteria, finalCode: finalCode, boilerplateCode: boilerplateCode, initialDatabaseSchema: initialDatabaseSchema, finalDatabaseSchema: formatDatabaseSchema(databaseSchema) })
    });

    logTrace(`Sending code to backend for GPT to evaluate attempt ${attempt}`, {
        code: finalCode,
        type: 'Gpt',
        attempt,
        fileName: fileName,
        testAttemptId,
    });

    try {
        const response = await fetch('/api/gpt-non-streamed', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                messages: messagesWithReplacedPlaceholders, 
                testAttemptId: testAttemptId,
                callName: 'DuringInterview',
                callCategory: 'CodeEvaluation',
                isAdmin: isAdmin
            }),
        });

        if (!response.ok) {
            // Log error details before throwing to retry
            const errorBody = await response.text(); // Attempt to read the response body
            logException('HTTP response not OK', {
                attempt,
                type: 'Gpt',
                fileName: fileName,
                statusCode: response.status,
                statusText: response.statusText,
                responseDetails: errorBody,
                testAttemptId,
            });

            // Retry logic for !response.ok
            if (attempt < maxRetries) {
                await sleep(retryDelays[attempt]);
                return sendNonStreamedGptMessages(chatInstruction, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, instructionsText, testAttemptId, isAdmin, currentSection, attempt + 1);
            } else {
                throw new Error(`HTTP error! status: ${response.status} after max retries`);
            }
        }

        const jsonResponse = await response.json();
        logTrace('Success: Received non-stream GPT response', {
            Response: jsonResponse,
            fileName: fileName,
            status: 'Success',
            type: 'Gpt',
            testAttemptId,
        });

        //Send back the final prompt and the response to append to messages state in chatbox along with the code submission
        return {
            gptPrompt: messagesWithReplacedPlaceholders,
            gptResponse: jsonResponse.response, 
            runId: jsonResponse.runId  // Extracting the runId from the response
        };
    } catch (e) {
        // Retry logic for exceptions
        if (attempt < maxRetries) {
            logException(`Retrying due to exception. Attempt ${attempt + 1}`, {
                errorMessage: e.message,
                attempt: attempt + 1,
                type: 'Gpt',
                fileName: fileName,
                code: finalCode,
                errorStack: e.stack,
                testAttemptId,
            });
            await sleep(retryDelays[attempt]);
            return sendNonStreamedGptMessages(chatInstruction, finalCode, instructionsText, attempt + 1);
        } else {
            logException('Max retries reached. Sending non-stream GPT to backend failed', {
                errorMessage: e.message,
                type: 'Gpt',
                fileName: fileName,
                code: finalCode,
                errorStack: e.stack,
                statusCode: e.response ? e.response.status : 'No response',
                testAttemptId,
            });
            throw e; // Rethrow the last error after all retries have failed
        }
    }
}