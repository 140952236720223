//replace the placeholders with actual text
const replacePlaceholdersForGpt = (message, placeholders) => {
  let content = message.content;
  
  Object.keys(placeholders).forEach(key => {
      content = content.replace(new RegExp(`{${key}}`, 'g'), placeholders[key]);
  });

  const updatedMessage = { ...message, content };
  
  return updatedMessage;
};

export default replacePlaceholdersForGpt;