// Database services for results of a company
import axios from "axios";
import { retryRequest } from '../utils/retryRequest';
import { logEvent, logException, logTrace } from "./loggerFront"; // To log errors
import useAuth from "../hooks/useAuth";

const fileName = "companyResultService.js";

// Fetch all the results for a company 
export const fetchCompanyResults = async (companyId, startAt = null, shareableLinkToken = null) => {
    logTrace("Fetching company results", { companyId, startAt }, fileName);

    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
        // Build the query string
        const queryParams = new URLSearchParams();
        if (startAt) {
            queryParams.append("startAt", startAt);
        }

        let url = new URL(`/api/company-result/company-results/${companyId}?${queryParams.toString()}`, window.location.origin);

        // Append the shareable link token if provided
        if (shareableLinkToken) {
            url.pathname += `/${shareableLinkToken}`;
        }

        const response = await fetch(url, {
            method: "GET",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
    
        if (!response.ok) {
            if (response.status === 404) {
                // Handle the case where no data is found
                return { data: [], issue: "noData" };
            } else if (response.status === 403) {
                // Handle the case where the user is not authorized
                logException("User not authorized to fetch company results", {
                    errorMessage: "Unauthorized access",
                    errorCode: response.status,
                    fileName,
                });
                throw new Error("Unauthorized access");
            } else {
                logException("Network response was not ok", {
                    errorMessage: response.statusText,
                    errorCode: response.status,
                    fileName,
                });
                throw new Error("Network response was not ok");
            }
        }
    
        const results = await response.json();
        return { data: results, issue: null }; // Return the results with no issue
    } catch (error) {
        logException("Error fetching company results", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            companyId,
        });
        return { data: [], issue: "unknown" }; // Return an empty array with an "unknown" issue
    }
};

// Fetch the results for a specific company test (used in ReportInterface.js as we just want results, not incomplete attempts, for that specific test)
export const fetchCompanyTestResults = async (companyTestId, startAt = null, shareableLinkToken = null) => {
    logTrace("Fetching company test results", { companyTestId, startAt }, fileName);

    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
        // Build the query string
        const queryParams = new URLSearchParams();
        if (startAt) {
            queryParams.append("startAt", startAt);
        }

        let url = new URL(`/api/company-result/company-test-results/${companyTestId}?${queryParams.toString()}`, window.location.origin);

        // Append the shareable link token if provided
        if (shareableLinkToken) {
            url.pathname += `/${shareableLinkToken}`;
        }

        const response = await fetch(url, {
            method: "GET",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
    
        if (!response.ok) {
            if (response.status === 404) {
                // Handle the case where no data is found
                return { data: [], issue: "noData" };
            } else if (response.status === 403) {
                // Handle the case where the user is not authorized
                logException("User not authorized to fetch company test results", {
                    errorMessage: "Unauthorized access",
                    errorCode: response.status,
                    fileName,
                });
                throw new Error("Unauthorized access");
            } else {
                logException("Network response was not ok", {
                    errorMessage: response.statusText,
                    errorCode: response.status,
                    fileName,
                });
                throw new Error("Network response was not ok");
            }
        }
    
        const results = await response.json();
        return { data: results, issue: null }; // Return the results with no issue
    } catch (error) {
        logException("Error fetching company test results", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            companyTestId,
        });
        return { data: [], issue: "unknown" }; // Return an empty array with an "unknown" issue
    }
};

// Get the summary results a 'user' has access to potentially limited by companyTestIds (e.g. for the sidebar of a specific test) or limit (for the user summary page)
export const fetchUserResults = async (companyTestIds = null, limit = null) => {
    return retryRequest(async () => {
      const { accessToken } = useAuth();
      const token = accessToken();
  
      let url = "/api/company-candidate/user-result-summary";
  
      if (companyTestIds) {
        let companyTestIdsQuery;
  
        if (Array.isArray(companyTestIds)) {
          if (companyTestIds.length === 1) {
            companyTestIdsQuery = companyTestIds[0]; // If there's only one company test id in the array, use it directly
          } else {
            companyTestIdsQuery = companyTestIds.join(','); // If there are multiple, join them with commas
          }
        } else {
          companyTestIdsQuery = companyTestIds; // If companyTestIds is a string, use it directly
        }
  
        url += `?companyTestIds=${companyTestIdsQuery}`;
      }
  
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: { 
            "Authorization": `Bearer ${token}`
          }
        });
  
        if (response.status === 404) {
          const errorResult = await response.json();
          logTrace("fetchUserResults 404", { errorResult });
          
          if (errorResult.message.includes("No report accesses found for the user")) {
            return { data: [], issue: "noAccesses" };
          } else if (errorResult.message.includes("No accessible reports found")) {
            return { data: [], issue: "noData" };
          } else {
            return { data: [], issue: "unknown" };
          }
        }
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const data = await response.json();
        return { data }; // Match the structure of fetchCompanyResults
      } catch (error) {
        logException("Error fetching user reports", {
          errorMessage: error.message,
          errorStack: error.stack,
          fileName,
        });
        return { data: [], issue: "unknown" }; // Match the structure of fetchCompanyResults
      }
    });
};

// Function to fetch all results (no longer using as we have getCandidateSummary which also gets attempts and invites)
export const fetchAllResults = async (startAt = null) => {
    logTrace("Fetching all results", { startAt }, fileName);

    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
        // Build the query string
        const queryParams = new URLSearchParams();
        if (startAt) {
            queryParams.append("startAt", startAt);
        }

        const response = await fetch(`/api/company-result/all-results?${queryParams.toString()}`, {
            method: "GET",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
    
        if (!response.ok) {
            if (response.status === 404) {
                // Handle the case where no data is found
                return { data: [], issue: "noData" };
            } else if (response.status === 403) {
                // Handle the case where the user is not authorized
                logException("User not authorized to fetch all results", {
                    errorMessage: "Unauthorized access",
                    errorCode: response.status,
                    fileName,
                });
                throw new Error("Unauthorized access");
            } else {
                logException("Network response was not ok", {
                    errorMessage: response.statusText,
                    errorCode: response.status,
                    fileName,
                });
                throw new Error("Network response was not ok");
            }
        }
    
        const result = await response.json();
        return result;
    } catch (error) {
        logException("Error getting all results", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
        });
        throw error;
    }
};

// Function to fetch user summary with optional companyId
export const getResultsShortSummary = async (companyId = null, resultLimit = 5) => {
    logTrace("Fetching results short summary", { companyId, resultLimit }, fileName);

    const { accessToken } = useAuth();
    const token = accessToken();
  
    try {
        // Build the query string
        const queryParams = new URLSearchParams({
            resultLimit: resultLimit.toString(),
        });
        if (companyId) {
            queryParams.append("companyId", companyId);
        }

        const response = await fetch(`/api/company-result/results-short-summary?${queryParams.toString()}`, {
            method: "GET", // Using GET to fetch the summary
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}` // Include the auth token
            }
        });
    
        if (!response.ok) {
            if (response.status === 404) {
                // Handle the case where no data is found
                return { data: [], issue: "noData" };
            } else {
                logException("Network response was not ok", {
                    errorMessage: response.statusText,
                    errorCode: response.status,
                    fileName,
                    companyId: companyId || 'No companyId',
                });
                throw new Error("Network response was not ok");
            }
        }
    
        const result = await response.json();
        return result; // Handle the result as needed
    } catch (error) {
        logException("Error getting company short summary", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            companyId: companyId || 'No companyId',
        });
        throw error; // Handle the error as needed
    }
};