import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCompanyTests, getTestOverview, getAllTestNames, editCompanyTest } from '../services/companyTestService';
import { logException } from '../services/loggerFront';

const useCompanyTestData = (viewPermissionLevel, isOrgAdmin, companyDetails, setErrorMessage, setNoTestData, setModalLoading) => {
    const [companyList, setCompanyList] = useState(null); // Used for filtering if more than 1
    const [roleList, setRoleList] = useState(null); // Used for filtering if more than 1
    const [companyTestData, setCompanyTestData] = useState([]);
    const [testOverviews, setTestOverviews] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [roleCounts, setRoleCounts] = useState({});
    const [companyCounts, setCompanyCounts] = useState({});
    const [allTestNames, setAllTestNames] = useState([]);
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(true); // State to show loading spinner to user
    const [dataFetchCalled, setDataFetchCalled] = useState(false); // State to prevent multiple data fetches
    const navigate = useNavigate();
    const fileName = 'useCompanyTestData.js';

    // On load, identify the user's permissions and fetch the relevant dashboard data
    useEffect(() => {
        if (dataFetchCalled) {
            return; // If data has already been fetched
        }

        const getDashboardAccessLevel = async () => {
            try {
                // If some is missing, wait, as the useEffect will run again
                if (viewPermissionLevel === null || Object.keys(companyDetails).length === 0 || isOrgAdmin === null) {
                    logException('Missing required data when checking dashboard access:', { viewPermissionLevel, companyDetails, isOrgAdmin, fileName });
                    return;
                }
                // Fetch the data depending on the user's permissions
                if (viewPermissionLevel === 'GLOBAL') { // If it is a global dashboard, fetch all results
                    fetchTestDashboardData(true);
                    return;
                } else if (isOrgAdmin) { // If it is an org admin, fetch all the company results
                    fetchTestDashboardData(false, true, companyDetails);
                    return;
                } else { 
                    fetchTestDashboardData(false, false, companyDetails);
                    return;
                }
            } catch (err) {
                logException('Failed to fetch test dashboard data:', {
                    errorMessage: err.message,
                    errorStack: err.stack,
                    companyDetails,
                    viewPermissionLevel,
                    isOrgAdmin,
                    fileName,
                });
                navigate('/login');
            }
        };
        getDashboardAccessLevel();
    }, [viewPermissionLevel, companyDetails, isOrgAdmin]);

    // Centralized data fetching logic
    const fetchTestDashboardData = useCallback(async (globalAccess = false, isOrgAdmin = false, companyDetails = null) => {
        setIsLoadingInitialData(true);
        setDataFetchCalled(true);
        let data = [];
        try {

            if (globalAccess) { // fetch all results for global admin
                const response = await fetchCompanyTests(); // Call without companyId to get all results
                data = response.data;
                setNoTestData(false);
            } else if (isOrgAdmin && companyDetails) { // fetch results for a specific company for company admin
                const companyId = companyDetails.companyID;
                try {
                    const response = await  fetchCompanyTests(companyId);
                    data = response.data;
                    setNoTestData(false);
                    if (response.issue === "noData") {
                        setNoTestData(true);
                        setErrorMessage("Atualmente, você não tem nenhum entrevista disponível.");
                    } else if (response.issue === "unknown") {
                        setErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    }
                } catch (error) {
                    throw error; 
                }
            } else if (!isOrgAdmin && companyDetails) { // fetch results for a specific company for company admin
                const companyId = companyDetails.companyID;
                try {
                    const response = await  fetchCompanyTests(companyId);
                    data = response.data;
                    setNoTestData(false);
                    if (response.issue === "noData") {
                        setNoTestData(true);
                        setErrorMessage("Atualmente, você não tem nenhum entrevista disponível.");
                    } else if (response.issue === "unknown") {
                        setErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    }
                } catch (error) {
                    throw error; 
                }
            } else {
                return;
            }
            setCompanyTestData(data);

            // if there is data
            if (data.length > 0) {
                calculateCounts(data);
            }
        } catch (error) {
            logException('Error fetching company test data:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
            setErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
            data = []; // Set to empty array instead of throwing an error
            setCompanyTestData(data);
        } finally {
            setIsLoadingInitialData(false); // Set loading to false
        }
    }, [navigate]);

    // Get all test names
    const fetchAllTestNames = async () => {
        try {
            const response = await getAllTestNames();
            const testNames = response;
            setAllTestNames(testNames);
        } catch (error) {
            logException('Error fetching all test names:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
        }
    };

    useEffect(() => {
        if (!allTestNames || allTestNames.length < 1) {
            fetchAllTestNames();
        }
    }, [allTestNames]);

    // COUNTS

    // Calculate role and company counts
    const calculateCounts = (summaryData) => {
        const totalCount = summaryData.length;
        const roleCounts = {};
        const companyCounts = {};
        const roleListSet = new Set();
        const companyListSet = new Set();

        // Get the role, company, and approved counts from the summary data
        summaryData.forEach(item => {
            roleCounts[item.roleName] = (roleCounts[item.roleName] || 0) + 1;
            companyCounts[item.companyName] = (companyCounts[item.companyName] || 0) + 1;

            // Add to role and company lists
            roleListSet.add(item.roleName);
            companyListSet.add(item.companyName);
        });

        setTotalCount(totalCount);
        setRoleCounts(roleCounts);
        setCompanyCounts(companyCounts);

        // Convert sets to arrays and set the lists
        setRoleList(Array.from(roleListSet));
        setCompanyList(Array.from(companyListSet));
    };

    // Expose a refresh function to re-fetch data
    const refreshData = useCallback(() => {
        if (viewPermissionLevel === 'GLOBAL') {
            resetData();
            fetchAllTestNames();
            fetchTestDashboardData(true);
            return;
        } else if (isOrgAdmin && companyDetails) {
            resetData();
            fetchAllTestNames();
            fetchTestDashboardData(false, true, companyDetails);
            return;
        } else if (!isOrgAdmin && companyDetails) {
            resetData();
            fetchAllTestNames();
            fetchTestDashboardData(false, false, companyDetails);
            return;
        }
    }, [fetchTestDashboardData, viewPermissionLevel, companyDetails, isOrgAdmin]);

    const resetData = () => {
        setCompanyList(null);
        setRoleList(null);
        setCompanyTestData([]);
        setTotalCount(0);
        setRoleCounts({});
        setCompanyCounts({});
        setAllTestNames([]);
        setDataFetchCalled(false);
    };

    // TEST OVERVIEW //
    const handleTestOverview = async (testId) => {
        // First check if the test overview is already in state
        if (testOverviews[testId]) {
            return;
        }
        // if not, fetch the data
        try {
            const response = await getTestOverview(testId);
            const testOverview = response[0];
            // save the data to testOverviews with the test id
            setTestOverviews(prevState => {
                return {
                    ...prevState,
                    [testId]: testOverview,
                };
            });
        } catch (error) {
            logException('Error fetching test overview:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
            return null;
        }
    }

    // Add custom name and role to test
    const handleEditTest = async (companyTestId, testData) => {
        setModalLoading(true);
        try {
            const response = await editCompanyTest(companyTestId, testData);
            setModalLoading(false);
            return response;
        } catch (error) {
            setModalLoading(false);
            logException('Error editing test:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
            return null;
        }
    }

    return {
        companyList,
        roleList,
        companyTestData,
        testOverviews,
        totalCount,
        roleCounts,
        companyCounts,
        allTestNames,
        isLoadingInitialData,
        refreshData,
        handleTestOverview,
        handleEditTest,
    };
};

export default useCompanyTestData;
