// Services related to the candidate's test instance
import { logTrace, logException } from "./loggerFront";
import { retryRequest } from "../utils/retryRequest";

const fileName = "candidateTestService.js"; // For logging purposes

// Get the company and role code for the test uuid
export const getTestCodes = async (uuid) => {
    logTrace("getTestCodes", { uuid, fileName });

    // Define the function to be retried
    const tryGetTestCodes = async () => {
        const response = await fetch(`/api/candidate-test/test-codes/${uuid}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error fetching test codes");
        }

        const result = await response.json();
        return result;
    };

    try {
        const result = await retryRequest(tryGetTestCodes, 3, 1000, 2);
        return result;
    } catch (error) {
        logException("Error fetching test codes after retries", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            uuid,
        });
        throw error; // Propagate the error after logging
    }
};

// Get test ids from company and role codes to allow transition from old method to new with uuid
export const getTestIdsFromCodes = async (companyCode, roleCode) => {
    logTrace("getTestIdsFromCodes", { companyCode, roleCode, fileName });

    // Define the function to be retried
    const tryGetTestIdsFromCodes = async () => {
        const response = await fetch("/api/candidate-test/get-test-ids-from-codes", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ company_code: companyCode, role_code: roleCode }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error getting test ids from codes");
        }

        const result = await response.json();
        return result;
    };

    try {
        const result = await retryRequest(tryGetTestIdsFromCodes, 3, 1000, 2);
        return result;
    } catch (error) {
        logException("Error getting test ids from codes after retries", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            companyCode: companyCode || "undefined",
            roleCode: roleCode || "undefined",
        });
        throw error;
    }
};

// Get the test id using the companyTestId
export const getTestIdFromCompanyTestId = async (companyTestId) => {
    logTrace("getTestIdFromCompanyTestId", { companyTestId, fileName });

    // Define the function to be retried
    const tryGetTestIdFromCompanyTestId = async () => {
        const response = await fetch("/api/candidate-test/get-test-id-from-company-test-id", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ company_test_id: companyTestId }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error getting test id from company test id");
        }

        const result = await response.json();
        return result;
    };

    try {
        const result = await retryRequest(tryGetTestIdFromCompanyTestId, 3, 1000, 2);
        return result;
    } catch (error) {
        logException("Error getting test id from company test id after retries", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            companyTestId: companyTestId || "undefined",
        });
        throw error;
    }
};

// Create a new test attempt and return the id
export const createTestAttempt = async (companyCode, roleCode = null, companyTestId = null) => {
    logTrace("createTestAttempt", { companyCode, roleCode, companyTestId, fileName });

    // Define the function to be retried
    const tryCreateTestAttempt = async () => {
        const response = await fetch("/api/candidate-test/create-test-attempt", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ company_code: companyCode, role_code: roleCode, company_test_id: companyTestId }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error creating test attempt");
        }

        const result = await response.json();
        return result;
    };

    try {
        const result = await retryRequest(tryCreateTestAttempt, 3, 1000, 2);
        return result;
    } catch (error) {
        logException("Error creating test attempt after retries", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            companyCode: companyCode || "undefined",
            roleCode: roleCode || "undefined",
            companyTestId: companyTestId || "undefined",
        });
        throw error;
    }
};

// Add or update a candidate
export const addOrUpdateCandidate = async (username, email, permission, companyTestId, testAttemptId) => {
    logTrace("addOrUpdateCandidate", { username, email, permission, companyTestId, testAttemptId, fileName });

    // Define the function to be retried
    const tryAddOrUpdateCandidate = async () => {
        const response = await fetch("/api/candidate-test/add-or-update-candidate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, email, permission, companyTestId, testAttemptId }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error adding or updating candidate");
        }

        const result = await response.json();
        return result;
    };

    try {
        const result = await retryRequest(tryAddOrUpdateCandidate, 3, 1000, 2);
        return result;
    } catch (error) {
        logException("Error adding or updating candidate after retries", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            username: username || "undefined",
            email: email || "undefined",
            permission: permission || "undefined",
            companyTestId: companyTestId || "undefined",
            testAttemptId: testAttemptId || "undefined",
        });
        throw error;
    }
};

// Update an exisiting test attempt
export const updateTestAttempt = async (testAttemptId, updateData, sendAlert = false) => {
    logTrace("updateTestAttempt", { testAttemptId, sendAlert, updateData, fileName });

    // Define the function to be retried
    const tryUpdateTestAttempt = async () => {
        
        const response = await fetch("/api/candidate-test/update-test-attempt", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ test_attempt_id: testAttemptId, sendAlert: sendAlert, ...updateData }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error updating test attempt");
        }

        const result = await response.json();
        return result;
    };

    try {
        const result = await retryRequest(tryUpdateTestAttempt, 3, 1000, 2);
        return result;
    } catch (error) {
        logException("Error updating test attempt after retries", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName,
            testAttemptId: testAttemptId || "undefined",
            updateData: JSON.stringify(updateData) || "undefined",
        });
        throw error;
    }
};
