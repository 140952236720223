import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { TooltipProvider } from "../contexts/TooltipContext";
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import useCompanySummaryData from "../hooks/useCompanySummaryData";
import useEnsureUserData from '../hooks/useEnsureUserData';
import { hasPermission } from "../utils/hasPermission";
import { getPermissionLevel } from '../utils/getPermissionLevel';
import useAuth from "../hooks/useAuth";
import { logException, logEvent, logTrace } from "../services/loggerFront";
// Standard page components
import Header from "../components/common/Header";
import FreeTrialBanner from "../components/common/FreeTrialBanner";
import TopRibbon from "../components/common/TopRibbon";
import ReportIssue from "../components/common/ReportIssue";
import FadeLoader from '../components/common/FadeLoader';
import AccessDenied from '../components/common/AccessDenied';
import CompanyPageSummary from "../components/companyPortal/CompanyPageSummary";
import InviteCandidateModal from "../components/companyPortal/InviteCandidateModal";
import InterviewOverviewModal from "../components/companyPortal/InterviewOverviewModal";
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 

const CompanySummaryPage = () => {
    const navigate = useNavigate(); // Function to navigate to a different page
    const { userPermissions, isOrgAdmin, companyAccountType } = useAppContext();
    const { companyDetails } = useTestContext();
    const companyName = companyDetails.companyName; // Company name

    // Permissions and user data
    const { fetchUserData } = useEnsureUserData();
    const { checkAndHandleTokenExpiration } = useAuth();
    // on load fetch check user is logged and get user data
    useEffect(() => {
        checkAndHandleTokenExpiration();
        fetchUserData();
    }, []);
    const [canViewInterviews, setCanViewInterviews] = useState(false); // State to check if the user has the VIEW_USERS permission
    const [canInviteCandidates, setCanInviteCandidates] = useState(false); // State to check if the user has the INVITE_CANDIDATES permission
    const [viewPermissionLevel, setViewPermissionLevel] = useState(null); // State to check the permission level of the user

    // check permissions 
    const checkPermissions = () => {
        // if permissions are not set, return
        if (!userPermissions) return;
        // check if the user has the permission to view users
        const canViewInterviews = hasPermission(userPermissions, 'VIEW_INTERVIEWS');
        setCanViewInterviews(canViewInterviews);  
        // get the permission level of the user
        const viewPermissionLevel = getPermissionLevel(userPermissions, 'VIEW_INTERVIEWS');
        setViewPermissionLevel(viewPermissionLevel);
        // check if the user has the permission to invite candidates
        const canInviteCandidates = hasPermission(userPermissions, 'INVITE_CANDIDATES');
        setCanInviteCandidates(canInviteCandidates);
    };

    // call the checkPermissions function when userPermissions change
    useEffect(() => {
        checkPermissions();
    }, [userPermissions]);
    
    const [testErrorMessage, setTestErrorMessage] = useState(null); // Error message to display when there is an error
    const [resultErrorMessage, setResultErrorMessage] = useState(null); // Error message to display when there is an error
    const [noTestData, setNoTestData] = useState(false); // State to check if there is no test data
    const [noResultData, setNoResultData] = useState(false); // State to check if there is no result data
    const { companyTestData,
        companyResultsSummary,
        isLoadingInitialData, 
        refreshData,
        handleTestOverview,
        testOverviews,
    } = useCompanySummaryData(viewPermissionLevel, isOrgAdmin, companyDetails, setTestErrorMessage, setResultErrorMessage, setNoTestData, setNoResultData); // Hook to fetch the company data
    const [showInviteModal, setShowInviteModal] = useState(false); // State to show the invite candidate modal
    const [showInterviewOverview, setShowInterviewOverview] = useState(false); // State to show the interview overview modal
    const [selectedTest, setSelectedTest] = useState(null); // State to store the selected test id for the interview overview
    
    const fileName = "CompanySummaryPage.js"; 

    const handleCloseInviteModal = () => {
        setShowInviteModal(false);
        setSelectedTest(null);
    };

    const handleCloseInterviewOverviewModal = () => {
        setShowInterviewOverview(false);
        setSelectedTest(null);
    };

    // Set the test and show the invite modal
    const handleInviteCandidate = (row) => {
        // check if there is a row, if not continue with the selected test
        if (row) {
            setSelectedTest(row);
        }
        setShowInterviewOverview(false);
        setShowInviteModal(true);
    };

    // ITEM CLICKS IN SUMMARY //
    const handleTestClick = (item) => {
        const testId = item.testId;
        handleTestOverview(testId);
        setSelectedTest(item);
        setShowInterviewOverview(true);
    }

    // Wen user clicks on a result in the summary
    const handleResultClick = (item) => {
        const urlCode = item.url_code;
        const baseUrl = `${window.location.origin}/relatorio`;
        const reportUrl = new URL(baseUrl);
        reportUrl.searchParams.set('tentativa', urlCode);
        window.open(reportUrl.toString(), '_blank');
    };

    // Show access denied message if the user doesn't have permission
    if (!canViewInterviews) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title={`${companyName} resumo`} />
                    </div>
                    <div className={styles.contentContainer}>
                        <AccessDenied />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    // Show loading spinner while fetching data
    if (isLoadingInitialData) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title={`${companyName} resumo`} />
                    </div>
                    <div className={styles.contentContainer}>
                        <FadeLoader text="Carregando dados..." />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    return (
        <>
            <TooltipProvider>
            <ReportIssue/>
                {showInviteModal && (
                    <InviteCandidateModal
                        onClose={handleCloseInviteModal}
                        selectedTest={selectedTest}
                        companyDetails={companyDetails}
                        >
                    </InviteCandidateModal>
                )}
                {showInterviewOverview && (
                    <InterviewOverviewModal
                        testOverviews={testOverviews}
                        onClose={handleCloseInterviewOverviewModal}
                        selectedTest={selectedTest}
                        handleInviteCandidate={handleInviteCandidate}
                        canInviteCandidates={canInviteCandidates}
                    >
                    </InterviewOverviewModal>
                )}
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    {companyAccountType === 'free' && (
                        <div className={styles.banner}>
                            <FreeTrialBanner />
                        </div>
                    )}
                    <div className={styles.ribbon}>
                        <TopRibbon title={`${companyName} resumo`} />
                    </div>
                    {isLoadingInitialData ? (
                        <FadeLoader text="Carregando dados..." />
                    ) : (
                        <div className={styles.contentContainer}>
                            <div className={styles.companySummaryOuterContainer}>
                                <div className={styles.companySummaryContainer}>
                                    <CompanyPageSummary 
                                        type="Test" 
                                        title="Entrevistas recentes" 
                                        noData={noTestData} 
                                        data={companyTestData?.tests || []}  // Safely access companyTestData.tests or fallback to an empty array
                                        totalCount={companyTestData?.totalTestCount || 0}  // Provide a default value of 0 if totalTestCount is undefined
                                        handleItemClick={handleTestClick} 
                                        errorMessage={testErrorMessage} 
                                    />
                                </div>
                                {!noTestData && (
                                    <div className={styles.companySummaryContainer}>
                                        <CompanyPageSummary 
                                            type="Result" 
                                            title="Resultados recentes" 
                                            noData={noResultData} 
                                            data={companyResultsSummary?.summary || []}  // Safely access companyResultsSummary.recentResults or fallback to an empty array
                                            totalCount={companyResultsSummary?.resultCount || 0}  // Provide a default value of 0 if resultCount is undefined
                                            handleItemClick={handleResultClick} 
                                            errorMessage={resultErrorMessage}
                                            canInviteCandidates={canInviteCandidates}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </TooltipProvider>
        </>
    );
};

export default CompanySummaryPage;
