// Functions needed for authenticating or registering users to view reports
import axios from "axios";
import { logException, logTrace } from "./loggerFront"; // To log to app insights
import useAuth from "../hooks/useAuth";

const fileName = "authenticationService"; // for Logging

const loginUser = async (email, password) =>  {
  logTrace("loginUser", { email, fileName });

  try {
    const response = await axios.post("/api/users/login", { email, password });
    return { success: true, data: response.data.token };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 429) {
        return { 
          success: false, 
          error: "Muitas tentativas de login. Por favor, aguarde alguns minutos antes de tentar novamente."
        };
      }
      return { 
        success: false, 
        error: error.response.data.message || "Ocorreu um erro durante o login" 
      };
    }
    logException("Error: logging in user", {
      errorMessage: error.message,
      errorStack: error.stack,
      email,
      fileName,
    });
    return { 
      success: false, 
      error: error.message || "Ocorreu um erro durante o login" 
    };
  }
};

// Change the user's password by sending the new password and the recovery token to the API. The token identifies the user
const resetPassword = async (recoveryToken, newPassword) => {
  logTrace("resetPassword", { recoveryToken, fileName });

  try {
    const response = await axios.post("/api/users/reset-password", { recoveryToken, newPassword });
    return { success: true, data: response.data };
  } catch (error) {
    logException("Error: resetting password", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    return {
      success: false,
      error: error.response?.data?.message || error.message || "Ocorreu um erro ao redefinir a senha"
    };
  }
};

// Check if token is valid and what permissions it has
const checkTokenPermissions = async (token) => {
  try {
    const response = await fetch(`/api/shareable-link/check-token/${token}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      if (response.status === 429) {
        return {
          success: false,
          error: "Muitas tentativas. Por favor, aguarde alguns minutos antes de tentar novamente."
        };
      }
      throw new Error("Network response was not ok");
    }

    const permissions = await response.json(); // Parse the JSON response
    return permissions;
  } catch (error) {
    logException("Error checking token", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: "yourFileName", // Replace with the actual file name variable if defined
      token,
    });
    throw error;
  }
};

// Function to generate a recovery token, which will lead to them getting an email with a link to reset their password
const generateRecoveryToken = async (email) => {
  logTrace("generateRecoveryToken", { email, fileName });

  try {
    const response = await axios.post("/api/users/generate-recovery-token", { email });
    return response.data; // Should return a success message
  } catch (error) {
    logException("Error: generating recovery token", {
      errorMessage: error.message,
      errorStack: error.stack,
      email,
      fileName,
    });
    throw error;
  }
};

// Check if the user has a password
const checkHasPassword = async () => {
  try {
    const { accessToken } = useAuth();
    const token = accessToken();
    const response = await fetch('/api/users/has-password', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      if (response.status === 429) {
        return {
          success: false,
          error: "Muitas tentativas. Por favor, aguarde alguns minutos antes de tentar novamente."
        };
      }
      throw new Error('Failed to check password status');
    }

    const data = await response.json();
    return data.hasPassword;
  } catch (error) {
    throw error;
  }
};

// Change password of logged in user
const changePassword = async (currentPassword, newPassword) => {
  
  try {
    const { accessToken } = useAuth(); // Get the access token from the auth hook
    const token = accessToken();
    logTrace("changePassword", { token, fileName });
    const response = await fetch(`/api/users/change-password`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify({ currentPassword, newPassword }), // Correctly structure the body
    });

    if (!response.ok) {
      if (response.status === 429) {
        return {
          success: false,
          error: "Muitas tentativas. Por favor, aguarde alguns minutos antes de tentar novamente."
        };
      }
      const errorData = await response.json(); // Parse the error response
      throw new Error(errorData.message || "Falha ao alterar a senha");
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    logException("Error: changing password", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    return {
      success: false,
      error: error.message || "Ocorreu um erro ao alterar a senha"
    };
  }
};

// Set up a password for the user (if the previous login was with SSO)
const setInitialPassword = async (newPassword) => {
  try {
    const { accessToken } = useAuth(); // Get the access token from the auth hook
    const token = accessToken();
    logTrace("setInitialPassword", { token, fileName });
    
    const response = await fetch(`/api/users/set-initial-password`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify({ newPassword }),
    });

    if (!response.ok) {
      if (response.status === 429) {
        return {
          success: false,
          error: "Muitas tentativas. Por favor, aguarde alguns minutos antes de tentar novamente."
        };
      }
      const errorData = await response.json();
      throw new Error(errorData.message || "Falha ao definir a senha inicial");
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    logException("Error: setting initial password", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    return { 
      success: false, 
      error: error.message || "Ocorreu um erro ao definir a senha inicial" 
    };
  }
};

// Verify email via the token sent in the email
const verifyEmail = async (token) => {
  logTrace("verifyEmail", { token, fileName });

  try {
    const response = await axios.get(`/api/users/verify-email/${token}`);
    return { success: true, data: response.data };
  } catch (error) {
    logException("Error: verifying email", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    return { 
      success: false, 
      error: error.response?.data?.message || error.message || "Ocorreu um erro durante a verificação do email" 
    };
  }
};

// Complete SSO signup
const completeSsoSignup = async (companyName) => {
  try {
    const { accessToken } = useAuth(); // Get the access token from the auth hook
    const token = accessToken();
    logTrace("completeSsoSignup", { companyName, token, fileName });
    
    const response = await fetch(`/api/oidc/complete-signup`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify({ companyName }),
    });

    if (!response.ok) {
      if (response.status === 429) {
        return {
          success: false,
          error: "Muitas tentativas. Por favor, aguarde alguns minutos antes de tentar novamente."
        };
      }
      const errorData = await response.json();
      throw new Error(errorData.message || "Falha ao completar o cadastro SSO");
    }

    const data = await response.json();
    return { success: true, data: data.token };
  } catch (error) {
    logException("Error: completing SSO signup", {
      errorMessage: error.message,
      errorStack: error.stack,
      companyName,
      fileName,
    });
    return { 
      success: false, 
      error: error.message || "Ocorreu um erro ao completar o cadastro SSO" 
    };
  }
};


export { 
  loginUser, 
  resetPassword, 
  checkTokenPermissions, 
  generateRecoveryToken, 
  checkHasPassword,
  changePassword,
  setInitialPassword,
  verifyEmail,
  completeSsoSignup
};
