// Table with the results of the interviews for the given company
export const getCompanyTestsTableColumns = (viewPermissionLevel, companies) => [
    { key: 'testName', label: 'Titulo', sortable: true, align: 'left' },
    ...(viewPermissionLevel === 'GLOBAL' && companies && companies.length > 0 ? [
        { key: 'companyName', label: 'Empresa', sortable: true, align: 'left' }
    ] : []),
    { key: 'roleName', label: 'Cargo', sortable: true, align: 'left' },
    { key: 'resultCount', label: 'Resultados', sortable: true, align: 'center' },
    { key: 'latestTimestamp', label: 'Última atividade', sortable: true, align: 'left' },
    { key: 'actions', label: 'Ações', sortable: false, align: 'center' }
];
