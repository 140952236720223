
// Format date to save in mySQL
const formatDateForMySQL = (date) => {
const d = new Date(date);
const year = d.getUTCFullYear();
const month = `${d.getUTCMonth() + 1}`.padStart(2, "0");
const day = `${d.getUTCDate()}`.padStart(2, "0");
const hours = `${d.getUTCHours()}`.padStart(2, "0");
const minutes = `${d.getUTCMinutes()}`.padStart(2, "0");
const seconds = `${d.getUTCSeconds()}`.padStart(2, "0");

return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export default formatDateForMySQL;
