// Database services for report access management
import axios from "axios";
import { retryRequest } from '../utils/retryRequest';
import { logEvent, logException, logTrace } from "./loggerFront"; // To log errors
import useAuth from "../hooks/useAuth";

const fileName = "companyUserService.js";

// Function to fetch user summary with optional companyId
export const fetchUserSummary = async (companyId = null) => {

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const url = companyId 
      ? `/api/company-admin/user-summary?companyId=${companyId}` 
      : `/api/company-admin/user-summary`;
      
    const response = await fetch(url, {
      method: "GET",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error fetching user summary", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      companyId,
    });
    throw error; // Handle the error as needed
  }
};

// Function to add a user
export const addUser = async (userData) => {
  logTrace("addUser", { userData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch("/api/company-admin/add-user", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error adding user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userData,
    });
    throw error; // Handle the error as needed
  }
};

// Accept a user request passing the user id to be accepted
export const acceptUserRequest = async (userId) => {
  logTrace("acceptUserRequest", { userId });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/accept-user-request`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    logEvent("User request accepted", { userId });
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error accepting user request", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
    });
    throw error; // Handle the error as needed
  }
};

// Function to remove a user
export const removeUser = async (userId) => {
  logTrace("removeUser", { userId });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/remove-user/${userId}`, {
      method: "DELETE",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error removing user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
    });
    throw error; // Handle the error as needed
  }
};

// Function to edit a user
export const editUser = async (userId, userData) => {
  logTrace("editUser", { userId, userData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/edit-user/${userId}`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error editing user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
      userData,
    });
    throw error; // Handle the error as needed
  }
};

// Function to edit your own profile
export const editProfile = async (userData) => {
  logTrace("editProfile", { userData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/users/edit-profile`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error editing profile", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
      userData,
    });
    throw error; // Handle the error as needed
  }
};

// Register a user after they input their name and password
export const registerUser = async (registerToken, name, surname, password) => {
  logTrace("registerUser", { registerToken, name, surname });

  try {
    const response = await fetch("/api/users/register", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ registerToken, name, surname, password }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error registering user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      registerToken,
    });
    throw error; // Handle the error as needed
  }
};

// Fetch role assignments for the current user
export const fetchRoleAssignments = async () => {
  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const url = `/api/company-admin/role-assignments`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Return the fetched role assignments
  } catch (error) {
    logException("Error fetching role assignments", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Update the company name
export const editCompanyName = async (companyId, newCompanyName) => {
  logTrace("editCompanyName", { companyId, newCompanyName });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/edit-company-name/${companyId}`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify({ newCompanyName: newCompanyName }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error editing company name", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: 'editCompanyName.js',
      companyId,
      newCompanyName,
    });
    throw error; // Handle the error as needed
  }
};

// Request account access for a user
export const requestUserAccount = async (email, referralReport) => {
  logTrace("requestAccount", { email, referralReport });

  try {
    const response = await fetch("/api/users/request-user-account", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, referralReport }), // Send email and referralReport as the request body
    });

    // If the response is not ok, extract the error message from the response body
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error requesting account", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: "authenticationService",
      email,
      referralReport,
    });
    throw error; // Propagate the error to be handled by the caller
  }
};

// Fetch user details. Their token provides their ID
export const fetchUserNameAndEmail = async () => {
  const { accessToken } = useAuth();
  const token = accessToken();

  try {
      const response = await axios.get('/api/users/name-email', {
          headers: {
              Authorization: `Bearer ${token}`, // Use the token to authenticate and get the user ID
          },
      });

      return response.data; // { name: 'User Name', email: 'user@example.com' }
  } catch (error) {
    logException("Error fetching user name and email", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    throw error;
  }
};

// Create a company and user when a user creates account
export const createNewAccount = async (email, password, firstNames, lastNames, companyName) => {
  logTrace("createNewAccount", { email, companyName });

  try {
    const response = await fetch("/api/users/create", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, firstNames, lastNames, companyName }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error creating new account", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: "companyUserService",
    });
    throw error; // Propagate the error to be handled by the caller
  }
};
