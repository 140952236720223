import React, { useState, useEffect } from 'react';
import { useTestContext } from '../contexts/TestContext';
import { useAppContext } from '../contexts/AppContext';
import { TooltipProvider } from "../contexts/TooltipContext";
import { uploadCompanyLogo } from '../services/uploadCompanyPortalImage';
import { editCompanyName } from '../services/companyUserService';
import { logException, logEvent, logTrace } from "../services/loggerFront";
import useEnsureUserData from '../hooks/useEnsureUserData';
import useAuth from "../hooks/useAuth"; // Custom hook to get the user's authentication status
import { getCompanyLogoPath } from '../utils/getCompanyLogo';

import FreeTrialBanner from '../components/common/FreeTrialBanner';
import Header from "../components/common/Header";
import TopRibbon from '../components/common/TopRibbon';
import AdminModal from '../components/common/AdminModal';
import ActionButton from '../components/common/ActionButton';
import UploadImage from '../components/inputs/UploadImage';
import EditCompanyNameForm from '../components/inputs/EditCompanyNameForm';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import styles from "../sharedStyles/CompanyAdminPages.module.css";

const MyCompanyPage = () => {
    const { companyDetails, setCompanyDetails } = useTestContext(); // Access the company context
    const { companyAccountType } = useAppContext();
    // on load fetch user data and check if token is expired
    const { fetchUserData, updateCompanyDetails } = useEnsureUserData();
    const auth = useAuth(); 
    const { checkAndHandleTokenExpiration } = useAuth();
    useEffect(() => {
        fetchUserData();
        checkAndHandleTokenExpiration();
    }, []);
    
    const [showEditModal, setShowEditModal] = useState(false); // Control modal visibility for editing company name
    const [loading, setLoading] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false); // If the image loading fails despite having a path
    const [partnerLogoPath, setPartnerLogoPath] = useState(null); // The path to the partner company logo
    useEffect(() => {
        logTrace('getting company logo path', { companyDetails });
        const logoPath = getCompanyLogoPath(companyDetails); // Get the partner company logo path
        logTrace('got company logo path', { logoPath });
        setPartnerLogoPath(logoPath);
    }, [companyDetails]);
    const [newCompanyName, setNewCompanyName] = useState('')

    // set the newComapnyName as the current
    useEffect(() => {
        const currentCompanyName = companyDetails.companyName
        setNewCompanyName(currentCompanyName)
    }, [companyDetails]); 

    const handleUploadSuccess = () => {
        updateCompanyDetails();
        setImageLoadError(false); // Reset the image load error
    };

    const handleEditCompanyName = async (newCompanyName) => {
        try {
            setLoading(true);
            const response = await editCompanyName(companyDetails.companyID, newCompanyName); 
            const token = response.token;
            auth.signIn(token); // Update the user's token
            const updatedCompanyDetails = await auth.getCompanyDetailsFromToken(); // Update the company details
            setCompanyDetails(updatedCompanyDetails); // Update the company context
            setShowEditModal(false); // Close modal after save
        } catch (error) {
            logException('Error editing company name', { 
                errorMessage: error.message,
                errorStack: error.stack,
                newCompanyName,
                companyID: companyDetails.companyID
            });
        } finally {
            setLoading(false);
        }
    };

    const handleImageError = () => {
        setImageLoadError(true); // Set image load error to true if the image fails to load
    };

    return (
        <TooltipProvider>
            <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                <div className={styles.header}>
                    <Header />
                </div>
                {companyAccountType === 'free' && (
                    <div className={styles.banner}>
                        <FreeTrialBanner />
                    </div>
                )}
                <div className={styles.ribbon}>
                    <TopRibbon title="Minha empresa" />
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.companyProfileContainer}>
                        <div className={styles.companyOverview}>
                            {/* Company Name */}
                            <div className={styles.companyDetailContainer}>
                                <div className={styles.companyName}>
                                    {companyDetails.companyName}
                                </div>
                                <ActionButton 
                                    icon={faEdit} 
                                    label="Editar Nome da Empresa"
                                    onClick={() => setShowEditModal(true)} // Show modal on click
                                    showHoverPopout={true}
                                    popoutText="Editar nome da empresa"
                                    popoutPosition="left"
                                />
                            </div>
                        </div>

                        <div className={styles.companyLogoOverview}>
                            {/* Company Logo Upload */}
                            {partnerLogoPath && !imageLoadError ? (
                                <>
                                    <div className={styles.currentCompanyLogoContainer}>
                                        <div className={styles.companyLogoLabel}>Logo atual</div>
                                        <img
                                            src={partnerLogoPath}
                                            alt="Logotipo da empresa"
                                            className={styles.myCompanyLogo}
                                            onError={handleImageError} // Handle image load error
                                        />
                                    </div>
                                    <div className={styles.uploadPlaceholder}>
                                        <UploadImage 
                                            onUpload={(file) => uploadCompanyLogo(companyDetails.companyID, file)} 
                                            onUploadSuccess={handleUploadSuccess} 
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className={styles.uploadPlaceholder}>
                                    <UploadImage 
                                        onUpload={(file) => uploadCompanyLogo(companyDetails.companyID, file)} 
                                        onUploadSuccess={handleUploadSuccess} 
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Edit Company Name Modal */}
                {showEditModal && (
                    <AdminModal
                        title="EditarnNome da empresa"
                        onClose={() => setShowEditModal(false)} // Close modal
                        onConfirm={() => handleEditCompanyName(newCompanyName)}  // Pass the new company name to the handler
                        isLoading={loading}
                    >
                        <EditCompanyNameForm
                            currentCompanyName={newCompanyName} 
                            setCompanyName={setNewCompanyName}   
                        />
                    </AdminModal>
                )}
            </div>
        </TooltipProvider>
    );
};

export default MyCompanyPage;
