// Interface for backend dev tests. Accessed by two main ways, with a shareable link token which contains a specific report id, or a group. Or a specific report ID forthe logged in user.
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom"; // To navigate to the feedback page while passing context
import { useAppContext } from "../../contexts/AppContext";
import { useTestContext } from "../../contexts/TestContext";
import FeedbackContext from "../../contexts/FeedbackContext";
import { hasPermission } from "../../utils/hasPermission";
import {convertOldInstructionsToMarkdown} from "../../utils/convertOldInstructionsToMarkdown";
import navigateWithContext from "../../utils/navigateWithContext"; // Redirects to a target URL with a redirect query parameter containing the current URL.
import useAuth from "../../hooks/useAuth"; // Custom hook for authentication functions
import {
  fetchScreenShares,
  checkValidCompanyAndRole,
  fetchCandidateReportData,
  getTagsForResult,
  fetchCompanyDetailsByCode,
  fetchCompanyDetailsById,
} from "../../services/databaseService";
import { fetchCompanyTestResults, fetchUserResults } from "../../services/companyResultService";
import { getCompanyIdFromCompanyTestId } from "../../services/companyTestService";
import { getTestIdsFromCodes } from "../../services/candidateTestService";
import { checkTokenPermissions } from "../../services/authenticationService";
import { logEvent, logTrace, logException } from "../../services/loggerFront";
import ChatMessage from "../chat/ChatMessage";
import CodeEditor from "../coding/CodeEditor";
import CodeOutput from "../coding/CodeOutput";
import ReportSchemaViewer from "../coding/ReportSchemaViewer";
import TotalScorePie from "../report/TotalScorePie";
import SkillSummary from "../report/SkillSummary";
import SubSkillSummary from "../report/SubSkillSummary";
import SubSkillDetail from "../report/SubSkillDetail";
import ReportSidebar from "../report/ReportSidebar";
import FeedbackInput from "../common/FeedbackInput"; // Import the FeedbackInput component
import CandidatePhotoStream from "../report/CandidatePhotoStream";
import ShareLinkModal from "../report/ShareLinkModal";
import { languageMappings } from "../coding/LanguageMapping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendarCheck,
  faFilePdf,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons"; // non-filled icons
import {
  faCamera,
  faShieldAlt,
  faDesktop,
  faCogs,
  faSignOutAlt,
  faEye,
  faEyeSlash,
  faShareAlt,
  faTag,
  faUniversalAccess,
} from "@fortawesome/free-solid-svg-icons"; // filled icons
import { FadeLoader } from "react-spinners";
import styles from "./ReportInterface.module.css";

const ReportInterface = () => {
  // Authentication
  const { setUserPermissions, setUserRoles } = useAppContext();
  const { isTokenExpired, getPermissions, getUserRoles } = useAuth();
  // Data
  const [overallScore, setOverallScore] = useState("");
  const [rank, setRank] = useState("");
  const [detailedSkillsData, setDetailedSkillsData] = useState([]);
  const [subSkillSections, setSubSkillSections] = useState([]); // List of the sections in the scoring JSON, enables filling outOfTime section if there is a mismatch between this and chatSections
  const [groupedMissingSubSkills, setGroupedMissingSubSkills] = useState([]); // Group missingSubSkillSections by SkillName so they appear together in the UI
  const [overallFeedback, setOverallFeedback] = useState("");
  const [username, setUsername] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [userInitialPhoto, setUserInitialPhoto] = useState(""); // Store their photo
  const [userScreenShares, setUserScreenShares] = useState(""); // Store their screen share
  const [testAttemptId, setTestAttemptId] = useState("");
  const [evalId, setEvalId] = useState("");
  const specialCaseMapping = {
    595: "9520595",
    730: "13140037",
    742: "14098247",
    828: "18216828",
  };
  const [companySummaryData, setCompanySummaryData] = useState(); // Summary data of all valid tests for that
  const [currentReportCode, setCurrentReportCode] = useState(""); // The current report code
  const [currentCompanyTestId, setCurrentCompanyTestId] = useState(""); // The current company test id
  // Code Challenge
  const [testInstructions, setTestInstructions] = useState(
    "Carregando instruções..."
  ); // // testInstructions: test_case_instructions
  const [finalCode, setFinalCode] = useState(""); // finalCode = test_chat_transcript. isCodeSubmission = yes. content
  const [codeLanguage, setCodeLanguage] = useState(""); // codeLanguage = test_chat_transcript. isCodeSubmission = yes. codeLanguage
  const [monacoLanguage, setMonacoLanguage] = useState(""); // codeLanguage = test_chat_transcript. isCodeSubmission = yes. codeLanguage
  const [aceLanguage, setAceLanguage] = useState(""); // codeLanguage = test_chat_transcript. isCodeSubmission = yes. codeLanguage
  const [consoleOutput, setConsoleOutput] = useState(""); // Final output when code is executed
  const [finalSchema, setFinalSchema] = useState(null); // finalSchema of the user
  // Chatbox
  const [messagesGroupedBySection, setMessagesGroupedBySection] = useState([]); // Group them into sections to display in the UI
  const [chatSections, setChatSections] = useState([]);
  // Time
  const [timeTaken, setTimeTaken] = useState("0"); // time_taken
  const [timeConcluded, setTimeConcluded] = useState("0"); // end_time
  const [accessibilityMode, setAccessibilityMode] = useState(false); // accessibility_mode
  // Anti Fraud
  const [screenShareActive, setScreenShareActive] = useState(true); // screen_share_active
  const [cameraInactiveRational, setCameraInactiveRational] = useState(null); // camera_inactive_rational
  const [screenShareInactiveRational, setScreenShareInactiveRational] = useState (null); // screen_share_inactive_rational
  const [integrityRiskData, setIntegrityRiskData] = useState({
    camera: { icon: faCamera, color: "red", status: "inativa" }, // Default values
    screenShare: { icon: faDesktop, color: "red", status: "não compartilhada" }, // Default values
    timeNotActive: { icon: faSignOutAlt, color: "red", time: 0 },
    developerTools: { icon: faCogs, color: "red", status: "Acessadas" },
  });
  const [overallIntegrityRisk, setOverallIntegrityRisk] = useState(0); // 0: low, 1: slight, 2+: high
  // UI management
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  ); // Check if the user in on mobile
  const [isLoadingData, setIsLoadingData] = useState(true); // Show the loading spinner while fetching data
  const [maxSubSkillHeights, setMaxSubSkillHeights] = useState([]); // For defining the height of the subskill name so it is uniform across rows
  const { pdfMode, setPdfMode } = useAppContext(); // Expand all elements to allow for pdf with all the content
  const [initialCodeEditorHeight, setInitialCodeEditorHeight] =
    useState("auto"); // Saves the initial height
  const codeEditorContainerRef = useRef(null);
  const [timeRanOut, setTimeRanOut] = useState(false); // Flag if time ran out to make the timeUp seciton appear
  const editorFontSize = pdfMode ? "12" : "14";
  const [codeEditorHeight, setCodeEditorHeight] = useState(null); // Default to 'auto' or any initial height
  const [isHoveringCamera, setIsHoveringCamera] = useState(false); // Show photo when they hover over camera
  const [isHoveringScreenShare, setIsHoveringScreenShare] = useState(false); // Show screen share when they hover over screen share
  const [isHoveringPdf, setIsHoveringPdf] = useState(false); // Show popout when they hover over pdf
  const [isHoveringAccessibility, setIsHoveringAccessibility] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile); // Open is not mobile, closed if mobile
  const [shiftClass, setShiftClass] = useState(""); // Make space for the sidebar when needed
  const [loadingInitialData, setLoadingInitialData] = useState(true); // Hide report until data is loaded
  const [reportChangeCounter, setReportChangeCounter] = useState(0); // Suports the rerendering of child component so the expanded content collapses
  const [followUpReached, setFollowUpReached] = useState(false); // If not reached then put the communication items in the timeUp section
  const [isSlideShowVisible, setIsSlideShowVisible] = useState(false); // Show the screen share slideshow
  const [isLoadingScreenShare, setIsLoadingScreenShare] = useState(false); // Show the loading spinner while fetching screen shares
  const [loadingScreenShareError, setLoadingScreenShareError] = useState(false); // Show the error message if fetching screen shares fails
  const [candidateReviewMode, setCandidateReviewMode] = useState(false); // Hide scoring to go through the chat transcript with the candidate
  const [candidateReviewIcon, setCandidateReviewIcon] = useState(faEyeSlash); // Icon to show the candidate review mode
  const [isHoveringReview, setIsHoveringReview] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false); // Show the share modal
  const [isHoveringShare, setIsHoveringShare] = useState(false);
  // Directing user
  const navigate = useNavigate(); // Function to navigate to a different page
  const location = useLocation();
  // Feedback
  const { feedbackMap } = useContext(FeedbackContext);
  const [isHoveringFeedback, setIsHoveringFeedback] = useState(null); // Show the feedback icons if they are hovering the feedback section
  const overallFeedbackKey = `${testAttemptId}_overallFeedback_${evalId}`;
  const shouldRenderFeedbackInput =
    feedbackMap[overallFeedbackKey]?.eval || (isHoveringFeedback && !pdfMode); // Show the feedback icons if they are hovering the rationale or they have clicked thumbs up or down
  // Tags
  const [reportCustomTags, setReportCustomTags] = useState({}); // Custom tags for the report
  // Shareable Links
  const [isLoggedIn, setIsLoggedIn] = useState(false); // If the user can make a shareable link
  const [shareableLinkToken, setShareableLinkToken] = useState(""); // The token for the shareable link
  const exampleLinkToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55VGVzdElkIjpudWxsLCJ1cmxDb2RlIjoxODIxNjgyOCwidHlwZSI6IlVOSVFVRSIsInN0YXJ0QXQiOiIyMDI0LTEwLTE3IiwiZW5kQXQiOiIyMDI0LTExLTE0IiwiaWF0IjoxNzMwMzc2MjA1LCJleHAiOjE3NjE5MTIyMDV9.rx5QsVgNo3OdppRoB4QxcLIqbpZLv262mLN0LbQohDI'
  // PDF states
  const [pdfGenerating, setPdfGenerating] = useState(false); // Allows user feedback that pdf is being made
  const [pageBreaks, setPageBreaks] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showErrorPopout, setShowErrorPopout] = useState(false); // if pdf fails
  const pdfError =
    "Desculpe, algo deu errado. Por favor, tente novamente em breve";

  const { companyDetails, setCompanyDetails, setTestStage } = useTestContext(); // Set the company details for the header to get the logo. Set test stage to show error message

  const fileName = "ReportInterface"; // For logging

  // FETCH DATA //

  // On load, check the url params for either a token (shareable link) or url code (logged in). NOTE all logeed in users can view all results within their company
  useEffect(() => {
    setIsLoadingData(true);
    const urlParams = new URLSearchParams(window.location.search);

    const urlCode = urlParams.get("tentativa"); // Logged in user
    const version = urlParams.get("version"); // For degrau team to review multiple versions
    const shareableLinkToken = urlParams.get("token"); // Shareable link

    // If there is no urlCode or shareableLinkToken, then redirect to login
    const handleInvalidLink = () => {
      setIsLoadingData(false);
      logEvent("Invalid report link", {
        fileName: fileName,
        url: window.location.href,
      });
      navigate("/login");
    };

    setIsLoadingData(true);

    if (urlCode) {
      if (urlCode === "828") { // Special case for the example report, as we want this available to all users
        setShareableLinkToken(exampleLinkToken);
        processShareableLink(exampleLinkToken);
        return;
      } else { // Normal case, get the data for the logged in user
        setCurrentReportCode(urlCode);
        processUserRequest(urlCode, version);
      }
    } else if (shareableLinkToken) { // Get the data for the shareable link
      processShareableLink(shareableLinkToken);
    } else { // Handle invalid links
      handleInvalidLink();
    }
  }, []);

  // For logged in users, fetch the specific result as per the url param and then the associated data for that company and role
  const processUserRequest = async (urlCode, version = null) => {

    const response = await checkUserAccess(); // Check if the user is logged in and has the correct permissions
    const isAdmin = response.isAdmin;
    if (!response.accessGranted) return;

    try {
      // Get the specific report data (the back end checks if the user is in the same comapny as the report)
      const candidateReportData = await getActiveReportData(urlCode, null, version); // null for no shareable link token as permission is given for the logged in token

      // Get the company and role of that report to get the relevant summary data
      const resultCompanyTestId = candidateReportData.testAttemptData.company_test_id;
      
      if (isAdmin) {
        gatherCompanyTestSummaryData(resultCompanyTestId);
      } else {
        gatherUserSummaryData(resultCompanyTestId);
      }
      setIsLoggedIn(true);
    } catch (error) {
      // Check if the error is due to permission issues
      if (error.message === "Permission denied") {
        navigate("/login"); // Redirect to login page
      } else {
        // Server or db or connection issues
        setIsLoadingData(false);
        setTestStage("reportLoadIssue"); // Show more generic error message
        logException(`Error: Fetching report for ${urlCode}`, {
          errorMessage: error.message,
          errorStack: error.stack,
          urlCode,
          fileName: fileName,
        });
      }
    }
  };

  // Check if the user is logged in and can read reports, if not direct to login
  const checkUserAccess = async () => {
    const currentUrl = location.pathname + location.search;

    // Check if the token has expired
    if (isTokenExpired()) {
      navigateWithContext(navigate, "/login", currentUrl);
      return { accessGranted: false, isAdmin: false };
    }

    // Check if the user has the correct permissions
    const permissions = getPermissions();
    if (!permissions) {
      navigateWithContext(navigate, "/login", currentUrl);
      return { accessGranted: false, isAdmin: false };
    } else if (!hasPermission(permissions, "READ_REPORTS")) {
      navigateWithContext(navigate, "/login", currentUrl);
      return { accessGranted: false, isAdmin: false };
    }

    // Set the user permissions in context
    setUserPermissions(permissions);

    // Check if the user is an admin
    const roles = getUserRoles();
    let isAdmin = false;
    if (roles) {
      setUserRoles(roles);
      isAdmin = roles.some(role => role.toLowerCase().includes("admin"));
    }

    return { accessGranted: true, isAdmin };
};

  // Check the permissions of the shareable link token and call the relevent function to get the permitted data
  const processShareableLink = async (token) => {
    setShareableLinkToken(token);
    try {
      const permissions = await checkTokenPermissions(token);
      let { companyCode, roleCode, startAt, urlCode } = permissions;
      let companyTestId = permissions.companyTestId;

      // if there is company and role codes from legacy tokens, get the companyTestId
      if (companyCode && roleCode) {
        const testIds = await getTestIdsFromCodes(companyCode, roleCode);
        companyTestId = testIds.company_test_id;
      }

      // if there is a urlCode, get the specific report data
      if (urlCode) {
          await getActiveReportData(urlCode, token);
          setIsSidebarOpen(false);
      // Otherwise if there is a company and role, get the summary data
      } else if (companyTestId) {
          const companySummaryData = await gatherCompanyTestSummaryData(companyTestId, startAt, token);
          // And get the most recent urlCode
          if (companySummaryData && companySummaryData.length > 0) {
              urlCode = companySummaryData.sort(
                  (a, b) => new Date(b.time_concluded) - new Date(a.time_concluded)
              )[0].url_code;
              await getActiveReportData(urlCode, token);
          } else {
              // Handle case where companySummaryData is empty
              setTestStage("shareableLinkNoData");
              setIsLoadingData(false);
              logException(`Error: No companySummaryData`, {
                  companyTestId: companyTestId || "No companyTestId",
                  fileName: fileName,
              });
          }
      } else {
        // Handle case where neither urlCode nor compantTestId are present
        setTestStage("shareableLinkNoData");
        setIsLoadingData(false);
        logEvent("Invalid report link", {
          fileName: fileName,
          url: window.location.href,
        });
      }
    } catch (error) {
      // Handle token errors
      setTestStage("shareableLinkLoadFailed");
      setIsLoadingData(false);
      logException(`Error: Fetching report for token ${token}`, {
        errorMessage: error.message,
        errorStack: error.stack,
        token,
        fileName: fileName,
      });
    }
  };

  // Get the specific report data for the url code
  const getActiveReportData = async (urlCode, shareableLinkToken = null, version = null) => {
    try {
      // Fetch and process all candidate report data
      const candidateReportData = await fetchCandidateReportData(urlCode, shareableLinkToken, version);
  
      // Stop if no data is returned
      if (!candidateReportData) {
        setIsLoadingData(false);
        setTestStage("reportLoadIssue");
        logException(`Error: No candidateReportData for ${urlCode}`, {
          urlCode,
          fileName: fileName,
        });
        return;
      }
  
      // Process the candidate report data
      processCandidateReportData(candidateReportData, companySummaryData);
  
      // Get any custom tags
      getReportCustomTags(urlCode);
      setCurrentReportCode(urlCode);
  
      setIsLoadingData(false);
  
      return candidateReportData;
    } catch (error) {
      // Handle the "Token has expired" error by informing the user with a popup
      if (error.message === "Token has expired") {
        setIsLoadingData(false);
        setTestStage("expiredToken");
        logException("Error: Token has expired", {
          urlCode,
          fileName: fileName,
          errorMessage: error.message,
          errorStack: error.stack,
        });
      } else if (error.message === "Permission denied") {
        // handle when a logged in route gives a permission denied, likely as the user is not logged in
        navigate("/login");
      } else {
        // Handle other errors
        setIsLoadingData(false);
        setTestStage("reportLoadIssue");
        logException("Error fetching candidate report data", {
          urlCode,
          fileName: fileName,
          errorMessage: error.message,
          errorStack: error.stack,
        });
      }
    }
  };  

  // Get the summary results of the permitted company and role
  const gatherCompanyTestSummaryData = async ( companyTestId, startAt = null, shareableLinkToken = null ) => {
    setIsSidebarOpen(false);
    // Fetch the data of the other candidate for that company
      try {
          // Fetch the company summary data. PROTECTED API
          const response = await fetchCompanyTestResults(
              companyTestId,
              startAt,
              shareableLinkToken,
          );
          const companySummaryData = response.data;

          if (companySummaryData && companySummaryData.length > 0) {
              setCompanySummaryData(companySummaryData);
              // if there is more than one record, open the sidebar
              if (companySummaryData.length > 1) {
                setIsSidebarOpen(true);
              }
              return companySummaryData;
          } else {
              // Handle cases where no data is returned
              setCompanySummaryData(null);
              setIsSidebarOpen(false); // Close the sidebar if there is no data to show
              logException(`Error: No companySummaryData`, {
                companyTestId: companyTestId || "No companyTestId",
                fileName: fileName,
              });
              return null;
          }
        } catch (error) {
            // Handle any errors that occurred during the fetch or processing
            setCompanySummaryData(null);
            setIsSidebarOpen(false); // Close the sidebar if there is no data to show
            logException(`Error: Fetching companySummaryData`, {
                errorMessage: error.message,
                errorStack: error.stack,
                companyTestId: companyTestId || "No companyTestId",
                fileName: fileName,
            });
        }
  };

  // Get the summary results of the user for the permitted role
  const gatherUserSummaryData = async (CompanyTestId, startAt = null) => {
    setIsSidebarOpen(false);
    
    try {
        // Fetch the user summary data. PROTECTED API
        const response = await fetchUserResults(CompanyTestId);  
        const userSummaryData = response.data;

        if (userSummaryData && userSummaryData.length > 0) {
          setCompanySummaryData(userSummaryData);  // Assuming you have a state variable for user summary data
          // if there is more than one record, open the sidebar
          if (userSummaryData.length > 1) {
            setIsSidebarOpen(true);
          }
          return userSummaryData;
        } else {
          // Handle cases where no data is returned
          setCompanySummaryData(null);
          setIsSidebarOpen(false); // Close the sidebar if there is no data to show
          logException(`Error: No userSummaryData`, {
            CompanyTestId,
            fileName: fileName,
          });
          return null;
        }
    } catch (error) {
      // Handle any errors that occurred during the fetch or processing
      setCompanySummaryData(null);
      setIsSidebarOpen(false); // Close the sidebar if there is no data to show
      logException(`Error: Fetching userSummaryData`, {
          errorMessage: error.message,
          errorStack: error.stack,
          CompanyTestId,
          fileName: fileName,
      });
    }
};

  // Process the specific candidate report data so it can show in the UI
  const processCandidateReportData = ( candidateReportData, companySummaryData ) => {
      // Process the test attempt data
      const testAttemptData = candidateReportData.testAttemptData;
      processCandidateAttemptData(testAttemptData); // Fetch the data for the testAttemptId

      // Process the candidateDetails
      const candidateDetails = candidateReportData.candidateDetails;
      processCandateDetails(candidateDetails);

      // Set the candidate photo
      const candidatePhotoUrl = candidateReportData.candidatePhotoUrl;
      processCandidatePhoto(candidatePhotoUrl, testAttemptData);

      // Process the evaluationOutput
      if (candidateReportData.evaluationOutput.final_output) {
          processScoringData(
              candidateReportData.evaluationOutput.final_output,
              candidateReportData.evaluationOutput.total_score,
              companySummaryData
          );
      } else {
          logException(`No scoring data for ${testAttemptData} in report`, {
              testAttemptId: testAttemptData,
              fileName: fileName,
          });
          setTestStage("reportLoadIssue");
      }
      setEvalId(candidateReportData.evaluationOutput.eval_id);

      // Process the codeResponseData if it exists
      if (candidateReportData.codeResponseData) {
          processCodeDetails(candidateReportData.codeResponseData, testAttemptData);
      }

      // Set the company and role details
      setCompanyAndRole(testAttemptData);
  };

  const getReportCustomTags = async (urlCode) => {
    // Fetch the custom tags for the report
    try {
      const customTags = await getTagsForResult(urlCode);
      setReportCustomTags(customTags);
    } catch (error) {
      logException(`Error: Fetching custom tags for report`, {
        errorMessage: error.message,
        errorStack: error.stack,
        urlCode,
        fileName: fileName,
      });
    }
  };

   // When the user clicks on a row in the sidebar, fetch the data for that report
   const handleRowClick = async (urlCode, approved, version) => {
    setIsLoadingData(true);
    // clear data
    setOverallScore("");
    setRank("");
    setDetailedSkillsData([]);
    setSubSkillSections([]);
    setOverallFeedback("");
    setUsername("");
    setCandidateEmail("");
    setUserInitialPhoto("");
    setUserScreenShares("");
    setTestAttemptId("");
    setEvalId("");
    setCameraInactiveRational(null);
    setScreenShareInactiveRational(null);
    setScreenShareActive(true);
    setIsSlideShowVisible(false);

    // Update the URL with the new urlCode
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tentativa', urlCode);
    // Only add version parameter if not approved AND version exists
    if (!approved && version) {
      searchParams.set('version', version);
    } else {
      // Remove version parameter if it exists
      searchParams.delete('version');
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    
    // if there is a shareableLinkToken, use it to get the data
    if (shareableLinkToken) {
      getActiveReportData(urlCode, shareableLinkToken);
    } else {
      getActiveReportData(urlCode)
    }
  };

  // PROCESS DATA //

  // Process the testAttemptdata to show the transcript (chat, code, instructions, anti-fraud) in the report
  const processCandidateAttemptData = async (testAttemptData) => {
    // If there is a testAttemptId then try to fetch the associated data in the db
    if (testAttemptData) {
      // Set the test attempt id
      setTestAttemptId(testAttemptData.test_attempt_id);

      setCodeAndMessage(testAttemptData); // Extract the code and messages array
      setTimeFacts(testAttemptData); // Set the time facts
      processTestCaseInstructions(testAttemptData); // set the test instructions
      setIntegrityFacts(testAttemptData); // Anti-Fraud
      setAccessibilityMode(testAttemptData.accessibility_mode);

      // if screen_share_active = 0, save the screen_share_inactive_rational
      if (testAttemptData.screen_share_active === 0) {
        setScreenShareActive(false);
        setScreenShareInactiveRational(testAttemptData.screen_share_inactive_rational);
      }

    } else {
      setTestStage("reportLoadIssue");
      logException(
        `Error: no testAttemptData for candidate report: ${testAttemptId}`,
        {
          testAttemptId,
          status: "Failed",
          type: "CandidateReport",
          fileName: fileName,
        }
      );
    }
  };

  // Process and set the scoring data
  const processScoringData = async (scoringData, totalScore, companySummaryData) => {
    setLoadingInitialData(true);

    if (scoringData && scoringData.Skills) {
      // Set the detailed skills data that will be shown in the report
      const detailedSkills = scoringData.Skills.map((skill) => ({
        name: skill.SkillName,
        score: parseFloat(skill.SkillScore).toFixed(4),
        weight: parseFloat(skill.SkillWeight).toFixed(4),
        percent: parseFloat(skill.SkillPercentageScore).toFixed(4),
        subSkills: skill.SubSkills.map((subSkill) => ({
          name: subSkill.Name,
          definition: subSkill.Definition,
          score: subSkill.Score,
          maxScore: subSkill.MaxScore,
          percentageScore: subSkill.PercentageScore,
          weight: subSkill.Weight,
          rationale: subSkill.Rationale,
          scoreDescription: subSkill.ScoreDescription,
          section: subSkill.ReportSection,
        })),
      }));
      setDetailedSkillsData(detailedSkills);

      const uniqueSections = [
        ...new Set(
          detailedSkills.flatMap((skill) =>
            skill.subSkills.map((subSkill) => subSkill.section)
          )
        ),
      ];
      setSubSkillSections(uniqueSections);

      const fetchedOverallScore = parseFloat(totalScore);
      const roundedOverallScore = fetchedOverallScore.toFixed(0);
      setOverallScore(roundedOverallScore);
      if (companySummaryData) {
        calculateRank(fetchedOverallScore, companySummaryData);
      }

      // Need to adjust after we transition to the new scoring output just using 'Feedback'
      let feedback = "";

      if (scoringData.GeneralFeedback) {
        // Check for feedback in the resumo key
        if (
          scoringData.GeneralFeedback.FeedbackGeral &&
          scoringData.GeneralFeedback.FeedbackGeral.resumo
        ) {
          feedback = scoringData.GeneralFeedback.FeedbackGeral.resumo;
        }

        // If not found in resumo, check for feedback in Feedback key
        if (!feedback && scoringData.GeneralFeedback.Feedback) {
          feedback = scoringData.GeneralFeedback.Feedback;
        }
      }

      setOverallFeedback(feedback);
    }
  };

  // Fetch the details of the company and role and set as states
  const setCompanyAndRole = async (testAttemptData) => {

    // get the companyId from the companyTestId are fall back on companyCode and roleCode for legacy reports
    let companyTestId = testAttemptData.company_test_id;

    if (!companyTestId) {
      const roleCode = testAttemptData.role_code;
      const companyCode = testAttemptData.company_code;
      if (companyCode && roleCode) {
        const testIds = await getTestIdsFromCodes(companyCode, roleCode);
        companyTestId = testIds.company_test_id;
      }
    }
    setCurrentCompanyTestId(companyTestId); // used for shareable link with range
    // Get the companyId from the companyTestId

    try {
      const companyId = await getCompanyIdFromCompanyTestId(companyTestId);

      // company details is empty, fetch and set the company details
      if (!companyDetails || companyDetails.company_id !== companyId) {
        const company = await fetchCompanyDetailsById(companyId);
        setCompanyDetails(company[0]);
      }
    } catch (error) {
      logException(`Error: Fetching companyId from companyTestId`, {
        errorMessage: error.message,
        errorStack: error.stack,
        companyTestId,
        fileName: fileName,
      });
    }

  };

  // CANDIDATE DETAILS //

  // Set the username and email
  const processCandateDetails = async (candidateDetails) => {
    const username = candidateDetails.username;
    const email = candidateDetails.email;
    setUsername(username);
    setCandidateEmail(email);
  };

  // Set the photo or the rational for the camera being inactive
  const processCandidatePhoto = async (candidatePhotoUrl, testAttemptData) => {
    if (candidatePhotoUrl) {
      setUserInitialPhoto(candidatePhotoUrl);
    } else {
      setCameraInactiveRational(testAttemptData.camera_inactive_rational);
    }
  };

  // Get the candidate photo by the blob name
  const getCandidateScreenShare = async (testAttemptId) => {
    logTrace(`getCandidateScreenShare triggered for ${testAttemptId}`, {
      testAttemptId,
      fileName: fileName,
    });
    setIsLoadingScreenShare(true);
    setLoadingScreenShareError(false);

    try {
      let screenShareStream = null;
      // if testAttempt 828 (the example report) sitch to 2869
      if (testAttemptId === 828) {
        screenShareStream = await fetchScreenShares('2871');
      } else {
        screenShareStream = await fetchScreenShares(testAttemptId); // (leads to API call)
      }
      logTrace(`getCandidateScreenShare fetched for ${testAttemptId}`, {
        testAttemptId,
        fileName: fileName,
      });
      setUserScreenShares(screenShareStream);
      setIsLoadingScreenShare(false);
    } catch (error) {
      logException(`Error: Fetching photo for candidate`, {
        errorMessage: error.message,
        errorStack: error.stack,
        testAttemptId,
        status: "Failed",
        type: "CandidateReport",
        fileName: "getCandidateScreenShare",
      });
      setIsLoadingScreenShare(false);
      setLoadingScreenShareError(true);
    }
  };

  // TEST TRANSCRIPT //

  // Get the code and messages array from the transcript
  async function setCodeAndMessage(testData) {
    let chatMessages = testData.test_chat_transcript; // The entire chat transcript
    // filter to exclude messages where isVisible is false or the content is empty or it is a code submission or instructions message
    let filteredMessagesArray = chatMessages.filter(
      (msg) =>
        !msg.isCodeSubmission &&
        msg.section !== "instructions" &&
        msg.content.trim() !== "" &&
        msg.isVisible !== false // Exclude messages with isVisible set to false
    );
    groupBySection(filteredMessagesArray); // Group the filtered messages by section

    // Code
    const codeSubmissionMessage = chatMessages.find(
      (msg) => msg.isCodeSubmission === true
    );
    if (codeSubmissionMessage) {
      setAceLanguage("plain_text"); // First set to plaintext to ensure re redner when switching between candidates
      const codeLanguageKey = codeSubmissionMessage.codeLanguage;

      // Now try accessing it dynamically
      const dynamicAceLanguage = languageMappings[codeLanguageKey]?.ace;
      const dynamicMonacoLanguage = languageMappings[codeLanguageKey]?.monaco;

      // Delay for ace scripts to load
      setTimeout(() => {
        setFinalCode(codeSubmissionMessage.content);
        setCodeLanguage(codeSubmissionMessage.codeLanguage);
        setMonacoLanguage(dynamicMonacoLanguage);
        setAceLanguage(dynamicAceLanguage);
        setLoadingInitialData(false);
      }, 1000);
    }
  }

  // Fetch the code outputs and final schema
  const processCodeDetails = async (codeResponseData, testAttemptData) => {
    if (codeResponseData && codeResponseData.length > 0) {
      const codeResponse = codeResponseData[0];

      // Set console output if available
      if (codeResponse.console_output) {
        setConsoleOutput(codeResponse.console_output);
      } else {
        logTrace(`No console output for ${testAttemptData} in report`, {
          testAttemptId: testAttemptData,
          fileName: fileName,
        });
        setConsoleOutput(null);
      }

      // Set final schema if available
      if (codeResponse.db_schema && codeResponse.db_schema.length > 0) {
        setFinalSchema(codeResponse.db_schema);
      } else {
        logTrace(`No final schema for ${testAttemptData} in report`, {
          testAttemptId: testAttemptData,
          fileName: fileName,
        });
        setFinalSchema(null);
      }
    } else {
      setConsoleOutput(null);
      setFinalSchema(null);
    }
  };

  // CODE //

  // CHAT MESSAGES //

  // Group the messages by section to layout beside their SubSkillDetail
  const groupBySection = (messages) => {
    const grouped = messages.reduce((acc, message) => {
      // Using 'other' as a fallback section might not be necessary if all messages have a defined section.
      const section = message.section || "other";
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(message);
      return acc;
    }, {});

    // Find the last non-timeUp section key
    const sections = Object.keys(grouped);
    setChatSections(sections); // Store outside so we can compare to subSkillSections to identify any missing
    const lastNonSpecialSectionKey = sections
      .reverse()
      .find((key) => key !== "timeUp" && key !== "closing");

    // If there are timeUp messages, add them to the last non-timeUp section's array
    if (grouped["timeUp"] && lastNonSpecialSectionKey) {
      grouped[lastNonSpecialSectionKey] = [
        ...grouped[lastNonSpecialSectionKey],
        ...grouped["timeUp"],
      ];

      // Remove the 'timeUp' section from 'grouped' after adding its messages to the last section
      delete grouped["timeUp"];
    }

    // If there are closing messages, add them to the last non-timeUp section's array
    if (grouped["closing"] && lastNonSpecialSectionKey) {
      grouped[lastNonSpecialSectionKey] = [
        ...grouped[lastNonSpecialSectionKey],
        ...grouped["closing"],
      ];

      // Remove the 'closing' section from 'grouped' after adding its messages to the last section
      delete grouped["closing"];
    }
    setMessagesGroupedBySection(grouped); // Save the output
  };

  // To group the messages by section to
  const lastSectionKey = Object.keys(messagesGroupedBySection).pop();

  // Map the section names to what the report should show. TODO: Get this from the section configs or add to chat messages
  const sectionNameMappings = {
    code: "code",
    codingChallenge: "Interações durante o desafio",
    extraTime: "Sugestões de melhoria",
    decisionMaking: "Discussão sobre decisão de programação",
    scaling: "Discussão sobre escalabilidade",
  };

  // TEST INTRUCTIONS //

  // Function to handle test_case_instructions
  const processTestCaseInstructions = (testAttemptData) => {
    let parsedInstructions;

    // Check if test_case_instructions is a valid JSON string
    try {
        parsedInstructions = JSON.parse(testAttemptData.test_case_instructions);
    } catch (error) {
        parsedInstructions = null;
    }

    // Access the instructions based on the format
    let testInstructions;
    if (parsedInstructions) {
        if (parsedInstructions.codingChallenge) {
            testInstructions = parsedInstructions.codingChallenge.instructions;
        } else {
            testInstructions =
                parsedInstructions.instructions || "Instructions not found";
        }
    } else {
        testInstructions =
            testAttemptData.test_case_instructions || "Instructions not found";
    }

    // Check if the instructions are HTML or already Markdown
    let finalInstructions;
    if (isMarkdown(testInstructions)) {
      finalInstructions = testInstructions;
    } else {
      // Convert HTML to Markdown
      finalInstructions = convertOldInstructionsToMarkdown(testInstructions);
    }

    setTestInstructions(finalInstructions);
  };

  // Function to check if the content is likely Markdown
  const isMarkdown = (content) => {
    const markdownPatterns = [
        /^\s{0,3}#{1,6}\s/m, // Headers
        /\*\*(.*?)\*\*/g,     // Bold
        /\*(.*?)\*/g,         // Italic
        /^\s*>\s+/m,          // Blockquotes
        /^\s*[-+*]\s+/m,      // Unordered lists
        /^\s*\d+\.\s+/m       // Ordered lists
    ];

    return markdownPatterns.some((pattern) => pattern.test(content));
  };

  // Set it as a system message
  const systemInstructionMessage = {
    type: "system",
    content: testInstructions,
    isCodeSubmission: false,
    isFirstMessage: false,
    isInstructions: true,
  };

  // TEST FACTS //

  // Calculate the rank of the candidate based on the overall score
  const calculateRank = (fetchedOverallScore, companySummaryData) => {
    // Ensure the scores are numbers and rounded to two decimal places for comparison
    const sortedScores = companySummaryData
      .map((data) => parseFloat(data.total_score).toFixed(2))
      .sort((a, b) => parseFloat(b) - parseFloat(a));

    // Ensure fetchedOverallScore is also converted to two decimal places
    const formattedOverallScore = parseFloat(fetchedOverallScore).toFixed(2);

    // Determine the rank position based on the formatted fetchedOverallScore
    let rankPosition = 1; // Start rank at 1
    for (const score of sortedScores) {
      if (parseFloat(score) > parseFloat(formattedOverallScore)) {
        rankPosition++; // Increase rank if the current score is higher
      } else {
        break; // Stop once we reach the same or lower score
      }
    }
    const totalEntries = sortedScores.length; // Total number of scores

    // Format rank as "rank / totalEntries"
    const formattedRank = `${rankPosition}º / ${totalEntries}`;

    setRank(formattedRank);
  };

  // Set the time taken, date concluded and the time away from the screen
  const setTimeFacts = (testData) => {
    // Time taken
    const timeTakenRounded = Math.round(testData.time_taken);
    setTimeTaken(timeTakenRounded);

    // Time of completion
    const concludedDate = new Date(testData.end_time);
    const formattedDate = concludedDate
      .toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
      .split(" de ")
      .join(" ");

    setTimeConcluded(formattedDate);

    // Temp flag for timeRanOut as first test didnt have timeUp message
    if (testData.status === "timeUp") {
      setTimeRanOut(true);
    } else {
      setTimeRanOut(false);
    }
  };

  // Colour time taken based on how long they took
  const getTimeTakenStyle = (timeTaken) => {
    let color = "#000"; // Default black
    let text = `${timeTaken}mins`;

    if (timeRanOut) {
      color = "#ff0000"; // Red if time ran out
      text += " (Não terminou)";
    }
    return { color, text };
  };

  // Colour and text of timeTaken to be used in the report
  const { color, text } = getTimeTakenStyle(timeTaken);

  // ANTI-FRAUD //

  // Set the facts as states
  const setIntegrityFacts = (testData) => {
    // Evaluate the risk of the anti-fraud data
    const evaluatedRisk = evaluateIntegrityRisk(testData);
    setIntegrityRiskData(evaluatedRisk);

    // Calculate overall risk
    const validRisks = [
      evaluatedRisk.camera,
      evaluatedRisk.screenShare,
      evaluatedRisk.timeNotActive,
      evaluatedRisk.developerTools,
    ].filter((risk) => risk !== null);
    const riskScore =
      validRisks.reduce(
        (acc, risk) => acc + (risk.color === "red" ? 1 : 0),
        0
      ) / validRisks.length;
    setOverallIntegrityRisk(riskScore);
  };

  // Flag if anti-fraud measures are red or green and calcualte the overall risk
  const evaluateIntegrityRisk = (testData) => {
    // Direct assignments from testData
    const cameraActive = testData.camera_active;
    const f12Count = testData.f12_count;
    const screenShareActive = testData.screen_share_active;
    const timeNotActive = testData.time_not_active
      ? Math.round(testData.time_not_active * 100) / 100
      : 0;

    // Determine time display and color
    let timeNotActiveDisplay = "0 mins";
    let timeNotActiveColor = "green";

    if (timeNotActive > 1.5) {
      timeNotActiveDisplay = `${Math.round(timeNotActive)} mins`;
      timeNotActiveColor = "red";
    } else if (timeNotActive > 1 && timeNotActive <= 1.5) {
      timeNotActiveDisplay = "1 min";
      timeNotActiveColor = "red";
    } else if (timeNotActive > 0) {
      timeNotActiveDisplay = "< 1 min";
      timeNotActiveColor = "green";
    }

    // Evaluate each metric for risk and the UI rules
    const cameraRisk =
      cameraActive !== null
        ? cameraActive
          ? { icon: faCamera, color: "green", status: "ativa" }
          : { icon: faCamera, color: "red", status: "Inativa" }
        : null;
    const screenShareRisk =
      screenShareActive !== null
        ? screenShareActive
          ? { icon: faDesktop, color: "green", status: "compartilhada" }
          : { icon: faDesktop, color: "red", status: "não compartilhada" }
        : null;
    const timeNotActiveRisk =
      timeNotActive !== null
        ? {
            icon: faSignOutAlt,
            color: timeNotActiveColor,
            time: timeNotActiveDisplay,
          }
        : null;
    const developerToolsRisk =
      f12Count !== null
        ? f12Count === 0
          ? { icon: faCogs, color: "green", status: "não acessadas" }
          : { icon: faCogs, color: "red", status: "acessadas" }
        : null;

    return {
      camera: cameraRisk,
      screenShare: screenShareRisk,
      timeNotActive: timeNotActiveRisk,
      developerTools: developerToolsRisk,
    };
  };

  // Calculate and format the overall risk
  const getRiskLevel = (score) => {
    if (score === 0) {
      return { label: "Baixo risco", color: styles.greenRisk };
    } else if (score >= 0.75) {
      return { label: "Alto risco", color: styles.redRisk };
    } else {
      return { label: "Risco potencial", color: styles.amberRisk };
    }
  };

  // SCORING SECTIONS //

  // Get the sections in the scoring output
  const filterSkillsBySection = (skills, section) => {
    return skills
      .filter((skill) =>
        skill.subSkills.some((subSkill) => subSkill.section === section)
      )
      .map((skill) => ({
        ...skill,
        subSkills: skill.subSkills.filter(
          (subSkill) => subSkill.section === section
        ),
      }));
  };

  // Legacy Function used to identify any scoring sections that dont exist in the chat messages, as the candidate didnt finish the test. These sections will show in the the outOfTImeSection
  const identifyMissingSubSkillSections = () => {
    const sectionsToIgnore = ["code", "communication"];
    const filteredSubSkillSections = subSkillSections.filter(
      (section) => !sectionsToIgnore.includes(section)
    );

    const missingSections = filteredSubSkillSections.filter(
      (section) => !chatSections.includes(section)
    );

    // Call the grouping function with missing sections
    groupSubSkillsBySkillName(missingSections);
  };

  // Legacy Function used when the user has not finished the test, now not needed since timer logic was updated
  const checkIfFollowUpReached = () => {
    const sectionsToIgnore = ["codingChallenge", "timeUp", "instructions"]; // Do not count the initial sections and time up
    const followUpChatSections = chatSections.filter(
      (section) => !sectionsToIgnore.includes(section)
    ); // check if there are any more chat sections present
    setFollowUpReached(followUpChatSections.length > 0); // Set setFollowUpReached based on whether followUpChatSections has one or more items
  };

  // Identify any missing subskill scores that havent appears in the messages (as the user didnt finish)
  useEffect(() => {
    identifyMissingSubSkillSections();
    checkIfFollowUpReached();
  }, [subSkillSections, chatSections]); // Rerun when these states change

  // Regroup the missing SubSkills by SkillName so they appear in the UI in missingScoring in one box (if the same skill)
  const groupSubSkillsBySkillName = (missingSections) => {
    const filteredSubSkills = detailedSkillsData.flatMap((skill) =>
      skill.subSkills
        .filter((subSkill) => missingSections.includes(subSkill.section))
        .map((subSkill) => ({
          ...subSkill,
          parentSkillName: skill.name,
          parentSkillScore: skill.score,
        }))
    );

    const groupedBySkillName = filteredSubSkills.reduce((acc, subSkill) => {
      if (!acc[subSkill.parentSkillName]) {
        acc[subSkill.parentSkillName] = {
          name: subSkill.parentSkillName,
          score: subSkill.parentSkillScore,
          subSkills: [],
        };
      }
      acc[subSkill.parentSkillName].subSkills.push(subSkill);
      return acc;
    }, {});

    const groupedData = Object.values(groupedBySkillName);
    // Assuming you have a state or a way to store this grouped data for rendering
    setGroupedMissingSubSkills(groupedData);
  };

  // SHARE RESULTS //

  // PDF //

  // Turn on pdfMode if the unique has the trigger. this is used in the backed by puppeteer to render the page in pdfMode
  useEffect(() => {
    if (setPdfMode) {
      // Parse the URL search parameters
      const queryParams = new URLSearchParams(window.location.search);
      const isPdfMode = queryParams.get("pdfMode") === "true";
      setPdfMode(isPdfMode);
    }
  }, [setPdfMode]);

  // Create pdf of report
  const pdfGeneration = async () => {
    setShowErrorPopout(false);
    setIsHoveringPdf(false); // Hide the hover message
    setPdfGenerating(true); // Indicates the PDF generation process has started
    // Build the dynamic path
    const path = window.location.pathname + window.location.search;
    const name = username;

    try {
      // Log the request with dynamic path
      logTrace("Sending PDF generation request", { path });

      const response = await fetch(
        `/api/generate-pdf?path=${encodeURIComponent(path)}`
      );
      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        // Use `username` in the filename
        link.setAttribute("download", `Degrau_Relatorio_${name}.pdf`); // Define a filename for the downloaded PDF
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // Log successful PDF generation
        logTrace("PDF generated successfully", {
          path,
          status: response.status,
        });
      } else {
        throw new Error(`Failed to generate PDF: ${response.statusText}`);
      }
    } catch (error) {
      // Log error
      logTrace("Error during PDF generation", {
        path,
        errorMessage: error.message,
      });
      // Show the popout
      setShowErrorPopout(true);
      setTimeout(() => setShowErrorPopout(false), 8000); // Auto-hide after X seconds
    } finally {
      setPdfGenerating(false);
    }
  };

  // Measure section heights
  const captureSectionDetails = () => {
    // Select all elements with id starting with 'pdfSection'
    const sections = document.querySelectorAll('[id^="pdfSection"]');
    const sectionDetails = Array.from(sections).map((section, index) => {
      // Use the actual rendered height of each section
      const height = `${section.clientHeight}px`;

      // Return the section name, calculated height, and its order
      return {
        name: section.id,
        height: height,
        order: index + 1,
      };
    });

    identifyPdfBreaks(sectionDetails);
  };

  // Call for measurements to begin
  useEffect(() => {
    if (codeEditorHeight && pdfMode && isInitialRender) {
      setIsInitialRender(false);
      // Set a timeout to call captureSectionDetails after 2 seconds
      const timer = setTimeout(() => {
        captureSectionDetails();
      }, 600); // 1000 milliseconds = 2 seconds

      // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
      return () => clearTimeout(timer);
    }
  }, [codeEditorHeight, pdfMode]); // Empty dependency array means this effect runs once on mount

  // Identify where to put page breaks depending on the heights of the sections
  function identifyPdfBreaks(sectionDetails, pageHeight = 1300) {
    if (!Array.isArray(sectionDetails)) {
      logException(`sectionDetails is not an array in pdf generation:`, {
        sectionDetails,
        status: "Failed",
        type: "Pdf",
        fileName: fileName,
      });
      return []; // Return an empty array or handle this case appropriately
    }

    let pageHeightRemaining = pageHeight;
    let currentPage = 0;
    let remainingHeightOnNextPage = pageHeight;

    sectionDetails.forEach((section, i) => {
      let sectionHeight = parseInt(section.height.replace("px", ""), 10);

      if (sectionHeight > pageHeightRemaining) {
        // Check if section fits within the sum of the remaining height on the current page and the full height of the next page
        if (
          sectionHeight > pageHeight &&
          sectionHeight < pageHeight + pageHeightRemaining
        ) {
          // If more than 400px left on the previous page, and section can fit within two pages, add to previous page
          // Calculate remaining height on next page considering this section
          remainingHeightOnNextPage =
            pageHeight + pageHeightRemaining - sectionHeight;
          pageHeightRemaining = 0; // Current page is considered fully utilized
        } else {
          // If the section doesn't fit in the remaining page height, mark previous section for a page break
          if (
            i > 0 &&
            pageBreaks[pageBreaks.length - 1] !== sectionDetails[i - 1].name
          ) {
            // Ensure there is a previous section and avoid duplicate page breaks
            pageBreaks.push(sectionDetails[i - 1].name);
          }
          // Start a new page for the current section
          pageHeightRemaining = pageHeight - sectionHeight;
          currentPage += 1;
          remainingHeightOnNextPage = pageHeight - sectionHeight;
        }
      } else {
        // Subtract the current section height from the remaining page height
        pageHeightRemaining -= sectionHeight;
      }

      // Adjust for the next section to consider remaining height on the next page if any
      if (pageHeightRemaining <= 0 && currentPage > 0) {
        pageHeightRemaining = remainingHeightOnNextPage;
        remainingHeightOnNextPage = pageHeight;
      }
    });
    console.log("pageBreaks", pageBreaks);
    setPageBreaks(pageBreaks);
  }

  useEffect(() => {
    // This effect does nothing more than trigger a re-render when `pageBreaks` changes to enable the pdf layout
  }, [pageBreaks]);

  // UI MANIPULATIONS //

  // Show slide show of screen shares
  const playSlideShow = () => {
    // if screenShareActive is false, return
    if (!screenShareActive) {
      return;
    }
    // If it is not loading and there is not content call getCandidateScreenShare
    if (!isLoadingScreenShare && userScreenShares.length === 0) {
      getCandidateScreenShare(testAttemptId);
    }
    setIsSlideShowVisible(true);
  };

  // Update ismobile is screen size changes to adjust the layout
  useEffect(() => {
    // Use addEventListener to listen for changes
    window.addEventListener("resize", handleResize);
    // Cleanup function to remove the event listener on component unmount
    return () => window.addEventListener("resize", handleResize);
  }, []);

  // When there is a resize, update the isMobile state
  const handleResize = () => {
    setIsMobile(window.matchMedia("(max-width: 768px)").matches);
  };

  // Define the heights of the subskill names based on the heightest per row
  useEffect(() => {
    if (detailedSkillsData && detailedSkillsData.length > 0) {
      const subSkillLengths = detailedSkillsData.map(
        (skill) => skill.subSkills.length
      );
      if (subSkillLengths.length > 0) {
        const maxSubSkills = Math.max(...subSkillLengths);
        setMaxSubSkillHeights(Array(maxSubSkills).fill(0));
      }
    }
  }, [detailedSkillsData]);

  // Set the subskills across the same row as the same height
  const updateMaxHeight = useCallback((position, height) => {
    if (pdfMode) {
      return;
    }
    const minHeight = pdfMode ? 20 : 30; // 20px in PDF mode, otherwise 34px
    setMaxSubSkillHeights((currentHeights) => {
      const newHeights = [...currentHeights];
      const currentHeight = newHeights[position] || 0;
      if (height > currentHeight) {
        newHeights[position] = Math.max(height, minHeight);
      } else if (currentHeight < minHeight) {
        newHeights[position] = minHeight;
      }
      return newHeights;
    });
  }, []);

  // Measure and store the initial height of the editor
  useEffect(() => {
    // Delay the measurement to allow for content to load or dynamic adjustments
    const timer = setTimeout(() => {
      if (codeEditorContainerRef.current) {
        const height = codeEditorContainerRef.current.offsetHeight + "px";
        setInitialCodeEditorHeight(height);
      }
    }, 500); // Adjust the timeout duration as needed

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  // Apply a max height
  useEffect(() => {
    if (codeEditorContainerRef.current) {
      codeEditorContainerRef.current.style.maxHeight = initialCodeEditorHeight;
    }
  }, [initialCodeEditorHeight]);

  // Show the sidebar and move the report to make space
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Add margin by changing class to make space for the sidebar when not in mobile
  useEffect(() => {
    let timeoutId;

    if (isMobile) {
      setShiftClass("");
      return;
    }

    if (isSidebarOpen) {
      // Set the class with a delay to ensure the browser recognizes it as a change
      timeoutId = setTimeout(() => {
        setShiftClass(styles.reportInterfaceShift);
      }, 130); // Delay in milliseconds
    } else {
      timeoutId = setTimeout(() => {
        setShiftClass("");
      }, 130); // Delay in milliseconds
    }

    // Clean up the timeout if the component unmounts or the effect runs again
    return () => clearTimeout(timeoutId);
  }, [isSidebarOpen, styles.reportInterfaceShift]);

  // SIDE BUTTON CLICKS //

  // Go to candidate review mode
  const handleCandidateClick = () => {
    // if isSidebarOpen is true, close the sidebar if we are entering review mode
    if (isSidebarOpen && !candidateReviewMode) {
      setIsSidebarOpen(false);
    }

    // Change the icon based on the mode
    const icon = candidateReviewMode ? faEyeSlash : faEye; 
    setCandidateReviewIcon(icon);

    setIsHoveringReview(false); // Hide the hover message
    setCandidateReviewMode(!candidateReviewMode);

  };

  // Show (or hide) share link modal
  const handleShareLinkClick = () => {
    setIsShareModalVisible((prev) => !prev);
    setIsHoveringShare(false); // Hide the hover
  };

  // HOVER EVENTS //

  // Show photo when camera icon is hovered
  const handleCameraMouseEnter = () => {
    setIsHoveringCamera(true);
  };

  // Stopping showing when they stop hovering
  const handleCameraMouseLeave = () => {
    setIsHoveringCamera(false);
  };

  const handleScreenShareMouseEnter = () => {
    setIsHoveringScreenShare(true);
  };

  const handleScreenShareMouseLeave = () => {
      setIsHoveringScreenShare(false);
  }

  // Show pdf popup when icon is hovered
  const handlePdfMouseEnter = () => {
    setIsHoveringPdf(true);
  };

  // Stopping showing when they stop hovering
  const handlePdfMouseLeave = () => setIsHoveringPdf(false);

  // Show review popup when icon is hovered
  const handleReviewMouseEnter = () => {
    setIsHoveringReview(true);
  };

  // Stopping showing when they stop hovering
  const handleReviewMouseLeave = () => setIsHoveringReview(false);

  // Show share popup when icon is hovered
  const handleShareMouseEnter = () => {
    setIsHoveringShare(true);
  };

  // Stopping showing when they stop hovering
  const handleShareMouseLeave = () => setIsHoveringShare(false);

  // Accessibility mode
  const handleAccessibilityMouseEnter = () => {
    setIsHoveringAccessibility(true);
  };
  
  const handleAccessibilityMouseLeave = () => {
    setIsHoveringAccessibility(false);
  };

  return (
    <div className={styles.reportInterfaceOuterContainer}>
      {/* Popout for slide show of candidate screen */}
      {isSlideShowVisible && (
        <div className={styles.candidatePhotoStream}>
          <CandidatePhotoStream
            isLoading={isLoadingScreenShare}
            error={loadingScreenShareError}
            photoStream={userScreenShares}
            isSlideShowVisible={isSlideShowVisible}
            setIsSlideShowVisible={setIsSlideShowVisible}
          />
        </div>
      )}

      {/* Popout for configurating shareable link */}
      {isShareModalVisible && (
        <div className={styles.shareLinkModal}>
            <ShareLinkModal
                currentReportCode={currentReportCode}
                currentCandidateName={username}
                currentCompanyTestId={currentCompanyTestId}
                setIsShareModalVisible={setIsShareModalVisible}
            />
        </div>
      )}

      {/* Side bar of all available results */}
      {companySummaryData && !pdfMode && !candidateReviewMode && (
        <ReportSidebar
          companySummaryData={companySummaryData}
          isOpen={isSidebarOpen}
          onToggle={toggleSidebar}
          onRowClick={handleRowClick}
          activeTestattempt={testAttemptId}
        />
      )}

      {/* Main report */}
      {isLoadingData ? (
        <div className={styles.loadingSpinner}>
          <FadeLoader size={10} color={"#246E24"} />
          Carregando dados...
        </div>
      ) : (
        <div
          className={`${styles.reportInterface} ${shiftClass} ${
            pdfMode ? styles.reportInterfacePdfMode : ""
          }`}
        >
          {!pdfMode && loadingInitialData && (
            <div className={styles.reportInterfaceCover}></div>
          )}

          {/* Buttons on right hand side for sharing links, downloading pdf, entering review  or adding tags */}
          {!pdfMode && (
            <div className={styles.sideButtonsContainer}>

              {/*Generate PDF - hiding for now until fixed due to auth
              {isLoggedIn && (
                <> 
                {pdfGenerating ? (
                  <div className={`${styles.pdfButton} ${styles.sideButton}`}>
                    <FadeLoader
                      color="#999"
                      height={8}
                      width={3}
                      radius={2}
                      margin={-6}
                      loading={pdfGenerating}
                    />
                  </div>
                ) : (
                  <div
                    className={`${styles.pdfButton} ${styles.sideButton}`}
                    onClick={pdfGeneration}
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      onMouseEnter={handlePdfMouseEnter}
                      onMouseLeave={handlePdfMouseLeave}
                    />
                    {isHoveringPdf && !pdfGenerating && (
                      <div
                        className={`${styles.pdfButtonHoverMessage} ${styles.sideButtonHoverMessage}`}
                      >
                        Baixar como pdf
                      </div>
                    )}
                  </div>
                )}
                {showErrorPopout && (
                  <div className={styles.pdfError}>{pdfError}</div>
                )}
                </>
              )}
              */}

              {/*Share link modal*/}
              {isLoggedIn && (
                <div
                  className={`${styles.shareButton} ${styles.sideButton}`}
                  onClick={handleShareLinkClick}
                >
                  <FontAwesomeIcon
                    icon={faShareAlt}
                    onMouseEnter={handleShareMouseEnter}
                    onMouseLeave={handleShareMouseLeave}
                  />
                  {isHoveringShare && (
                    <div
                      className={`${styles.shareButtonHoverMessage} ${styles.sideButtonHoverMessage}`}
                    >
                      Compartilhar relatorio(s)
                    </div>
                  )}
                </div>
              )}
              {/*Candidate Review Mode*/}
              <div
                className={`${styles.reviewButton} ${styles.sideButton}`}
                onClick={handleCandidateClick}
              >
                <FontAwesomeIcon
                  icon={candidateReviewIcon}
                  onMouseEnter={handleReviewMouseEnter}
                  onMouseLeave={handleReviewMouseLeave}
                />
                {isHoveringReview && (
                  <div
                    className={`${styles.reviewButtonHoverMessage} ${styles.sideButtonHoverMessage}`}
                  >
                    {candidateReviewMode
                      ? "Sair do modo de revisão"
                      : "Entrar no modo de revisão"}
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            id="pdfSectionSummary"
            className={`${styles.pdfSection1} ${
              pdfMode ? styles.pdfSection1PdfMode : styles.pdfSection
            } ${
              pageBreaks.includes("pdfSectionSummary")
                ? styles.pageBreakAfter
                : ""
            }`}
          >
            {/* Summary container with name, score, feedback and anti-fraud */}
            {!candidateReviewMode ? (
              <div
                className={`${styles.summaryContainer} ${
                  pdfMode ? styles.summaryContainerPdfMode : ""
                }`}
              >
                <div className={styles.scoreAndRank}>
                  <div
                    className={`${styles.totalScore} ${
                      pdfMode ? styles.totalScorePdfMode : ""
                    }`}
                  >
                    <div
                      className={`${styles.totalScorePie} ${
                        pdfMode ? styles.totalScorePiePdfMode : ""
                      }`}
                    >
                      <TotalScorePie score={overallScore} pdfMode={pdfMode} />
                    </div>
                  </div>
                  <div className={styles.rank}>{rank}</div>
                </div>
                <div
                  className={`${styles.nameAndFeedback} ${
                    pdfMode ? styles.nameAndFeedbackPdfMode : ""
                  }`}
                >
                  <div
                    className={`${styles.candidateNameContainer} ${
                      pdfMode ? styles.candidateNameContainerPdfMode : ""
                    }`}
                  >
                    <div className={styles.nameAndTags}>
                      <h4>
                        {username}
                        <br />
                      </h4>
                      {/* custom tags */}
                      {reportCustomTags.length > 0 && (
                        <div className={styles.customTagContainer}>
                          {reportCustomTags.map(
                            (tag, index) =>
                              tag && ( // Check if tag is not empty or undefined
                                <div
                                  key={index}
                                  className={styles.customTags}
                                  style={{
                                    backgroundColor: tag.colour,
                                    color: tag.text_colour,
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    className={styles.detailIcon}
                                  />
                                  <p>{tag.tag_name}</p>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.nameSubHeader}>
                      {/* user email */}
                      <div className={styles.factWithIcon}>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className={styles.detailIcon}
                        />
                        <p>{candidateEmail}</p>
                      </div>
                      {/* date of interview */}
                      <div className={styles.factWithIcon}>
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          className={styles.detailIcon}
                        />
                        <p>{timeConcluded}</p>
                      </div>
                      {/* time taken */}
                      <div className={styles.factWithIcon}>
                        <FontAwesomeIcon
                          icon={faClock}
                          className={styles.detailIcon}
                        />
                        <p style={{ color }}>
                          {text}
                          <br />
                        </p>
                        {accessibilityMode === 1 && (
                          <div 
                            className={styles.accessibilityModeIcon}
                            onMouseEnter={handleAccessibilityMouseEnter}
                            onMouseLeave={handleAccessibilityMouseLeave}
                          >
                            <FontAwesomeIcon icon={faUniversalAccess} />
                            {isHoveringAccessibility && (
                              <div className={styles.accessibilityHoverMessage}>
                                Teste realizado em modo de acessibilidade, sem limitação de tempo.
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.overallFeedback} ${
                      pdfMode ? styles.overallFeedbackPdfMode : ""
                    }`}
                    onMouseEnter={() => setIsHoveringFeedback(true)}
                    onMouseLeave={() => setIsHoveringFeedback(null)}
                  >
                    {overallFeedback}
                    <span className={styles.trailingSpace}></span>{" "}
                    {/* Adds trailing space using CSS */}
                    {shouldRenderFeedbackInput && (
                      <FeedbackInput
                        feedbackDetails={{
                          source: "CandidateEvaluation",
                          test_attempt_id: testAttemptId,
                          prompt_id: evalId,
                          location: "overallFeedback",
                          content: overallFeedback,
                        }}
                        popout={true}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.antiFraudSummary} ${
                    pdfMode ? styles.antiFraudSummaryPdfMode : ""
                  }`}
                >
                  <div className={styles.integrityHeader}>
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className={styles.fraudIcon}
                    />
                    <p>
                      Integridade:{" "}
                      <span className={getRiskLevel(overallIntegrityRisk).color}>
                        {getRiskLevel(overallIntegrityRisk).label}
                      </span>{" "}
                    </p>
                  </div>

                  <div className={styles.cheatingContainer}>
                    {/* camera risk */}
                    {integrityRiskData.camera && (
                      <div
                        className={`${styles.integrityRow} ${styles.firstRow}`}>
                        <div className={styles.integrityIcon}>
                          <FontAwesomeIcon
                            icon={integrityRiskData.camera.icon}
                            className={styles.fraudIcon}
                          />
                          {isHoveringCamera && userInitialPhoto && (
                            <div className={styles.candidatePhotoFrame}>
                              <img
                                src={userInitialPhoto}
                                alt="Foto do candidato"
                                className={styles.candidatePhoto}
                              />
                            </div>
                          )}
                          {isHoveringCamera && cameraInactiveRational && (
                            <div className={styles.cameraInactiveRationalPopout}>
                              <p id="cameraMotive">
                                <b>Motivo:</b> "{cameraInactiveRational}"
                              </p>
                            </div>
                          )}
                        </div>
                        <div 
                          className={styles.integrityTextClickable}
                          onMouseEnter={handleCameraMouseEnter}
                          onMouseLeave={handleCameraMouseLeave}
                          onFocus={handleCameraMouseEnter} 
                          onBlur={handleCameraMouseLeave} 
                          tabIndex="0" 
                          aria-labelledby="cameraStatus cameraMotive"
                        >
                          <p>Câmera </p>
                          <div
                            className={
                              integrityRiskData.camera.color === "green"
                                ? styles.greenFraudSummary
                                : styles.redFraudSummary
                            }
                            id="cameraStatus"
                          >
                            {integrityRiskData.camera.status}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* screen share risk */}
                    {integrityRiskData.screenShare && (
                      <div className={styles.integrityRow}>
                        <div className={styles.integrityIcon}>
                          <FontAwesomeIcon
                            icon={integrityRiskData.screenShare.icon}
                            className={styles.fraudIcon}
                          />
                          {isHoveringScreenShare && screenShareInactiveRational && (
                            <div className={styles.screenShareInactiveRationalPopout}>
                              <p id="screenShareMotive">
                                <b>Motivo:</b> "{screenShareInactiveRational}"
                              </p>
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.integrityTextClickable}
                          tabIndex="0"
                          onClick={playSlideShow}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              playSlideShow();
                            }
                          }}
                          onMouseEnter={handleScreenShareMouseEnter}
                          onMouseLeave={handleScreenShareMouseLeave}
                          onFocus={handleScreenShareMouseEnter}
                          onBlur={handleScreenShareMouseLeave}
                          aria-labelledby="screenShareStatus screenShareMotive"
                        >
                          <p>Tela </p>
                          <div
                            className={
                              integrityRiskData.screenShare.color === "green"
                                ? styles.greenFraudSummary
                                : styles.redFraudSummary
                            }
                            id="screenShareStatus"
                          >
                            {integrityRiskData.screenShare.status}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* time away from screen risk */}
                    {integrityRiskData.timeNotActive && (
                      <div className={styles.integrityRow}>
                        <div className={styles.integrityIcon}>
                          <FontAwesomeIcon
                            icon={integrityRiskData.timeNotActive.icon}
                            className={styles.fraudIcon}
                          />
                        </div>
                        <div className={styles.integrityText}>
                          <div
                            className={
                              integrityRiskData.timeNotActive.color === "green"
                                ? styles.greenFraudSummary
                                : styles.redFraudSummary
                            }
                          >
                            {integrityRiskData.timeNotActive.time}
                          </div>
                          <p> em outra janela</p>
                        </div>
                      </div>
                    )}
                    {/* dev tools risk */}
                    {integrityRiskData.developerTools && (
                      <div className={`${styles.integrityRow} ${styles.lastRow}`}>
                        <div className={styles.integrityIcon}>
                          <FontAwesomeIcon
                            icon={integrityRiskData.developerTools.icon}
                            className={styles.fraudIcon}
                          />
                        </div>
                        <div className={styles.integrityText}>
                          <p>DevTools </p>
                          <div
                            className={
                              integrityRiskData.developerTools.color === "green"
                                ? styles.greenFraudSummary
                                : styles.redFraudSummary
                            }
                          >
                            {integrityRiskData.developerTools.status}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.summaryContainerReviewMode}>
                <div
                  className={`${styles.nameAndFeedback} ${
                    pdfMode ? styles.nameAndFeedbackPdfMode : ""
                  }`}
                >
                  <div
                    className={`${styles.candidateNameContainer} ${
                      pdfMode ? styles.candidateNameContainerPdfMode : ""
                    }`}
                  >
                    <div className={styles.nameAndTags}>
                      <h4>
                        {username}
                        <br />
                      </h4>
                    </div>
                    <div className={styles.nameSubHeader}>
                      {/* user email */}
                      <div className={styles.factWithIcon}>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className={styles.detailIcon}
                        />
                        <p>{candidateEmail}</p>
                      </div>
                      {/* date of interview */}
                      <div className={styles.factWithIcon}>
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          className={styles.detailIcon}
                        />
                        <p>{timeConcluded}</p>
                      </div>
                      {/* time taken */}
                      <div className={styles.factWithIcon}>
                        <FontAwesomeIcon
                          icon={faClock}
                          className={styles.detailIcon}
                        />
                        <p style={{ color }}>
                          {text}
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Summary of skills and scores */}
            {!candidateReviewMode && (
              <div className={styles.scoreDetail}>
                {detailedSkillsData.map((skill, index) => (
                  <div
                    key={index}
                    className={`${styles.scoreDetailContainer} ${
                      pdfMode ? styles.scoreDetailContainerPdfMode : ""
                    }`}
                  >
                    <SkillSummary
                      skillName={skill.name}
                      skillScore={skill.score}
                      skillWeight={skill.weight}
                      isFirst={index === 0}
                      pdfMode={pdfMode}
                    />
                    <SubSkillSummary
                      key={`subskill-summary-${reportChangeCounter}`} // Changes on each report, causing re-mount
                      subSkills={skill.subSkills}
                      updateMaxHeight={updateMaxHeight}
                      maxHeights={maxSubSkillHeights}
                      pdfMode={pdfMode}
                      isFirst={index === 0}
                      isLast={index === detailedSkillsData.length - 1}
                      testAttemptId={testAttemptId}
                      evalId={evalId}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Case instructions */}
          <div
            id="pdfSectionInstructions"
            className={`${
              pdfMode ? styles.pdfSectionPdfMode : styles.pdfSection
            } ${
              pageBreaks.includes("pdfSectionInstructions")
                ? styles.pageBreakAfter
                : ""
            }`}
          >
            <div className={styles.transcriptHeader}>
              <div className={styles.transcriptHeaderText}>
                Transcrição da entrevista
              </div>
            </div>
            <div className={styles.headerAndDivider}>
              <h3 className={styles.sectionHeading}>Instruções do desafio</h3>
              <hr className={styles.sectionDivider} />
            </div>
            <div className={styles.instructionsDetail}>
              {!candidateReviewMode && (
                <div
                  className={`${styles.instructionsScoring} ${
                    pdfMode ? styles.instructionsScoringPdfMode : ""
                  }`}
                ></div>
              )}
              <div
                className={`${styles.instructionsContainer} ${
                  candidateReviewMode ? styles.reviewMode : ""
                }`}
              >
                <ChatMessage
                  key="systemInstructionMessage"
                  data={systemInstructionMessage}
                  showIcon={true}
                  fullWidth={true}
                  pdfMode={pdfMode}
                  feedbackMode={false}
                  testAttemptId={testAttemptId}
                />
              </div>
            </div>
          </div>

          {/* Code editor and scoring */}
          <div
            id="pdfSectionCase"
            className={`${
              pdfMode ? styles.pdfSectionPdfMode : styles.pdfSection
            } ${
              pageBreaks.includes("pdfSectionCase") ? styles.pageBreakAfter : ""
            }`}
          >
            <div className={styles.headerAndDivider}>
              <h3 className={styles.sectionHeading}>Código submetido</h3>
              <hr className={styles.sectionDivider} />
            </div>
            <div className={styles.codeDetail}>
              {!candidateReviewMode && (
                <div
                  className={`${styles.codeScoring} ${
                    pdfMode ? styles.codeScoringPdfMode : ""
                  }`}
                >
                  {detailedSkillsData.map((skill, index) => {
                    // Filter subSkills by section
                    const filteredSubSkills = skill.subSkills.filter(
                      (subSkill) => subSkill.section === "code"
                    );

                    // Only render SubSkillDetail if there are filteredSubSkills
                    return (
                      filteredSubSkills.length > 0 && (
                        <SubSkillDetail
                          key={index}
                          skillName={skill.name}
                          skillScore={skill.percent}
                          subSkills={filteredSubSkills}
                          pdfMode={pdfMode}
                          testAttemptId={testAttemptId}
                          evalId={evalId}
                        />
                      )
                    );
                  })}
                </div>
              )}
              {/* Load code editor with code submission and language prop */}
              <div
                ref={codeEditorContainerRef}
                className={`${styles.codeEditorContainer} ${
                  finalSchema ? styles.database : ""
                } ${candidateReviewMode ? styles.reviewMode : ""}`}
                style={pdfMode ? { minHeight: codeEditorHeight } : {}}
              >
                <div className={styles.codeLanguage}>{codeLanguage}</div>
                <div className={styles.codeEditor}>
                  <CodeEditor
                    key={`code-editor-${reportChangeCounter}`} // Changes on each report, causing re-mount
                    monacoLanguage={monacoLanguage}
                    aceLanguage={aceLanguage}
                    monacoEditorTheme="vs-dark"
                    aceEditorTheme="monokai"
                    defaultValue={finalCode}
                    onChange={() => {}}
                    theme="dark"
                    autocompleteEnabled={false}
                    editorFontSize={editorFontSize}
                    readOnly={true}
                    onContentHeightChange={setCodeEditorHeight}
                    pdfMode={pdfMode}
                  />
                </div>
                {consoleOutput && (
                  <div className={styles.codeConsole}>
                    <CodeOutput
                      compilerResponse={consoleOutput}
                      currentlyCompiling={false}
                      candidateReport={true}
                    />
                  </div>
                )}
                {finalSchema && (
                  <div className={styles.schemaViewer}>
                    <ReportSchemaViewer schema={finalSchema} />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Each chat section and scoring */}
          {Object.keys(messagesGroupedBySection).map((section, index) => {
            const sectionId = `pdfSectionChat${index}`;
            return (
              <div
                key={section}
                id={sectionId}
                className={`${styles.sectionContainer} ${
                  pdfMode ? styles.pdfSectionPdfMode : styles.pdfSection
                } ${pageBreaks.includes(sectionId) ? styles.pageBreakAfter : ""}`}
              >
                {/* Section Header */}
                <div className={styles.headerAndDivider}>
                  <h3 className={styles.sectionHeading}>
                    {sectionNameMappings[section] || section}
                  </h3>
                  <hr className={styles.sectionDivider} />
                </div>
                {/* Section Content */}
                <div className={styles.chatDetail}>
                  {!candidateReviewMode && (
                    <div
                      className={`${styles.chatScoring} ${
                        pdfMode ? styles.chatScoringPdfMode : ""
                      }`}
                    >
                      {/* Render the current section's SubSkillDetail */}
                      {filterSkillsBySection(detailedSkillsData, section).map(
                        (skill, index) => (
                          <SubSkillDetail
                            key={index}
                            skillName={skill.name}
                            skillScore={skill.percent}
                            subSkills={skill.subSkills}
                            pdfMode={pdfMode}
                            testAttemptId={testAttemptId}
                            evalId={evalId}
                          />
                        )
                      )}

                      {/* If this is the last section, also include the communication SubSkillDetail */}
                      {section === lastSectionKey &&
                        followUpReached &&
                        filterSkillsBySection(
                          detailedSkillsData,
                          "communication"
                        ).map((skill, index) => (
                          <SubSkillDetail
                            key={`communication-${index}`}
                            skillName={skill.name}
                            skillScore={skill.percent}
                            subSkills={skill.subSkills}
                            pdfMode={pdfMode}
                            className="communicationDetail"
                            testAttemptId={testAttemptId}
                            evalId={evalId}
                          />
                        ))}
                    </div>
                  )}
                  <div
                    className={`${styles.chatboxContainer} ${
                      pdfMode ? styles.chatboxContainerPdfMode : ""
                    } ${candidateReviewMode ? styles.reviewMode : ""}`}
                  >
                    {messagesGroupedBySection[section].map((message, index) => (
                      <ChatMessage
                        key={index}
                        data={{
                          ...message,
                          isCodeSubmission: false,
                          isFirstMessage: false,
                        }}
                        showIcon={true}
                        pdfMode={pdfMode}
                        feedbackMode={true}
                        testAttemptId={testAttemptId}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}

          {timeRanOut && (
            <div
              id="pdfSectionTimeOut"
              className={`${
                pageBreaks.includes("pdfSectionTimeOut")
                  ? styles.pageBreakAfter
                  : ""
              }`}
            >
              <div className={styles.outOfTimeNotice}>
                <div className={styles.outOfTimeHeading}>
                  <FontAwesomeIcon
                    icon={faClock}
                    className={styles.clockIconOutOfTime}
                  />
                  <p className={styles.outOfTimeText}>
                    Limite de Tempo Atingido Aqui
                  </p>
                </div>
                <hr className={styles.outOfTimeDivider} />
              </div>
              <div className={styles.missingDetail}>
                <div
                  className={`${styles.missingScoring} ${
                    pdfMode ? styles.missingScoringPdfMode : ""
                  }`}
                >
                  {/* Dynamically render SubSkillDetail for all missing sections grouped by skill name */}
                  {groupedMissingSubSkills.map((groupedSkill, index) => (
                    <SubSkillDetail
                      key={index}
                      skillName={groupedSkill.name}
                      skillScore={groupedSkill.score}
                      subSkills={groupedSkill.subSkills}
                      pdfMode={pdfMode}
                      testAttemptId={testAttemptId}
                      evalId={evalId}
                    />
                  ))}
                  {/* Dynamically render communciation scores in the time up sections if the didnt get to the follow up */}
                  {!followUpReached &&
                    filterSkillsBySection(
                      detailedSkillsData,
                      "communication"
                    ).map((skill, index) => (
                      <SubSkillDetail
                        key={`communication-${index}`}
                        skillName={skill.name}
                        skillScore={skill.percent}
                        subSkills={skill.subSkills}
                        pdfMode={pdfMode}
                        className="communicationDetail"
                        testAttemptId={testAttemptId}
                        evalId={evalId}
                      />
                    ))}
                </div>
                <div className={styles.missingContainer}></div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportInterface;
