import React, { useState, useEffect } from 'react';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';
import { getPermissionLevel } from '../../utils/getPermissionLevel';
import { format, subWeeks, addWeeks } from 'date-fns'; // Importing date-fns for date manipulation

const AddReportAccessForm = ({ users, tests, userPermissions, setFormError, setAddReportAccessDetails, companyDetails, companies, preselectedUsers = [] }) => {
    const [companyId, setCompanyId] = useState(companyDetails.companyID); // Set the company ID from the company details
    const [selectedUsers, setSelectedUsers] = useState(preselectedUsers); // State for the selected users, default to preselected users
    const [selectedTest, setSelectedTest] = useState(tests.length === 1 ? tests[0].id : ''); // Default to the only test if one test is provided
    const [selectedTestName, setSelectedTestName] = useState(tests.length === 1 ? tests[0].testName : ''); // Default to the only test if one test is provided
    const [selectedCompanyId, setSelectedCompanyId] = useState(companyId); // Default to the only company if one company is provided
    const [selectedCompanyName, setSelectedCompanyName] = useState(companies.find(company => company.companyID === companyId)?.companyName || ''); // Default to the company name
    const [filteredTests, setFilteredTests] = useState([]); // State for the filtered tests
    const [dateFrom, setDateFrom] = useState(format(subWeeks(new Date(), 2), 'yyyy-MM-dd')); // Default to 2 weeks ago
    const [dateTo, setDateTo] = useState(format(addWeeks(new Date(), 2), 'yyyy-MM-dd')); // Default to 2 weeks from now
    const [previousDateFrom, setPreviousDateFrom] = useState(dateFrom); // Store the original start date to enable toggling of no date 
    const [previousDateTo, setPreviousDateTo] = useState(dateTo); // Store the original end date to enable toggling of no date 
    const [noStartDate, setNoStartDate] = useState(false); // Checkbox state for no start date
    const [noEndDate, setNoEndDate] = useState(false); // Checkbox state for no end date
    const permissionLevel = getPermissionLevel(userPermissions, 'ADD_PERMISSION');

    // set companyId from companyDetails
    useEffect(() => {
        setCompanyId(companyDetails.companyID);
    }, [companyDetails]);

    // Handle changes in test selection
    const handleTestChange = (e) => {
        const selectedTestId = e.target.value;
        const selectedTestObject = tests.find(test => test.id.toString() === selectedTestId);
        
        setSelectedTest(selectedTestId);
        setSelectedTestName(selectedTestObject ? selectedTestObject.testName : '');
    };

    // Handle changes in company selection
    const handleCompanyChange = (e) => {
        const selectedCompanyID = e.target.value;
        const selectedCompanyObject = companies.find(company => company.companyID === parseInt(selectedCompanyID, 10));
        setSelectedCompanyId(selectedCompanyID);
        setSelectedCompanyName(selectedCompanyObject ? selectedCompanyObject.companyName : '');
        
        // Reset selected test when company changes as it depends on company
        setSelectedTest('');
        setSelectedTestName('');
    };

    // Filter tests based on selected company
    useEffect(() => {
        const filtered = tests.filter(test => test.companyId === parseInt(selectedCompanyId, 10));
        setFilteredTests(filtered);
        
        // Reset selected test if it's not in the filtered list
        if (!filtered.some(test => test.id === selectedTest)) {
            setSelectedTest('');
            setSelectedTestName('');
        }
    }, [selectedCompanyId, tests]);

    // Handle selection of users
    const handleUserSelection = (e) => {
        const selectedUserId = e.target.value;
        if (selectedUserId) {
            const selectedUser = users.find(user => user.id === parseInt(selectedUserId, 10));
    
            if (selectedUser && !selectedUsers.includes(selectedUser)) {
                setSelectedUsers([...selectedUsers, selectedUser]);
            }
        }
    };

    // Remove selected user
    const removeUser = (userId) => {
        setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
    };

    // Set the default test if only one test is available
    useEffect(() => {
        if (tests.length === 1) {
            setSelectedTest(tests[0].id);  // Set the only test as default
            setSelectedTestName(tests[0].testName); // Set the only test name as default
        } else {
            setSelectedTest(''); // No test selected by default
            setSelectedTestName(''); // Clear the test name
        }
    }, [tests]);

    // Set the default company if only one company is available
    useEffect(() => {
        if (companies.length === 1) {
            setSelectedCompanyId(companies[0].companyID); // Set the only company as default
            setSelectedCompanyName(companies[0].companyName); // Set the only company name as default
        } else {
            setSelectedCompanyId(companyId); // No company selected by default
            setSelectedCompanyName(companyDetails.companyName); // Clear the company name
        }
    }, [companies]);

    // Handle changes to the no start date checkbox
    const handleNoStartDateChange = () => {
        if (!noStartDate) {
            setPreviousDateFrom(dateFrom); // Save the current date before setting it to null
            setDateFrom(null); // Set date to null when the checkbox is checked
        } else {
            setDateFrom(previousDateFrom); // Revert to the previous date when the checkbox is unchecked
        }
        setNoStartDate(!noStartDate);
    };

    // Handle changes to the no end date checkbox
    const handleNoEndDateChange = () => {
        if (!noEndDate) {
            setPreviousDateTo(dateTo); // Save the current date before setting it to null
            setDateTo(null); // Set date to null when the checkbox is checked
        } else {
            setDateTo(previousDateTo); // Revert to the previous date when the checkbox is unchecked
        }
        setNoEndDate(!noEndDate);
    };

    // Call setAddReportAccessDetails whenever any of the fields change
    useEffect(() => {
        const selectedIds = selectedUsers.map(user => user.id);
        setAddReportAccessDetails({
            userIds: selectedIds,
            testId: selectedTest,
            testName: selectedTestName,
            dateFrom: noStartDate ? null : dateFrom, // Set to null if no start date
            dateTo: noEndDate ? null : dateTo, // Set to null if no end date
            companyId: selectedCompanyId,
            companyName: selectedCompanyName, // Pass companyName
            users: selectedUsers, // Pass the selected user objects
        });
    }, [selectedUsers, selectedTest, selectedTestName, dateFrom, dateTo, noStartDate, noEndDate, selectedCompanyId, selectedCompanyName]);    

    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Selecionar contas</label>
                {selectedUsers.length > 0 && (
                    <div className={styles.selectedUsersList}>
                        {selectedUsers.map(user => (
                            <span key={user.id} className={styles.selectedUserTag}>
                                {user.name} ({user.email})
                                <button type="button" onClick={() => removeUser(user.id)}>x</button>
                            </span>
                        ))}
                    </div>
                )}
                <select value="" onChange={handleUserSelection}>
                    <option value="" disabled>
                        {selectedUsers.length > 0 ? 'Adicione outra conta' : 'Selecione uma conta'}
                    </option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>{user.name} {user.surname} ({user.email})</option>
                    ))}
                </select>
            </div>
            {permissionLevel === 'GLOBAL' && (
                <div className={styles.inputGroup}>
                    <label>Empresa</label>
                    <select value={selectedCompanyId} onChange={handleCompanyChange}>
                        <option value="" disabled>Selecione uma empresa</option> {/* Placeholder */}
                        {companies.map((company) => (
                            <option key={company.companyID} value={company.companyID}>{company.companyName}</option>
                        ))}
                    </select>
                </div>
            )}
            <div className={styles.inputGroup}>
                <label>Entrevista</label>
                <select value={selectedTest} onChange={handleTestChange}>
                    <option value="" disabled>Selecione uma entrevista</option> {/* Placeholder */}
                    {filteredTests.map((test, index) => (
                        <option key={index} value={test.id}>{test.testName}</option>
                    ))}
                </select>
            </div>
            <div className={styles.inputGroup}>
                <label>Data de Início</label>
                <input
                    type="date"
                    value={dateFrom || previousDateFrom || ''}
                    onChange={(e) => setDateFrom(e.target.value)}
                    className={noStartDate ? styles.disabledDateInput : ''}
                    disabled={noStartDate} // Disable input if no start date is checked
                />
                <label className={styles.checkboxContainer}>
                    <input
                        type="checkbox"
                        checked={noStartDate}
                        onChange={handleNoStartDateChange}
                        className={styles.checkboxInput}
                    />
                    <span className={styles.checkboxLabel}>Acesso ilimitado a relatórios passados</span>
                </label>
            </div>

            <div className={styles.inputGroup}>
                <label>Data de Fim</label>
                <input
                    type="date"
                    value={dateTo || previousDateTo || ''}
                    onChange={(e) => setDateTo(e.target.value)}
                    className={noEndDate ? styles.disabledDateInput : ''}
                    disabled={noEndDate} // Disable input if no end date is checked
                />
                <label className={styles.checkboxContainer}>
                    <input
                        type="checkbox"
                        checked={noEndDate}
                        onChange={handleNoEndDateChange}
                        className={styles.checkboxInput}
                    />
                    <span className={styles.checkboxLabel}>Acesso ilimitado a relatórios futuros</span>
                </label>
            </div>

        </div>
    );
};

export default AddReportAccessForm;
