import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import getRelativeTime from '../../utils/getRelativeTime'; // For relative date calculation
import colourScore5point from '../../utils/colourScore5point'; // For coloring scores
import Button from '../common/Button';
import styles from './CompanyPageSummary.module.css'; // Assuming you have some CSS module for styling

const CompanyPageSummary = ({ type = 'Test', title = "", noData, data, totalCount = '', handleItemClick, errorMessage, canInviteCandidates }) => {
    const navigate = useNavigate();

    // Configuration for each data type (Test, Result, etc.)
    const config = {
        Test: {
            titleKey: 'testName',
            details: [
                { label: 'Resultados', key: 'resultCount' },
                { 
                    label: 'Última atividade', 
                    key: 'latestTimestamp', 
                    isDate: true,
                },
            ],
            buttonText: 'Ver todas as entrevistas',
            page: 'entrevistas',
            noDataHeader: 'Vamos adicionar uma entrevista!',
            noDataMessage: 'Para comecar a avaliar pessoas, adicione uma entrevista e convidar elas.',
            noDataButton: 'Ir para entrevistas',
            noDataPage: 'entrevistas',
            errorButton: 'Ir para entrevistas',
        },
        Result: {
            titleKey: 'candidate_name',
            details: [
                { label: 'Status', key: 'status' },
                { label: 'Última atividade', key: 'last_activity', isDate: true },
                { 
                    label: 'Nota', 
                    key: 'total_score', 
                    isScore: true,
                    condition: (item) => item.status === 'Avaliado'
                },
            ],
            buttonText: 'Ver todos os resultados',
            page: 'resultados',
            noDataHeader: 'Você ainda nao tem accesso a resultados.',
            noDataHeaderInvite: 'Vamos convidar pessoas para entrevistas!',
            noDataMessage: 'Agora nao tem resultados. O seu Admin pode conceder acesso a resultados aos seus colaboradores.',
            noDataMessageInvite: 'Agora nao tem resultados. Convide pessoas para entrevistas e veja os resultados aqui.',
            noDataButton: 'Ir para entrevistas',
            noDataPage: 'entrevistas',
            errorButton: 'Ir para resultados',
        },
    };

    // Get the config for the current type
    const { titleKey, details, buttonText, page, noDataHeader, noDataMessage, noDataButton, errorButton, noDataPage, noDataHeaderInvite, noDataMessageInvite } = config[type];

    if ((noData || !data)) {
        return (
            <div className={styles.pageSummaryContainer}>
                <div className={styles.pageSummaryHeader}>
                    {title}
                </div>
                <div className={styles.noDataContainer}>
                    <div className={styles.noDataHeader}>
                        {canInviteCandidates ? noDataHeaderInvite : noDataHeader}
                    </div>
                    <div className={styles.noDataMessage}>
                        {canInviteCandidates ? noDataMessageInvite : noDataMessage}
                    </div>
                </div>
                <div className={styles.pageSummaryButton}>
                    <Button type="proceed" onClick={() => navigate(`/${noDataPage}`)}>{noDataButton}</Button>
                </div>
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className={styles.pageSummaryContainer}>
                <div className={styles.pageSummaryHeader}>
                    {title}
                </div>
                <div className={styles.noDataContainer}>
                    <div className={styles.noDataMessage}>
                        {errorMessage}
                    </div>
                </div>
                <div className={styles.pageSummaryButton}>
                    <Button type="proceed" onClick={() => navigate(`/${page}`)}>{errorButton}</Button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.pageSummaryContainer}>
            <div className={styles.pageSummaryHeader}>
                {title}
            </div>

            <div className={styles.dataList}>
                {data.map(item => {
                    const isClickable = type !== 'Result' || (item.status && item.status === 'Avaliado');
                    const itemClassName = `${styles.dataItem} ${!isClickable ? styles.notClickable : ''}`;

                    return (
                        <div 
                            key={item.id} 
                            className={itemClassName}
                            onClick={() => isClickable && handleItemClick(item)}
                            onKeyDown={(e) => {
                                if (isClickable && (e.key === 'Enter' || e.key === ' ')) {
                                    e.preventDefault();
                                    handleItemClick(item);
                                }
                            }}
                            role={isClickable ? "button" : "div"}
                            tabIndex={isClickable ? 0 : undefined}
                        >
                            <div className={styles.dataTitle}>
                                <span title={item[titleKey]}>{item[titleKey]}</span>
                            </div>
                            <div className={styles.dataDetails}>
                                {details.map((detail, idx) => {
                                    // Check if the detail should be displayed
                                    if (detail.condition && !detail.condition(item)) {
                                        return null;
                                    }
                                    let value = item[detail.key] ? item[detail.key] : '0';

                                    // Format and convert the date to a relative time
                                    if (detail.isDate && value !== '0' && value !== null) {
                                        value = getRelativeTime(value);  // Convert to relative time
                                    } else if (detail.isDate) {
                                        value = 'N/A';
                                    }

                                    // Round and color the score if it's a score field
                                    if (detail.isScore && value !== 'N/A') {
                                        value = Math.round(value); // Round to 0 decimals
                                        value = (
                                            <span style={{ color: colourScore5point(value), fontWeight: 'bold' }}>
                                            {value}
                                            </span>
                                        );
                                    }

                                    return (
                                        <span key={`${item[detail.key]}-${detail.key}`}>
                                            {detail.label}: {value}
                                        </span>
                                    );
                            })}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className={styles.pageSummaryButton}>
                <Button type="proceed" onClick={() => navigate(`/${page}`)}>{buttonText}</Button>
            </div>
        </div>
    );
};

export default CompanyPageSummary;
