import React, { useEffect } from 'react';
import ActionButton from '../common/ActionButton';
import { faPlus, faEdit, faTrash, faSync } from '@fortawesome/free-solid-svg-icons';
import styles from './ReportSummarySidebarFilters.module.css';

const ReportSummarySidebarFilters = ({ 
    tests, 
    testFilters, 
    handleTestFilterChange,
    totalCount,
    testCounts,
    tagFilters, 
    handleTagFilterChange, 
    tagCounts,
    statusList,
    statusFilters,
    handleStatusFilterChange,
    statusCounts,
    handleAddCompanyTag, 
    companyCustomTags, 
    handleEditCompanyTag, 
    handleRemoveTag, 
    handleSelectAll, 
    selectAll, 
    candidateNameFilter, 
    handleCandidateNameChange,
    companies,
    companyFilters,
    handleCompanyFilterChange,
    companyCounts,
    approvedFilters,
    handleApprovedFilterChange,
    approvedCounts,
    isGlobalDashboard = false,
    refreshData,
}) => {

    // Sort test list by count
    const sortedTests = (tests && tests.length > 0 && testCounts) ? tests
        .filter(test => testCounts[test.test_name])
        .sort((a, b) => testCounts[b.test_name] - testCounts[a.test_name])
        : [];

   // Sort companies list by count
   const sortedCompanies = (companies && companies.length > 0 && companyCounts) ? companies
        .filter(company => companyCounts[company.company_id])
        .sort((a, b) => companyCounts[b.company_id] - companyCounts[a.company_id])
        : [];


    // Sort approved list by count
    const sortedApproved = approvedCounts ? Object.entries(approvedCounts)
        .sort((a, b) => b[1] - a[1])
        : [];

    // Sort tags list by count
    const sortedTags = (companyCustomTags && tagCounts) ? companyCustomTags
        .sort((a, b) => (tagCounts[b.tag_id] || 0) - (tagCounts[a.tag_id] || 0))
        : [];

    // Sort status list by count
    const sortedStatus = (statusList && statusCounts) ? statusList
        .map(status => ({
            ...status,
            count: statusCounts[status.status] || 0
        }))
        .sort((a, b) => b.count - a.count)
        : [];
    
    return (
        <div className={styles.sidebarContainer}>
            <input
                type="text"
                placeholder="Buscar por nome"
                value={candidateNameFilter}
                onChange={handleCandidateNameChange}
                className={styles.filterInput}
            />
            <div className={`${styles.totalFilterRow} ${styles.filterRow}`}>
                <input
                    type="radio"
                    id="selectAll"
                    name="testFilter"
                    checked={selectAll}
                    onChange={handleSelectAll}
                />
                <label htmlFor="selectAll">
                    Todos ({totalCount})
                </label>
            </div>
            {/* Test filters */}
            <div className={styles.filterTitle}>Entrevistas</div>
            {sortedTests.map(
                (test) => (
                    <div key={test.test_name} className={styles.filterRow}>
                        <input
                            type="checkbox"
                            id={test.test_name}
                            name="testFilter"
                            checked={
                                testFilters.includes(test.test_name) && !selectAll
                            }
                            onChange={() => handleTestFilterChange(test.test_name)}
                        />
                        <label htmlFor={test.test_name}>
                            {test.test_name} ({testCounts[test.test_name]})
                        </label>
                    </div>
                )
            )}
            {/* Company filters */}
            {isGlobalDashboard && sortedCompanies && sortedCompanies.length > 0 && (
                <>
                    <div className={styles.filterTitle}>Empresas</div>
                    {sortedCompanies.map(
                        (company) => (
                            <div key={company.company_id} className={styles.filterRow}>
                                <input
                                    type="checkbox"
                                    id={company.company_id}
                                    name="companyFilter"
                                    checked={
                                        companyFilters.includes(company.company_id) && !selectAll
                                    }
                                    onChange={() => handleCompanyFilterChange(company.company_id)}
                                />
                                <label htmlFor={company.company_id}>
                                    {company.company_name} ({companyCounts[company.company_id]})
                                </label>
                            </div>
                        )
                    )}
                </>
            )}
           {/* Approved filter */}
           {isGlobalDashboard && sortedApproved.length > 0 && (
                <>
                    <div className={styles.filterTitle}>Approved</div>
                    {sortedApproved.map(([approved, count]) => (
                        <div key={approved} className={styles.filterRow}>
                            <input
                                type="checkbox"
                                id={`approved-${approved}`}
                                name="approvedFilter"
                                checked={approvedFilters.includes(approved) && !selectAll}
                                onChange={() => handleApprovedFilterChange(approved)}
                            />
                            <label htmlFor={`approved-${approved}`}>
                                {approved} ({count})
                            </label>
                        </div>
                    ))}
                </>
            )}
            {/* Status filters */}
            {sortedStatus.length > 0 && (
                <>
                    <div className={styles.filterTitle}>Status</div>
                    {sortedStatus.map((status) => (
                        <div key={status.status} className={styles.filterRow}>
                            <input
                                type="checkbox"
                                id={status.status}
                                name="statusFilter"
                                checked={statusFilters.includes(status.status) && !selectAll}
                                onChange={() => handleStatusFilterChange(status.status)}
                            />
                            <label htmlFor={status.status}>
                                {status.status} ({status.count})
                            </label>
                        </div>
                    ))}
                </>
            )}
            {/* Tag filters */}
            <div className={styles.filterTitle}>
                Tags
                <ActionButton 
                    icon={faPlus} 
                    label="Adicionar Tag" 
                    onClick={handleAddCompanyTag}
                    showHoverPopout={true}
                    popoutText="Adicionar tag"
                    popoutPosition="left"
                />
            </div>
            {sortedTags.map((tag) => (
                <div key={tag.tag_id} className={styles.tagFilterRow}>
                    <div className={styles.tagAndCheckbox}>
                        <input
                            type="checkbox"
                            id={`tag-${tag.tag_id}`}
                            name="tagFilter"
                            checked={tagFilters.includes(tag.tag_name)}
                            onChange={() => handleTagFilterChange(tag.tag_name)}
                        />
                        <label
                            className={styles.tagLabel}
                            htmlFor={`tag-${tag.tag_id}`}
                            style={{
                                backgroundColor: tag.colour,
                                color: tag.text_colour,
                            }}
                        >
                            {tag.tag_name}{tagCounts[tag.tag_id] > 0 ? ` (${tagCounts[tag.tag_id]})` : ''}
                        </label>
                    </div>
                    <div className={styles.tagActions}>
                        <ActionButton 
                            icon={faEdit} 
                            label="Editar Tag" 
                            onClick={() => handleEditCompanyTag(tag.tag_id)}
                            showHoverPopout={true}
                            popoutText="Editar tag"
                            popoutPosition="left"
                        />
                        <ActionButton 
                            icon={faTrash} 
                            label="Excluir Tag" 
                            onClick={() => handleRemoveTag(tag.tag_id)}
                            showHoverPopout={true}
                            popoutText="Excluir tag"
                            popoutPosition="left"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ReportSummarySidebarFilters;
