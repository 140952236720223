// Complete feedback report of the candidate's test attempt. All the logic for the report is in the ReportInterface.js
import React from "react";
import Header from "../components/common/Header";
import ReportIssue from "../components/common/ReportIssue";
import ReportInterface from "../components/interfaces/ReportInterface";
import Popup from "../components/popup/Popup";
import ScreenCover from "../components/popup/ScreenCover";
import { FeedbackProvider } from "../contexts/FeedbackContext"; // Enables capturing users feedback as the view the report
import { useTestContext } from "../contexts/TestContext";
import { TooltipProvider } from "../contexts/TooltipContext"; // Needed for header to work

const CandidateReportPage = () => {
  const { testStage } = useTestContext(); // To load error state

  return (
    <FeedbackProvider>
      <TooltipProvider>
        <Header />
        <ReportIssue/>
        {/* Placeholder for user to input email when submitting issue */}
        <ReportInterface />

        {/* Error states */}

        {/* Catch all error when permissions are there but data loading fails*/}
        {testStage === "reportLoadIssue" && (
          <>
            <ScreenCover isVisible={true} variant="complete" zIndex={15} />
            <Popup variant="ReportLoadIssue" isVisible={true} zIndex={16} /> 
          </>
        )}
        {/* Shareable link returns no urlcode or no company or role code or the company and role code returns no data */}
        {testStage === "shareableLinkNoData" && (
          <>
            <ScreenCover isVisible={true} variant="complete" zIndex={15} />
            <Popup variant="ShareableLinkNoData" isVisible={true} zIndex={16} />
          </>
        )}
        {/* shareable link got permissions but data loading failed */}
        {testStage === "shareableLinkLoadFailed" && (
          <>
            <ScreenCover isVisible={true} variant="complete" zIndex={15} />
            <Popup variant="ShareableLinkLoadFailed" isVisible={true} zIndex={16} />
          </>
        )}
        {/* shareable link expired */}
        {testStage === "expiredToken" && (
          <>
            <ScreenCover isVisible={true} variant="complete" zIndex={15} />
            <Popup variant="ShareableLinkExpired" isVisible={true} zIndex={16} />
          </>
        )}
      </TooltipProvider>
    </FeedbackProvider>
  );
};

export default CandidateReportPage;
