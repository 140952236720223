import React, { useState, useEffect, useRef } from 'react';
import Button from '../common/Button';
import ActionButton from "../common/ActionButton";
import InterviewOverview from './InterviewOverview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from '../../sharedStyles/ModalStyles.module.css';

const InterviewOverviewModal = ({ testOverviews, onClose, selectedTest, handleInviteCandidate, canInviteCandidates }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedTestData, setSelectedTestData] = useState(null);
    const modalRef = useRef(null);
    // get the test details for the selected test using the test id in selected company 
    useEffect(() => {
        if (selectedTest && Object.keys(testOverviews).length > 0) {
            const selectedTestId = selectedTest.testId;
            const selectedTestData = testOverviews[selectedTestId]; // Accessing the object directly by key
            setSelectedTestData(selectedTestData);
        }
    }, [selectedTest, testOverviews]);    

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className={styles.modalOverlay} 
            role="dialog" 
            aria-labelledby="modal-title" 
            aria-modal="true"
            onClick={handleOverlayClick}
        >
            <div className={`${styles.modalContainer} ${styles.wide}`} ref={modalRef} tabIndex="-1">
                <button onClick={onClose} className={styles.closeButton} aria-label="Fechar">
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className={styles.modalHeader}>
                    <div id="modal-title" className={styles.modalTitle}>
                        Resumo da entrevista
                    </div>
                    {canInviteCandidates && <Button type="proceed" onClick={() => handleInviteCandidate(selectedTest)}>Convidar pessoas</Button>}
                </div>
                <InterviewOverview data={selectedTestData} />

            </div>
        </div>
    );
};

export default InterviewOverviewModal;