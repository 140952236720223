import { useState, useCallback, useEffect } from 'react';
import { getConfig, getTestInstructions } from '../services/databaseService';
import { logException } from '../services/loggerFront';

export const useTestConfig = (testId, environment, setTestStage, page) => {
    const [testConfig, setTestConfig] = useState(null);
    const [isLoadingConfig, setIsLoadingConfig] = useState(false);
    const [configError, setConfigError] = useState(null);
    const [feedbackLink, setFeedbackLink] = useState(null);
    const [testSections, setTestSections] = useState([]);
    const [currentSection, setCurrentSection] = useState(null);
    const [caseInstructions, setCaseInstructions] = useState({});
    const [caseCandidateSummary, setCaseCandidateSummary] = useState('');

    const loadConfig = useCallback(async () => {
        if (!testId || !environment) {
            return;
        }

        setIsLoadingConfig(true);
        setConfigError(null);

        try {
            const config = await getConfig('test', testId, environment);
            setTestConfig(config.config);
            setFeedbackLink(config.config.general.feedbackLink);
            await setUpSections(config.config.sections);
            setTestStage('acceptTerms');
        } catch (error) {
            setConfigError(error);
            setTestStage('loadFailed');
            logException('Error fetching test config', {
                errorMessage: error.message,
                errorStack: error.stack,
                errorCode: error.code,
                testId,
                environment,
                fileName: 'useTestConfig.js',
            });
        } finally {
            setIsLoadingConfig(false);
        }
    }, [testId, environment, setTestStage]);

    const setUpSections = useCallback(async (sections) => {
        if (sections && sections.length > 0) {
            setTestSections(sections);
            let firstSection = sections[0];
            setCurrentSection(firstSection);
            await fetchCaseInstructions(sections);
        } else {
            setTestSections([]);
            setCurrentSection(null);
        }
    }, []);

    const fetchCaseInstructions = async (sections, retryCount = 0) => {
        const maxRetries = 2;

        const instructionsPromises = sections.map(async (section) => {
            if (section.case && section.case.instructions) {
                try {
                    const instructions = await getTestInstructions(section.case.instructions);
                    return {
                        sectionName: section.sectionName,
                        instructions: instructions.instructions,
                        preEvalCriteria: instructions.preEvalCriteria || '',
                        candidateSummary: instructions.candidateSummary || '',
                        characterName: instructions.characterName || '',
                        image: instructions.image || '',
                        systemPrompt: instructions.systemPrompt || ''
                    };
                } catch (error) {
                    logException(`Error fetching instructions for section: ${section.sectionName}`, {
                        errorMessage: error.message,
                        errorStack: error.stack,
                        section: section.sectionName,
                        testId,
                        environment,
                    });
                    if (retryCount < maxRetries) {
                        await new Promise(resolve => setTimeout(resolve, 10000));
                        return fetchCaseInstructions([section], retryCount + 1);
                    } else {
                        return { sectionName: section.sectionName, instructions: null, preEvalCriteria: null, candidateSummary: null, characterName: null, image: null, systemPrompt: null };
                    }
                }
            }
            return null;
        });

        const instructionsResults = await Promise.all(instructionsPromises);
        const validInstructions = instructionsResults.filter(result => result !== null);

        const candidateSummaryCase = validInstructions.find(result => result?.candidateSummary);
        if (candidateSummaryCase) {
            setCaseCandidateSummary(candidateSummaryCase.candidateSummary);
        }

        const instructionsMap = validInstructions.reduce((acc, curr) => {
            acc[curr.sectionName] = {
                instructions: curr.instructions,
                preEvalCriteria: curr.preEvalCriteria,
                candidateSummary: curr.candidateSummary || '',
                characterName: curr.characterName || '',
                image: curr.image || '',
                systemPrompt: curr.systemPrompt || ''
            };
            return acc;
        }, {});
        setCaseInstructions(instructionsMap);

        if (Object.values(instructionsMap).some(instruction => instruction === null)) {
            setTestStage('loadFailed');
            logException('Failed to load all section instructions after retries', {
                sections: sections.map(section => section.sectionName),
                testId,
                environment,
            });
        }
    };

    useEffect(() => {
        if (testId && environment) {
            loadConfig();
        }
    }, [testId, environment, loadConfig]);

    return {
        testConfig,
        setTestConfig,
        isLoadingConfig,
        configError,
        feedbackLink,
        setFeedbackLink,
        testSections,
        setTestSections,
        currentSection,
        setCurrentSection,
        caseInstructions,
        caseCandidateSummary,
    };
};