import React, { useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, 
    faUsersCog, 
    faSignOutAlt, 
    faUserShield, 
    faUser, 
    faQuestionCircle, 
    faBuilding, 
    faChartPie, 
    faComments,
    faLaptopCode
} from "@fortawesome/free-solid-svg-icons";
import { faCompass } from '@fortawesome/free-regular-svg-icons';
import styles from "./Navbar.module.css";
import useAuth from "../../hooks/useAuth";
import { hasPermission } from "../../utils/hasPermission"; // Import the permission utility

const Navbar = ({ isOpen, userPermissions, toggleNavbar }) => {
    const { userInitials, userFirstName, userLastName, userEmail } = useAppContext();
    const { signOut } = useAuth();
    const navigate = useNavigate();
    const sidebarRef = useRef(null);

    // Define navigation options based on user roles
    const commonLinks = [
        { path: "/minha-conta", label: "Minha conta", icon: faUser },
        { path: "/resumo", label: "Resumo", icon: faHome },
        { path: "/resultados", label: "Resultados", icon: faChartPie },
    ];

    const documentationLink = [
        { path: "/documentacao", label: "Documentação", icon: faQuestionCircle },
    ]

    // Check if the user has permission to view users and permissions
    const adminLinks = [
        hasPermission(userPermissions, 'VIEW_INTERVIEWS') && { path: "/entrevistas", label: "Entrevistas", icon: faLaptopCode },
        hasPermission(userPermissions, 'VIEW_USERS') && { path: "/contas", label: "Contas", icon: faUsersCog },
        hasPermission(userPermissions, 'VIEW_PERMISSIONS') && { path: "/acesso-a-relatorios", label: "Acesso a relatórios", icon: faUserShield },
        hasPermission(userPermissions, 'EDIT_COMPANY') && { path: "/minha-empresa", label: "Minha empresa", icon: faBuilding },
    ].filter(Boolean); // Filter out `false` values

    const links = [
        ...commonLinks,
        ...adminLinks,
        ...documentationLink,
    ];

    const handleLogout = () => {
        signOut();
        navigate("/login");
    };

    // Handle click outside the sidebar and manage focus
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                toggleNavbar(); // Close the sidebar
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === "Tab" && sidebarRef.current) {
                const focusableElements = sidebarRef.current.querySelectorAll(
                    'a[href], button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
                );
                const firstElement = focusableElements[0];
                const lastElement = focusableElements[focusableElements.length - 1];

                if (event.shiftKey) {
                    // Tab backwards
                    if (document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    }
                } else {
                    // Tab forwards
                    if (document.activeElement === lastElement) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            }
        };

        // Attach the event listeners if the navbar is open
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("keydown", handleKeyDown);

            // Focus the navbar itself when opened
            if (sidebarRef.current) {
                sidebarRef.current.focus();
            }
        }

        // Clean up the event listeners when component unmounts or navbar closes
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, toggleNavbar]); // Re-run effect if `isOpen` or `toggleNavbar` changes

    return (
        <nav
            className={`${styles.navbar} ${isOpen ? styles.open : ""}`}
            ref={sidebarRef} // Attach ref to the sidebar
            tabIndex={-1} // Make the navbar focusable
        >
            <ul className={styles.menu}>
                <li className={styles.accountSummary}>
                    <div 
                        className={styles.userIcon} 
                        onClick={() => { 
                            navigate('/minha-conta'); 
                            toggleNavbar(); // Close the navbar after navigation
                        }}
                    > 
                        {userInitials} 
                    </div>
                    <div className={styles.userInfo}>
                        <div className={styles.userName}>{userFirstName} {userLastName}</div>
                        <div className={styles.userEmail}>{userEmail}</div>
                    </div>
                </li>
                {links.map((link) => (
                    <li key={link.path} className={styles.menuItem}>
                        <Link to={link.path} onClick={toggleNavbar} className={styles.link}>
                            <div className={styles.linkIcon}>
                                <FontAwesomeIcon icon={link.icon} className={styles.icon} /> 
                            </div>
                            <div className={styles.linkText}>
                                {link.label}
                            </div>
                        </Link>
                    </li>
                ))}
                <li className={styles.logOutItem}>
                    <button onClick={handleLogout} className={styles.link}>
                        <div className={styles.linkIcon}>
                            <FontAwesomeIcon icon={faSignOutAlt} className={styles.icon} />
                        </div>
                        <div className={styles.linkText}>
                            Logout
                        </div>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
