import { Navigate, Route, Routes } from "react-router-dom";

// Page components to be routed to
import AdminPage from "../pages/AdminPage"; // For updating configurations
import AuthPage from "../pages/AuthPage"; // For logging in and signing up
import CandidateFeedbackPage from "../pages/CandidateFeedbackPage"; // Page with iframe to google forms for candidates after the interview
import CandidateReportPage from "../pages/CandidateReportPage"; // Show the candidates detailed results
import CompanyResultsPage from "../pages/CompanyResultsPage"; // For the user to see their dashboard with the results they have access to
import CompanySummaryPage from "../pages/CompanySummaryPage"; // For the user to see their dashboard with the results they have access to
import CompanyTestsPage from "../pages/CompanyTestsPage"; // For the user to see the tests they have access to
import ReportAccessManagementPage from "../pages/ReportAccessManagementPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"; // Simple page with privacy policy
import TermsConditionsPage from "../pages/TermsConditionsPage"; // Simple page with terms and conditions
import TestPage from "../pages/TestPage"; // Main page for running interviews
import UserManagementPage from "../pages/UserManagementPage"; // For the admin to manage users
import MyAccountPage from "../pages/MyAccountPage";
import MyCompanyPage from "../pages/MyCompanyPage";
import DocumentationPage from "../pages/DocumentationPage";
import EmailVerificationPage from "../pages/EmailVerificationPage";

export default function ProtectedRoutes() {
  return (
    <Routes>
      <Route element={<Navigate replace to="/resultados" />} path="*" />
      <Route element={<AdminPage />} path="/admin" />
      <Route element={<CandidateReportPage />} path="/candidate-report" />
      <Route element={<TestPage />} path="/entrevista" />
      <Route element={<TestPage />} path="/entrevista/:uuid" />
      <Route element={<CompanyTestsPage />} path="/entrevistas" />
      <Route element={<CandidateFeedbackPage />} path="/feedback" />
      <Route element={<ReportAccessManagementPage />} path="/acesso-a-relatorios" />
      <Route element={<PrivacyPolicyPage />} path="/politica-de-privacidade" />
      <Route element={<PrivacyPolicyPage />} path="/privacy-policy" />
      <Route element={<CandidateReportPage />} path="/relatorio" />
      <Route element={<CompanyResultsPage />} path="/resultados" />
      <Route element={<CompanySummaryPage />} path="/resumo" />
      <Route element={<TermsConditionsPage />} path="/termos-e-condicoes" />
      <Route element={<TermsConditionsPage />} path="/terms-and-conditions" />
      <Route element={<UserManagementPage />} path="/contas" />
      <Route element={<MyAccountPage />} path="/minha-conta" />
      <Route element={<MyCompanyPage />} path="/minha-empresa" />
      <Route element={<DocumentationPage />} path="/documentacao" />
      <Route element={<EmailVerificationPage />} path="/verificar-email" />
      <Route element={<AuthPage />} path="/auth/callback" />
    </Routes>
  );
}
