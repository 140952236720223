// Page to handle the authentication of the user (login, register, forgot password)
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";  // Decode the JWT token to extract user details
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import {validatePassword, passwordRulesDescriptions} from '../utils/validatePassword';
import getInitials from "../utils/getInitials"; // Function to get the initials of the user's name
import {isBusinessEmail} from "../utils/isBusinessEmail"; // Function to check if the email is a business email
import FadeLoader from "../components/common/FadeLoader"; // Loader to display while loading
import Popup from "../components/popup/Popup"; // Popup with different variants which has the inputs for user
import ScreenCover from "../components/popup/ScreenCover"; // Darkens the screen behind the popup (can be "blur" or "complete")
import useAuth from "../hooks/useAuth"; // Custom hook to get the user's authentication status
import { registerUser, requestUserAccount, createNewAccount } from "../services/companyUserService"; // Function to call the API to register a new user
import {
  loginUser,
  resetPassword,
  generateRecoveryToken,
  completeSsoSignup,
} from "../services/authenticationService"; // Functions to call the API to login, register, and reset password
import { logException, logTrace } from "../services/loggerFront";

const AuthPage = () => {
  const { setUserRoles, 
    setUserPermissions, 
    setUserFirstName, 
    setUserLastName, 
    setUserFullName, 
    setUserInitials, 
    setUserEmail, 
    setUserId, 
    setCompanyId, 
    setCompanyAccountType,
    baseUrl } = useAppContext(); // Set the user's roles and permissions
  const { setCompanyDetails } = useTestContext(); // Set the company details (need to move to app context)
  // Navigation and location states
  const auth = useAuth(); // Get the user's authentication status
  const location = useLocation(); // Get the current location (URL)
  const navigate = useNavigate(); // Function to navigate to a different page
  const [referrerUrl, setReferrerUrl] = useState('/resumo'); // Default redirection path
  const [referralReport, setReferralReport] = useState(false); // The report that the user is trying to access
  // Variants to display different popups
  const [variant, setVariant] = useState("login"); // Can be "login", "register" (triggered by location), or "forgotPassword" (triggered by clicking a button in login popup)
  const [showRequestAccess, setShowRequestAccess] = useState(false); // Show the request access button in login popup
  const [isLoading, setIsLoading] = useState(false); // Show the loading spinner
  // States to store the user's input
  const [inputEmail, setInputEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // Only used in the "ResetPassword" and "Register" variant
  const [name, setName] = useState(""); // Only used in the "Register" variant
  const [firstNames, setFirstNames] = useState(""); // Only used in the "Create Account" variant
  const [lastNames, setLastNames] = useState(""); // Only used in the "Create Account" variant
  const [companyName, setCompanyName] = useState(""); // Only used in the "Create Account" variant
  // Messages to the user
  const [errorMessage, setErrorMessage] = useState(null); // To display any error messages to the user
  const [successMessage, setSuccessMessage] = useState(null); // To display any success messages to the user
  // Token handling
  const [recoveryToken, setRecoveryToken] = useState(""); // Only used in the recuperar-senha variant
  const [registerToken, setRegisterToken] = useState(""); // Only used in the registrar variant

  const fileName = "AuthPage";

  // SSO configuration
  const ssoProviders = [
    {
      name: 'microsoft',
      logoUrl: 'https://degrau.blob.core.windows.net/companylogos/microsoft-logo.png',
    },
    {
      name: 'google',
      logoUrl: 'https://degrau.blob.core.windows.net/companylogos/google-logo.svg',
    },
  ];

  // Handle reset password route
  const handleResetPasswordRoute = (queryParams) => {
    const token = queryParams.get('token');
    if (token) {
      setRecoveryToken(token);
      setVariant("ResetPassword");
      clearPage();
    } else {
      setErrorMessage("Token de recuperação inválido. Por favor, solicite um novo link de recuperação.");
      setTimeout(() => setErrorMessage(""), 5000);
      setVariant("ResetPassword");
    }
  };

  // Handle register route
  const handleRegisterRoute = (queryParams) => {
    const token = queryParams.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setRegisterToken(token);
        setInputEmail(decodedToken.email);
        setVariant("Register");
      } catch (error) {
        setErrorMessage("Token inválido. Por favor, solicite um novo convite.");
        setTimeout(() => setErrorMessage(""), 5000);
        setVariant("Register");
      }
    } else {
      setErrorMessage("Token inválido. Por favor, solicite um novo convite.");
      setTimeout(() => setErrorMessage(""), 5000);
      setVariant("Register");
    }
  };
  
  // Handle create account route
  const handleCreateAccountRoute = (queryParams) => {
    setVariant("CreateAccount");
  };

  // Handle default route
  const handleDefaultRoute = () => {
    setVariant("Login");
    setRecoveryToken("");
  };


  // Handle callback from OIDC and SAML
  const handleSsoLoginCallback = async (queryParams) => {
    const loginToken = queryParams.get('login_token'); // Check if the token exists in the URL

    if (loginToken) {
      try {
        // Handle successful login
        auth.signIn(loginToken);
        const decodedToken = jwtDecode(loginToken);
        const result = await setUserDataFromToken(decodedToken); 
        setIsLoading(false);

        if (result.success) { // if it works, go to the homepage
            navigate(referrerUrl || "/resumo")
        } else if (result.reason === 'noCompany') { // if it doesn't work, set the variant to CreateUserCompany
          setVariant("CreateUserCompany");
        } else {
          setErrorMessage('Erro ao processar o login. Por favor, tente novamente.'); // Set the error message from the API
          setTimeout(() => setErrorMessage(""), 5000);
        }
      } catch (error) {
        setIsLoading(false);
        logException('Error: handling SSO login', {
          errorMessage: error.message,
          errorStack: error.stack,
          loginToken,
          fileName,
        });
        setErrorMessage("Falha ao processar o login. Por favor, tente novamente.");
        navigate(`/login?error=${encodeURIComponent("Falha ao processar o login. Por favor, tente novamente.")}`);
      }
    } else {
      setIsLoading(false);
      logException('Error: handling SSO login, no login token', {fileName });
      setErrorMessage("Falha ao processar o login. Por favor, tente novamente.");
      navigate(`/login?error=${encodeURIComponent("Falha ao processar o login. Por favor, tente novamente.")}`);
    }
  };

  // Handle callback from OIDC to signup
  const handleSsoSignupCallback = async (queryParams) => {
    logTrace('handleSsoSignupCallback called', {queryParams, fileName});
    const signupToken = queryParams.get('signup_token'); // Check if the token exists in the URL
    logTrace('signupToken', signupToken, fileName);

    if (signupToken) {
      try {
        // sign in with the current token 
        auth.signIn(signupToken);
        // Set variant to get missing information
        setVariant("CreateUserCompany");
      } catch (error) {
        logException('Error: handling SSO signup', {
          errorMessage: error.message,
          errorStack: error.stack,
          signupToken,
          fileName,
        });
        setErrorMessage("Falha ao processar o cadastro. Por favor, tente novamente.");
        navigate(`/login?error=${encodeURIComponent("Falha ao processar o cadastro. Por favor, tente novamente.")}`);
      }
    } else {
        setErrorMessage("Falha ao processar o cadastro. Por favor, tente novamente.");
        navigate(`/login?error=${encodeURIComponent("Falha ao processar o cadastro. Por favor, tente novamente.")}`);
    }
  };

  // Main useEffect for handling location changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setIsLoading(false);

    // OIDC and SAML callbacks
    if (location.pathname === "/auth/callback") {
      // if query params contains login_token, call handleSsoLoginCallback, if signup_token, call handleSsoSignupCallback
      if (queryParams.has('login_token')) {
        handleSsoLoginCallback(queryParams);
      } else if (queryParams.has('signup_token')) {
        handleSsoSignupCallback(queryParams);
      }
      return;
    }

    // Check for error message in query parameters, if any, display it to the user
    const error = queryParams.get('error');
    if (error) {
        setErrorMessage(error);
        setTimeout(() => setErrorMessage(""), 10000); // Clear the error message after 5 seconds
    }

    // Handle other paths (reset password, register, etc.)
    switch (location.pathname) {
      case "/recuperar-senha":
        handleResetPasswordRoute(queryParams);
        break;
      case "/registrar":
        handleRegisterRoute(queryParams);
        break;
      case "/criar-conta":
        handleCreateAccountRoute(queryParams);
        break;
      default:
        handleDefaultRoute();
        break;
    }
  }, [location]);

  // Store the url that redirected the user to the login page
  useEffect(() => {
    const from = location.state?.from;
    if (from) {
      setReferrerUrl(from); // Set the referrer URL if passed
      // if from contains relatorio, setShowRequestAccess to true so that the user can request access and save the tentativa param as setReferralReport
      if (from.includes("relatorio")) {
        setShowRequestAccess(true); // Show the request access button

        // Extract the query string part (everything after '?') from the from URL
        const queryString = from.split('?')[1];
        if (queryString) {
          const queryParams = new URLSearchParams(queryString);
          const report = queryParams.get('tentativa');
          setReferralReport(report);
        }
      } else {
        setShowRequestAccess(false); // Hide the request access button
      }
    } else {
      setReferrerUrl('/resumo'); // Default redirection path
      setShowRequestAccess(false); // Hide the request access button
    }
  }, [location]);

  // Clear the page's state variables
  const clearPage = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setInputEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  // FUNCTIONS TO HANDLE BUTTON ACTIONS

  // Direct to SSO login for either SAML or OIDC
  const handleSsoLogin = (provider) => {
    setIsLoading(true);
    logTrace('handleSsoLogin called', { provider, fileName });
    let url = '';
    
    // Determine the SSO provider
    if (provider === 'microsoft') {
      url = `${baseUrl}/api/oidc/login-ms?p=B2C_1_degrau_company_signin_ms`;  // also OIDC as SAML need to solve adding them to the db
    } else if (provider === 'google') {
      url = `${baseUrl}/api/oidc/login-google?p=B2C_1_degrau_company_signin`;  // OIDC (Google)
    }
    window.location.href = url;  // Redirect to the SSO login endpoint
  };

  // Direct to SSO signup for OIDC
  const handleSsoSignup = (provider) => {
    logTrace('handleSsoSignup called', { provider, fileName });
    let url = '';
    
    // Determine the SSO provider
    if (provider === 'microsoft') {
      url = `${baseUrl}/api/oidc/signup?p=B2C_1_degrau_b2c_signup_signin`;  // using OIDC for sign up as SAML just does sign in
    } else if (provider === 'google') {
      url = `${baseUrl}/api/oidc/signup?p=B2C_1_degrau_b2c_signup_signin`;  // OIDC (Google)
    }
    window.location.href = url;  // Redirect to the SSO signup endpoint
  };

  // Get the user's extra information for OIDC signup and send it to the API to finish the signup process
  const handleSsoExtraInfo = async (companyName) => {
    logTrace('handleSsoExtraInfo called', companyName, fileName);
    try {
      const result = await completeSsoSignup(companyName);
      if (result.success) {
        logTrace('Sso signup completed', result, fileName);
        const token = result.data;
        setSuccessMessage("Conta criada com sucesso!");

        auth.signIn(token); // Log the user in by storing the JWT token
        const decodedToken = jwtDecode(token);
        await setUserDataFromToken(decodedToken);
        
        setTimeout(() => {
          setSuccessMessage("");
          navigate("/resumo"); // Redirect to the dashboard or desired page
        }, 3000);
      } else {
        logException('Error: handling SSO signup', {
          errorMessage: result.error,
          errorStack: result.error,
          fileName,
        });
        setErrorMessage("Erro ao criar a conta. Por favor, tente novamente.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
    } catch (error) {
      logException('Error: handling SSO signup', {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
      });
      setErrorMessage("Erro ao criar a conta. Por favor, tente novamente.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
  };

  // Login: check if the user exists and navigate to the company summary page
  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const loginResult = await loginUser(inputEmail, password);
      if (loginResult.success) {
        const token = loginResult.data;
        // Handle successful login
        auth.signIn(token);

        // Decode the JWT token to extract user details
        const decodedToken = jwtDecode(token);

        const tokenResult = await setUserDataFromToken(decodedToken);
        if (tokenResult.success) {
          setIsLoading(false);
          // Check if a referrer URL is set and navigate to it, otherwise navigate to the default page
          if (referrerUrl) {
            navigate(referrerUrl);
          } else {
            navigate("/resumo"); // Default navigation if no referrer URL is found
          }
        } else if (tokenResult.reason === 'noCompany') { // if it doesn't work, set the variant to CreateUserCompany
          setIsLoading(false);
          setVariant("CreateUserCompany");
        } else {
          setIsLoading(false);
          setErrorMessage('Erro ao processar o login. Por favor, tente novamente.'); // Set the error message from the API
          setTimeout(() => setErrorMessage(""), 5000);
        }
      } else {
        setIsLoading(false);
        setErrorMessage(loginResult.error); // Set the error message from the API
        setTimeout(() => setErrorMessage(""), 5000);
      }
    } catch (error) {
      setIsLoading(false);
      logException('Error: handling login', {
        errorMessage: error.message,
        errorStack: error.stack,
        status: "Failed",
        type: "Login",
        fileName,
      });
      setErrorMessage(error.message || "Ocorreu um erro durante o login");
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  const setUserDataFromToken = async (decodedToken) => {
     // Extract user details from the decoded token
     const { roles, permissions, name, surname, email, companyDetails, userId, companyAccountType } = decodedToken;

     // first check if the user comapnyId is null, if so move to varient "SelectCompany"
     if (!companyDetails) {
      logTrace('companyDetails is null', { userId, decodedToken, fileName });
      return { success: false, reason: 'noCompany' };
    }

     setUserRoles(roles); // Set the user's roles
     setUserPermissions(permissions); // Set the user's permissions
     setCompanyDetails(companyDetails); // Set the user's company ID
     setCompanyId(companyDetails?.companyID); // Set the user's company ID
     setUserEmail(email); // Set the user's email
     setUserId(userId); // Set the user's ID

     // Set name and initials
     setUserFirstName(name); // Set the user's first name
     setUserLastName(surname); // Set the user's last name
     const fullName = `${name} ${surname}`; // Combine the first and last name
     setUserFullName(fullName); // Set the user's full name

     // Get the first letter of the first name and the first letter of the last name to display in the top right corner
     const initials = await getInitials(fullName); // Await the asynchronous getInitials
     setUserInitials(initials);

     // if companyAccountType is not null, set the company account type
     if (companyAccountType) {
      logTrace('companyAccountType', companyAccountType, companyDetails, fileName);
      setCompanyAccountType(companyAccountType);
     }
     return { success: true };
  };

  // Reset Password: check if the passwords match and update the users's password by sending the new password and the recovery token to the API
  const handleResetPassword = async () => {

  // Check if the new password meets the requirements
    try {
      await validatePassword(password);
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 5000); // Clear the error message after 5 seconds
      return;
    }

    // Check if the passwords match
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não coincidem");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    try {
      const result = await resetPassword(recoveryToken, password);
      if (result.success) {
        setSuccessMessage("Senha redefinida com sucesso. Você será redirecionado para a página de login.");
        setTimeout(() => { setSuccessMessage(""); navigate("/login"); }, 5000);
      } else {
        setErrorMessage(result.error); // Set the error message from the API
        setTimeout(() => setErrorMessage(""), 5000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Token de recuperação inválido ou expirado.");
        setTimeout(() => setErrorMessage(""), 5000);
      } else {
        setErrorMessage("Erro ao redefinir a senha. Por favor, tente novamente mais tarde.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
    }
  };

  // Recover password: send a link including recovery token to the user's email to reset the password. When clicked on the link, the user will be redirected to the reset page
  const handleRecoverPassword = async () => {
    try {
      await generateRecoveryToken(inputEmail);
      // Clear the email and give a success message telling them to check their email
      setSuccessMessage("Link de redefinição de senha enviado para seu email");   
    } catch (error) {
      // Assuming the API returns an error message we can display it to the user
      setErrorMessage("Erro ao enviar o email de recuperação. Por favor, tente novamente mais tarde ou entre em contato com o suporte: suporte@degrau.co");
      setTimeout(() => setErrorMessage(""), 10000);
    }
  };

  // Register User: check if the passwords match and register the users's name and password by sending them and the token to the API
  const handleRegisterUser = async () => {

    // Check if the name field is empty
    if (!firstNames.trim() || !lastNames.trim()) {
      setErrorMessage("O nome não pode estar vazio.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    // Check if the new password meets the requirements
    try {
      await validatePassword(password);
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 5000); // Clear the error message after 5 seconds
      return;
    }

    // Check if the passwords match
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não coincidem");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    try {
      await registerUser(registerToken, firstNames, lastNames, password);
      // Give success message then navigate to the login page
      setSuccessMessage("Registrada com sucesso. Você será redirecionado para a página de login.");
      setTimeout(() => {
        setSuccessMessage("");
        navigate("/login");
      }, 5000);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Token de registrar inválido ou expirado.");
        setTimeout(() => setErrorMessage(""), 5000);
      } else {
        setErrorMessage("Erro ao registrar. Por favor, tente novamente mais tarde.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  // Request Access: send a request to the admin to get access to the platform
  const handleRequestAccess = async () => {
    // check email is not blank and follows the regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!inputEmail.trim()) {
      setErrorMessage("O email não pode estar vazio.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }
    if (!emailRegex.test(inputEmail)) {
      setErrorMessage("Por favor, insira um email válido.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    try {
      await requestUserAccount(inputEmail, referralReport);
      setSuccessMessage("Solicitação de acesso enviada com sucesso. Você receberá um e-mail quando for aceito.");
      // clear the message after 10 seconds
      setTimeout(() => setSuccessMessage(""), 10000);
    } catch (error) {
      setErrorMessage(error.message || "Erro ao solicitar acesso. Por favor, tente novamente mais tarde.");
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  // Create account: Create the user account in the user table and the company in the ValidCompany table
  const handleCreateAccount = async () => {

    // DATA VALIDATIONS

    // Ensure the email, name, password and companyName are not blank
    if (!inputEmail.trim() || !firstNames.trim() || !password.trim() || !companyName.trim()) {
      // specific error messages for each field
      if (!inputEmail.trim()) {
        setErrorMessage("O email não pode estar vazio.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      if (!name.trim()) {
        setErrorMessage("O nome não pode estar vazio.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      if (!password.trim()) {
        setErrorMessage("A senha não pode estar vazia.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      if (!companyName.trim()) {
        setErrorMessage("O nome da empresa não pode estar vazio.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      return;
    }
    
    // EMAIL 

    // check email follows the regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputEmail)) {
      setErrorMessage("Por favor, insira um email válido.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }
    // // Ensure it is a business email
    // if (!isBusinessEmail(inputEmail)) {
    //   setErrorMessage("Por favor, insira um email de negócios.");
    //   setTimeout(() => setErrorMessage(""), 5000);
    //   return;
    // }

    // PASSWORD

    // Check if the new password meets the requirements
    try {
      await validatePassword(password);
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 7000); // Clear the error message after Xms
      return;
    }

    try {
      const { token } = await createNewAccount(inputEmail, password, firstNames, lastNames, companyName);
      setSuccessMessage("Conta criada com sucesso!");

      auth.signIn(token); // Log the user in by storing the JWT token
      const decodedToken = jwtDecode(token);
      const result = await setUserDataFromToken(decodedToken);
      if (result.success) {
          setTimeout(() => {
            setSuccessMessage("");
            navigate("/resumo"); // Redirect to the dashboard or desired page
        }, 3000);
      } else if (result.reason === 'noCompany') { // if it doesn't work, set the variant to CreateUserCompany
        setVariant("CreateUserCompany");
      } else {
        setErrorMessage('Erro ao processar o login. Por favor, tente novamente.'); // Set the error message from the API
        setTimeout(() => setErrorMessage(""), 5000);
      }
    } catch (error) {
      setErrorMessage(error.message || "Erro ao criar a conta. Por favor, tente novamente.");
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  if (isLoading) {
    return (
      <>
        <FadeLoader text="Autenticando..." height={"100vh"} />
      </>
    );
  }

  return (
    <>
      <ScreenCover isVisible={true} variant="complete" zIndex={11} />
      {variant === "Login" && (
        <Popup
          variant="Login"
          isVisible={true}
          zIndex={12}
          email={inputEmail}
          setEmail={setInputEmail}
          password={password}
          setPassword={setPassword}
          setVariant={setVariant}
          handleAction={handleLogin}
          errorMessage={errorMessage}
          successMessage={successMessage}
          showRequestAccess={showRequestAccess}
          handleSsoAction={handleSsoLogin}
          ssoProviders={ssoProviders}
        />
      )}
      {variant === "ResetPassword" && (
        <Popup
          variant="ResetPassword"
          isVisible={true}
          zIndex={12}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleAction={handleResetPassword}
          errorMessage={errorMessage}
          successMessage={successMessage}
          passwordRules={passwordRulesDescriptions}
        />
      )}
      {variant === "ForgotPassword" && (
        <Popup
          variant="ForgotPassword"
          isVisible={true}
          zIndex={12}
          email={inputEmail}
          setEmail={setInputEmail}
          handleAction={handleRecoverPassword}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      )}
      {variant === "Register" && (
        <Popup
          variant="Register"
          isVisible={true}
          zIndex={12}
          wide={false}
          email={inputEmail}
          firstNames={firstNames}
          setFirstNames={setFirstNames}
          lastNames={lastNames}
          setLastNames={setLastNames}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleAction={handleRegisterUser}
          errorMessage={errorMessage}
          successMessage={successMessage}
          passwordRules={passwordRulesDescriptions}
          handleSsoAction={handleSsoLogin}
          ssoProviders={ssoProviders}
        />
      )}
      {variant === "RequestAccess" && (
        <Popup
          variant="RequestAccess"
          isVisible={true}
          zIndex={12}
          email={inputEmail}
          setEmail={setInputEmail}
          handleAction={handleRequestAccess}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      )}
      {variant === "CreateAccount" && (
        <Popup
          variant="CreateAccount"
          isVisible={true}
          zIndex={12}
          wide={true}
          email={inputEmail}
          setEmail={setInputEmail}
          firstNames={firstNames}
          setFirstNames={setFirstNames}
          lastNames={lastNames}
          setLastNames={setLastNames}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          companyName={companyName}
          setCompanyName={setCompanyName}
          handleAction={handleCreateAccount}
          errorMessage={errorMessage}
          successMessage={successMessage}
          passwordRules={passwordRulesDescriptions}
          handleSsoAction={handleSsoSignup}
          ssoProviders={ssoProviders}
        />
      )}
      {variant === "CreateUserCompany" && (
        <Popup
          variant="CreateUserCompany"
          isVisible={true}
          zIndex={12}
          companyName={companyName}
          setCompanyName={setCompanyName}
          handleAction={handleSsoExtraInfo}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      )}
    </>
  );
};

export default AuthPage;
