import React, { useEffect, useState } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import { useTooltip } from '../../contexts/TooltipContext';
import Tooltip from '../tooltip/Tooltip';
import PropTypes from 'prop-types';
import styles from './Tabs.module.css';

const Tabs = ({ children }) => {
    const { theme } = useCodeContext();
    const themeClass = theme === 'dark' ? styles.dark : styles.light;
    const [activeTab, setActiveTab] = useState(0);

    // Tooltip
    const { activeTooltips, getTooltipConfig } = useTooltip();
    const isTabsTooltipActive = activeTooltips['tabs'];
    const tabsTooltipConfig = getTooltipConfig('tabs');

    return (
        <div className={styles.tabs}>
            {isTabsTooltipActive && tabsTooltipConfig && <Tooltip variant="tabs" />}
            <div className={`${styles.tabHeaders} ${themeClass}`}>
                {children.map((child, index) => (
                <button
                    key={index}
                    className={`${styles.tabHeader} ${themeClass} ${activeTab === index ? styles.active : ''}`}
                    onClick={() => setActiveTab(index)}
                >
                    {child.props.label}
                </button>
                ))}
            </div>
            <div className={styles.tabContent}>
                {children[activeTab]}
            </div>
        </div>
    );
};

const Tab = ({ children }) => <div className={styles.individualTabContent}>{children} </div>;

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Tabs, Tab };
