//Chatbox component which focuses on rendering the UI and the functions to add to it like adding system messages or preparing the chatinput depending on what userinput is needed
import React, { useState, useEffect, useContext, useRef } from 'react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import PhotoContainer from '../inputs/PhotoCheck';
import ScreenShare from '../inputs/ScreenShare'
import Button from '../common/Button';
import Tooltip from '../tooltip/Tooltip';
import SimpleMultiFileEditor from '../coding/SimpleMultiFileEditor';
import { TooltipContext } from '../../contexts/TooltipContext';
import { useChatContext } from '../../contexts/ChatContext';
import { useTestContext } from '../../contexts/TestContext';
import styles from './Chatbox.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // For the arrow of the scroll down button
import { FadeLoader } from 'react-spinners'; // For the loading icon waiting for GPT to respond

const Chatbox = ({ style }) => {
    const { 
        chats, currentChatId, registerScrollToBottomCallback, isPhotoCheckVisible, isScreenShareVisible,
        inputVisible, inputType, showTimeUpButtons, isAccessibilityQuestionInput, isUndoAccessibilityButtonVisible, showLoader,
        handleAccessibilityResponse, handleUndoAccessibilityResponse, handleProceedClick } = useChatContext();
    const { testStage, isFinalTimeUp, isTestStarted, showProceedButton } = useTestContext();
    
    // TOOLTIPS //

    // Test retrieval including tooltip
    const { activeTooltips } = useContext(TooltipContext);

    // Check if the tooltips should be visible
    const showRetrievalTooltip = activeTooltips['retrievedSession'];

    // SCROLLING //
    const messagesEndRef = useRef(null); //for scrolling down to

    // Scroll to the bottom of the chat, smoothly
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Scroll to bottom if the callback is triggered
    useEffect(() => {
        registerScrollToBottomCallback(scrollToBottom); // Pass scrollToBottom as the callback to the parent
    }, []);
    
    // STYLES AND CLASSES //

    function getIntroChatMessagesHeight() {
        if (isScreenShareVisible) {
            return 'calc(100% - 10px)';
        }
        if (isPhotoCheckVisible) {
            return 'calc(100% - 360px)';
        }
        return 'calc(100% - 85px)';
    }
      
    const introChatMessagesHeight = getIntroChatMessagesHeight();
      

    // Allow dynamic styles if the chatbox is being used during the introChat or the test
    const chatboxClass = `${styles.chatbox} ${
        testStage === 'introChat' ? styles.chatboxIntro : styles.chatboxTest
    }`;

    // Allow dynamic styles if the chatbox is being used during the introChat or the test
    const chatboxMessagesClass = `${styles.chatboxMessages} ${
        testStage === 'introChat' ? styles.introChatMessages : styles.testChatMessages
    }`;

    // Allow dynamic styles if the chatbox is being used during the introChat or the test
    const chatboxMessagesStyle = testStage === 'introChat'
        ? { height: introChatMessagesHeight }
        : {};

    // Ensure the current chat is not empty and has messages
    const currentMessages = chats[currentChatId] || [];

        return (
            <div className={chatboxClass} style={style}>
                {showRetrievalTooltip && <Tooltip variant="retrievedSession" />}
    
                <div className={chatboxMessagesClass} style={chatboxMessagesStyle}>
                    {
                        currentMessages.length === 0 && !(testStage === 'test' && !isTestStarted) ? (
                            <div className={styles.chatMessagesLoader} >
                                <FadeLoader size={10} color={"#246E24"} />
                            </div>
                        ) : (
                            currentMessages.filter(msg => msg.isVisible !== false).map((msg) => {
                                if (msg.isCodeEditor) {
                                    return (
                                        <div key={msg.id} className={styles.codeEditorMessage}>
                                            <div className={styles.codeMessageHeader}>
                                                Seu código
                                            </div>
                                            <SimpleMultiFileEditor
                                                files={msg.files}
                                                language={msg.language}
                                                readOnly={true}
                                            />
                                        </div>
                                    );
                                }
                                return <ChatMessage key={msg.id} data={msg} />;
                            })
                        )
                    }
                    {/* Components to handle user inputs needed */}
                    {isPhotoCheckVisible && (
                        <PhotoContainer />
                    )}
                    {showTimeUpButtons && (
                        <div className={styles.timeUpButtons}>
                            <Button 
                                type="proceed" 
                                className="startTest"
                                onClick={handleSendDraftAndProceed}
                            >
                                Envie rascunho e finalize teste
                            </Button>
                            <Button 
                                type="subtle" 
                                className="startTest"
                                onClick={handleProceedClick}
                            >
                                Não envie rascunho e finalize teste
                            </Button>
                        </div>
                    )}
                    {inputVisible && (
                        <ChatInput 
                            inputType={inputType} 
                            isTimeUp={isFinalTimeUp}
                        />
                    )}
                    {isAccessibilityQuestionInput && (
                        <div className={styles.accessibilityQuestionButtons}>
                            <Button 
                                type="proceed" 
                                onClick={() => handleAccessibilityResponse('Sim')}
                            >
                                Sim
                            </Button>
                            <Button 
                                type="proceed" 
                                onClick={() => handleAccessibilityResponse('Não')}
                            >
                                Não
                            </Button>
                        </div>
                    )}
                    {isUndoAccessibilityButtonVisible && (
                        <button
                            onClick={handleUndoAccessibilityResponse}
                            role={"button"}
                            className={styles.undoAccessibilityResponseButton}
                            >
                            Desfazer seleção de acessibilidade
                        </button>
                    )}
                    {isScreenShareVisible && (
                        <ScreenShare />
                    )}
                    <div ref={messagesEndRef} style={{ float: 'left', clear: 'both' }} />
                </div>
    
                <>
                    {showProceedButton && (
                        <Button 
                            type="proceed" 
                            className="startTest"
                            onClick={handleProceedClick}
                        >
                            {testStage === 'introChat' ? 'Entrar na tela da entrevista' : 'Finalizar entervista'}
                        </Button>
                    )}
                    {showLoader && (
                        <div className={styles.timeUpSendingLoader}>
                            <FadeLoader size={10} color={"#246E24"} />
                        </div>
                    )}
                </>
            </div>
        );
    };

export default Chatbox;