// Show terms of use and privacy policy and checkboxes to accept and accept permission to be contacted in the future. 
import React, { useState, useEffect } from 'react';
import Dropdown from '../common/Dropdown';
import Button from '../common/Button';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css';


const SelectDevEnvContent = ({ stackItems, setDevEnvStack, description, handleDevEnvConfirm, onLoad }) => {
    const [selectedStack, setSelectedStack] = useState(null); // Track the selected stack

    return (
        <>
            <div id="popup-title" className={styles.srOnly}>
                Pop-up de escolha de ambiente de desenvolvimento
            </div>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad}/>
            <p id="popup-description" className={styles.popupTextBold}>
                Antes de começar, por favor escolha o ambiente de desenvolvimento
            </p>
            <p className={styles.popupText}>
                {description}
            </p>

            {/* Select language */}
            <Dropdown
                items={stackItems}
                label="Ambiente de desenvolvimento"
                setSelectedItem={(stack) => {
                    setSelectedStack(stack);
                    setDevEnvStack(stack);
                }}
                placeholder="-- Selecione uma opção --"
                width="100%"
                className={styles.dropdownContainer}
            />

            <Button type="proceed" disabled={!selectedStack} onClick={handleDevEnvConfirm}>Continuar</Button>
        </>
    );
};

export default SelectDevEnvContent;
