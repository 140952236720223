export const detectSilence = (values, threshold = 0.1) => {
    const sum = values.reduce((acc, val) => acc + val, 0);
    const average = sum / (values.length || 1);
    return average < threshold;
};

export const detectClipping = (values, threshold = 0.95) => {
    const sum = values.reduce((acc, val) => acc + val, 0);
    const average = sum / (values.length || 1);
    return average > threshold;
};