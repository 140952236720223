import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../common/Button';
import ActionButton from '../common/ActionButton';
import styles from './UploadImage.module.css';

const UploadImage = ({ onUpload, onUploadSuccess, acceptedFileTypes = 'image/*', maxFileSize = 5000000 }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        validateAndPreviewFile(file);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        validateAndPreviewFile(file);
    };

    const validateAndPreviewFile = (file) => {
        if (file) {
            // Validate the file type and size
            if (!file.type.startsWith(acceptedFileTypes.split('/')[0])) {
                setErrorMessage(`Por favor, selecione um arquivo válido do tipo ${acceptedFileTypes}.`);
                return;
            }
            if (file.size > maxFileSize) { // Max file size limit
                setErrorMessage(`O arquivo deve ser menor que ${maxFileSize / 1000000}MB.`);
                return;
            }
            setSelectedFile(file);
            setErrorMessage('');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setErrorMessage('Por favor, selecione um arquivo antes de enviar.');
            return;
        }

        setIsUploading(true);
        try {
            const response = await onUpload(selectedFile);
            if (response.success) {
                setSelectedFile(null);
                onUploadSuccess();
                setErrorMessage('');
            } else {
                setErrorMessage('Ocorreu um erro ao enviar o arquivo. Por favor, tente novamente.');
            }
        } catch (error) {
            setErrorMessage('Ocorreu um erro ao enviar o arquivo. Por favor, tente novamente.');
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div 
            className={`${styles.uploadContainer} ${selectedFile ? styles.fileSelected : ''}`} 
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
        >
            {!selectedFile ? (
                <div className={styles.uploadIconAndMessage}>
                    <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} /> {/* Upload icon */}
                    <div className={styles.uploadText}>Faça upload do logotipo da sua empresa</div>
                    <input 
                        type="file" 
                        accept={acceptedFileTypes} 
                        onChange={handleFileChange} 
                        className={styles.fileInput}
                        aria-label="Carregar arquivo"
                    />
                </div>
            ) : (
                <div className={styles.previewContainer}>
                    <div className={styles.previewImageContainer}>
                        <div className={styles.previewHeader}>
                            Nova imagen
                            <ActionButton 
                                icon={faTimes} 
                                label="Excluir nova imagem" 
                                onClick={() => setSelectedFile(null)}
                                showHoverPopout={true}
                                popoutText="Excluir nova imagem"
                                popoutPosition="left"
                                textColor = 'red'
                            />
                        </div>
                        <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Preview"
                            className={styles.imagePreview}
                        />
                    </div>
                    <div className={styles.actionButtons}>
                        <Button 
                            onClick={handleUpload} 
                            type="proceed"
                            disabled={isUploading}
                            aria-label="Enviar arquivo"
                        >
                            {isUploading ? 'Enviando...' : 'Enviar Arquivo'}
                        </Button>
                    </div>
                </div>
            )}
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        </div>
    );
};

UploadImage.propTypes = {
    onUpload: PropTypes.func.isRequired,
    onUploadSuccess: PropTypes.func.isRequired,
    acceptedFileTypes: PropTypes.string,
    maxFileSize: PropTypes.number,
};

export default UploadImage;
