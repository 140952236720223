import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { audioPlayer } from './audioPlayerService';

const ffmpeg = new FFmpeg({ log: true });

let speechToTextWs = null;
let _isWebSocketOpen = false;
const speechToTextMessageQueue = [];
let mediaRecorder = null;
let audioChunks = [];
let audioQueueRef = [];
let isPlayingRef = false;
let isCancelled = false;
let isQuestionCompleted = false;

// Message types enum
const MessageType = {
    CONTROL: 'control',
    AUDIO: 'audio'
};

let onConversationUpdate = null;
let onNewAssistantMessage = null;

export function setConversationUpdateCallback(callback) {
    onConversationUpdate = callback;
}

export function setNewAssistantMessageCallback(callback) {
    onNewAssistantMessage = callback;
}
// Callback registry
const callbacks = {
    CONVERSATION_UPDATE: new Set(),
    NEW_ASSISTANT_MESSAGE: new Set(),
    AUDIO_RECEIVED: new Set(),
    PLAYBACK_STATUS_UPDATE: new Set(),
    QUESTION_STARTED: new Set(),
    QUESTION_COMPLETED: new Set(),
    INTERVIEW_COMPLETE: new Set()
};

export function subscribe(type, callback) {
    callbacks[type].add(callback);
    return () => callbacks[type].delete(callback);
}

// INTERVIEW CONTROL

export function startInterview() {
    sendControlMessage({ type: 'start_interview' });
}

// WEBSOCKET CONTROL

export async function openSpeechToTextWebSocket(webSocketUrl, testAttemptId, identifier) {
    if (speechToTextWs && speechToTextWs.readyState === WebSocket.OPEN) {
        console.log("WebSocket already open");
        return speechToTextWs;
    }

    console.log("Connecting to Speech to Text WebSocket server at:", webSocketUrl);
    
    speechToTextWs = new WebSocket(webSocketUrl);

    return new Promise((resolve, reject) => {
        speechToTextWs.onopen = () => {
            console.log("Speech to Text WebSocket connection established");
            _isWebSocketOpen = true;

            sendControlMessage({
                type: 'setup',
                testAttemptId: testAttemptId,
                identifier: identifier
            });

            while (speechToTextMessageQueue.length > 0) {
                const queuedMessage = speechToTextMessageQueue.shift();
                if (queuedMessage.messageType === MessageType.AUDIO) {
                    sendAudioData(queuedMessage.data);
                } else {
                    sendControlMessage(queuedMessage.data);
                }
            }

            resolve(speechToTextWs);
        };

        // Handle message from the server
        speechToTextWs.onmessage = (event) => {
            console.log("WebSocket event received:", event);
            try {
                const data = JSON.parse(event.data);
                console.log("Parsed ws data:", data);
                
                switch(data.type) {
                    case 'user_message': // The return when a user speaks
                        console.log("Processed user message, transcription:", data.text, 'conversation:', data.conversation);
                        callbacks.CONVERSATION_UPDATE.forEach(cb => cb(data.conversation));
                        break;
                    case 'audio': // The AI response streamed in audio chunks
                        console.log("Received audio data with sequence:", data.sequence, 'no longer playing it');
                        // if (typeof data.audioData === 'string') {
                        //     handleAudioData(data.audioData, data.sequence);
                        // }
                        break;
                    case 'gpt_response': // The full response from the AI
                        console.log("Received GPT response:", data.text);
                        if (data.conversation && Array.isArray(data.conversation)) {
                            // Get the latest message (the one just added)
                            const latestMessage = data.conversation[data.conversation.length - 1];
                            
                            // Auto-play if it's an assistant message with audio
                            if (latestMessage.role === 'assistant' && latestMessage.audioUrl) {
                                // Subscribe to audio events for this message
                                console.log("subscribing to audio events for message", latestMessage.id);
                                const unsubscribe = audioPlayer.subscribe(latestMessage.id, {
                                    onEnded: () => {
                                        console.log("audio ended, checking if question completed", isQuestionCompleted);
                                        if (isQuestionCompleted) {
                                            console.log("Final response completed - moving to next question");
                                            isQuestionCompleted = false;
                                            // Send message to backend to move to next question
                                            sendControlMessage({ type: 'next_question' });
                                        }
                                        unsubscribe();
                                    },
                                    onPlay: () => {
                                        console.log("audio started playing", latestMessage.id);
                                    }
                                });

                                console.log("playing audio", latestMessage.id, latestMessage.audioUrl);
                                
                                audioPlayer.play(latestMessage.id, latestMessage.audioUrl);
                            }
                            
                            callbacks.CONVERSATION_UPDATE.forEach(cb => 
                                cb(data.conversation)
                            );
                        } else {
                            console.warn("Received invalid conversation format:", data.conversation);
                        }
                        callbacks.NEW_ASSISTANT_MESSAGE.forEach(cb => 
                            cb(data.text, data.audioUrl)
                        );
                        break;                    
                    case 'fixed_question': // The full audio fixed question from the AI
                        console.log("Received fixed question:", data.text);
                        if (data.conversation && Array.isArray(data.conversation)) {
                            // Get the latest message (the fixed question)
                            const latestMessage = data.conversation[data.conversation.length - 1];
                            
                            // Auto-play if it's an assistant message with audio
                            if (latestMessage.role === 'assistant' && latestMessage.audioUrl) {
                                console.log("playing fixed question audio", latestMessage.id, latestMessage.audioUrl);
                                audioPlayer.play(latestMessage.id, latestMessage.audioUrl);
                            }
                            
                            callbacks.CONVERSATION_UPDATE.forEach(cb => 
                                cb(data.conversation)
                            );
                        } else {
                            console.warn("Received invalid conversation format:", data.conversation);
                        }
                        callbacks.NEW_ASSISTANT_MESSAGE.forEach(cb => 
                            cb(data.text, data.audioUrl)
                        );
                        break;
                    case 'error':
                        console.error("Received error:", data.error);
                        break;
                    case 'alert':
                        handleAlert(data);
                        break;
                    default:
                        console.log("Received message:", data);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        speechToTextWs.onerror = (error) => {
            console.error('Speech to Text WebSocket Error:', error);
            _isWebSocketOpen = false;
            reject(error);
        };

        speechToTextWs.onclose = () => {
            console.log("Speech to Text WebSocket closed");
            _isWebSocketOpen = false;
            speechToTextWs = null;
        };
    });
}

function handleAlert(data) {
    switch(data.alert) {
        case 'question_started':
            console.log("Question started");
            callbacks.QUESTION_STARTED.forEach(cb => cb());
            isQuestionCompleted = false;
            break;
        case 'question_completed':
            console.log("Question completed");
            callbacks.QUESTION_COMPLETED.forEach(cb => cb());
            callbacks.CONVERSATION_UPDATE.forEach(cb => cb(data.conversation));
            isQuestionCompleted = true;
            break;
        case 'interview_complete':
            console.log("Interview completed");
            callbacks.INTERVIEW_COMPLETE.forEach(cb => cb());
            callbacks.CONVERSATION_UPDATE.forEach(cb => cb(data.conversation));
            break;
        default:
            console.warn("Unknown alert type:", data.alert);
    }
}

export function isSpeechToTextWebSocketOpen() {
    return _isWebSocketOpen && speechToTextWs?.readyState === WebSocket.OPEN;
}

export function sendControlMessage(data) {
    console.log("Trying to send control message:", data);
    const message = {
        messageType: MessageType.CONTROL,
        ...data
    };

    if (isSpeechToTextWebSocketOpen()) {
        console.log("Sending control message:", message);
        speechToTextWs.send(JSON.stringify(message));
    } else {
        console.log("WebSocket not open, queueing message");
        speechToTextMessageQueue.push({ messageType: MessageType.CONTROL, data });
    }
}
// RECORDING CONTROL FUNCTIONS

export async function startRecordingAudio() {
    if (!ffmpeg.loaded) {
        await ffmpeg.load();
    }

    isCancelled = false;

    navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });

            mediaRecorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    audioChunks.push(event.data);
                }
            };

            mediaRecorder.onstop = async () => {
                if (!isCancelled) {
                    console.log("Recording stopped. Processing audio data...");
                    const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                    audioChunks = [];

                    const wavBlob = await convertToWav(audioBlob);
                    sendAudioData(wavBlob);
                }
            };

            mediaRecorder.start();
        })
        .catch(error => {
            console.error("Error capturing audio:", error);
        });
}

export function stopRecordingAudio() {
    console.log("Stopping recording...");
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
    }
}

export function cancelRecordingAudio() {
    console.log("Cancelling recording...");
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        isCancelled = true; // Set cancelled flag before stopping
        mediaRecorder.stop();
        // Stop all tracks in the stream
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
        console.log("Recording cancelled");
    }
}

// USER AUDIO

// Send candidates audio message to be processed by the AI in the server
export function sendAudioData(audioBlob) {
    if (isSpeechToTextWebSocketOpen()) {
        console.log("Sending audio data:", audioBlob.size, "bytes");
        audioBlob.arrayBuffer().then(buffer => {
            speechToTextWs.send(buffer);
        }).catch(error => {
            console.error("Error converting blob to buffer:", error);
        });
    } else {
        console.log("WebSocket not open, queueing audio data");
        speechToTextMessageQueue.push({ messageType: MessageType.AUDIO, data: audioBlob });
    }
}

let onAudioReceived = null;
export function setAudioCallback(callback) {
    onAudioReceived = callback;
}


// Convert to WAV using FFmpeg
async function convertToWav(audioBlob) {
    const audioFilename = 'recording.webm';
    const outputFilename = 'output.wav';
    console.log("Converting to WAV...");
    try {
        await ffmpeg.writeFile(audioFilename, await fetchFile(audioBlob));
        
        await ffmpeg.exec([
            '-i', audioFilename,
            '-ar', '16000',
            '-ac', '1',
            '-acodec', 'pcm_s16le',
            outputFilename
        ]);

        const data = await ffmpeg.readFile(outputFilename);
        return new Blob([data.buffer], { type: 'audio/wav' });
    } catch (error) {
        console.error("Error during conversion:", error);
        throw error;
    } finally {
        await ffmpeg.deleteFile(audioFilename);
        await ffmpeg.deleteFile(outputFilename);
    }
}

// AI AUDIO

// Convert and play the audio data received directly from the server (no longer using)
export function handleAudioData(audioData, sequence) {
    console.log("Handling audio data with sequence:", sequence);
    
    // Convert base64 to blob directly
    const byteCharacters = atob(audioData);
    const byteNumbers = new Array(byteCharacters.length);
    
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(blob);
    const audio = new Audio(audioUrl);

    // Add to queue and sort by sequence
    audioQueueRef = [...audioQueueRef, { audio, sequence }]
        .sort((a, b) => a.sequence - b.sequence);

    // Try to play if not already playing
    if (!isPlayingRef) {
        playFromQueue();
    }
}

// Play the audio from the AI from the queue (no longer using)
function playFromQueue() {
    if (audioQueueRef.length === 0 || isPlayingRef) {
        console.log("Cannot play: queue empty or already playing", { 
            queueLength: audioQueueRef.length, 
            isPlaying: isPlayingRef 
        });
        return;
    }
    
    // Get the next audio to play
    const { audio, sequence } = audioQueueRef[0];
    console.log("Playing audio sequence:", sequence);
    
    // Remove it from queue
    audioQueueRef = audioQueueRef.slice(1);
    
    // Update playing status
    isPlayingRef = true;
    callbacks.PLAYBACK_STATUS_UPDATE.forEach(cb => cb(true));

    audio.onEnded = () => {
        console.log("Audio ended, sequence:", sequence);
        isPlayingRef = false;
        callbacks.PLAYBACK_STATUS_UPDATE.forEach(cb => cb(false));
        URL.revokeObjectURL(audio.src);
        playFromQueue(); // Try to play next in queue
    };

    audio.play().catch(error => {
        console.error('Error playing audio:', error, sequence);
        isPlayingRef = false;
        callbacks.PLAYBACK_STATUS_UPDATE.forEach(cb => cb(false));
        URL.revokeObjectURL(audio.src);
        playFromQueue(); // Try next one on error
    });
}