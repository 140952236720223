import React, { useState, useEffect, useRef } from 'react';
import Button from '../common/Button';
import ActionButton from "../common/ActionButton";
import Dropdown from '../common/Dropdown';
import InterviewOverview from './InterviewOverview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from '../../sharedStyles/ModalStyles.module.css';

const AddTestModal = ({ testNames, currentTests, testOverviews, onClose, selectedTest, setSelectedTest, handleTestOverview, handleAddTest }) => {
    const [selectedTestData, setSelectedTestData] = useState(null);
    const [isTestActive, setIsTestActive] = useState(false);
    const modalRef = useRef(null);

    // on load set the selected test to the first test in the list
    useEffect(() => {
        if (testNames && testNames.length > 0) {
            setSelectedTest(testNames[0]);
        }
    }, [testNames]);

    // call the handleTestOverview function to get the test details for the selected test
    useEffect(() => {
        if (selectedTest) {
            handleTestOverview(selectedTest.id);
        }
    }, [selectedTest]);

    // get the test details for the selected test using the test id in selected company 
    useEffect(() => {
        if (selectedTest && Object.keys(testOverviews).length > 0) {
            const selectedTestId = selectedTest.id;
            const selectedTestData = testOverviews[selectedTestId]; // Accessing the object directly by key
            setSelectedTestData(selectedTestData);
        }
    }, [selectedTest, testOverviews]);    

    // Check if the selected test is already in currentTests (i.e., active)
    useEffect(() => {
        if (selectedTest && currentTests) {
            const testAlreadyActive = currentTests.some(test => test.testId === selectedTest.id);
            setIsTestActive(testAlreadyActive);
        }
    }, [selectedTest, currentTests]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className={styles.modalOverlay} 
            role="dialog" 
            aria-labelledby="modal-title" 
            aria-modal="true"
            onClick={handleOverlayClick}
        >
            <div className={`${styles.modalContainer} ${styles.wide}`} ref={modalRef} tabIndex="-1">
                <button onClick={onClose} className={styles.closeButton} aria-label="Fechar">
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <div id="modal-title" className={styles.header}>
                    <div className={styles.modalTitle}>Adicionar uma entrevista</div>
                </div>
                <div className={styles.topRibbon}>
                    <div className={styles.testDropdown}>
                        <Dropdown
                            items={testNames}
                            label="Selecione uma entrevista:"
                            setSelectedItem={setSelectedTest}
                            placeholder="-- Selecione uma entrevista --"
                            selectedItem={selectedTest}
                        />
                    </div>
                    <div className={styles.userButton}>
                        <Button
                            type="proceed"
                            onClick={handleAddTest}
                            disabled={isTestActive} // Disable if test is already active
                        >
                            {isTestActive ? "Teste já ativo" : "Adicionar entrevista"}
                        </Button>
                    </div>
                </div>
                <InterviewOverview data={selectedTestData} />

            </div>
        </div>
    );
};

export default AddTestModal;