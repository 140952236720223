import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../common/DegrauLogo';
import InputField from '../inputs/InputField';
import Button from '../common/Button';
import styles from './Popup.module.css'; 

const ForgotPasswordContent = ({ email, setEmail, handleAction, errorMessage, successMessage, onLoad, logoLoaded, ...otherProps }) => {
  return (
    <>
      <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
      {logoLoaded && (
        <>
          <p className={styles.popupTextLarge}>
            Digite seu e-mail e você receberá um link para redefinir sua senha
          </p>
          <form
            className={styles.inputFieldContainer}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              handleAction(); // Call the custom action handler
            }}
          >
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="email">Email</label>
              <InputField
                id="email"
                type="email"
                autocomplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            {errorMessage && (
              <p className={styles.errorText} role="alert">
                {errorMessage}
              </p>
            )}
            {successMessage && (
              <p className={styles.successText} role="alert">
                {successMessage}
              </p>
            )}
            <Button
              type="form"
              className={styles.confirmRejectPhoto}
            >
              Submeter
            </Button>
          </form>
        </>
      )}
    </>
  );
};

// Specify the types of each prop passed to the component and if it's required or not
ForgotPasswordContent.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  onLoad: PropTypes.func,
  logoLoaded: PropTypes.bool,
};

export default ForgotPasswordContent;
