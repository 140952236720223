// Form for user to login. Requires email and password. Also includes a link to the forgot password page and a link to the request access page if they arrived from a report.
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import Logo from "../common/DegrauLogo";
import InputField from "../inputs/InputField";
import styles from "./Popup.module.css";

const CreateUserCompanyContent = ({
  companyName,
  setCompanyName,
  handleAction,
  errorMessage,
  successMessage,
  onLoad,
  logoLoaded,
  ...otherProps
}) => { 

  return (
    <>
      <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
      {logoLoaded ? (
        <>
          <p className={styles.popupTextLarge}>
            Bem-vindo à Degrau! Informe o nome da sua empresa para completar seu cadastro.
          </p>
          <form
            className={styles.inputFieldContainer}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              handleAction(companyName); // Call the custom action handler
            }}
          >
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="companyName">Nome da empresa</label>
              <InputField
                id="companyName"
                type="text"
                autocomplete="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            <Button
              disabled={!companyName}
              type="form"
            >
              Continuar
            </Button>
          </form>
          {errorMessage && (
            <p className={styles.errorText} role="alert">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className={styles.successText} role="alert">
              {successMessage}
            </p>
          )}
        </>
      ) : null}
    </>
  );
};

CreateUserCompanyContent.propTypes = {
  companyName: PropTypes.string.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

export default CreateUserCompanyContent;
