// Function to enable the resizers, horizontal is between intructions and chatbox, vertical is between IDE and the instructions/Chatbox
import { useState, useRef, useCallback } from 'react';

    const minWidth = 200; // Minimum width in pixels for the chat box and instructions
    const minRemainingWidth = 500; // For the IDE
    const minHeight = 200; // Minimum height in pixels
    const minRemainingHeight = 200;

//limits the # of changes so it doesnt cause to many re-renders
const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function() {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function() {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};

//inital horizontal is the hight of the intructions (chatbox is 1 -), verticial is the width of the instruction container (IDE is 1 -)
const useResize = (initialHorizontal = 50, initialVertical = 33, throttleDelay = 50) => {
    const [horizontalPosition, setHorizontalPosition] = useState(initialHorizontal);
    const [verticalPosition, setVerticalPosition] = useState(initialVertical);
    const [ isResizing, setIsResizing ] = useState(false);
    const resizeState = useRef({
        initialMouseX: 0,
        initialMouseY: 0,
    });

    const handleMouseMove = useCallback((e, isHorizontal) => {
        const { initialMouseX, initialMouseY } = resizeState.current;
        let newHeightPercentage, newWidthPercentage;
    
        if (isHorizontal) {
            const deltaY = e.clientY - initialMouseY;
            const deltaYPercentage = (deltaY / window.innerHeight) * 100;
            newHeightPercentage = horizontalPosition + deltaYPercentage;
            // Apply min and max height percentage boundaries
            newHeightPercentage = Math.max(newHeightPercentage, minHeight / window.innerHeight * 100);
            newHeightPercentage = Math.min(newHeightPercentage, 100 - minRemainingHeight / window.innerHeight * 100);
            // Update the CSS variable
            document.documentElement.style.setProperty('--instructions-height', `${newHeightPercentage}%`);
        } else {
            const deltaX = e.clientX - initialMouseX;
            const deltaXPercentage = (deltaX / window.innerWidth) * 100;
            newWidthPercentage = verticalPosition + deltaXPercentage;
            // Apply min and max width percentage boundaries
            newWidthPercentage = Math.max(newWidthPercentage, minWidth / window.innerWidth * 100);
            newWidthPercentage = Math.min(newWidthPercentage, 100 - minRemainingWidth / window.innerWidth * 100);
            setVerticalPosition(newWidthPercentage);
            // Update the CSS variable
            document.documentElement.style.setProperty('--instructions-width', `${newWidthPercentage}%`);
        }
    
    }, [horizontalPosition, verticalPosition]);
       

    const throttledHandleMouseMove = throttle(handleMouseMove, throttleDelay);

    const startResizing = (e, isHorizontal) => {
        setIsResizing(true);
        resizeState.current.initialMouseX = e.clientX;
        resizeState.current.initialMouseY = e.clientY;

        // Define the function to handle mouse move
        const moveHandler = (moveEvent) => throttledHandleMouseMove(moveEvent, isHorizontal);

        // Add event listener for mouse move
        document.addEventListener('mousemove', moveHandler);

        // Add mouseup event listener to stop resizing
        const stopResizing = () => {
            document.removeEventListener('mousemove', moveHandler); // Remove mousemove listener
            setIsResizing(false); // Set the resizing state to false when resizing stops
            document.body.classList.remove('noSelect'); // Remove class preventing text selection
        };

        // Prevent text selection while dragging
        document.body.classList.add('noSelect');

        // Add mouseup event listener (run once)
        document.addEventListener('mouseup', stopResizing, { once: true });
    };

    return { isResizing, startResizing };
};

export default useResize;