import { useState, useEffect } from 'react';

const useFilterData = (initialData, resultTagsMap) => {
    const [filteredData, setFilteredData] = useState(initialData);
    const [testFilters, setTestFilters] = useState([]); // Filter by test
    const [companyFilters, setCompanyFilters] = useState([]); // Filter by company
    const [approvedFilters, setApprovedFilters] = useState([]); // Filter by approved status
    const [tagFilters, setTagFilters] = useState([]);
    const [statusFilters, setStatusFilters] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    const [candidateNameFilter, setCandidateNameFilter] = useState("");

    useEffect(() => {
        updateFilteredData(initialData, testFilters, companyFilters, approvedFilters, statusFilters, candidateNameFilter, selectAll, tagFilters);
    }, [testFilters, companyFilters, approvedFilters, statusFilters, candidateNameFilter, selectAll, tagFilters, initialData]);

    const updateFilteredData = (data, testFilters, companyFilters, approvedFilters, statusFilters, nameFilter, selectAll, tagFilters) => {
        let newData = [...data];
        if (!selectAll) {
            if (testFilters.length > 0) {
                newData = newData.filter((d) => testFilters.includes(d.company_test_name));
            }
            if (companyFilters.length > 0) {
                newData = newData.filter((d) => companyFilters.includes(d.company_id));
            }
            if (approvedFilters.length > 0) {
                newData = newData.filter((d) => approvedFilters.includes(d.approved.toString()));
            }
            if (statusFilters.length > 0) {
                newData = newData.filter((d) => statusFilters.includes(d.status));
            }
        }
        if (nameFilter) {
            newData = newData.filter((d) =>
                d.candidate_name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }
        // Tag-based filtering
        if (tagFilters.length > 0) {
            newData = newData.filter((d) => {
                const resultTags = resultTagsMap[d.url_code]?.map((tag) => tag.tag_name) || [];
                return tagFilters.some((tagFilter) => resultTags.includes(tagFilter));
            });
        }
        setFilteredData(newData);
    };

    return {
        filteredData,
        testFilters,
        companyFilters,
        approvedFilters,
        statusFilters,
        tagFilters,
        selectAll,
        candidateNameFilter,
        setTestFilters,
        setCompanyFilters,
        setApprovedFilters,
        setStatusFilters,
        setTagFilters,
        setSelectAll,
        setCandidateNameFilter,
        handleTestFilterChange: (testId) => {
            if (testFilters.includes(testId)) {
                setTestFilters(testFilters.filter((id) => id !== testId));
            } else {
                setTestFilters([...testFilters, testId]);
            }
            setSelectAll(false);
        },
        handleCompanyFilterChange: (companyId) => { 
            if (companyFilters.includes(companyId)) {
                setCompanyFilters(companyFilters.filter((id) => id !== companyId));
            } else {
                setCompanyFilters([...companyFilters, companyId]);
            }
            setSelectAll(false);
        },
        handleApprovedFilterChange: (approved) => {
            const approvedString = approved.toString();
            if (approvedFilters.includes(approvedString)) {
                setApprovedFilters(approvedFilters.filter((a) => a !== approvedString));
            } else {
                setApprovedFilters([...approvedFilters, approvedString]);
            }
            setSelectAll(false);
        },
        handleStatusFilterChange: (status) => {
            if (statusFilters.includes(status)) {
                setStatusFilters(statusFilters.filter((s) => s !== status));
            } else {
                setStatusFilters([...statusFilters, status]);
            }
            setSelectAll(false);
        },
        handleTagFilterChange: (tagName) => {
            if (tagFilters.includes(tagName)) {
                setTagFilters(tagFilters.filter((tag) => tag !== tagName));
            } else {
                setTagFilters([...tagFilters, tagName]);
            }
            setSelectAll(false);
        },
        handleSelectAll: () => {
            setSelectAll(true);
            setTestFilters([]);
            setTagFilters([]);
            setCompanyFilters([]);
            setApprovedFilters([]);
            setStatusFilters([]);
        },
        handleCandidateNameChange: (event) => {
            setCandidateNameFilter(event.target.value);
        }
    };
};

export default useFilterData;
