// Visualise the files available in the IDE and allow the user to open them.
import React, { useState, useEffect } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder, faFolderOpen, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import styles from './FileExplorer.module.css';

const FileExplorer = ({ 
  currentCode, 
  activeFile, 
  setActiveFile, 
  defaultFile,
  sortedPaths 
}) => {

  const [openFolders, setOpenFolders] = useState(() => {
      const initialState = { '': true };  // Root folder always open
      
      // If there's a default file, open its parent folders
      if (defaultFile) {
          const parts = defaultFile.split('/');
          let path = '';
          parts.slice(0, -1).forEach(part => {
              path = path ? `${path}/${part}` : part;
              initialState[path] = true;
          });
      }
      
      return initialState;
  });

  const toggleFolder = (folderPath) => {
    setOpenFolders(prev => ({
        ...prev,
        [folderPath]: !prev[folderPath]
    }));
  };

  // Get all unique folder paths
  const getFolders = () => {
      const folders = new Set();
      Object.keys(currentCode).forEach(path => {
          const parts = path.split('/');
          let currentPath = '';
          parts.slice(0, -1).forEach(part => {
              currentPath = currentPath ? `${currentPath}/${part}` : part;
              folders.add(currentPath);
          });
      });
      return Array.from(folders);
  };

  // Update the getFilesInFolder function
  const getFilesInFolder = (folderPath) => {
    return sortedPaths
        .filter(path => {
            if (!folderPath) {
                // Root level: only show files with no '/'
                return !path.includes('/');
            }
            // For subfolders: match files that are direct children
            const regex = new RegExp(`^${folderPath}/[^/]+$`);
            return regex.test(path);
        })
        .filter(path => currentCode[path] !== undefined); // Only actual files
  };

  // Get subfolders in a specific folder
  const getSubfolders = (folderPath) => {
      return sortedPaths
          .filter(path => {
              if (!folderPath) {
                  // Root level: only show top-level folders
                  return !path.includes('/') && currentCode[path] === undefined;
              }
              // For subfolders: match folders that are direct children
              const regex = new RegExp(`^${folderPath}/[^/]+$`);
              return regex.test(path) && currentCode[path] === undefined;
          });
  };

  const renderFolder = (folderPath) => {
    const files = getFilesInFolder(folderPath);
    const subfolders = getSubfolders(folderPath);
    const folderName = folderPath.split('/').pop();
    const isOpen = openFolders[folderPath];

    return (
        <div key={folderPath}>
            <div
                onClick={() => toggleFolder(folderPath)}
                className={styles.folderItem} 
            >
                <FontAwesomeIcon 
                    icon={isOpen ? faChevronDown : faChevronRight}
                    className={styles.chevronIcon}
                />
                <span>{folderName}</span>
            </div>
            {isOpen && (
                <div className={styles.indent}>
                    {subfolders.map(subfolder => renderFolder(subfolder))}
                    {files.map(filePath => renderFile(filePath))}
                </div>
            )}
        </div>
    );
  };

  const renderFile = (filePath) => (
    <div
        key={filePath}
        onClick={() => setActiveFile(filePath)}
        className={`${styles.fileItem} ${activeFile === filePath ? styles.activeFile : ''}`}
    >
        <FontAwesomeIcon icon={faFile} className={styles.fileIcon} />
        <span>{filePath.split('/').pop()}</span>
    </div>
  );

  // Update the return statement in the main component
  return (
    <div className={styles.container}>
        {sortedPaths
            .filter(path => !path.includes('/')) // Only process root level items
            .map(path => 
                currentCode[path] === undefined 
                    ? renderFolder(path) 
                    : renderFile(path)
        )}
    </div>
  );
};

FileExplorer.propTypes = {
  currentCode: PropTypes.object.isRequired,
  activeFile: PropTypes.string.isRequired,
  setActiveFile: PropTypes.func.isRequired,
};

export default FileExplorer;
