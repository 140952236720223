// Know which section is current during the test to show in progress bar and help on time calculation in the follow up q's
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { createTestAttempt, addOrUpdateCandidate, updateTestAttempt } from '../services/candidateTestService';
import { savePhoto, ongoingPhotoCapture, stopVideoStream } from '../services/PhotoCaptureService';
import { saveCapture, captureScreen, stopScreenShareStreams } from '../services/ScreenCaptureService';
import { openConnectionWebSocket, closeConnectionWebSocket, isConnectionWebSocketCurrentlyOpen, sendCriticalErrorMessage, signalIntentionalClosure, sendErrorResolvedMessage } from '../services/websocketService';
import { logEvent, logException, logTrace } from '../services/loggerFront';
import formatDateForMySQL from '../utils/formatDateForMysql';
import { useDevEnv } from '../hooks/useDevEnv';
import { useVsCodeChannel } from '../hooks/useVsCodeChannel';
import { useTestConfig } from '../hooks/useTestConfig';
import useTestRetrieval from '../hooks/useTestRetrieval';
import useValidateTestType from '../hooks/useValidateTestType'; // Custom hook to check the url for which company and role they apply for
import useTimer from '../hooks/useTimer';

// Create context
export const TestContext = createContext();

export const TestProvider = ({ children, location }) => {
    const { webSocketUrl, environment, isAdmin, setIsAdmin, globalState, userAgent, candidateIp, screenSize, f12Count, switchScreenCount, INTERRUPTION_RETRY_MAX, INTERRUPTION_RETRY_INTERVAL } = useAppContext();
    const page = location.pathname; // Ref to store the location
    // Test Details
    const [ testUuid, setTestUuid ] = useState(null); // The test uuid
    const [ testId, setTestId ] = useState(null); // The test id
    const [ companyTestId, setCompanyTestId ] = useState(null); // The company test id
    const [ roleDetails, setRoleDetails ] = useState(); // The role details such as name, description, etc
    const [ candidateId, setCandidateId ] = useState(null); // The candidate ID for the test
    const [ candidateUsername, setCandidateUsername ] = useState(); // The candidate username for the test
    const [ candidateEmail, setCandidateEmail ] = useState(); // The candidate email for the test
    const [ testAttemptId, setTestAttemptId ] = useState(null); // The test attempt ID for the test
    const [ codeResponseId, setCodeResponseId ] = useState(); // The code response ID for the test
    const [ companyDetails, setCompanyDetails ] = useState({}); // The company details such as name, logo, etc
    const [ accessibilityMode, setAccessibilityMode ] = useState(false); // Flag to identify if the test is in accessibility mode
    const [ accessibilityDetails, setAccessibilityDetails ] = useState(); // The accessibility details for the test
    const [ trialTest, setTrialTest ] = useState(false); // Flag to identify if the test is a trial test
    // Test management
    const [ testStage, setTestStage ] = useState('initialLoading'); // The current stage of the test. Can be initialLoading, acceptTerms, introChat, test, or failure states
    const testStageRef = useRef(testStage); // Ref to store the test stage
    useEffect(() => {
        testStageRef.current = testStage; // Update the ref with the current test stage
    }, [testStage]);
    const [ isLastSection, setIsLastSection ] = useState(false); // Flag to identify if the current section is the last one
    const [ progress, setProgress ] = useState(0); // To divide the sections as per their weights
    const [ showProceedButton, setShowProceedButton ] = useState(false); // Is the proceed button visible
    // Test Config
    const {
        testConfig,
        feedbackLink,
        testSections,
        currentSection,
        setCurrentSection,
        caseInstructions,
        caseCandidateSummary,
    } = useTestConfig(testId, environment, setTestStage, page);
    // Code
    const [ finalCode, setFinalCode ] = useState(""); // The final code submitted by the candidate
    const [ codeSubmitted, setCodeSubmitted ] = useState(false); // Flag to identify if the code has been submitted
    const [ finalConsoleOutput, setFinalConsoleOutput ] = useState(""); // The final output of the code submitted by the candidate
    const [ boilerplateCode, setBoilerplateCode ] = useState(""); // The boilerplate code GPT context
    const [ initialDatabaseSchema, setInitialDatabaseSchema ] = useState(""); // The initial database schema for the GPT context
    const devEnv = useDevEnv(testSections, testAttemptId, setTestStage, page, setBoilerplateCode); // Custom hook to determine if a dev env is needed and set the items accordingly
    const { channel, sendChannelMessage, sendFinishAttemptMessage, extensionAliveReceived } = useVsCodeChannel(testAttemptId, devEnv.devEnvNeeded, setTestStage, page);
    // Retrieval
    const [hasRetrievedSection, setHasRetrievedSection] = useState(false); // Flag to identify if a section has been retrieved

    // LOGGING // 
    const fileName = 'TestContext.js'; // For logging purposes

    // Log changes in testStage to track candidate journey
    useEffect(() => {
        // First, check if Application Insights has been initialized. If not when it is it will be called again due to the dependency
        if (globalState.appInsightsInitialized) {
            let properties = { testStage: testStage };
            // Add candidateId to properties if it exists
            if (candidateId) {
                properties.candidate_id = candidateId;
            }
            // Add testAttemptId to properties if it exists
            if (testAttemptId) {
                properties.test_attempt_id = testAttemptId;
            }
            // Log the event with properties for every testStage change
            logEvent(`testStage changed to: ${testStage}`, properties);
        }
    }, [testStage, globalState.appInsightsInitialized]); // Include appInsightsInitialized in the dependency array

    // INITIAL LOADING //

    // Custom hook to check the url for which test the candidate is accessing when they land on the page
    useValidateTestType(testStage, testUuid, setTestId, setCompanyTestId, setCompanyDetails, setRoleDetails, setTestStage, page, setTrialTest);

    // To ensure the testAttempt is created once
    const [isCreatingTestAttempt, setIsCreatingTestAttempt] = useState(false);

    // Create a test attempt and save the returning id
    const enterNewTestAttempt = () => {
        // check if the testAttempt has been created or is being created
        if (testAttemptId || isCreatingTestAttempt) {
            return;
        }
        // check if neccessary details are present
        if (!companyDetails?.companyCode || !companyTestId || !roleDetails?.roleCode) {
            return;
        }

        setIsCreatingTestAttempt(true); // Set this to true before starting the creation process

            // Create a test attempt and save the returning id
            createTestAttempt(companyDetails.companyCode, roleDetails.roleCode, companyTestId).then(result => {
                setTestAttemptId(result.test_attempt_id);
                setIsCreatingTestAttempt(false); // Set this back to false after the process is complete
            }).catch(error => {
                logException('Error creating test attempt', {
                    errorMessage: error.message,
                    errorStack: error.stack,
                    fileName: fileName,
                    companyCode: companyDetails.companyCode,
                    roleCode: roleDetails?.roleCode || null,
                    companyTestId: companyTestId || null,
                });
            }).finally(() => {
            setIsCreatingTestAttempt(false); // Set this back to false after the process is complete
        });
    }

    // Call for enterNewTestAttempt
    useEffect(() => {
        enterNewTestAttempt();
    }, [testAttemptId, isCreatingTestAttempt, companyDetails, companyTestId, roleDetails]); 

    // TEST RETRIEVAL //

    const [retrievedSession, setRetrievedSession] = useState(false); // Is it a received session

    // Custom hook to identify if it is a retrieved test and to retrieve the test data from the URL
    const {
        restoreTestAttemptFromURL, // Ensure this is exposed from the hook
        retrievedSessionId,
        retrievedMessages,
        retrievedInstructionIndex,
        retrievedCode,
        retrievedGptCodeEvaluation,
        retrievedCodeSubmitted,
        retrievedCodeActivities,
        retrievedSqlLogs,
        retrievedTimeLeft,
        retrievedSessionSection,
        languageToRestore,
    } = useTestRetrieval();

    // On load, trigger the check to retrieve the test
    useEffect(() => {
        restoreTestAttemptFromURL(setRetrievedSession, setTestStage, setCompanyDetails, setRoleDetails, setCandidateId, setCandidateUsername, setCandidateEmail, setTimeNotActive, setFullScreenExited, setTimeTaken, setKeyFactsLog, page, setAccessibilityMode, setAccessibilityDetails, setCompanyTestId, setTestId);
    }, []);

    // ACCEPT TERMS AND CONDITIONS //

    // Default state of T&Cs and permission to contact (passed to the popup)
    const [termsStatus, setTermsStatus] = useState({ termsAgreed: false, contactPermission: true });

    // Update terms agreed when checkbox ticked (passed to the popup)
    const updateTermsStatus = (termsAgreed, contactPermission) => {
        setTermsStatus({ termsAgreed, contactPermission });
    };

    // Set default state for error message in terms popup
    const [termsErrorVisible, setTermsErrorVisible] = useState(false);

    // Move to 'introChat' or 'selectDevEnv' if terms are accepted. Show error message if user input isnt valid
    const acceptTerms = () => {
        if (testStage === 'acceptTerms' && termsStatus.termsAgreed) { // If terms are agreed
            if (devEnv.devEnvNeeded) {
                setTestStage('selectDevEnv');
            } else if (trialTest) {
                setCandidateId(0);
                setTimeout(() => {
                    completeIntroChat(null, null, true);
                }, 300);
            } else {
                setTestStage('introChat') // Proceed to introChat
            }
        } else if (testStage === 'acceptTerms' && !termsStatus.termsAgreed) { // If terms are not agreed
            setTermsErrorVisible(true); // Show error
        } 
    };

    // INTRO CHAT //

    // When email is entered, update user details, add or update the user in the db and check if they have an active test. newDetails are received from the chatInput
    const updateUserDetails = (newDetails) => {
        if (newDetails.email) {
            setCandidateEmail(newDetails.email); // Store the email in state
            saveCandidateDetailsWithRetry(
                newDetails.email,
                candidateUsername,
                termsStatus.contactPermission,
                testAttemptId,
                companyTestId
            )
            .then(({ candidateId, isAdmin }) => {
                setCandidateId(candidateId); // DB responds with the candidate ID, save it as part of the user details
            })
            .catch(error => {
                setTestStage('saveFailed');
                logException('Error: saveCandidateDetailsWithRetry failed', {
                    errorMessage: error.message,
                    errorStack: error.stack,
                    fileName: fileName,
                    email: newDetails.email,
                });
            });
        }
        if (newDetails.username) {
            setCandidateUsername(newDetails.username);
        }
    };
    
    const [activeTest, setActiveTest] = useState(false); // Flag to identify if there is an active (previous and valid) test from the candidate
    const [activeTestDetails, setActiveTestDetails] = useState( {Id: null, Status: null} ); // To store the active test id and status if there is one

    // Save user details (name, email, permission to contact) to a new entry in the db, or update exisitng if its a repeating email, and get the candidateId in reponse. Retry if it fails. Recongnises if the user has an activeTest
    const saveCandidateDetailsWithRetry = async (email, username, permission, testAttemptId, companyTestId, attempt = 0) => {

        try {
            const candidateData = await addOrUpdateCandidate(username, email, permission, companyTestId, testAttemptId); // Save the candidate details
            const candidateId = candidateData.candidate_id; // Extract the candidateId from the response

            const isAdmin = candidateData.admin === 1; // Identify if it is an admin account
            setIsAdmin(isAdmin); // Set the admin state    

            // Identify if there is an active test and set the state which triggers the ActiveTestContent popup
            if (candidateData.activeTests && candidateData.activeTests.length > 0) { // If there are test attempts
                setActiveTest(true); // Set activeTest to true
                const testAttemptId = candidateData.activeTests[0].testAttemptId; // Get the testAttemptId
                const testStatus = andidateData.activeTests[0].status; // Get the test status
                setActiveTestDetails( {Id: testAttemptId, Status: testStatus} ); // As testAttempts includes only the most recent test
                logTrace(`Active test found for candidate: ${candidateId}`, {
                    testAttemptId: testAttemptId,
                    testStatus: testStatus,
                    candidateId: candidateId,
                    fileName: fileName,
                    type: 'Test Retrieval',
                });
            } else { // If there are no active test attempts
                setActiveTest(false);
            }
            if (candidateId) { // If the candidateId is returned
                return { candidateId, isAdmin };    
            } else {
                logException('Error: No candidateId returned from saveCandidateDetails', {
                    errorMessage: 'No candidateId returned',
                    username: username || null,
                    email: email || null,
                    permission: permission || null,
                    fileName: fileName,
                });
                throw new Error('No candidateId returned');
            }
        } catch (error) { // If the save fails
            if (attempt < 2) { // Retry up to 2 times
                logException(`Error: retrying save Candidate details. Attempt ${attempt + 1} failed`, { 
                    errorMessage: error.message,
                    errorStack: error.stack, 
                    fileName: fileName,
                    attempt 
                });
                return new Promise((resolve, reject) => { // Retry with a delay
                    setTimeout(() => {
                        saveCandidateDetailsWithRetry(username, email, permission, testAttemptId, companyTestId, attempt + 1)
                            .then(resolve)
                            .catch(reject);
                    }, 5000); // Wait 5 seconds before retrying
                });
            } else {
                logException('Error: Failed to save candidate details after max retries', { 
                    errorMessage: error.message,
                    errorStack: error.stack, 
                    fileName: fileName,
                    attempt 
                });
                throw new Error('Failed to save candidate details after 2 retries'); // Rethrow error after max retries
            }
        }
    };

    // When start test button is clicked at the end of introChat (in Chatbox.js) call for the testAttempt to be saved and start the test timer. If it fails show the saveFailed popup
    const completeIntroChat = (introChatTranscript, introChatInstructions, trialTest = false) => {
        
        // ensure there is a testAttemptId, if not 
        if (!testAttemptId) {
            logException('Error: no testAttemptId found in completeIntroChat', { 
                fileName: fileName,
                candidateId: candidateId,
                type: 'Save TestAttempt',
                status: 'Failed',
            });
            return;
        }

        // Prepare the data for the testattempt entry
        const updateData = {
            camera_active: cameraStatus.isActive,
            camera_inactive_rational: cameraStatus.rationale,
            photo_url: photoUrl,
            status: trialTest ? 'trialTest' : 'codingChallenge', // Change to section
            start_time: formatDateForMySQL(new Date()),
            intro_chat_transcript: JSON.stringify(introChatTranscript),
            intro_chat_instructions: JSON.stringify(introChatInstructions),
            test_case_instructions: JSON.stringify(caseInstructions),
            time_left: parseFloat((timeLeft / 60).toFixed(2)),
            total_time: parseFloat((maxTime / 60).toFixed(2)),
            user_agent: userAgent,
            candidate_ip: candidateIp,
            screen_size: JSON.stringify(screenSize),
            screen_share_active: screenShareStatus.isActive,
            screen_share_inactive_rational: screenShareStatus.rationale,
            screen_2_share_active: screen2ShareStatus.isActive,
            screen_2_share_inactive_rational: screen2ShareStatus.rationale,
            retrieved_session: retrievedSession,
            retrieved_session_id: retrievedSessionId,
            valid_test: trialTest ? 0 : (isAdmin ? 0 : 1),
            accessibility_mode: accessibilityMode,
            accessibility_details: accessibilityDetails
        };
        
        updateTestAttempt(testAttemptId, updateData, true)
            .then(() => {
                // Move the site to test testStage so testStageComponent updates
                setTestStage('test')
                // Open the connection websocket to identify if we lose connection to servers or pass any critical error message
                const initialData = {
                    candidateId: candidateId,
                    testAttemptId: testAttemptId,
                    candidateEmail: candidateEmail,
                    adminAccount: trialTest ? 1 : isAdmin,
                };
                openConnectionWebSocket(webSocketUrl, initialData); 
                moveToNextSection(); // Move to the next section
                // if devEnvNeeded is true, and there is a channel, then send the message to the extension to start the dev env
                // if (devEnv.devEnvNeeded && channel) {
                //     sendChannelMessage('start_attempt');
                // }
            })
            .catch(error => {
                logException(`Error: inserting testattempt after retries`, { 
                    errorMessage: error.message,
                    errorStack: error.stack,
                    fileName: fileName,
                });
                setTestStage('saveFailed');
            });
    };

    // TEST //

    const [isTestStarted, setIsTestStarted] = useState(false); // Triggered afte the last tooltip, which unblurs the instructions and starts the timer
    const [isTestInterrupted, setIsTestInterrupted] = useState({ interrupted: false, reason: null }); // Flag is triggered to move to the error state, bringing the popup to the user and alerting the back to send an email
    const isTestInterruptedRef = useRef({ interrupted: false, reason: null }); // Ref to update as there is retry loop which would have a stake version of the state

    // TEST - TIMER //

    const isFinalTimeUpRef = useRef(false); // Ref to update as there is retry loop which would have a stake version of the state 

    // Custom hook to call the useTimer hook and get back key time facts
    const { setSectionTimer, timeLeft, timeTaken, totalTimeTaken, timeAdded, setTimeAdded, timeNotActive, isFinalTimeUp, maxTime, sectionTimeLimit, setStopTimer, sectionStartTime, sectionTimeUp, setSectionTimeUp, setTimeNotActive, setTimeTaken, is1MinsLeft, is10MinsLeft, is15MinsLeft, is20MinsLeft } = useTimer(isTestStarted, testSections, currentSection, testAttemptId, candidateId, testStage, isTestInterrupted, isLastSection, retrievedSession, retrievedTimeLeft, accessibilityMode);

    // Update ref whenever isFinalTimeUp changes
    useEffect(() => {
        isFinalTimeUpRef.current = isFinalTimeUp;
    }, [isFinalTimeUp]);

    const [canvasReady, setCanvasReady] = useState(false);
    
    // TEST - SAVING //

    const RETRY_LIMIT_FOR_TEST = 1; // Maximum number of retries for a single save attempt (1 retry in addition to the initial attempt)
    const MAX_FAILED_SAVES_FOR_TEST = 2; // Maximum allowed cumulative failed save attempts
    const [keyFactsLog, setKeyFactsLog] = useState([]); // Log to have key facts at frequent intervals for test retrieval
    
    // Update isTestInterrupted for database saves
    useEffect(() => {
        isTestInterruptedRef.current = isTestInterrupted;
    }, [isTestInterrupted]); // Update ref whenever codeResponseId changes
    
    // Update the log of key facts to be saved and enable retrieval
    const addToKeyFactsLog = (f12Count, switchScreenCount, timeNotActive, fullScreenExited, timeLeft, currentSection, totalTimeTaken) => {
        const timestamp = formatDateForMySQL(new Date());
        const sectionName = currentSection ? currentSection.sectionName : null;
        const entry = {
            timestamp: timestamp,
            facts: { 
                F12Count: f12Count, 
                SwitchScreenCount: switchScreenCount, 
                TimeNotActive: parseFloat((timeNotActive / 60).toFixed(2)), 
                FullScreenExited: fullScreenExited, 
                TimeLeft: parseFloat((timeLeft / 60).toFixed(2)),
                currentSection: sectionName,
                TotalTimeTaken: parseFloat((totalTimeTaken / 60).toFixed(2)),
            }
        };
        setKeyFactsLog(currentLog => [...currentLog, entry]);
    };

    // Update the test attempt with lastest data (note chat transcript saving gets called in Chatbox)
    const updateTestAttemptRecord = () => {

        // Dont run it if we are in the testInteruppted mode due to passed failures to save
        if (isTestInterruptedRef.current.interrupted) {
            return; // Early return if the test is in an interrupted state
        }

        // Update to the log
        addToKeyFactsLog(f12Count, switchScreenCount, timeNotActive, fullScreenExited, timeLeft, currentSection, totalTimeTaken);

        // Serialize the log to include it in the details
        const keyFactsLogSerialized = JSON.stringify(keyFactsLog);

        const sectionName = currentSection ? currentSection.sectionName : null;
        
        // Gather the relevent data
        const updateData = {
            time_taken: parseFloat((totalTimeTaken / 60).toFixed(2)),
            time_left: parseFloat((timeLeft / 60).toFixed(2)),
            time_not_active: parseFloat((timeNotActive / 60).toFixed(2)),
            switch_screen_count: switchScreenCount,
            end_time: formatDateForMySQL(new Date()),
            f12_count: f12Count,
            photo_url: photoUrl,
            full_screen_exited: fullScreenExited,
            key_facts_log: keyFactsLogSerialized,
            current_section: sectionName,
            // other details
        };
    
        // Gather the id
    
        // If there is no id then quit
        if (!testAttemptId) {
            logException(`Error: no testAttemptId found in updateTestAttemptRecord`, { 
                fileName: fileName,
                candidateId: candidateId,
                type: 'Save TestAttempt',
                status: 'Failed',
            });
            return;
        }
        
        // Attempt to update the testAttempt entry. If it fail retry, if its already a retry then incrmement the count of failed saves, if that reaches the threshold trigger isTestInterrupted so a popup comes to the user (controlled in Layout.js)
        const performUpdate = async (retryCount = 0, afterInterruptionRetryCount = 0) => {
            try {
                await updateTestAttempt(testAttemptId, updateData);
                // If it works, reset the fail settings
                setTestAttemptSaveFailedCount(0);
                setIsTestInterrupted({ interrupted: false, reason: null });
            } catch (error) {
                logException(`Error: updating test attempt`, { 
                    errorMessage: error.message,
                    errorStack: error.stack, 
                    fileName: fileName,
                    testAttemptId: testAttemptId 
                });
                if (retryCount < RETRY_LIMIT_FOR_TEST) {
                    setTimeout(() => performUpdate(retryCount + 1, afterInterruptionRetryCount), 5000);
                } else {
                    setTestAttemptSaveFailedCount(prevCount => {
                        const newCount = prevCount + 1;
                        if (newCount >= MAX_FAILED_SAVES_FOR_TEST && !isTestInterruptedRef.current.interrupted) {
                            setIsTestInterrupted({ interrupted: true, reason: 'testattempt database appContext' });
                            setTimeout(() => performUpdate(retryCount, afterInterruptionRetryCount + 1), INTERRUPTION_RETRY_INTERVAL * 1000);
                        }
                        // Trigger the extended retry logic if within limits and test is interrupted
                        if (isTestInterruptedRef.current.interrupted && afterInterruptionRetryCount < INTERRUPTION_RETRY_MAX) {
                            setTimeout(() => performUpdate(retryCount, afterInterruptionRetryCount + 1), INTERRUPTION_RETRY_INTERVAL * 1000);
                        }
                        return newCount; // Important: Return the updated count
                    });
                }
            }
        };

        performUpdate();
    };
    
    // Update test status column of the testattempt entry. Change from codingChallenge to followUp to complete. Knowing if it is codingChallange or not enables test recovery (so we dont re render the IDE as it is not needed if code has been submitted)
    const updateTestStatus = (status) => {
        const updateData = {
            status: status,
        };
        updateTestAttempt(testAttemptId, updateData);
    };

    // Call for testattempt to be updated every X (saveInterval) seconds
    useEffect(() => {
        const saveInterval = 30; // X seconds
    
        // Only proceed with setting up the interval and checks if we're in the 'test' stage
        if (testStage === 'test') {
            const maybeUpdate = () => {
                // Ensure action is taken only if timeTaken is a multiple of saveInterval and we're not just starting and time isnt up
                if (timeTaken % saveInterval === 0 && timeTaken !== 0 && !isFinalTimeUpRef.current) {
                    updateTestAttemptRecord();
                }
            };
    
            // Immediately invoke maybeUpdate in case we're already at a save point
            maybeUpdate();
    
            // Set up a regular check every second to see if it's time to save again
            const saveTimerId = setInterval(maybeUpdate, 1000);
    
            // Clear the interval on cleanup to prevent memory leaks or unintended actions after component unmounts or state changes
            return () => clearInterval(saveTimerId);
        }
    }, [timeTaken, testStage ]); // Depend on timeTaken, testStage, and timeLeft to dynamically adjust behavior

    // TEST - SECTIONS //

    // Set up the sections from the test config (should be intro)
    const setUpSections = (sections) => {
        if (sections && sections.length > 0) {
            let firstSection = sections[0];
            setSectionTimer(firstSection, testStage);
            setPromptPlaceholders(sections);
        }
    };  

    useEffect(() => {
        if (testConfig && testConfig.sections) {
            setUpSections(testConfig.sections);
        }
    }, [testConfig]);

    // Set the placeholders for the AI prompts
    const setPromptPlaceholders = (sections) => {
        // Identify the coding challenge section, if any
        const codingChallengeSection = sections.find(section => section.sectionName === 'codingChallenge');

        // If it contains ideDefaultCode then save as boilerplate code
        if (codingChallengeSection && codingChallengeSection.coding.ideDefaultCode) {
            setBoilerplateCode(codingChallengeSection.coding.ideDefaultCode);
        }
        // If it contains a database schema then save it as initialDatabaseSchema
        if (codingChallengeSection && codingChallengeSection.coding.initialDatabaseSchema) {
            setInitialDatabaseSchema(codingChallengeSection.coding.initialDatabaseSchema);
        }
    };

    // Function to move to the next section
    const moveToNextSection = () => {
        if (currentSection === null || !testSections.length) return; // If there is no current section or no sections, return

        const currentIndex = testSections.findIndex(section => section.sectionName === currentSection.sectionName);
        let nextIndex = currentIndex + 1;

        if (retrievedSessionSection && !hasRetrievedSection) { // If there is a retrieved section and it has not been retrieved yet
            nextIndex = testSections.findIndex(section => section.sectionName === retrievedSessionSection); // Move to the retrieved section
            setHasRetrievedSection(true); // Mark that the retrieved section has been retrieved
        }

        // If the next section is within the bounds of the sections array, set it as the current section
        if (nextIndex < testSections.length) {
            const nextSection = testSections[nextIndex];
            setCurrentSection(nextSection);
            setSectionTimer(nextSection, testStageRef.current); // Set the timer for the first section
        }
    };

    // Update state if last section
    useEffect(() => {
        if (currentSection && testSections.length > 0) {
            const currentIndex = testSections.findIndex(section => section.sectionName === currentSection.sectionName);
            setIsLastSection(currentIndex === testSections.length - 1);
        }
    }, [currentSection, testSections]);

    // END OF TEST //

    const [isTestCompleted, setIsTestCompleted] = useState(false); // Prevent running closing actions multiple times. Triggered in completeTest
    const isTestCompletedRef = useRef(false); // Ref to update as there is retry loop which would have a stake version of the state
    
    useEffect(() => {
        isTestCompletedRef.current = isTestCompleted;
    }, [isTestCompleted]); // Update ref whenever isTestCompleted changes

    // Move to 'endOfTest' testStage after test is completed by the user clicking the end test button and stop the websocket connection
    const completeTest = () => {

        if (isTestCompletedRef.current) {
            return; // Prevent multiple clicks
        }

        setIsTestCompleted(true); // Set the flag to trigger the end of test popup

        // Log in AppInsights
        logTrace("completeTest", { 
            testAttemptId: testAttemptId,
            candidateId: candidateId,
         });

        updateTestAttemptRecord(); // Update the test attempt one last time
        setTestStage('endOfTest') // Move to endOfTest stage which triggers the popup to go to the feedback page

        // Close the websocket checking the connection with the server
        signalIntentionalClosure();
        closeConnectionWebSocket();

        /// Send the POST request asynchronously to trigger evaluation
        logTrace("Triggering evaluation after test completion", { testAttemptId: testAttemptId });
        fetch('/api/trigger-evaluation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                testAttemptId, 
                isAdmin,
            }),
        }).catch((error) => {
            logException('Error: triggering evaluation after test completion', {
                errorMessage: error.message,
                errorStack: error.stack,
                testAttemptId,
                fileName: fileName,
            });
        });
    }; 

    // TEST INTERRUPTED //

    const [testAttemptSaveFailedCount, setTestAttemptSaveFailedCount] = useState(0); // Count to trigger saveFailed popup to user if saving continues to fail
    const [criticalAlertSent, setCriticalAlertSent] = useState(false); // Flag if we sent and alert to just send a resolved message after a critical error message

    // If test becomes interrupted, send critical error message via the websocket, if after it is resolved, send the resolved message
    useEffect(() => {
        // Determine if either test or chat is interrupted
        if (testStage === 'test') {
            // Gather the details
            const isInterrupted = isTestInterrupted.interrupted
            const reason = isTestInterrupted.reason;
    
            // Inform the back if the test is interrupted
            if (isInterrupted && !criticalAlertSent) {
                const adminStatus = trialTest ? true : isAdmin;
                sendCriticalErrorMessage(testAttemptId, candidateId, candidateEmail, adminStatus, reason); // Send message to the back via the websocket
                setCriticalAlertSent(true); // Mark that a critical alert has been sent
            } else if (!isInterrupted && criticalAlertSent) {
                // If the interruption flags were true but now both are false, and a critical alert was previously sent
                sendErrorResolvedMessage(testAttemptId, candidateId); // Send resolved message to the backend
                setCriticalAlertSent(false); // Reset the flag as the error has been resolved
            }
        }
    }, [isTestInterrupted]);

    // ANTI-FRAUD //

    // CAMERA //

    const videoRef = useRef(null); // Create refs for global video and canvas elements so photo can be taken across multiple states as we take photos throughout the test but also in the introChat
    const canvasRef = useRef(null); // Create refs for global video and canvas elements so photo can be taken across multiple states

    const [initialPhotoSaved, setInitialPhotoSaved] = useState(false); // So we just save the intitial photo once
    const savePhotoRetryDelays = [10000, 20000, 40000];
    // Photo taking: Use 'isPhotoCheckActive' variable to manage the view of the video and canvas in the introChat when the user takes the initial photo
    const [isPhotoCheckActive, setIsPhotoCheckActive] = useState(false);
    // Photo taking: Camera status (if they accept to take photo or not), rationale if rejected, and the url name all to be saved in the DB
    const [cameraStatus, setCameraStatus] = useState({ isActive: false, rationale: "" }); // Rationale is the reason why the user rejects taking the photo, blank if they accept
    const [photoUrl, setPhotoUrl] = useState(null); // The blob name we give the photo so we can save in testattempt table to retrieve in the report

    // Save the photo in the db and the name in testAttempt (setPhotoUrl)
    const attemptSavePhoto = (canvasRef, candidateId, testAttemptId, setPhotoUrl, attempt = 0) => {
        if (attempt >= savePhotoRetryDelays.length) {
            logException(`Error: saving photo, out of retries`, { 
                status: 'Failed',
                type: 'Photo',  
                fileName: fileName,
                testAttemptId,
                candidateId,
            });
            return; // Stop retrying after all attempts are made
        }
    
        savePhoto(canvasRef, candidateId, testAttemptId, setPhotoUrl)
            .then(data => {
                setInitialPhotoSaved(true);
            })
            .catch(error => {
                logException(`Error: saving photo on attempt ${attempt}:`, { 
                    errorMessage: error.message,
                    errorStack: error.stack,
                    testAttemptId,
                    candidateId,
                    status: 'Failed',
                    type: 'Photo',  
                    fileName: fileName,
                    attempt
                });
                setTimeout(() => attemptSavePhoto(attempt + 1), savePhotoRetryDelays[attempt]); // Wait and retry
            });
    };

    // Call for photo to be taken every X seconds during the test. Managed in PhotoCaptureService.
    const beginOngoingPhotoCapture = (canvasRef, videoRef, candidateId, testAttemptId) => {
        // This function will be called every X seconds depending on the interval
        const capture = () => {
            // Check the conditions before proceeding with the photo capture
            if (!isFinalTimeUpRef.current) {
                ongoingPhotoCapture(canvasRef, videoRef, candidateId, testAttemptId);
            }
        };
    
        // Perform the first capture immediately if conditions are met
        capture();
    
        // Then set up the interval to repeat the captures, checking conditions each time
        const intervalId = setInterval(capture, 30000); // 30,000 ms = 30 seconds
    
        // Return a cleanup function to clear the interval when the component unmounts or conditions change
        return () => clearInterval(intervalId);
    };    
    
    // Call initial photo saving and ongoing photo taking functions when testAttemptId updates (as we use this as an identifier and it coincides with the test starting)
    useEffect(() => {
        // Destructure necessary states
        const { isActive } = cameraStatus;
    
        // Check if camera is active and candidateId and testAttemptId are available
        if (isActive && candidateId && testAttemptId) {
            // Condition specific for attemptSavePhoto: Also check if initial photo hasn't been saved yet
            if (!initialPhotoSaved && canvasRef.current) {
                attemptSavePhoto(canvasRef, candidateId, testAttemptId, setPhotoUrl)
            }
    
            // Call startOngoingPhotoCapture directly, no need for an intermediary or retry logic
            const cleanupOngoingCapture = beginOngoingPhotoCapture(canvasRef, videoRef, candidateId, testAttemptId);
            return cleanupOngoingCapture;
        }
    }, [testAttemptId, cameraStatus.isActive]); 
    
    // Stop the video and screen share streams when the test is done
    useEffect(() => {
        if ( (testStage !== 'test' || isFinalTimeUpRef.current) && isTestStarted) {
            stopVideoStream(videoRef);
            stopScreenShareStreams(screenStream1, setScreenStream1, screenStream2, setScreenStream2);
        }
    }, [testStage, isFinalTimeUpRef.current]);

    // SCREEN SHARE //

    const [screenShareStatus, setScreenShareStatus] = useState({ isActive: false, rationale: "" }); // Save status of screen sharing (if they accept or not), rationale if rejected
    const [screen2ShareStatus, setScreen2ShareStatus] = useState({ isActive: false, rationale: "" }); // Save status of screen sharing (if they accept or not), rationale if rejected
    const screenCanvasRef1 = useRef(null); // ref for screen number 1 to capture and save waht is on the users first screen
    const screenCanvasRef2 = useRef(null); // ref for screen number 2 to capture and save waht is on the users second screen
    
    const [screenStream1, setScreenStream1] = useState(null); // For screen 1
    const [screenStream2, setScreenStream2] = useState(null); // For screen 2 if it exists

    // Function to update the stream
    const setScreenShareStream = (stream, screenNumber) => {
        if (screenNumber === 1) {
            setScreenStream1(stream);
        } else if (screenNumber === 2) {
            setScreenStream2(stream);
        }
    };

    // Call for the screen recording streams to be captured and saved every x seconds
    const startOngoingScreenRecording = (canvasRef1, canvasRef2, screenStream1, screenStream2, candidateId, testAttemptId, getFocusWarning) => {
        let intervalId;
    
        // Function to handle capture and save
        const captureAndSave = async (canvasRef, stream, screenId) => {
            if (stream && canvasRef.current) {
                await captureScreen(stream, canvasRef); // Ensure capture is complete before saving
                saveCapture(canvasRef, candidateId, testAttemptId, screenId);
            }
        };
    
        // Immediately capture once before setting up the interval
        setTimeout(async () => {
            if (screenStream1) await captureAndSave(canvasRef1, screenStream1, 1);
            if (screenStream2) await captureAndSave(canvasRef2, screenStream2, 2);
        }, 1000); // Adjust the delay as needed
    
        // Setup for regular interval captures
        const setupInterval = () => {
            const intervalDuration = getFocusWarning() ? 15000 : 60000; // Adjust based on focusWarning
            
            intervalId = setInterval(async () => {
                if (screenStream1) await captureAndSave(canvasRef1, screenStream1, 1);
                if (screenStream2) await captureAndSave(canvasRef2, screenStream2, 2);
            }, intervalDuration);
        };
    
        setupInterval();
    
        // Cleanup function to stop the interval
        return () => clearInterval(intervalId);
    };    
    
    // Call for the screen recording to occur when testAttemptId updates (as we use this as an identifier and it coincides with the test starting)
    useEffect(() => {
        // Destructure necessary states
        const { isActive } = screenShareStatus;
        const focusWarning = globalState.focusWarning; // Assuming this is how you access focusWarning
    
        // Define a getter for focusWarning to pass into startOngoingScreenRecording
        const getFocusWarning = () => focusWarning;
    
        if (isActive && candidateId && testAttemptId) {
            // Start or adjust ongoing screen recording
            const stopOngoingRecording = startOngoingScreenRecording(
                screenCanvasRef1, 
                screenCanvasRef2, 
                screenStream1, 
                screenStream2, 
                candidateId, 
                testAttemptId, 
                getFocusWarning
            );
    
            // Adjust the recording interval when focusWarning changes
            return () => {
                stopOngoingRecording(); // Cleanup when dependencies change
            };
        }
    }, [testAttemptId, globalState.focusWarning]);     

    // FULL SCREEN //

    const [fullScreenExited, setFullScreenExited] = useState(false); // Flag when the user clicks full screen button from prompted

    // Check to see if user is in full screen mode
    function checkIsFullScreen() {
        return document.fullscreenElement != null;
    }

    // Run check if user is on full screen after they enter the test
    useEffect(() => {
        if (testStage === 'test') {
            const checkFullScreenInterval = setInterval(() => {
                const isFullScreen = checkIsFullScreen();

                // If it's determined that they are not in full screen, set fullScreenExited to true
                if (!isFullScreen) {
                    setFullScreenExited(true);
                }
            }, 30000); // 1000 milliseconds = 1 second

            // Clean up interval on component unmount
            return () => clearInterval(checkFullScreenInterval);
        }
    }, [testStage]); // Activate the check after they go full screen to see if they maintain
    
    return (
        <TestContext.Provider value={{ 
            testStage, 
            setTestStage,
            testUuid,
            setTestUuid,
            showProceedButton,
            setShowProceedButton,
            roleDetails,
            candidateId,
            testAttemptId,
            testId,
            candidateEmail,
            candidateUsername,
            accessibilityMode,
            setAccessibilityMode,
            setAccessibilityDetails,
            codeResponseId,
            setCodeResponseId,
            companyDetails,
            setCompanyDetails,
            testConfig,
            testStage,
            testSections,
            currentSection,
            progress, 
            setCurrentSection, 
            isLastSection,
            progress,
            updateTermsStatus,
            acceptTerms,
            termsErrorVisible,
            setTermsErrorVisible,
            updateUserDetails,
            updateTestStatus,
            completeIntroChat,
            activeTest,
            activeTestDetails,
            moveToNextSection,
            caseInstructions,
            caseCandidateSummary,
            setIsTestStarted,
            isTestStarted,
            setIsTestCompleted,
            isTestCompleted,
            is1MinsLeft,
            is10MinsLeft,
            is15MinsLeft,
            is20MinsLeft,
            completeTest,
            isTestInterrupted,
            setIsTestInterrupted,
            finalCode,
            setFinalCode,
            codeSubmitted,
            setCodeSubmitted,
            finalConsoleOutput,
            setFinalConsoleOutput,
            timeLeft,
            setStopTimer,
            timeAdded,
            setTimeAdded,
            sectionStartTime,
            sectionTimeLimit,
            sectionTimeUp,
            setSectionTimeUp,
            setScreenShareStatus,
            setScreen2ShareStatus,
            isPhotoCheckActive,
            setIsPhotoCheckActive,
            canvasRef,
            videoRef,
            screenCanvasRef1,
            screenCanvasRef2,
            cameraStatus,
            setCameraStatus,
            setScreenShareStream,
            retrievedGptCodeEvaluation,
            retrievedMessages,
            retrievedInstructionIndex,
            retrievedCode,
            retrievedCodeSubmitted,
            retrievedCodeActivities,
            retrievedSqlLogs,
            retrievedTimeLeft,
            retrievedSession,
            languageToRestore,
            boilerplateCode,
            initialDatabaseSchema,
            feedbackLink,
            ...devEnv,
            extensionAliveReceived,
            sendChannelMessage,
            sendFinishAttemptMessage,
        }}>
            {children}
        </TestContext.Provider>
    );
};

export const useTestContext = () => {
    return useContext(TestContext);
};