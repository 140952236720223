const freeTrialMessage = {
    title: 'Experimente grátis',
    details: [
        'Realize 10 entrevistas gratuitas',
        'IA entevistadora e availiadora',
        'Feedback personalizado',
        '100% automatizado',
        'Sem custos ou obrigações',

    ]
};

export default freeTrialMessage;
