// Columns for the report access management tables
export const getReportAccessTableColumns = (viewPermissionLevel) => [
    { key: "name", label: "Nome completo", sortable: true },
    { key: "email", label: "Email", sortable: true },
    ...(viewPermissionLevel === 'GLOBAL' ? [{ key: "companyName", label: "Empresa", sortable: true }] : []),
    { key: "testName", label: "Entrevista", sortable: true },
    { key: "dateFrom", label: "Data início", sortable: true },
    { key: "dateTo", label: "Data fim", sortable: true },
    { key: "grantingUserName", label: "Concedido por", sortable: true },
    { key: "actions", label: "Ações", sortable: false, align: 'center' }
];
