import React from "react";
import PropTypes from "prop-types";

const Logo = ({ className = "", onLoad }) => {
  const logoImagePath = "https://degrau.blob.core.windows.net/companylogos/degrau-transparent.svg";

  return (
    <img
      className={className}
      src={logoImagePath}
      alt="Logotipo da Degrau"
      onLoad={onLoad}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  onLoad: PropTypes.func,
};

export default Logo;
