// When user's screen width is too small
import React from "react";
import Logo from "../common/DegrauLogo";
import styles from "./Popup.module.css";

const ComingSoonContent = ({ onLoad, logoLoaded, ...otherProps }) => {
  return (
    <>
      <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
      <p className={styles.popupTextLarge}>Em breve.</p>
    </>
  );
};

export default ComingSoonContent;
