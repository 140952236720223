import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { contactSales, checkCompanyAccountCredit } from "../../services/companyAccountService";
import Button from "./Button";
import Popup from "../popup/Popup";
import ScreenCover from "../popup/ScreenCover";
import styles from "./FreeTrialBanner.module.css";

const FreeTrialBanner = () => {
    const { setCompanyAccountCredits, companyId, userEmail } = useAppContext();
    const navigate = useNavigate();
    // contact sales popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [email, setEmail] = useState(userEmail);
    const [mobile, setMobile] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    // Credits
    const [credits, setCredits] = useState(0);

    // check company account credit on load
    useEffect(() => {
        const checkCredit = async () => {
            const credit = await checkCompanyAccountCredit(companyId);
            if (credit > 0) {
                setCompanyAccountCredits(credit);
                setCredits(credit);
            } else {
                setCredits(0);
            }
        };
        checkCredit();
    }, []);

    const handleContactSalesClick = () => {
        setIsPopupOpen(true);
    };

    const handleContactSales = () => {
        const source = 'free-trial-banner';
        setErrorMessage("");
        setSuccessMessage("");
        const result = contactSales(email, mobile, message, source);
        if (result.error) {
            setTimeout(() => {
                setErrorMessage('Ocorreu um erro ao enviar a solicitação. Por favor, tente novamente.');
            }, 5000);
        } else {
            setSuccessMessage('Solicitação enviada com sucesso!');
            setTimeout(() => {
                setIsPopupOpen(false);
            }, 3000);
        }
    };

    return (
        <div className={styles.banner}>
            <div className={styles.bannerText}>Você está em um teste gratuito com {credits} créditos restantes. Faça o upgrade agora para avaliar mais candidatos!</div>
            <div className={styles.bannerButtonContainer}>
                <Button type="tooltip" onClick={handleContactSalesClick}>Contate-nos</Button>
            </div>
            {isPopupOpen && (
                <>
                    <ScreenCover isVisible={true} variant="blur" zIndex={11} />
                    <Popup
                        variant="ContactSales"
                        isVisible={true}
                        zIndex={12}
                        email={email}
                        setEmail={setEmail}
                        mobile={mobile}
                        setMobile={setMobile}
                        message={message}
                        setMessage={setMessage}
                        handleAction={handleContactSales}
                        setIsPopupOpen={setIsPopupOpen}
                        errorMessage={errorMessage}
                        successMessage={successMessage}
                    />
                </>
            )}
        </div>
    );
};

export default FreeTrialBanner;